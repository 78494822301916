import { Environment } from '@cv/portal-common-lib/ajax/constants';
import {
  getNews,
  addNews,
  deleteNews,
  GetNewsRequest,
  AddNewsRequest,
  DeleteNewsRequest,
  Category,
} from '@cv/portal-rts-lib/v0/information/news';

export default class NewsService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  async getNews({ accessToken, vin, folderId }: GetNews) {
    const request: GetNewsRequest = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        vin,
      },
      queryParams: {
        folderId,
      },
    };

    const response = await getNews(request);
    return { data: response.data };
  }

  async addNews({ accessToken, vin, data }: AddNews) {
    const request: AddNewsRequest = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        vin,
      },
      data,
    };

    return await addNews(request);
  }

  async deleteNews({ accessToken, vin, folderId, channelId }: DeleteNews) {
    const request: DeleteNewsRequest = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        vin,
      },
      queryParams: {
        folderId,
        channelId,
      },
    };

    return await deleteNews(request);
  }
}

export type GetNews = NewsRequest & { folderId: string };

export type AddNews = NewsRequest & { data: Category[] };

export type DeleteNews = NewsRequest & { folderId: string; channelId: string };

type NewsRequest = {
  accessToken: string;
  vin: string;
};
