export enum SPORTS {
  SPORTS_SCHEDULES = 'SPORTS_SCHEDULES',
  SPORTS_SCORES = 'SPORTS_SCORES',
  SCHEDULES = 'SCHEDULES',
  SCORES = 'SCORES',
}

export type MISLabels = {  [key: string]: string  };

type Channel = {
  channelId: string;
  channelName: string;
  channelContentType: string;
  count: number;
};

export type Folder = {
  folderId: string;
  folderName: string;
  folderContentType: string;
  channels?: Channel[];
};

export type FolderPayload = Pick<Folder, 'folderId' | 'folderName'>;

export type FoldersResponse = {
  data: Array<Folder>;
};

export type TabFolders = { [key: string]: Array<Folder> };

export type Option = {
  label: string;
  value: string;
};

export type Team = {
  key: string;
  value: string;
  channelId?: string;
};

type SportsTeamsItem = {
  name: string;
  misSportTeams: {
    misSportTeam: Team[];
  };
}

export type SportsTeams = {
  misSportGameType: SportsTeamsItem[];
};
