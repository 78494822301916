// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3QZpvFs9XpvCCiAGFYJmYZ {\n  color: var(--theme-color-header);\n  margin: 1rem 0;\n}\n\n._3QZpvFs9XpvCCiAGFYJmYZ label {\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://./src/components/LinkVehicle/CreateAccount/CreateAccount.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".form {\n  color: var(--theme-color-header);\n  margin: 1rem 0;\n}\n\n.form label {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "_3QZpvFs9XpvCCiAGFYJmYZ"
};
export default ___CSS_LOADER_EXPORT___;
