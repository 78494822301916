import { Environment } from "@cv/portal-common-lib/ajax/constants";
import { GetLocationTrackerStatusByVehicleId } from "@cv/portal-rts-lib/location-tracker";
import { GetLocationStatusTracker } from "@cv/portal-rts-lib/location-tracker/models/location-tracker";

export default class StolenVehicleLocatorService {
    private environment: Environment;
    constructor(environment = Environment.LOCAL) {
        this.environment = environment
    }
    async getSvl(
        locale: string,
        accessToken: string,
        vehicleId: string,
        idToken: string
    ) {
        const request: GetLocationStatusTracker = {
            environment: this.environment,
            headers: {
                Authorization: accessToken,
                'Accept-Language': locale,
                id_token: idToken
            },
            pathParams: {
                vehicleId,
            },
        };
        const response = await GetLocationTrackerStatusByVehicleId(request);
        return { data: response.data || {} };
    }
}
