import React from 'react';
import clsx from 'clsx';
import { NewsItem } from '@cv/portal-rts-lib/v0/information/news';
import { MISLabels } from '../types';
import ContentFlexGrid from '../ContentFlexGrid';
import TextTile from '../TextTile';
import Button from '@components/Button';

import styles from '../MobileInformationService.module.css';

export type Props = {
  labels: MISLabels;
  allCategories: (string | NewsItem)[];
  onAdd: (newCategory: string, isSelected: boolean) => void;
  onSave: () => void;
  canSave: boolean;
  deleted: string[];
};

const LeftColumn = ({ labels, allCategories, onAdd, onSave, canSave, deleted }: Props) => {
  const isSelected = (categoryName: string) =>
    allCategories.some((category) => {
      const categoryItem = (typeof category === 'string' ? category : category.channelName)?.toUpperCase();
      return categoryItem === categoryName && !deleted.includes(categoryItem);
    });

  return (
    <div className={clsx(styles['news-left-column'], 'column')}>
      <h4 className={clsx(styles['news-title'], styles['sports-intro'])}>{labels.selectYourNews}</h4>
      <ContentFlexGrid className={styles['news-grid']}>
        {[
          labels.business,
          labels.entertainment,
          labels.health,
          labels.international,
          labels.political,
          labels.sports,
          labels.strange,
          labels.technology,
          labels.countryNews,
        ].map((category) => {
          const upperCategory = category.toUpperCase();
          const selected = isSelected(upperCategory);

          return (
            <TextTile
              className={styles['category-text-tile']}
              onClick={() => onAdd(upperCategory, selected)}
              isSelected={selected}
              htmlTitle={category}
              key={category}
            >
              {category}
            </TextTile>
          );
        })}
      </ContentFlexGrid>
      <Button className={styles['bottom-btn-save']} onClick={onSave} disabled={!canSave}>
        {labels.misSave}
      </Button>
    </div>
  );
};

export default LeftColumn;
