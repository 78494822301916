import React from 'react';
import InputSelect from '@components/InputSelect';
import styles from '../MobileInformationService.module.css';
import TextTile from '@components/MobileInformationService/TextTile';
import ContentFlexGrid from '@components/MobileInformationService/ContentFlexGrid';
import Button from '@components/Button';
import { Option, Team } from '../types';

export type Props = {
  sportsIntroText: string;
  selectedSportType: string;
  selectedUpdateType: string;
  sportTypesList: Option[];
  updateTypeList: Option[];
  handleChangeSportType: (option: Option) => void;
  handleChangeUpdateType: (option: Option) => void;
  teamsList: Team[];
  selectedTeams: Team[];
  savedTeams: Team[];
  onAddTeam: (team: Team) => void;
  onRemoveTeam: (team: Team) => void;
  saveButtonText: string;
  onSave: () => void;
};

const LeftColumn = ({
  sportsIntroText,
  selectedSportType,
  selectedUpdateType,
  sportTypesList,
  updateTypeList,
  handleChangeSportType,
  handleChangeUpdateType,
  teamsList,
  selectedTeams,
  savedTeams,
  onAddTeam,
  onRemoveTeam,
  saveButtonText,
  onSave,
}: Props) => {
  const handleOnclick = (team: Team) => {
    team.value = team.value.toUpperCase();
    team.key = team.key.toUpperCase();
    if (!selectedTeams.find((selectedTeam) => selectedTeam.key === team.key)) {
      onAddTeam(team);
    } else {
      onRemoveTeam(team);
    }
  };

  return (
    <div className={styles['sports-left-wrapper']}>
      <div className={styles['sports-top']}>
        <p className={styles['sports-intro']}>{sportsIntroText}</p>
        <div className={styles['sports-selector-wrapper']}>
          <div className={styles['sports-selector']}>
            <InputSelect
              styles={{ height: 40 }}
              nativeDropdown={false}
              styleType="primary"
              options={sportTypesList}
              onChange={handleChangeSportType}
              value={selectedSportType}
            />
          </div>
          <div className={styles['sports-selector']}>
            <InputSelect
              nativeDropdown={false}
              styleType="primary"
              options={updateTypeList}
              onChange={handleChangeUpdateType}
              value={selectedUpdateType}
            />
          </div>
        </div>
      </div>

      <ContentFlexGrid>
        {teamsList.map((team) => (
          <TextTile
            className={styles['category-text-tile']}
            onClick={() => handleOnclick(team)}
            isSelected={!!selectedTeams.find((selectedTeam) => selectedTeam.key === team.key.toUpperCase())}
            key={team.key}
          >
            {team.value}
          </TextTile>
        ))}
      </ContentFlexGrid>
      <div className={styles['sports-bottom']}>
        <Button
          className={styles['bottom-btn-save']}
          onClick={onSave}
          disabled={JSON.stringify(savedTeams) === JSON.stringify(selectedTeams)}
        >
          {saveButtonText}
        </Button>
      </div>
    </div>
  );
};

export default LeftColumn;
