import Button from '@components/Button';
import { useDialog } from '@components/Dialog/useDialog';
import clsx from 'clsx';
import React from 'react';
import Markdown from '@components/Markdown';
import styles from './ModalExpandingText.module.css';

interface Props {
  minifiedText: string;
  expandedText: string;
  title: string;
  labelOk: string;
}

const ModalExpandingText = (props: Props) => {
  const { minifiedText, expandedText, title, labelOk } = props;
  const dialog = useDialog();

  return (
    <Button
      className={clsx(styles['minifiedText'])}
      onClick={() => {
        dialog({
          title,
          children: <Markdown>{expandedText}</Markdown>,
          labelOk,
        });
      }}
    >
      {minifiedText}
    </Button>
  );
};

export default ModalExpandingText;
