interface Option<V extends string> {
  label: string;
  value: V;
}

function transformToOptions<K extends string, T extends Option<K>>(options: Array<string>): Array<T> {
  return options.map((str) => {
    const [value, label] = str.split('::');
    return {
      value,
      label,
    };
  });
}

export default transformToOptions;
export { Option };
