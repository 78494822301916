import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import ContentRenderer from '@components/ContentRenderer';
import Dropdown from '@components/Dropdown';
import useToggle from '@hooks/useToggle';

import styles from './Header.module.css';
import useIsAuthorised from '@hooks/useIsAuthorised';

type HeaderUser = {
  userPrefix?: string;
  navigation?: object;
  logoutLabel?: string;
  loginLabel?: string;
  pagesToDisplayLogout?: string[];
  pagesToHideTcLinkInUserDropDown?: string[];
  pagesToHideDropdown?: string[];
  className?: string;
  theme?: string;
};

export default function HeaderUser({
  userPrefix = '',
  navigation,
  logoutLabel,
  loginLabel,
  pagesToDisplayLogout = [],
  pagesToHideTcLinkInUserDropDown = [],
  pagesToHideDropdown = [],
  className,
  theme,
}: HeaderUser) {
  const history = useHistory();
  const { account } = useSelector(({ accountReducer }) => accountReducer);
  const userLabel = `${userPrefix} ${account?.data?.userName?.givenName || ''}`;
  const isAuthenticated = useIsAuthorised();
  const location = useLocation();
  const [showLogoutLabel, setShowLogoutLabel] = useToggle(false);
  const [showTCinDropdown, setShowTCinDropdown] = useToggle(false);
  const [forceHidden, setForceHidden] = useToggle(false);

  useEffect(() => {
    setShowLogoutLabel(logoutLabel && pagesToDisplayLogout.includes(location.pathname));
    setShowTCinDropdown(!pagesToHideTcLinkInUserDropDown.includes(location.pathname));
    setForceHidden(pagesToHideDropdown.includes(location.pathname));
  }, [location]);

  if (!navigation) {
    return <div className={styles['Header-user']}>{userLabel}</div>;
  }

  const handleLogout = () => {
    history.push('/logout');
  };

  if (!isAuthenticated) {
    return (
      <Link className={styles['Header-user']} to="/login">
        {loginLabel || 'Login'}
      </Link>
    );
  }
  if (forceHidden || !userLabel) {
    return null;
  }

  return (
    <Dropdown firstItemLabel={userLabel} theme={theme} classes={{ container: className }}>
      {showTCinDropdown && (
        <ContentRenderer
          name="userMenuNavigation"
          content={[navigation]}
          className={styles['Header-dropdown-navigation']}
        />
      )}
      {showLogoutLabel && (
        <div className={styles['Header-dropdown-logout']} onClick={handleLogout}>
          {logoutLabel}
        </div>
      )}
    </Dropdown>
  );
}
