import React from 'react';

import './Loader.css';

type LoaderProps = {
  style?: Record<string, string>;
};

export default function Loader({ style }: LoaderProps): JSX.Element {
  return (
    <div className="Loader" style={style}>
      <div className="Loader-inner">Loading...</div>
    </div>
  );
}
