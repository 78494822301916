import {
  PointOfInterestState,
  PointOfInterestAction,
  VehicleLocationState,
  FavoriteState,
  SentToCarState,
  FolderState,
  RouteSummaryState,
  RouteSummary,
  MapCenterState,
  UserLocationState,
  TimerWindowState,
  MapAction,
  MapTimerValue,
  VehicleLocationClicked,
} from "./types";
import {
  GET_POINTS_OF_INTEREST,
  SET_POINTS_OF_INTEREST,
  SET_SELECTED_POINT_OF_INTEREST,
  SET_VEHICLE_LOCATION,
  SET_FAVORITES,
  REMOVE_FROM_FAVORITES,
  SET_SENT_TO_CAR,
  REMOVE_FROM_SENT_TO_CAR,
  SET_JOURNEYS,
  REMOVE_JOURNEY,
  SET_FOLDERS,
  ADD_FAVORITE,
  SET_ROUTE_SUMMARY,
  SET_MAP_CENTER,
  SET_MAP,
  SET_USER_LOCATION,
  RESET_USER_LOCATION,
  SET_TIMER_WINDOW,
  SET_MAP_TIMER_VALUE,
  SET_VEHICLE_LOCATION_CLICKED,
} from "./constants";

const pointsOfInterestState: PointOfInterestState = {
  loading: false,
  pointsOfInterest: [],
  selectedPointOfInterest: null,
};

const vehicleLocationState: VehicleLocationState = {
  location: null,
};

const favoritesState: FavoriteState = {
  favorites: [],
};

const sentToCarState: SentToCarState = {
  sentToCar: [],
};

const journeysState = {
  journeys: []
}

const foldersState: FolderState = {
  folders: []
}

const routeSummaryState: RouteSummaryState = {
  routeSummary: null
}

const mapCenterState: MapCenterState = {
  mapCenter: null
}

const mapState = {
  map: null
}

const userLocationState: UserLocationState = {
  userLocation: null
}

const timerWindowState: TimerWindowState = {
  timerWindow: false
}

const mapTimerValueState: MapTimerValue = {
  mapTimerValue: 600
}

const vehicleLocationClickedState: VehicleLocationClicked = {
  vehicleLocationClicked: false
}

export const defaultState = {
  ...pointsOfInterestState,
  ...vehicleLocationState,
  ...favoritesState,
  ...sentToCarState,
  ...journeysState,
  ...foldersState,
  ...routeSummaryState,
  ...mapCenterState,
  ...mapState,
  ...userLocationState,
  ...timerWindowState,
  ...mapTimerValueState,
  ...vehicleLocationClickedState,
};

export default (
  state = defaultState,
  action: MapAction
) => {
  switch (action.type) {
    case GET_POINTS_OF_INTEREST:
      return { ...state, loading: true };
    case SET_POINTS_OF_INTEREST:
      return {
        ...state,
        loading: false,
        pointsOfInterest: action.pointsOfInterest,
      };
    case SET_SELECTED_POINT_OF_INTEREST:
      return {
        ...state,
        selectedPointOfInterest: action.selectedPointOfInterest,
        ...(action.poiIndex && {pointsOfInterest: state.pointsOfInterest.map(
          (poi, i) => i === action.poiIndex ? {...poi, options: {icon: action.icon}} : poi
        )})
      };
    case SET_VEHICLE_LOCATION:
      return { ...state, vehicleLocation: action.vehicleLocation };
    case SET_FAVORITES:
      return { ...state, favorites: action.favorites };
    case ADD_FAVORITE:
      return { ...state, favorites: [...state.favorites, action.favorite ]}
    case REMOVE_FROM_FAVORITES:
      return {
        ...state,
        favorites: state.favorites.filter(poi => poi.id !== action.pinId) 
      };
    case SET_SENT_TO_CAR:
      return { ...state, sentToCar: action.sentToCar };
    case REMOVE_FROM_SENT_TO_CAR:
      return { ...state, sentToCar: action.sentToCar };
    case SET_JOURNEYS:
      return { ...state, journeys: action.journeys }
    case REMOVE_JOURNEY:
      return {
        ...state,
        journeys: state.journeys.filter(journey => journey.channelId !== action.channelId)
      }
    case SET_FOLDERS: 
      return { ...state, folders: action.folders}
    case SET_ROUTE_SUMMARY:
      return { ...state, routeSummary: action.routeSummary }
    case SET_MAP_CENTER:
      return { ...state, mapCenter: action.mapCenter}
    case SET_MAP:
      return {...state, map: action.map }
    case SET_USER_LOCATION:
      return { ...state, userLocation: action.userLocation }
    case RESET_USER_LOCATION: 
      return { ...state, userLocation: null }
    case SET_TIMER_WINDOW:
      return { ...state, timerWindow: action.timerWindow }
    case SET_MAP_TIMER_VALUE: 
      return { ...state, mapTimerValue: action.mapTimerValue }
    case SET_VEHICLE_LOCATION_CLICKED:
      return { ...state, vehicleLocationClicked: action.vehicleLocationClicked }
    default:
      return state;
  }
};
