// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pbE6kFRH-CHiBpjfFlV5f ul {\n  padding-left: 1rem;\n}\n\n.pbE6kFRH-CHiBpjfFlV5f li > p {\n  margin: 0;\n}\n\n._33W-GM-s2HBg6KGMNqONoO {\n  margin-top: 2rem;\n  margin-bottom: 2rem;\n}\n\n.VqeUr7WvRR5VuHN4FyAzH {\n  min-width: 150px;\n  margin: 1rem;\n}\n\n._2oaKBCzV6Y-ivhCGlp3oLG {\n  display: flex;\n  justify-content: center;\n  text-align: center;\n}\n\n._1AXxILJPxH4BEOIE0asifY {\n  max-width: 100%;\n  color: var(--theme-color-text);\n  margin: 0 auto;\n  margin-top: 3rem;\n}\n\n._1AXxILJPxH4BEOIE0asifY ._1gbpGVJjMCK0EOYuklhXC0 {\n  text-align: center;\n}\n\n@media (min-width: 1200px) {\n  ._1AXxILJPxH4BEOIE0asifY {\n    max-width: 58.33%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/ServiceOverview/ServicesOverview.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,8BAA8B;EAC9B,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".container ul {\n  padding-left: 1rem;\n}\n\n.container li > p {\n  margin: 0;\n}\n\n.body {\n  margin-top: 2rem;\n  margin-bottom: 2rem;\n}\n\n.button {\n  min-width: 150px;\n  margin: 1rem;\n}\n\n.buttonsContainer {\n  display: flex;\n  justify-content: center;\n  text-align: center;\n}\n\n.card {\n  max-width: 100%;\n  color: var(--theme-color-text);\n  margin: 0 auto;\n  margin-top: 3rem;\n}\n\n.card .title {\n  text-align: center;\n}\n\n@media (--viewport-l) {\n  .card {\n    max-width: 58.33%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "pbE6kFRH-CHiBpjfFlV5f",
	"body": "_33W-GM-s2HBg6KGMNqONoO",
	"button": "VqeUr7WvRR5VuHN4FyAzH",
	"buttonsContainer": "_2oaKBCzV6Y-ivhCGlp3oLG",
	"card": "_1AXxILJPxH4BEOIE0asifY",
	"title": "_1gbpGVJjMCK0EOYuklhXC0"
};
export default ___CSS_LOADER_EXPORT___;
