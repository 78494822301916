import React, { ReactNode } from 'react';
import clsx from 'clsx';

import styles from './MobileInformationService.module.css';

type Props = {
  className?: string;
  children: ReactNode;
};

const ContentFlexGrid = ({ className = '', children }: Props) => (
  <div className={clsx(styles['mis-content-flex-grid-container'], className)}>{children}</div>
);

export default ContentFlexGrid;
