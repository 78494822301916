import React from 'react';
import styles from './ScheduleServiceButton.module.css';
import clsx from 'clsx';
import { enterKeyPressed } from '@utils/enterKeyPressed';

type ScheduleServiceButtonProps = {
  url: string;
  label: string;
  displayViewMode: string;
  classes?: { container?: string; button?: string };
};

const ScheduleServiceButton = ({ url, label, displayViewMode, classes }: ScheduleServiceButtonProps) => {
  const isWarningViewMode = displayViewMode === 'warning';
  return (
    <div
      className={
        (clsx(styles['ScheduleServiceButtonContainer'], {
          [styles['ScheduleServiceButtonContainer--warning']]: isWarningViewMode,
        }),
        classes?.container)
      }
    >
      <div
        className={clsx(
          styles['ScheduleServiceButton'],
          {
            [styles['ScheduleServiceButton--warning']]: isWarningViewMode,
          },
          classes?.button,
        )}
        role="button"
        tabIndex={0}
        onKeyPress={(e) => enterKeyPressed(e) && window.open(url, '_blank')}
        onClick={() => window.open(url, '_blank')}
        aria-label="Schedule Service Button"
      >
        {label}
      </div>
    </div>
  );
};

export default ScheduleServiceButton;
