import React from 'react';

import { PaymentContextProvider, SubscriptionProps } from '@cv/portal-components-lib';
import { ServerAsset } from '@utils/assets';
import { useGenerateSubscriptionContent, useGenerateSubscriptionProps } from '@components/SubscriptionWrapper/utils';
import { ContentfulLabelType, findDictionary } from '@utils/labels';
import useLabels from '@hooks/useLabels';

type CreditCardInfoConnectorProps = {
  creditCardImages: ServerAsset[];
  labelCollections: ContentfulLabelType[];
};

export function CreditCardInfo({ creditCardImages, labelCollections }: CreditCardInfoConnectorProps) {
  const subHeadersDictionary = useLabels(findDictionary(labelCollections, 'subHeaders'));
  const content = useGenerateSubscriptionContent({
    labels: labelCollections,
    assets: creditCardImages,
  });

  const paymentProps: SubscriptionProps = useGenerateSubscriptionProps();

  const webContent = {
    ...content,
    commonWebContent: {
      ...content.commonWebContent,
      continueButtonLabel: content.commonWebContent.saveLabel,
      saveAndContinueButtonLabel: content.commonWebContent.saveLabel,
    },
    titles: subHeadersDictionary.getAllPrimaries(),
  };

  return <PaymentContextProvider content={webContent} subscriptionProps={paymentProps} />;
}

export default CreditCardInfo;
