import ContentfulComponent from '@customTypes/ContentfulComponent';
import { ComponentMapping } from '@components/component-mappings';
import { aggregateContent, ContentfulComponentWithContent, getContentTypes } from './contentful';
import { Filter } from '@hooks/useFilterByMake';
import { BrandNamesFilterGroup } from '@customTypes/BrandNamesFilterGroup';
import BrandModelFilter from '@customTypes/BrandModelFilter';
import RuleType from '@customTypes/password/RuleType';
import filterByMake from '@utils/filterByMake';

export type ServerLabel = {
  name: string;
  componentType: ComponentMapping;
  labelKey: string;
  labelValue?: string;
  additionalLabelValue?: string;
  models?: BrandModelFilter;
  analyticsEventName?: string;
  targetTestName?: string;
  carMake?: string;
  ruleType?: RuleType;
};

export type Label = {
  primary: string;
  secondary?: string;
  models?: BrandModelFilter;
  analyticsEventName?: string;
};

export type LabelObject = { [key: string]: Label };
export type LabelDictionary = {
  labels: LabelObject;
  filterGroup: BrandNamesFilterGroup;
};
export type OnlyLabels = Omit<LabelDictionary, 'filterGroup'>;

export type ContentfulContent = {
  content: ServerLabel[];
};

export type ContentfulLabelType = ContentfulComponent & ContentfulContent;

export const getDictionaries = (components: ContentfulComponent[] = []) => {
  return getContentTypes(components, 'portalLabelDictionary') as ContentfulComponentWithContent<ServerLabel>[];
};

export const getDictionariesContent = (components: ContentfulComponent[] = []) => {
  const dictionaries = getDictionaries(components);
  return aggregateContent(dictionaries);
};

export const findDictionary = (dictionaries: ContentfulLabelType[], key: string) => {
  return dictionaries.find((lbl) => lbl.dictionaryKey === key)?.content || [];
};

export const generateLabelsObjects = (labels: ServerLabel[] = []) => {
  return labels.reduce((acc: LabelObject, label: ServerLabel) => {
    const { labelValue, labelKey, additionalLabelValue, analyticsEventName, models } = label;

    let values;
    if (labelValue && additionalLabelValue) {
      values = {
        primary: labelValue,
        secondary: additionalLabelValue,
        models,
        analyticsEventName,
      };
    } else {
      values = {
        primary: labelValue || additionalLabelValue || '',
        models,
        analyticsEventName,
      };
    }

    return {
      ...acc,
      [labelKey]: values,
    };
  }, {} as LabelObject);
};

export const filterLabels: Filter<LabelObject> = ({ content: allLabels, makeName, filterGroup }) => {
  const content = Object.entries(allLabels).map(([name, value]) => ({ name, value }));
  const filteredLabels = filterByMake({ content, makeName, filterGroup });
  const labels = cleanUpNames(filteredLabels, makeName);
  return labels.reduce<LabelObject>((obj, { name, value }) => ({ ...obj, [name]: value }), {});
};

function cleanUpNames(filteredLabels: { name: string; value: Label }[], makeName: string) {
  return filteredLabels.map(({ name, ...others }) => {
    const cleanName = name.includes(makeName) ? name.slice(0, name.indexOf(makeName)).trim() : name;
    return { ...others, name: cleanName };
  });
}
