// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3xhOkfAK5ExBvEPBJp1dxI {\n  display: flex;\n  flex-flow: column;\n}\n\n._45pTfoYM_ip-z9X6UwYl6 {\n  background-color: var(--theme-cancel-subscription-button-background-color-primary);\n  color: var(--theme-cancel-subscription-button-text-color-primary);\n  margin-top: 20px;\n}\n\n.owzwraGaHphpmeEvsi4r- {\n  background-color: var(--theme-cancel-subscription-button-background-color-secondary);\n  border: none;\n  color: var(--theme-cancel-subscription-button-text-color-secondary);\n  cursor: pointer;\n  font-size: smaller;\n  margin-top: 21px;\n  outline: 0;\n  padding: 0;\n  text-decoration: underline;\n}\n", "",{"version":3,"sources":["webpack://./src/components/SubscriptionManagementButtons/SubscriptionManagementButtons.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,kFAAkF;EAClF,iEAAiE;EACjE,gBAAgB;AAClB;;AAEA;EACE,oFAAoF;EACpF,YAAY;EACZ,mEAAmE;EACnE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,UAAU;EACV,UAAU;EACV,0BAA0B;AAC5B","sourcesContent":[".container {\n  display: flex;\n  flex-flow: column;\n}\n\n.main-button {\n  background-color: var(--theme-cancel-subscription-button-background-color-primary);\n  color: var(--theme-cancel-subscription-button-text-color-primary);\n  margin-top: 20px;\n}\n\n.cancel-button {\n  background-color: var(--theme-cancel-subscription-button-background-color-secondary);\n  border: none;\n  color: var(--theme-cancel-subscription-button-text-color-secondary);\n  cursor: pointer;\n  font-size: smaller;\n  margin-top: 21px;\n  outline: 0;\n  padding: 0;\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_3xhOkfAK5ExBvEPBJp1dxI",
	"main-button": "_45pTfoYM_ip-z9X6UwYl6",
	"cancel-button": "owzwraGaHphpmeEvsi4r-"
};
export default ___CSS_LOADER_EXPORT___;
