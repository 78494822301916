import React from "react";
import clsx from "clsx";
import styles from "../Field.module.css";

const FieldWrapper = ({ children, className }: any) => {
  return (
    <div className={clsx(styles["field-wrapper"], className)}>{children}</div>
  );
};

export default FieldWrapper;
