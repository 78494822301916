import React from "react";
import clsx from "clsx";
import styles from "../Field.module.css";

type HelpTextContainer = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  error: boolean;
};

const HelpTextContainer = ({
  children,
  className,
  error = false,
  ...rest
}: any) => {
  return (
    <div
      className={clsx(className, styles["help-text"], {
        [styles["error"]]: error,
      })}
      {...rest}
    >
      {children}
    </div>
  );
};

export default HelpTextContainer;
