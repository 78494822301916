import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Link from '@components/Link';
import { useNavigation } from '@components/Navigation';
import { Path } from '@customTypes/Navigation';
import { BrandNamesFilterGroup } from '@customTypes/BrandNamesFilterGroup';
import styles from './Navigation.module.css';
// footerStyles added to have correct styles nesting in sit (we change classNames to hashes in prod build)
import footerStyles from '@components/Footer/Footer.module.css';
import linkStyles from '@components/Link/Link.module.css';
import { Links } from '@customTypes/Navigation';
import { ACTIVE, STOLEN_VEHICLE } from '@components/Header/constant';
import clsx from 'clsx';
import Dropdown from '@components/Dropdown';
import useMediaQuery from '@hooks/useMediaQuery';
import useIsAuthorised from '@hooks/useIsAuthorised';

type NavigationLabel = {
  labelKey: string;
  labelValue: string;
};
export type NavigationProps = {
  content?: Links;
  name: string;
  navigationAlignment?: 'left' | 'right' | 'center' | 'justify';
  orientation?: 'horizontal' | 'vertical';
  className?: string;
  isNavigationMachine?: boolean;
  stateMachineLabels?: NavigationLabel[];
  hiddenTabsPageList?: string[];
  hideTabsOnNoVehicleDataPages?: string[];
  linksFilter?: BrandNamesFilterGroup;
};

const getTitle = (path: Path) => {
  return (path.label || path.page).toUpperCase();
};

const Navigation = ({
  content,
  navigationAlignment = 'left',
  orientation = 'horizontal',
  className = '',
  isNavigationMachine = false,
  hiddenTabsPageList = [],
  hideTabsOnNoVehicleDataPages = [],
}: NavigationProps) => {
  const isMobileView = useMediaQuery('(max-width: 991px)');
  const { paths, currentPath } = useNavigation();
  const listAlignmentClass = styles[`Navigation-list--${navigationAlignment}`];
  const listOrientationClass = orientation === 'vertical' ? styles['Navigation-list--vertical'] : '';
  const { vehicle } = useSelector(({ vehicleReducer }) => vehicleReducer);
  const { svl } = useSelector(({ stolenVehicleReducer }) => stolenVehicleReducer);
  const [showHeaderNav, setShowHeaderNav] = useState<boolean>(false);
  const location = useLocation();
  const isAuthorised = useIsAuthorised();

  useEffect(() => {
    setShowHeaderNav(
      !(
        hiddenTabsPageList?.includes(location.pathname) ||
        (hideTabsOnNoVehicleDataPages?.includes(location.pathname) && !vehicle?.vin)
      ),
    );
  }, [location]);

  const renderLink = (path: Path, index: number) => {
    const { subPages, label, page, disabled, url, hiddenInNavigation, authorised } = path;
    const isStolenVehicle = svl?.status === ACTIVE && svl?.type === STOLEN_VEHICLE && label !== 'PROFILE';

    if (isStolenVehicle || !showHeaderNav || hiddenInNavigation || isAuthorised !== !!authorised) {
      return null;
    }

    if (subPages) {
      return (
        <li
          className={clsx(
            styles['Navigation-item'],
            styles['Navigation-item-dropdown'],
            styles['Navigation-item-dropdown-block'],
          )}
          key={`${label}${index}`}
        >
          <Dropdown
            firstItemLabel={getTitle(path)}
            classes={{
              button: clsx(linkStyles.Link, {
                [linkStyles['Link--active']]: subPages?.some((innerPage) => innerPage === currentPath),
              }),
            }}
            isInline={isMobileView}
          >
            <ul className={clsx(styles['Navigation-list'], listAlignmentClass, styles['Navigation-list--vertical'])}>
              {subPages.map(renderLink)}
            </ul>
          </Dropdown>
        </li>
      );
    }

    return (
      <li className={clsx(styles['Navigation-item'], { [styles['disabled']]: disabled })} key={`${page}${index}`}>
        <NavLink
          className={linkStyles.Link}
          activeClassName={disabled ? undefined : linkStyles['Link--active']}
          to={url || ''}
        >
          {getTitle(path)}
        </NavLink>
      </li>
    );
  };
  return (
    <nav className={clsx(styles.Navigation, className, footerStyles['Navigation-block'])}>
      <ul className={clsx(styles['Navigation-list'], listAlignmentClass, listOrientationClass)}>
        {isNavigationMachine
          ? paths.map(renderLink)
          : content?.map((item) => (
              <li className={styles['Navigation-item']} key={item.name}>
                <Link {...item} />
              </li>
            ))}
      </ul>
    </nav>
  );
};
export default Navigation;
