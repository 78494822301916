import config from '@config/config';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { userRoleLink } from '@cv/portal-idm-lib/user/user-role';
import { userRoleUrls } from '@cv/portal-idm-lib/user/user-role/enums';
import { UserRoleLink } from '@cv/portal-idm-lib/user/user-role/models';
import { formatUserData } from '../formatters/';

type UserLinkRoleArgs = {
  userId: string;
  roleId?: string;
  tenantId: string;
  accessToken: string;
  locale: string;
};

const defaultRoleId = config.getOemValue('DEFAULT_USER_ROLE_ID');

export default class AccountLinkService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  userRoleLink({ userId, roleId = defaultRoleId, tenantId, accessToken, locale }: UserLinkRoleArgs) {
    const payload: UserRoleLink = {
      pathParams: { userId, roleId },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        'Accept-Language': locale,
      },
    };

    const idmApiProxyBaseUrl = config.get<string>('IDM_API_PROXY_BASE_URL');
    if (idmApiProxyBaseUrl) {
      const { userRoleLink } = userRoleUrls;
      const path = userRoleLink.replace('{userId}', userId).replace('{roleId}', roleId);

      const url = new URL(path, idmApiProxyBaseUrl);
      payload.url = url.toString();

      payload.headers['CV-Tenant-Id'] = tenantId;
    }

    return userRoleLink(payload).then((response) => ({ data: formatUserData(response.data) }));
  }
}
