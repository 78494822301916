import React from 'react';

import ContentRenderer from '@components/ContentRenderer';
import ContentfulComponent from '@customTypes/ContentfulComponent';

import './OneColumnSection.css';

type OneColumnSectionProps = {
  content: Array<ContentfulComponent>;
  name: string;
}

function OneColumnSection({content, name}: OneColumnSectionProps) {
  return (
    <div className="Layout-container OneColumn-container">
      <div className="column">
        <ContentRenderer name={name} content={content} />
      </div>
    </div>
  );
}

export default OneColumnSection;
