import React from 'react';
import { ModelConnector } from '@components/ApiConnectorHOCs/';
import Carousel, { CarouselProps } from './Carousel';

import 'pure-react-carousel/dist/react-carousel.es.css';

type CarouselConnectorProps = CarouselProps & {
  apiConnector: {
    api: string;
    errorLabel?: string;
  };
};

function CarouselConnector(props: CarouselConnectorProps) {
  if (props.apiConnector) {
    const CarouselWithData = ModelConnector(Carousel);
    return <CarouselWithData {...props} />;
  }
  return <Carousel {...props} />;
}

export default CarouselConnector;
