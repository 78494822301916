import set from 'lodash/set';
import config from '@config/config';

import { APIRequest } from '@cv/portal-common-lib/ajax/models';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { HttpMethodEnum } from '@cv/portal-common-lib/ajax/constants';
import { callHttpRequestMethod } from '@cv/portal-common-lib/utils';

import { AddVinData } from '../types';

type AddVinProps = {
  data: AddVinData;
  userId: string;
  locale: string;
  tenantId: string;
};

type RemoveVinProps = {
  vin: string;
  userId: string;
  locale: string;
  tenantId: string;
};

export default class VinService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  async addVin({ data, tenantId, locale, userId }: AddVinProps) {
    const request: APIRequest = {
      data: { ...data, userId },
      environment: this.environment,
      method: HttpMethodEnum.POST,
      headers: {
        'Accept-Language': locale,
        'CV-Tenant-Id': tenantId,
      },
    };

    const idmApiProxyBaseUrl = config.get<string>('IDM_API_PROXY_BASE_URL');

    if (!idmApiProxyBaseUrl) {
      return Promise.reject(new Error('Proxy base url not configured'));
    }

    const path = '/v1/user/enroll';
    const url = new URL(path, idmApiProxyBaseUrl);
    const payload = set(request, 'url', url.toString());

    return callHttpRequestMethod(payload);
  }

  async removeVin({ vin, userId, tenantId, locale }: RemoveVinProps) {
    const request: APIRequest = {
      environment: this.environment,
      method: HttpMethodEnum.DELETE,
      headers: {
        'Accept-Language': locale,
        'CV-Tenant-Id': tenantId,
      },
    };

    const idmApiProxyBaseUrl = config.get<string>('IDM_API_PROXY_BASE_URL');

    if (!idmApiProxyBaseUrl) {
      return Promise.reject(new Error('Proxy base url not configured'));
    }

    const path = `/v1/user/${userId}/vin/${vin}`;
    const url = new URL(path, idmApiProxyBaseUrl);
    const payload = set(request, 'url', url.toString());

    return callHttpRequestMethod(payload);
  }
}
