import React from 'react';
import clsx from 'clsx';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { useTheme } from '@components/Theme';
import styles from './FavoriteButton.module.css';

type FavoriteButtonProps = {
  onClickHandler: () => void;
  icon: {
    variant: 'filled' | 'outlined';
    outlineColor?: string;
    fillColor?: string;
    size?: string;
  };
};

const FavoriteButton = ({ onClickHandler, icon }: FavoriteButtonProps) => {
  const theme = useTheme();
  const {
    variant,
    outlineColor = theme.get('color-grey-c1'),
    fillColor = theme.get('color-primary'),
    size = '20px',
  } = icon;

  return (
    <button
      onClick={onClickHandler}
      className={clsx(styles['favorite-button'])}
      style={{
        cursor: 'pointer',
      }}
    >
      {variant === 'filled' ? (
        <FaHeart size={size} color={fillColor} />
      ) : (
        <FaRegHeart size={size} color={outlineColor} />
      )}
    </button>
  );
};

export default FavoriteButton;
