import React, { useEffect, useState, useMemo, useCallback } from 'react';
import WarningBanner, { CustomWarning, WarningBannerProps } from './WarningBanner';
import { useApi } from '@api';
import { useDispatch, useSelector } from 'react-redux';
import { get } from '@components/GlobalPreferences';
import {
  getAuthNotificationStatus,
  AUTH_NOTIFICATION_STATUSES,
  OtpFormConnector,
  ContentfulComponent,
} from '@cv/portal-components-lib';
import { UserDataFormatter } from '@api/formatters/personalData';
import { WarningModal } from '@components/VehicleCondition/VehicleConditionItem';
import { ContentfulLabelType, findDictionary } from '@utils/labels';
import ModalContainer from '@components/ModalContainer/ModalContainer';
import useLabels from '@hooks/useLabels';
import { SETTINGS_ACTIONS } from '@redux/actions';
import { ServerAsset, generateAssetsObjects } from '@utils/assets';
import vcStyles from '../../VehicleCondition/VehicleCondition.module.css';
import styles from './WarningBanner.module.css';
import { AuthForm } from './CustomWarnings';
import { ContentfulComponentWithContent } from '@utils/contentful';
import config from '@config/config';
import checkWarningStatus from '@utils/warning-status-checker';

type WarningBannerConnectorProps = WarningBannerProps & {
  content: Array<ContentfulComponent>;
  labelCollections: ContentfulLabelType[];
  assets: ServerAsset[];
  existingMilTypes?: string[];
};

const WarningBannerConnector = ({
  content,
  labelCollections,
  assets,
  existingMilTypes,
  ...warningBanner
}: WarningBannerConnectorProps) => {
  //STANDARD WARNINGS

  const { data: vehicleStatus } = useSelector(({ vehicleReducer }) => vehicleReducer.vehicleHealth);
  const standardWarnings = (): ContentfulComponentWithContent<ContentfulComponent[]> => {
    const keysToShow = vehicleStatus?.mil.reduce((result: string[], item) => {
      if (item.status === '1') result.push(item.type);
      return result;
    }, []);

    return content.map((item: ContentfulComponentWithContent<ContentfulComponent[]>) => {
      const content = item.content.filter((item) => keysToShow.includes(item.apiKey));
      return { ...item, content: content };
    });
  };

  const standardWarningsLength = checkWarningStatus(vehicleStatus?.mil, existingMilTypes);

  //CUSTOM WARNINGS
  const [customWarnings, setCustomWarnings] = useState<CustomWarning[]>([]);

  const updateCustomWarnings = (data: CustomWarning, remove?: boolean): void => {
    setCustomWarnings((state) => {
      const oldState = state;
      const idx = oldState.findIndex((warning) => warning?.id === data.id);

      if (idx >= 0) {
        if (remove) {
          oldState.splice(idx, 1);
          if (state !== oldState) {
            return [...oldState];
          }
          return state;
        }
        oldState.splice(idx, 1, data);
        if (state !== oldState) {
          return [...oldState];
        }
        return state;
      }
      if (remove) {
        if (state !== oldState) {
          return [...oldState];
        }
        return state;
      }
      if (state !== [...oldState, data]) {
        return [...oldState, data];
      }
      return state;
    });
  };

  //AUTH_NOTIFICATION_WARNING

  const isAuthCodeFunctionality = get('isAuthCodeFunctionality') as unknown as boolean;
  const { account } = useSelector(({ accountReducer }) => accountReducer) as { account: UserDataFormatter };
  const api = useApi();
  const dispatch = useDispatch();
  const [authIsSuccess, setAuthIsSuccess] = useState(false);
  const { isAuthCodeVerified } = useSelector(({ settingsReducer }: RootState) => settingsReducer);
  const authCodeFormDictionary = useLabels(findDictionary(labelCollections || [], 'auth-code-form'));
  const authCodeFormContent = authCodeFormDictionary.getAll();
  const vehicleId = api.storeService.getVehicleData().id;

  const subscriptionProps = useMemo(
    () => ({
      tenantId: api.storeService.getTenantId(),
      accessToken: api.storeService.getAccessToken(),
      config,
      userDetails: { userId: api.storeService.getUserId() },
      vehicleDetails: { vin: api.storeService.getVehicleData().vin },
    }),
    [api],
  );

  const authWarningModal = useCallback(
    (isConfirmationModalVisible, toggleConfirmationModal, isAuthStatusError) => (
      <ModalContainer
        show={isConfirmationModalVisible}
        header={{ text: '', position: 'center', showTopBorder: false }}
        onCloseHandler={() => toggleConfirmationModal(false)}
        classes={{
          header: vcStyles['WarningModal-header'],
          body: styles['WarningModal-body'],
          dialog: styles['WarningModal'],
        }}
      >
        <OtpFormConnector
          subscriptionProps={subscriptionProps}
          authCodeFormContent={authCodeFormContent}
          assets={generateAssetsObjects(assets)}
          onSuccessValidation={() => {
            setAuthIsSuccess(true);
          }}
          classes={{ form: styles.OtpForm }}
          isAuthStatusError={isAuthStatusError}
        />
      </ModalContainer>
    ),
    [assets, authCodeFormContent, subscriptionProps],
  );

  const authWarningErrorModal = useCallback(
    (isConfirmationModalVisible, toggleConfirmationModal) => (
      <WarningModal
        label={authCodeFormContent?.otpErrorTitle?.primary}
        description={authCodeFormContent?.otpErrorMessage?.primary}
        {...{
          isConfirmationModalVisible,
          toggleConfirmationModal,
        }}
      />
    ),
    [authCodeFormContent],
  );

  const warningPendingObj: CustomWarning = useMemo(
    () => ({
      id: 'AUTHORIZATION_WARNING',
      content: {
        statusWarningLabel: authCodeFormContent?.otpStatusPending?.primary || '',
        label: authCodeFormContent?.otpStatusPending?.primary || '',
        moreLabel: authCodeFormContent?.authorizeNow?.primary || '',
        warningDescription: authCodeFormContent?.bodyHeadUnit?.primary || '',
        modal: authWarningModal,
      },
      renderMethod: (props) => <AuthForm commonWarningIcon={warningBanner.commonWarningIcon} {...props} />,
    }),
    [],
  );

  const warningErrorObj: CustomWarning = useMemo(
    () => ({
      id: 'AUTHORIZATION_WARNING',
      content: {
        statusWarningLabel: authCodeFormContent?.otpErrorTitle?.primary || '',
        label: authCodeFormContent?.otpErrorTitle?.primary || '',
        warningDescription: authCodeFormContent?.otpErrorMessage?.primary || '',
        modal: authWarningModal,
        isAuthStatusError: true,
      },
      renderMethod: (props) => <AuthForm commonWarningIcon={warningBanner.commonWarningIcon} {...props} />,
    }),
    [],
  );

  useEffect(() => {
    if (authIsSuccess) {
      const timer = setTimeout(() => updateCustomWarnings(warningPendingObj, true), 3000);
      dispatch({ type: SETTINGS_ACTIONS.SET_SUCCESS_AUTH_STATUS, data: true });
      return () => clearTimeout(timer);
    }
  }, [authIsSuccess, dispatch, warningPendingObj]);

  useEffect(() => {
    if (!isAuthCodeVerified) {
      const vehicles = account?.data?.vehicles || [];
      const vehiclesData = vehicles.find(({ id }) => id === vehicleId);
      const authWarningStatus = getAuthNotificationStatus(vehiclesData, isAuthCodeFunctionality);
      if (authWarningStatus === AUTH_NOTIFICATION_STATUSES.SHOW) {
        updateCustomWarnings(warningPendingObj);
      }
      if (authWarningStatus === AUTH_NOTIFICATION_STATUSES.ERROR) {
        updateCustomWarnings(warningErrorObj);
      }
      if (authWarningStatus === AUTH_NOTIFICATION_STATUSES.HIDE) {
        updateCustomWarnings(warningPendingObj, true);
      }
    }
  }, [isAuthCodeFunctionality, account, isAuthCodeVerified, vehicleId, warningErrorObj, warningPendingObj]);

  const warningsAmount = standardWarningsLength + customWarnings.length;

  if (!warningsAmount) {
    return null;
  }

  const singleWarningContent = standardWarningsLength ? standardWarnings()[0].content[0] : customWarnings[0]?.content;

  return (
    <WarningBanner
      {...warningBanner}
      warningsAmount={warningsAmount}
      standardWarnings={standardWarnings()}
      customWarnings={customWarnings}
      singleWarningContent={singleWarningContent}
    />
  );
};

export default WarningBannerConnector;
