import React from 'react';
import * as yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';

import { FormikInputField } from '@components/FormikFields';
import Button from '@components/Button';
import Widget from '@components/Widget/Widget';

import styles from '@components/ResetPassword/ResetPassword.module.css';

interface UpdatePinForm {
  [index: string]: string;
}

type UpdatePasswordVerificationProps = {
  isDisabled: boolean;
  onValidation: (payload: any, setFieldError: (fieldName: string, message: string) => void) => void;
  labels: Record<string, string>;
};

const validationSchema = yup.object().shape({
  surname: yup
    .string()
    .trim()
    .required('Debe ingresar un Apellido Paterno')
    .min(2, 'El apellido paterno debe tener al menos 2 caracteres alfanuméricos.')
    .matches(/^([a-zA-Z\- '0-9]*)$/, "Solo se permite usar letras, espacios, apóstrofo (') y guion (-)."),
  surname2: yup
    .string()
    .trim()
    .required('Debe ingresar un Apellido Materno')
    .min(2, 'El apellido materno debe tener al menos 2 caracteres alfanuméricos.')
    .matches(/^([a-zA-Z\- '0-9]*)$/, "Solo se permite usar letras, espacios, apóstrofo (') y guion (-)."),
});

const UpdatePasswordVerification = ({ isDisabled, onValidation, labels }: UpdatePasswordVerificationProps) => {
  const initialValues = {
    surname: '',
    surname2: '',
  };

  const onSubmit = (
    values: UpdatePinForm,
    setFieldError: (fieldName: string, message: string) => void,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    onValidation(values, setFieldError);
    setSubmitting(false);
  };

  return (
    <Widget title={labels.verificationTitle} titleAlignment="center">
      <p className={styles['forget-password-description']}>
        {labels.verificationDescription}
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setFieldError, setSubmitting }) => {
          onSubmit(values, setFieldError, setSubmitting);
        }}
      >
        {(props: FormikProps<UpdatePinForm>) => (
          <Form onSubmit={props.handleSubmit}>
            <div className={styles['reset-field']}>
              <FormikInputField name="surname" label="Apellido Paterno" disabled={isDisabled} />
            </div>
            <div className={styles['reset-field']}>
              <FormikInputField name="surname2" label="Apellido Materno" disabled={isDisabled} />
            </div>
            <div className={styles['actions']}>
              <Button
                variant="filled"
                className={styles['button']}
                disabled={props.isSubmitting || isDisabled}
                onClick={props.submitForm}
              >
                CONTINUAR
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Widget>
  );
};

export default UpdatePasswordVerification;
