import React from 'react';
import { FaRegEnvelope, FaCheckCircle } from 'react-icons/fa';

import Button from '@components/Button';
import Widget from '@components/Widget/Widget';

import styles from './ResetPassword.module.css';

type ResetPasswordEmailResendProps = {
  labels: Record<string, string>;
  onLogin: () => void;
  onResend: () => void;
};

const ResetPasswordEmailResend = ({ labels, onLogin, onResend }: ResetPasswordEmailResendProps) => {
  const renderAvatar = () => (
    <div className={styles['reset-avatar']}>
      <FaRegEnvelope />
      <span className={styles['reset-avatar-check']}>
        <FaCheckCircle />
      </span>
    </div>
  );

  return (
    <Widget avatar={renderAvatar()} title={labels.title} titleAlignment="center">
      <p className={styles['forget-password-description']}>{labels.emailSent}</p>
      <div className={styles.actions}>
        <Button variant="outlined" className={styles.button} onClick={onResend}>
          {labels.resendEmail}
        </Button>
        <Button variant="filled" className={styles.button} onClick={onLogin}>
          {labels.login}
        </Button>
      </div>
    </Widget>
  );
};

export default ResetPasswordEmailResend;
