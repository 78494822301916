import React from 'react';

import RichText, { RichTextContainerProps } from '@components/RichTextContainer';
import Button from '@components/Button';

import styles from './ServicesOverview.module.css';
import clsx from 'clsx';

type ServicesOverviewProps = {
  labelTitle?: React.ReactNode;
  labelContinue: string;
  labelCancel?: string;
  content: RichTextContainerProps;
  onContinue: () => void;
  onCancel?: () => void;
  classes?: {
    title?: string;
    container?: string;
    content?: string;
    buttonsContainer?: string;
    cancelButton?: string;
    continueButton?: string;
  }
};

const ServicesOverview = ({
  labelTitle,
  labelContinue,
  labelCancel,
  content,
  onContinue,
  onCancel,
  classes = {}
}: ServicesOverviewProps) => {
  return (
    <div className={clsx(styles.container, classes?.container)}>
      {labelTitle && <h1 className={clsx(classes?.title)}>{labelTitle}</h1>}
      <div className={clsx(styles.body, classes?.content)}>{content && <RichText {...content} />}</div>
      <div className={clsx(styles.buttonsContainer, classes?.buttonsContainer)}>
        {onCancel && labelCancel && (
          <Button variant="outlined" onClick={onCancel} className={clsx(styles.button, classes?.cancelButton)}>
            {labelCancel}
          </Button>
        )}
        <Button variant="filled" onClick={onContinue} className={clsx(styles.button, classes?.continueButton)}>
          {labelContinue}
        </Button>
      </div>
    </div>
  );
};

export default ServicesOverview;
