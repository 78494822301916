import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { passwordUrls } from '@cv/portal-idm-lib/password/enums';
import { updatePassword } from '@cv/portal-idm-lib/password';
import { GetPasswordRequest, UpdatePasswordPayload, UpdatePasswordRequest } from '@cv/portal-idm-lib/password/models';
import config from '@config/config';

type UpdatePasswordProps = {
  data: UpdatePasswordPayload | {};
  userId: string;
  accessToken: string;
  tenantId: string;
};

// In order to reset the password and send the email it is required to send an empty object as 'data'.
type ResetPasswordRequest = Omit<UpdatePasswordRequest, 'data'> & { data: UpdatePasswordPayload | {} };

export default class PasswordService {
  private payload: GetPasswordRequest;

  constructor(environment = Environment.LOCAL) {
    this.payload = {
      environment,
      pathParams: {
        userId: '',
      },
      headers: {
        Authorization: 'token',
      },
    };
  }

  updatePassword({ data, userId, accessToken, tenantId }: UpdatePasswordProps) {
    const request: ResetPasswordRequest = {
      pathParams: {
        userId,
      },
      headers: {
        Authorization: accessToken,
      },
      data,
    };

    const idmApiProxyBaseUrl = config.get<string>('IDM_API_PROXY_BASE_URL');
    if (idmApiProxyBaseUrl) {
      const { password } = passwordUrls;
      const urlIdSuffix = '{_id}';
      const passwordUrl = password.replace(urlIdSuffix, userId);

      const url = new URL(passwordUrl, idmApiProxyBaseUrl);
      request.url = url.toString();

      request.headers['CV-Tenant-Id'] = tenantId;
    }

    return updatePassword(request);
  }
}
