import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { useAnalytics } from '@cv/webframework-react-components';
import styles from './Tab.module.css';
import { isServiceSubscribed } from '@utils/checkIsServiceSubscribed';

export type TabItemProps = {
  activeTab: 'none' | string;
  label: string;
  onClick: (label: string) => void;
  tabType: string;
  children: React.ReactNode;
  ['data-label']: string;
  className: string;
  tabItemContent: Array<object>;
  serviceTags: string[];
  analyticsEventName: string;
};

const TabItem = ({
  activeTab = 'none',
  label = '',
  onClick = () => {},
  tabType = 'primary',
  className,
  serviceTags,
  analyticsEventName,
}: Partial<TabItemProps>) => {
  const { vehicle } = useSelector(({ vehicleReducer }) => vehicleReducer);
  const { trackEvent } = useAnalytics();
  if (serviceTags && !isServiceSubscribed(serviceTags, vehicle?.activeServices)) {
    return null;
  }
  const labelLowerCase = label.toLowerCase();
  const itemClassName = clsx(
    styles.TabItem,
    styles[`TabItem--${tabType}`],
    { [styles['TabItem--active']]: labelLowerCase === activeTab },
    className,
  );

  return (
    <li className={styles.TabListItem}>
      <button
        className={itemClassName}
        onClick={() => {
          if (analyticsEventName) trackEvent(analyticsEventName);
          onClick(labelLowerCase);
        }}
      >
        {label}
      </button>
    </li>
  );
};

export default TabItem;
