import React from 'react';
import * as yup from 'yup';
import { Formik, Form, FormikProps } from 'formik';

import Button from '@components/Button';
import { FormikInputField } from '@components/FormikFields';
import { useAnalytics } from '@cv/webframework-react-components';

import styles from './Forms.module.css';
import stylesInput from '@components/Input/Input.module.css';

type Values = {
  [key: string]: string;
};

type BoundaryFormProps = {
  label?: string;
  placeholder?: string;
  onFormClose?: () => void;
  onFormConfirm?: (values: Values) => void;
  initialValues: Values;
  errorMessage: string;
  cancelButtonLabel: string;
  confirmButtonLabel: string;
  innerElements?: () => void;
};

export default function BoundaryForm({
  label = '',
  placeholder,
  onFormClose,
  onFormConfirm,
  initialValues,
  errorMessage,
  cancelButtonLabel,
  confirmButtonLabel,
  innerElements,
}: BoundaryFormProps) {
  const { trackEvent } = useAnalytics();

  const validationSchema = yup.object().shape({
    search: yup.string().trim().required(errorMessage),
  });

  return (
    <Formik
      key={label}
      initialValues={initialValues}
      onSubmit={(values) => {
        trackEvent('ServicesMonitoringAlerts::ManageAlerts::Boundary::Add::SEARCH-clicked');
        onFormConfirm && onFormConfirm(values);
      }}
      validationSchema={validationSchema}
    >
      {(props: FormikProps<Values>) => (
        <Form onSubmit={props.handleSubmit} className={styles['form-boundary']}>
          <div className={styles['form-boundary-field']}>
            <FormikInputField
              name={'search'}
              label={label}
              placeholder={placeholder}
              className={stylesInput['input-dark']}
              classes={{
                label: styles['search-input-label'],
              }}
              onFocus={() =>
                trackEvent('ServicesMonitoringAlerts::ManageAlerts::Boundary::Add::BoundaryLocation-Clicked')
              }
            />
          </div>
          {innerElements && innerElements()}

          <div className={styles['button-container']}>
            <Button
              variant="outlined"
              onClick={() => {
                trackEvent('ServicesMonitoringAlerts::ManageAlerts::Boundary::Add::CANCEL-clicked');
                if (onFormClose) onFormClose();
              }}
            >
              {cancelButtonLabel}
            </Button>
            <Button variant="filled">{confirmButtonLabel}</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
