import React from 'react';
import { useHistory } from 'react-router-dom';
import { formatAnalyticsEventName } from '@cv/portal-common-lib';
import useLinkConfig from '@hooks/useLinkConfig';
import LinkButton from './LinkButton';

import styles from './Link.module.css';

export type LinkProps = {
  name: string;
  label: string;
  url: string;
  newTab?: boolean;
  navigationLink?: boolean;
  showAsButton?: boolean;
  linkConfig?: string;
  analyticsEventName?: string;
};

const Link = ({
  label,
  url: staticUrl,
  newTab,
  navigationLink,
  showAsButton,
  linkConfig,
  analyticsEventName,
}: LinkProps): JSX.Element => {
  const history = useHistory();
  const url = useLinkConfig(staticUrl, linkConfig);

  const formatEventName = formatAnalyticsEventName(analyticsEventName);
  const handleAnchorTag = () => {
    history.push(`${url}`);
  };

  if (showAsButton) {
    return (
      <LinkButton onClick={handleAnchorTag} formatEventName={formatEventName} label={label} url={url} newTab={newTab} />
    );
  }

  if (navigationLink) {
    return (
      <a className={styles.Link} onClick={handleAnchorTag} data-analyticseventname={formatEventName}>
        {label}
      </a>
    );
  }

  return (
    <a
      className={styles.Link}
      href={url}
      target={newTab ? '_blank' : undefined}
      rel="noreferrer"
      data-analyticseventname={formatEventName}
    >
      {label}
    </a>
  );
};

export default Link;
