import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import Markdown from '@components/Markdown';

import { FormikInputField } from '@components/FormikFields';
import Widget from '@components/Widget/Widget';
import InputRadio from '@components/InputRadio';
import Button from '@components/Button';
import { formatAnalyticsEventName } from '@cv/portal-common-lib';

import styles from './SecurityAuthentication.module.css';

export type Contacts = {
  primaryPhone?: string;
  secondaryPhone?: string;
  email?: string;
};

type SecurityAuthenticationFormProps = {
  onSubmit: (channel: string) => Promise<void>;
  labels: { [index: string]: { primary?: string; analyticsEventName?: string } };
  contacts: Contacts;
};

interface FormProps {
  channel: string;
}

const initialValues = {
  channel: 'email',
};

const SecurityAuthenticationForm = ({ onSubmit, labels, contacts }: SecurityAuthenticationFormProps) => {
  const {
    otpSendTitle,
    otpSendBody,
    otpSendEmail,
    otpSendEmailDescription,
    otpSendSms,
    otpSendSmsDescription,
    otpSendSmsTermsAndPolicy,
    submitButton,
  } = labels;

  const { primaryPhone = '', secondaryPhone = '', email = '' } = contacts;

  const OTP_SEND_VALUES = [
    {
      label: otpSendEmail.primary,
      value: 'email',
      description: `${otpSendEmailDescription.primary} ${email}`.trimEnd(),
      analyticsEventName: otpSendEmail.analyticsEventName,
    },
    {
      label: otpSendSms.primary,
      value: 'sms',
      description: `${otpSendSmsDescription.primary} ${primaryPhone}`.trimEnd(),
      analyticsEventName: otpSendSms.analyticsEventName,
    },
    secondaryPhone && {
      label: otpSendSms.primary,
      value: 'sms-secondary',
      description: `${otpSendSmsDescription.primary} ${secondaryPhone}`.trimEnd(),
      analyticsEventName: otpSendSms.analyticsEventName,
    },
  ].filter(Boolean);

  const handleSubmit = ({ channel }: { channel: string }) => {
    onSubmit(channel);
  };

  return (
    <Widget title={otpSendTitle.primary} titleAlignment="center">
      <div className={styles['SecurityForm']}>
        <p>{otpSendBody.primary}</p>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {(props: FormikProps<FormProps>) => (
            <Form onSubmit={props.handleSubmit}>
              <FormikInputField
                name="channel"
                InputComponent={InputRadio}
                onChange={() => {}}
                options={OTP_SEND_VALUES}
              />
              <div className={styles['termsAndPolicy']}>
                {otpSendSmsTermsAndPolicy?.primary && <Markdown>{otpSendSmsTermsAndPolicy?.primary}</Markdown>}
              </div>
              <div className={styles['buttonWrapper']}>
                <Button
                  className={styles['submitButton']}
                  type="submit"
                  role="button"
                  variant="filled"
                  data-analyticseventname={formatAnalyticsEventName(submitButton.analyticsEventName)}
                >
                  {submitButton.primary}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Widget>
  );
};

export default SecurityAuthenticationForm;
