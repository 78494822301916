import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@components/Theme';
import ModalContainer from '@components/ModalContainer';
import Button from '@components/Button';
import { FaInfoCircle } from 'react-icons/fa';
import { format } from 'date-fns';
import clsx from 'clsx';
import { useAnalytics } from '@cv/webframework-react-components';
import { BOUNDARY_ACTIONS } from '@redux/actions/boundaries';

import BingMap, { getConfigs } from './Map';
import MapWindowHome from './MapWindowHome';
import MapWindowDestinations from './MapWindowDestinations';
import MapWindowMonitor from './MapWindowMonitor';
import useToggle from '@hooks/useToggle';
import MapWindowToggle from './MapWindowToggle';
import nissanCarIcon from '@assets/nissan-car-location-selected.png';
import infinitiCarIcon from '@assets/infiniti-car-location-selected.png';

import { useApi } from '@api';
import { myCarFinderLabels } from './constants';
import { getContentContext } from '@components/App/AppWrapper';
import { useNavigation } from '@components/Navigation';
import { filterLabelDictionary } from '@utils/filter-label-dictionary';
import { PointOfInterest } from './types';
import { clearMapPins, clearMapBoundaries } from '@utils/clearMapEntities';
import { RootState } from '@app/reducers';
import initiatePolling, { PollingStatus } from '@utils/polling';
import { Status } from '@cv/portal-rts-lib/doors/enums';
import { GetLocationTrackerResponse } from '@cv/portal-rts-lib/location-tracker/models';
import { APIResponse } from '@cv/portal-common-lib/ajax/models';

type OptionalProperties<T, Keys extends keyof T> = Omit<T, Keys> & Partial<Pick<T, Keys>>;

const iconForTheme = {
  nissan: nissanCarIcon,
  infiniti: infinitiCarIcon,
};

// as per PORTAL-3880 expected result after 1 minute of finding a car,
// banner resets to default label
export const RESET_CAR_FINDER_LABEL_TIMEOUT = 60000;

const MapWrapper = React.memo(() => {
  const { currentPath } = useNavigation();
  const location = currentPath.page;
  const { trackEvent } = useAnalytics();

  const [collapsed, toggleMapWindow] = useToggle(false);
  const content: any = getContentContext();
  const theme = useTheme();
  const dispatch = useDispatch();
  const api = useApi();
  const [pushPins, addToPushPins] = useState<PointOfInterest[]>();
  const [map, setMap] = useState(null);
  const [directions, setDirections] = useState(null);
  const [showCarFinderInfoModal, toggleCarFinderInfoModal] = useToggle(false);
  const pins = useSelector(({ mapReducer }) => mapReducer.pointsOfInterest);
  const userLocation = useSelector(({ mapReducer }) => mapReducer.userLocation);
  const vehicleLocation = useSelector(({ mapReducer }) => mapReducer.vehicleLocation);
  const boundaryCoordinates = useSelector(({ boundaryReducer }) => boundaryReducer.boundaryCoordinates);
  const multipleBoundaryCoordinates = useSelector(({ boundaryReducer }) => boundaryReducer.multipleBoundaryCoordinates);
  const locale = useSelector(({ settingsReducer }) => settingsReducer.locale);
  const vehicleLocationClicked = useSelector(({ mapReducer }) => mapReducer.vehicleLocationClicked);
  const { vehicle } = useSelector(({ vehicleReducer }) => vehicleReducer);

  const country = useSelector(({ vehicleReducer }: RootState) => vehicleReducer.vehicle.registrationCountry);
  const { UoM: systemUoM } = getConfigs(country);

  // These labels are living in Portal Map Overlay Destinations Content since the map wrapper sits over top of multiple pages
  const vehicleLocationTextOptions = filterLabelDictionary(content, 'destinations');
  const {
    FindVehicleText,
    FindingVehicleText,
    FoundVehicleText,
    NotFoundVehicleText,
    CarFinderModalTitle,
    CarFinderModalDescription,
  } = vehicleLocationTextOptions;

  const [vehicleLocationText, setVehicleLocationText] = useState(FindVehicleText);

  // make sure SearchManager is loaded and available before calling the callback fn
  const withSearchManager = (Maps, callback) => {
    if (window.bingMapsLoaded && !window.Microsoft?.Maps?.Search?.SearchManager) {
      // load search manager, then run the callback
      Maps.loadModule('Microsoft.Maps.Search', callback);
    } else {
      callback();
    }
  };

  const themedCarIcon = iconForTheme[theme.get('name', 'infiniti').toLowerCase()];

  const setPinToCenter = (location?: object) => {
    // Set a pin to the map and center the view around it
    if (location && window.bingMapsLoaded) {
      const Maps = window.Microsoft.Maps;
      const { latitude, longitude, formattedAddress, lastUpdated } = location;

      const carPin = {
        center: { latitude, longitude },
        options: {
          anchor: new Maps.Point(12, 39),
          icon: themedCarIcon,
          enableClickedStyle: true,
        },
        metadata: {
          title: `${vehicle.make} ${vehicle.model}`,
          description: `${formattedAddress} <br/> ${lastUpdated}`,
        },
      };
      const pins = new Array(carPin);
      setPins(pins);
    }
  };

  const showUserLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      dispatch({ type: 'SET_USER_LOCATION', userLocation: position.coords });
    });
  };

  let latitude: number, longitude: number, lastUpdated: string;

  const validationCallback = (data: OptionalProperties<APIResponse<GetLocationTrackerResponse>, 'status'>) => {
    const isError = (status: number | undefined) => status && status >= 400;

    if (isError(data?.status)) {
      return PollingStatus.ERROR;
    }

    const { svcReqId } = svcRequest;
    const { svcRequests } = data;

    const { status: requestStatus } = (svcReqId && svcRequests.find((req) => req.id === svcReqId)) || svcRequests[0];

    if (requestStatus === Status.SUCCESS) {
      const {
        locations: [locationLocal] = [],
        svcRequests: [requestInfo],
      } = data;

      ({ latitude, longitude } = locationLocal.coordinates);
      lastUpdated = format(new Date(requestInfo.statusChangedTime), 'dd MMMM, yyyy, @ hh:mm a');

      return PollingStatus.SUCCESS;
    }

    if (requestStatus === Status.FAILED) {
      return PollingStatus.ERROR;
    }

    setVehicleLocationText(FindingVehicleText);
    return PollingStatus.PENDING;
  };

  const errorCallback = () => {
    setVehicleLocationText(NotFoundVehicleText);
    setPins([]);
    showUserLocation();
  };

  const startPolling = (label: string, svcReqId: string) => {
    initiatePolling({
      pollingFunc: api.getCurrentVehicleLocation.bind(api, api.storeService.getAccessToken(), svcReqId),
      validationCallback,
      successCallback: setPinOnMap.bind(this, label),
      errorCallback,
    });
  };

  const setPinOnMap = (label: string) => {
    const Maps = window.Microsoft.Maps;
    withSearchManager(Maps, () => {
      const searchManager = new Maps.Search.SearchManager(map);
      const reverseGeocodeRequestOptions = {
        location: new Maps.Location(latitude, longitude),
        callback: (answer: { address: { formattedAddress: string } }) => {
          const vehicleLocationLocal = {
            latitude,
            longitude,
            formattedAddress: answer.address.formattedAddress,
            lastUpdated,
          };
          dispatch({ type: 'SET_USER_LOCATION', userLocation: null });
          dispatch({ type: 'SET_VEHICLE_LOCATION', vehicleLocation: vehicleLocationLocal });
          dispatch({ type: 'SET_VEHICLE_LOCATION_CLICKED', vehicleLocationClicked: true });
          setVehicleLocationText(label);
          setPinToCenter(vehicleLocationLocal);
        },
      };
      searchManager.reverseGeocode(reverseGeocodeRequestOptions);
    });
  };

  let svcRequest: GetLocationTrackerResponse | { svcReqId: string };

  const getCarLocation = async (label: string, findLocation: boolean = false) => {
    try {
      svcRequest = findLocation ? await api.getServiceRequestId(api.storeService.getAccessToken()) : { svcReqId: '' };
      startPolling(label, svcRequest.svcReqId);
    } catch (err) {
      // TODO - IMPLEMENT ERROR HANDLING
      setVehicleLocationText(NotFoundVehicleText);
      console.log('ERROR', err);
      showUserLocation();
    }
  };

  useEffect(() => {
    // When the map is loaded get the vehicle location if it hasn't already been gotten
    if (map) {
      getCarLocation(FindVehicleText);
    }
  }, [map, vehicle]);

  useEffect(() => {
    setPins(pins);
  }, [pins]);

  const setPins = (pins: PointOfInterest[]) => {
    addToPushPins(pins);
  };

  useEffect(() => {
    resetMap();
  }, [location]);

  const handleCarLocation = async (e: any) => {
    trackEvent('ServicesRemote::FindMyCar-Clicked');
    e.target.classList.add('loading');
    setVehicleLocationText(FindingVehicleText);
    await getCarLocation(FoundVehicleText, true);
    e.target.classList.remove('loading');
    setTimeout(() => {
      setVehicleLocationText(FindVehicleText);
    }, RESET_CAR_FINDER_LABEL_TIMEOUT);
  };

  const handleBoundaryChange = (radius: number) => {
    dispatch({
      type: BOUNDARY_ACTIONS.SET_RADIUS,
      radius: { value: radius, unit: systemUoM },
    });
  };

  const handleRouteSummary = (summary: any) => {
    dispatch({
      type: 'SET_ROUTE_SUMMARY',
      routeSummary: summary,
    });
  };

  const setMapCenter = (mapCenter: object) => {
    dispatch({
      type: 'SET_MAP_CENTER',
      mapCenter: mapCenter,
    });
  };

  const handleViewChange = (e: any) => {
    if (e.location) {
      const { latitude, longitude } = e.location;
      setMapCenter({ latitude, longitude });
    }
  };

  const renderMap = () => {
    if (map) {
      const center = map.getCenter();
      dispatch({ type: 'SET_MAP', map });
      setMapCenter(center);
    }

    const boundaryColor = content
      .find((item: object) => item.location === 'destinations')
      ?.contentList[0].content.find((item: object) => item.name === 'Boundary Color');
    const mapDirections = location === 'destinations' ? directions : null;
    const unit = locale === 'es-MX' ? 'km' : 'miles';
    const hasCarFinderService = vehicle?.activeServices?.some((service: string) => myCarFinderLabels.includes(service));

    return (
      <div style={{ display: 'flex' }}>
        <BingMap
          setMap={setMap}
          height="570px"
          width="100%"
          mapOptions={{
            showMapTypeSelector: false,
            enableInertia: false,
            maxZoom: 16,
            navigationBarOrientation: 'horizontal',
          }}
          viewOptions={{
            center: boundaryCoordinates,
          }}
          pushPins={pushPins}
          directions={mapDirections}
          location={location}
          boundaryLocation={boundaryCoordinates}
          multipleBoundaryLocations={multipleBoundaryCoordinates}
          handleBoundaryChange={handleBoundaryChange}
          userLocation={userLocation}
          setRouteSummary={handleRouteSummary}
          unit={unit}
          handleViewChange={handleViewChange}
          boundaryColor={boundaryColor?.labelValue}
          vehicleLocationClicked={vehicleLocationClicked}
        />
        {showCarFinderInfoModal && (
          <ModalContainer
            show={showCarFinderInfoModal}
            header={{ text: CarFinderModalTitle, position: 'center' }}
            onCloseHandler={() => toggleCarFinderInfoModal(false)}
            size="md"
            height="auto"
            overflowY="visible"
          >
            <div className="map__car-location-modal-content">
              <p className="map__car-location-modal-description">{CarFinderModalDescription}</p>
              <Button
                variant="filled"
                className="map__car-location-modal-button"
                onClick={() => toggleCarFinderInfoModal(false)}
              >
                OK
              </Button>
            </div>
          </ModalContainer>
        )}
        {vehicle?.activeServices && hasCarFinderService && (
          <div>
            <div className="map__car-location-description">
              <FaInfoCircle className="map__car-location-info-icon" onClick={toggleCarFinderInfoModal} />
              {vehicleLocationText}
            </div>
            <button className="map__car-location-button" onClick={handleCarLocation}></button>
          </div>
        )}
      </div>
    );
  };

  const resetMap = () => {
    dispatch({ type: 'RESET_MULTIPLE_BOUNDARY_COORDINATES' });
    dispatch({ type: 'SET_BOUNDARY_COORDINATES', boundaryCoordinates: null });
    dispatch({ type: 'SET_VEHICLE_LOCATION_CLICKED', vehicleLocationClicked: false });
    clearMapPins(map);
    clearMapBoundaries(map);
    setDirections(null);
    if (vehicleLocation && map) {
      setPinToCenter(vehicleLocation);
      map.setView({ center: vehicleLocation, zoom: 16 });
    }
    if (!vehicleLocation) {
      showUserLocation();
    }
  };

  const renderMapWindow = () => {
    // TODO: pass prop to MapWindowHome and MapWindowDestinations when content type and fields are ready.
    const getContentByLocation = content.find((item: { location: string }) => item.location === location);
    const formatSecurityPinContent = () => {
      const portalSecurity = getContentByLocation?.contentList?.find(
        ({ componentType }: any) => componentType === 'portalSecurityPinModal',
      );
      return portalSecurity;
    };
    const iconsList = getContentByLocation?.listOfIcons;
    if (['home', 'remote'].includes(location)) {
      const labels = filterLabelDictionary(content, location);
      return (
        <MapWindowHome
          labels={labels}
          listOfIcons={iconsList}
          securityPinContent={formatSecurityPinContent()}
          resetMap={resetMap}
        />
      );
    }

    if (location === 'destinations') {
      const destinationLabels = filterLabelDictionary(content, location);
      return (
        <MapWindowDestinations
          labels={destinationLabels}
          setDirections={setDirections}
          resetMap={resetMap}
          setPinToCenter={setPinToCenter}
          vehicleLocationPinIcon={themedCarIcon}
        />
      );
    }

    if (location === 'monitor') {
      return <MapWindowMonitor content={getContentByLocation} />;
    }
  };

  return (
    <div className="MapWrapper">
      {/* BING MAPS RENDERS ROUTE INFORMATION - WE HIDE THIS AND PULL THE HTML TO DISPLAY ROUTE INFO */}
      <div className="itinerary-list-container">
        <div id="directionsItinerary"></div>
      </div>
      <div className="map-wrapper-inner">
        {renderMap()}
        <div className={clsx('map-overlay', { 'map-overlay--collapsed': collapsed })}>
          {renderMapWindow()}
          <MapWindowToggle collapsed={collapsed} onToggle={toggleMapWindow} />
        </div>
      </div>
    </div>
  );
});

export default MapWrapper;
