import { Tracker, VehicleTrackerStatus } from "@cv/portal-rts-lib/location-tracker/enums";
export enum STOLEN_VEHICLE_STATUS {
    STOLEN_STATUS = 'STOLEN_STATUS',
}

export interface StolenVehicleLocator {
    type: Tracker,
    status: VehicleTrackerStatus
}

export const svlStatus = (data: StolenVehicleLocator) => {
    return {
        type: STOLEN_VEHICLE_STATUS.STOLEN_STATUS,
        data,
    };
};
