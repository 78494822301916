import { AccountData, useApi } from '@api';
import { CarrierCodes, ChannelName } from '@cv/portal-cps-lib/vehicle/vehicle-notification-service/enums';
import React, { useEffect, useState } from 'react';
import {
  ServiceNotificationPreference,
  preferencesData,
} from '@cv/portal-cps-lib/vehicle/vehicle-notification-service/models';
import useLabels, { Label } from '@hooks/useLabels';

import { FaEdit } from 'react-icons/fa';
import { InfoBox } from '@components/InfoBox';
import { ModelConnector } from '@components/ApiConnectorHOCs';
import SettingsGrid from '@components/SettingsGrid';
import styles from './NotificationPreferences.module.css';
import truncate from 'lodash/truncate';
import useDialogActions from '@redux/hooks/dialog';
import useLoadingActions from '@redux/hooks/loading';
import { useVehicleSelector } from '@redux/selectors/vehicle';
import { useAccountSelector } from '@redux/selectors/account';
import EditPreferencesForm, { PreferenceValues } from './EditPreferencesForm';

export type NotificationPreferencesProps = {
  data?: AccountData;
  labels: { content: Label[] };
  timeZoneValues: string[];
};

type ChangeData = Channel & {
  serviceName: string;
  telematicsServiceName: string;
};

type Preferences = Omit<preferencesData, 'serviceNotificationPreferences'> & {
  serviceNotificationPreferences: ChangeData[];
};

type Channel = Record<Lowercase<ChannelName>, boolean>;

const changeDataChannelName = (channel: ChannelName) => channel.toLowerCase() as keyof Channel;

function transform_data(data: ServiceNotificationPreference[] = [], labels: { [key: string]: string }): ChangeData[] {
  return data
    .filter((item) => item.displayOnUI)
    .map((item) => ({
      telematicsServiceName: item.telematicsServiceName,
      serviceName: labels[item.telematicsServiceName],
      ...(item.channels
        .filter((channel) => channel.editable)
        .reduce(
          (acc, item) => ({
            ...acc,
            [changeDataChannelName(item.channel)]: item.enabled,
          }),
          {},
        ) as Channel),
    }));
}

function revert_data(data: ChangeData[]) {
  const channels = [ChannelName.EMAIL, ChannelName.TEXT, ChannelName.PUSH];
  return data.map((item) => ({
    telematicsServiceName: item.telematicsServiceName,
    channels: channels.map((channel) => ({
      channel,
      enabled: item[changeDataChannelName(channel)],
    })),
  }));
}

export function NotificationPreferences({ data, labels: { content }, timeZoneValues }: NotificationPreferencesProps) {
  const api = useApi();
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [preferences, setPreferences] = useState<Partial<Preferences>>({});
  const [isProvisioningPending, setIsProvisioningPending] = useState<boolean>(false);
  const { showDialog } = useDialogActions();
  const { showLoading, hideLoading } = useLoadingActions();
  const vehicle = useVehicleSelector();
  const user = useAccountSelector();
  const isSubscribed = vehicle.services?.length;

  useEffect(() => {
    if (vehicle?.vin && isSubscribed) {
      api
        .getNotificationPreferences()
        .then((response) => {
          handleUpdate(response?.data);
        })
        .catch((error) => {
          setIsProvisioningPending(!vehicle.activeServices.length && error.data?.code === 'ACTIVE_DEVICE_NOT_FOUND');
        });
    }
  }, [vehicle?.vin, vehicle?.activeServices, vehicle?.services]);

  const labels = useLabels(content).getAllPrimaries();
  const { email, text, timeZone } = preferences;
  const { phone, carrier } = text || { phone: '', carrier: CarrierCodes.UNKNOWN };

  const handleEditClick = () => {
    setMode('edit');
  };

  const handleCancelClick = () => {
    setMode('view');
  };

  const { givenName, fathersName, mothersName } = data?.userName || {
    givenName: '',
    fathersName: '',
    mothersName: '',
  };
  const fullName = [givenName, fathersName, mothersName].join(' ');
  const truncateSettings = { length: 25 };
  const contacts = [
    { label: labels.name, value: fullName },
    { label: labels.primaryEmail, value: truncate(email, truncateSettings) },
    { label: labels.primaryPhone, value: phone },
    { label: labels.timeZone, value: timeZone },
    { label: labels.carrier, value: carrier },
  ];

  const fields = {
    serviceName: labels.notificationType,
    email: labels.notificationTypeEmail,
    push: labels.notificationTypePush,
    text: labels.notificationTypeText,
  };

  const handleUpdate = (updateData: preferencesData = {}) => {
    // We can not use default values with destruction since API may return null
    // (preferencesData is not properly typed at the moment)
    const { serviceNotificationPreferences, email, text, timeZone } = updateData;
    setPreferences({
      email: email || '',
      text: text || { phone: user.primaryPhone?.number || '', carrier: CarrierCodes.UNKNOWN },
      timeZone: timeZone || '',
      serviceNotificationPreferences: transform_data(serviceNotificationPreferences || [], labels),
    });
  };

  const handleSubmit = async (values: PreferenceValues) => {
    showLoading();
    const notificationPreferences = preferences?.serviceNotificationPreferences;
    if (!notificationPreferences) {
      return;
    }

    try {
      const updateResponse = await api.updateNotificationPreferences({
        ...preferences,
        timeZone: values.timeZone,
        email: values.email,
        text: { phone: values.phone, carrier: values.carrier },
        serviceNotificationPreferences: revert_data(values.serviceNotificationPreferences),
      });
      if (updateResponse?.data) {
        handleUpdate(updateResponse.data);
        showDialog({ title: labels.success, message: labels.successMessage });
      }
    } catch (e) {}
    setMode('view');
    hideLoading();
  };

  return (
    <div className={styles['notification-preferences']}>
      {mode === 'view' && (
        <>
          <button className={styles['edit-button']} onClick={handleEditClick} disabled={!isSubscribed}>
            <FaEdit className={styles['edit-icon']} />
          </button>
          <p>{labels.helpText}</p>
          <InfoBox entries={contacts} />
          <SettingsGrid indexing="serviceName" data={preferences.serviceNotificationPreferences} fields={fields} />
          {!isSubscribed && <p className={styles['not-available']}>{labels.notificationNotAvailable}</p>}
          {isProvisioningPending && <p className={styles['not-available']}>{labels.notificationPendingProvisioning}</p>}
          <p className={styles['push-footnote']}>{labels.pushFootnote}</p>
        </>
      )}
      {mode === 'edit' && (
        <EditPreferencesForm
          fullName={fullName}
          labels={labels}
          timeZoneValues={timeZoneValues}
          handleSubmit={handleSubmit}
          handleCancel={handleCancelClick}
          preferences={{
            email,
            phone,
            timeZone,
            carrier,
            serviceNotificationPreferences: preferences.serviceNotificationPreferences ?? [],
          }}
        />
      )}
    </div>
  );
}

export default ModelConnector(NotificationPreferences, { showUiOnError: true });
