import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useApi } from '@api';
import { LoaderBackdrop } from '@components/Loader';
import { getTenantId } from '@utils/getTenantId';
import ResetPasswordForm from './ResetPasswordForm';
import ResetPasswordEmailResend from './ResetPasswordEmailResend';
import useLabels from '@hooks/useLabels';
import styles from './ResetPassword.module.css';

enum Step {
  Form = 'form',
  Resend = 'resend',
}

enum Status {
  Idle = 'idle',
  Loading = 'loading',
}

const ResetPasswordPage = ({ labelDictionary }: any) => {
  const labels = useLabels(labelDictionary).getAllPrimaries();

  const api = useApi();
  const history = useHistory();
  const [step, setStep] = useState<Step>(Step.Form);
  const [status, setStatus] = useState<Status>(Status.Idle);
  const [userName, setUserName] = useState('');

  const navigateTo = (path: string) => {
    history.push(`/${path}`);
  };

  const onConfirm = (step?: Step) => {
    step && setStep(step);
  };

  const onSubmit = async (
    filter: { userName: string },
    setFieldError?: (fieldName: string, message: string) => void,
    setSubmitting?: (isSubmitting: boolean) => void,
  ) => {
    try {
      setStatus(Status.Loading);
      const { data } = await api.queryAccount({ filter, tenantId: getTenantId() });

      if (!data.resultCount) {
        setFieldError && setFieldError('userName', labels.userNotFound);
      } else {
        const userId = data?.result?.[0]._id;

        // reset password by passing an empty object
        const { data: success } = await api.updatePasswordData({}, userId, getTenantId());

        if (success) {
          onConfirm();
          setUserName(filter.userName);
          if (step === Step.Form) {
            setStep(Step.Resend);
          }
        }
      }
      setSubmitting && setSubmitting(false);
      setStatus(Status.Idle);
    } catch (e) {
      // error message will be displayed in dialog.
      if (setSubmitting) {
        setSubmitting(false);
      }

      setStatus(Status.Idle);
    }
  };

  return (
    <div className={styles['reset-password-container']}>
      {status === Status.Loading && <LoaderBackdrop />}
      {step === Step.Form && (
        <ResetPasswordForm
          labels={labels}
          onSubmit={onSubmit}
          onCancel={() => navigateTo('login')}
        />
      )}
      {step === Step.Resend && (
        <ResetPasswordEmailResend
          labels={labels}
          onLogin={() => navigateTo('login')}
          onResend={() => onSubmit({ userName })}
        />
      )}
    </div>
  );
};

export default ResetPasswordPage;
