import { VehicleResponse } from "@cv/portal-cps-lib/vehicle/vehicle-management/models";
import { UserInfoByQueryResponse } from "@cv/portal-idm-lib/user/user-Info/models";

export default class MockService {
  private defaultLatency: number;

  constructor({ defaultLatency = 350 } = {}) {
    this.defaultLatency = defaultLatency;
  }

  mockResponse({ status = 200, latency = this.defaultLatency, ...data }) {
    return new Promise((resolve, reject) => {
      if (status >= 400) {
        setTimeout(() => reject(new Error("API error")), latency);
      }
      setTimeout(
        () =>
          resolve({ data: "default" in data ? data.default : data, status }),
        latency
      );
    });
  }

  fetchUserInfoByID() {
    return this.getByString('user-info');
  }

  getAccountDetails() {
    return this.getByString('account');
  }

  getSubscription() {
    return this.getByString('subscription');
  }

  getNotificationSettings() {
    return this.getByString('notifications');
  }

  getContacts() {
    return this.getByString('contacts');
  }

  getDrivers() {
    return this.getByString('drivers');
  }

  getByString(path: string) {
    return import(`../fixtures/${path}.json`).then((data) =>
      this.mockResponse({ data })
    );
  }

  getPoiItems(searchTerm: string) {
    const destinationSearches = ["atm", "food", "gas", "shop", "pharmacy"];
    const poiType = destinationSearches.includes(searchTerm) ? searchTerm : "address";


    return this.getByString(`poi-${poiType}`);
  }

  getAutocompleteAddress(searchTerm: string) {
    return this.getByString(`autocomplete-${searchTerm}`);
  }

  getVehicleLocation(latency?: number) {
    return import("../fixtures/vehicle-location.json").then((data) =>
      this.mockResponse({ data, latency })
    );
  }

  searchDestinations(destination: string) {
    if (destination[0] === "1") {
      return this.getByString('new-york-destination-location');
    }

    return this.getByString('denver-destination-location');
  }

  getFavorites() {
    return this.getByString('favorites');
  }

  getSentToCar() {
    return this.getByString('sent-to-car');
  }

  getVehicleHealth() {
    return this.getByString('vehicle-health');
  }

  getFolders() {
    return this.getByString('folders');
  }

  getAlerts() {
    return this.getByString('alerts');
  }

  getCurfews() {
    return this.getByString('curfews');
  }
  getGeofence() {
    return this.getByString('boundaries');
  }
  getJourneys() {
    return this.getByString('journeys');
  }

  removeJourney() {
    return this.mockResponse({ data: {} });
  }

  getVehicleConditionCarInfo() {
    return this.getByString('vehicle-status-car-info');
  }

  getBreaches() {
    return this.getByString('breaches');
  }

  getFcaAccessToken() {
    return this.getByString('fcaAccessToken');
  }

  getSxmAccessToken() {
    return this.getByString('sxmAccessToken');
  }

  getSxmKeyDetails() {
    return this.getByString('sxmKeyDetails');
  }

  addBoundary(boundary: string) {
    return this.mockResponse({ data: {} })
  }

  updateBoundary(boundary: string) {
    return this.mockResponse({ data: {} })
  }

  removeBoundary(boundary: string) {
    return this.mockResponse({ data: {} })
  }

  searchVehicle() {
    const suffix = Math.random() < 0.5 ? 'active' : 'inactive';
    return this.getByString(`vehicle-search-${suffix}`) as Promise<{ data: VehicleResponse[] }>;
  }

  queryAccount() {
    const suffix = Math.random() < 0.5 ? '-empty' : '';
    return this.getByString(`query-user-info${suffix}`) as Promise<{ data: UserInfoByQueryResponse }>;
  }

  linkVehicle() {
    return this.mockResponse({ data: {} });
  }

  unlinkVehicle() {
    return this.mockResponse({ data: {} });
  }
}
