import {
  AccountCreationService,
  AccountDetailsService,
  AccountLinkService,
  AccountUpdateService,
  AgreementService,
  AlertsService,
  AuthService,
  CurfewService,
  CalendarService,
  DestinationService,
  DoorLockUnlockService,
  EngineStartStopService,
  FolderService,
  GeofenceService,
  GetLinkService,
  HeadUnitsService,
  HornLightsService,
  JourneyService,
  MockService,
  NewsService,
  NotificationPreferencesService,
  OTPCodeService,
  PasswordService,
  PaymentMethodsService,
  PinService,
  PoiService,
  RoleService,
  SecurityPinService,
  SecurityQuestionsService,
  SportsService,
  StockService,
  StolenVehicleLocatorService,
  StoreService,
  SubscribedPackagesService,
  TelematicServices,
  TokenExchangeService,
  TokenLoginService,
  VehicleContactsService,
  VehicleContactUpdateService,
  VehicleDetailsService,
  VehicleLinkService,
  VehicleLocationService,
  VehicleStatusService,
  VinService,
} from './services';
import {
  AccountFormData,
  AddVinData,
  CapableServicesResponce,
  ContactFormData,
  PaymentMethodData,
  SubscribedServices,
} from './types/';
import MapDistance from '@customTypes/MapDistance';
import UserCalendar from '@customTypes/UserCalendar';
import { AddDestination, UpdateDestination } from '@cv/portal-rts-lib/v0/information/destinations/models';
import { Category } from '@cv/portal-rts-lib/v0/information/news';
import { SportsItem } from '@cv/portal-rts-lib/v0/information/sports';
import { StockCategory } from '@cv/portal-rts-lib/v0/information/stocks';
import { CreateAgreementPayload, GetEffectiveAgreement } from '@cv/portal-cps-lib/agreements/agreement-service/models';
import { CreateAlertsRequest, DrivingBehaviorByVehicleId } from '@cv/portal-rts-lib/alerts/models';
import { CreateCurfewPayload, UpdateCurfewPayload } from '@cv/portal-rts-lib/curfews/models';
import { CreateGeofencePayload, UpdateGeofencePayload } from '@cv/portal-rts-lib/geofences/models';
import { OrderPayload, Product } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { ResetPinByKBAPayload, ResetPinPayload } from '@cv/portal-idm-lib/pin/models';
import { SearchVehiclePayload, VehicleServiceDetails } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import {
  SetupPinAndSecurityQuestionsRequest,
  UpdateSecurityQuestionsByPinRequest,
} from '@cv/portal-idm-lib/security-questions-pin/models';
import { flatten, invoke } from 'lodash';

import { AddJourney } from '@cv/portal-rts-lib/v0/information/journey/models';
import ApiRequestPayload from '@app/types/ApiRequestPayload';
import { ContactTypes } from '@cv/portal-idm-lib/contact/enums';
import { DistanceUnit } from '@cv/portal-rts-lib/v0/enums';
import { DoorUnlockRequestPayload } from './services/DoorLockUnlockService';
import { EngineStartStopRequestPayload } from './services/EngineStartStopService';
import { HornLightsRequestPayload } from './services/HornLightsService';
import { IVehicle } from '@redux/actions';
import { Journey } from '@cv/portal-rts-lib/v0/information/journey';
import { PackageType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { QueryFilterKeys } from '@cv/portal-idm-lib/models';
import { UpdatePasswordPayload } from '@cv/portal-idm-lib/password/models';
import { UserCreatePayload } from '@cv/portal-idm-lib/user/user-create/models';
import { UserUpdatePayload } from '@cv/portal-idm-lib/user/user-update-profile/models';
import config from '@config/config';
import { preferencesData } from '@cv/portal-cps-lib/vehicle/vehicle-notification-service/models';
import SearchAddressService from './services/SearchAddressService';
import { convertToSend } from '@api/formatters/convertCalendar';

const environmentEnum = config.getEnvironmentEnum();

const mockService = new MockService();
const authService = new AuthService();

const accountDetailsService = new AccountDetailsService(environmentEnum);
const vehicleContactsService = new VehicleContactsService(environmentEnum);
const vehicleLocationService = new VehicleLocationService(environmentEnum);
const subscribedPackagesService = new SubscribedPackagesService(environmentEnum);
const accountUpdateService = new AccountUpdateService(environmentEnum);
const paymentMethodService = new PaymentMethodsService(environmentEnum);
const accountCreationService = new AccountCreationService(environmentEnum);
const accountLinkService = new AccountLinkService(environmentEnum);
const vehicleStatusService = new VehicleStatusService(environmentEnum);
const doorLockUnlockService = new DoorLockUnlockService(environmentEnum);
const hornLightsService = new HornLightsService(environmentEnum);
const engineStartStopService = new EngineStartStopService(environmentEnum);
const vehicleDetailsService = new VehicleDetailsService(environmentEnum);
const alertsService = new AlertsService(environmentEnum);
const curfewService = new CurfewService(environmentEnum);
const geofenceService = new GeofenceService(environmentEnum);
const vehicleContactUpdateService = new VehicleContactUpdateService(environmentEnum);
const pinService = new PinService(environmentEnum);
const securityQuestionsService = new SecurityQuestionsService(environmentEnum);
const passwordService = new PasswordService(environmentEnum);
const headUnitsService = new HeadUnitsService(environmentEnum);
const securityPinService = new SecurityPinService(environmentEnum);
const poiService = new PoiService(environmentEnum);
const folderService = new FolderService(environmentEnum);
const stockService = new StockService(environmentEnum);
const newsService = new NewsService(environmentEnum);
const sportsService = new SportsService(environmentEnum);
const calendarService = new CalendarService(environmentEnum);
const destinationService = new DestinationService(environmentEnum);
const journeyService = new JourneyService(environmentEnum);
const vehicleLinkService = new VehicleLinkService(environmentEnum);
const agreementService = new AgreementService(environmentEnum);
const roleService = new RoleService(environmentEnum);
const notificationPreferencesService = new NotificationPreferencesService(environmentEnum);
const stolenVehicleLocatorService = new StolenVehicleLocatorService(environmentEnum);
const vinService = new VinService(environmentEnum);
const telematicServices = new TelematicServices(environmentEnum);
const tokenExchangeService = new TokenExchangeService();
const otpCodeService = new OTPCodeService();
const tokenLoginService = new TokenLoginService();
const searchAddressService = new SearchAddressService(environmentEnum);
const getLinkService = new GetLinkService();

export default class Api {
  constructor(public storeService: StoreService) {}

  createAccount(data: UserCreatePayload) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return accountCreationService.createUser({ data, accessToken, locale });
  }

  userRoleLink({ userId, roleId, tenantId }: { userId: string; roleId?: string; tenantId: string }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return accountLinkService.userRoleLink({ userId, roleId, tenantId, accessToken, locale });
  }

  updateUserInfo(data: UserUpdatePayload[], locale?: string, userId?: string) {
    return accountDetailsService.updateUserInfo({
      userId: userId || this.storeService.getUserId(),
      locale: locale || this.storeService.getLocale(),
      accessToken: this.storeService.getAccessToken(),
      data,
    });
  }

  getAccountDetails(locale?: string, userId?: string, accessToken?: string) {
    return accountDetailsService.getAccountDetails({
      userId: userId || this.storeService.getUserId(),
      locale: locale || this.storeService.getLocale(),
      accessToken: accessToken || this.storeService.getAccessToken(),
    });
  }

  queryAccount({ filter, fields, tenantId = '' }: { filter: QueryFilterKeys; fields?: string[]; tenantId?: string }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return accountDetailsService.queryAccount({ filter, fields, accessToken, locale, tenantId });
  }

  getVehicleLocation(accessToken: string) {
    /*if (environmentEnum === Environment.LOCAL) {
      return mockService.getVehicleLocation(3000);
    }*/
    return vehicleLocationService.getVehicleLocation(
      accessToken || this.storeService.getAccessToken(),
      this.storeService.getVehicleData().vehicleId,
      this.storeService.getIdToken(),
    );
  }

  getServiceRequestId(accessToken: string) {
    return vehicleLocationService.getServiceRequestId(
      accessToken || this.storeService.getAccessToken(),
      this.storeService.getVehicleData().vehicleId,
      this.storeService.getIdToken(),
    );
  }

  getCurrentVehicleLocation(accessToken: string, svcReqId: string) {
    return vehicleLocationService.getVehicleLocation(
      accessToken || this.storeService.getAccessToken(),
      this.storeService.getVehicleData().vehicleId,
      this.storeService.getIdToken(),
      svcReqId,
    );
  }

  getNotificationPreferences() {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    const userId = this.storeService.getUserId();
    const locale = this.storeService.getLocale();
    return notificationPreferencesService.getPreferences({ accessToken, locale, vin, userId });
  }

  updateNotificationPreferences(data: preferencesData) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    const userId = this.storeService.getUserId();
    const locale = this.storeService.getLocale();
    return notificationPreferencesService.updatePreferences({ accessToken, locale, vin, userId, ...data });
  }

  getSubscription({
    vehicleId,
    userId,
    tenantId,
    excludeDefaultPkgs = false,
  }: {
    vehicleId?: string;
    userId?: string;
    tenantId?: string;
    excludeDefaultPkgs?: boolean;
  }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();

    return subscribedPackagesService.getSubscription({
      userId: userId || this.storeService.getUserId(),
      vehicleId: vehicleId || this.storeService.getVehicleData().vehicleId,
      accessToken,
      locale,
      tenantId: tenantId || this.storeService.getTenantId(),
      excludeDefaultPkgs,
    });
  }

  getAgreement() {
    return agreementService.getAgreement(
      this.storeService.getLocale(),
      this.storeService.getAccessToken(),
      this.storeService.getUserId(),
      this.storeService.getVehicleData().vin,
    );
  }

  searchEligiblePackages({ vehicleId, userId }: { vehicleId?: string; userId?: string }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    const tenantId = this.storeService.getTenantId();
    return subscribedPackagesService.searchEligiblePackages({
      userId: userId || this.storeService.getUserId(),
      vehicleId: vehicleId || this.storeService.getVehicleData().vehicleId,
      accessToken,
      locale,
      tenantId,
    });
  }

  async getCapableServices({
    vehicleId: initialVehicleId,
    userId: initialUserId,
  }: {
    vehicleId?: string;
    userId?: string;
  }): Promise<CapableServicesResponce> {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    const tenantId = this.storeService.getTenantId();
    const vehicleId = initialVehicleId || this.storeService.getVehicleData().vehicleId;
    const userId = initialUserId || this.storeService.getUserId();

    const { data: eligiblePackages } = await subscribedPackagesService.searchEligiblePackages({
      userId,
      vehicleId,
      accessToken,
      locale,
      tenantId,
    });
    const allProducts = eligiblePackages.map((item) => item.products).flat();
    const allProductsById = allProducts.reduce<Record<string, Product>>(
      (memo, product) => ({ ...memo, [product.id]: product }),
      {},
    );

    const {
      data: {
        allVehicleServiceDetails: { vehicleServiceDetails },
      },
    } = await vehicleDetailsService.getVehicleDetails({
      vehicleId,
      subscribedServices: this.storeService.getSubscribedServices(),
      accessToken,
      locale,
      tenantId,
    });

    const capableServices = vehicleServiceDetails.filter(
      (service) => service.customerFacing && service.provisioningStatus !== 'NULL',
    );
    const capableServicesById = capableServices.reduce<Record<string, VehicleServiceDetails>>(
      (memo, service) => ({ ...memo, [service.vehicleServiceId]: service }),
      {},
    );

    const productsWithServices = Object.values(allProductsById)
      .map(({ id, productName, serviceIds }) => ({
        id,
        productName,
        services: serviceIds.map((id) => capableServicesById[id]).filter(Boolean),
      }))
      .filter((product) => product.services.length > 0);

    return Promise.resolve({ data: productsWithServices });
  }

  createOrder({
    vehicleId,
    userId,
    ...rest
  }: {
    vehicleId?: string;
    userId?: string;
  } & OrderPayload) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    const tenantId = this.storeService.getTenantId();
    return subscribedPackagesService.createOrder({
      userId: userId || this.storeService.getUserId(),
      vehicleId: vehicleId || this.storeService.getVehicleData().vehicleId,
      accessToken,
      locale,
      tenantId,
      ...rest,
    });
  }

  createAgreement(data: CreateAgreementPayload) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return agreementService.createAgreement({ data, accessToken, locale });
  }

  getEffectiveAgreement(data: GetEffectiveAgreement) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return agreementService.getEffectiveAgreement({ data, accessToken, locale });
  }

  getContacts(locale: string, accessToken: string) {
    return vehicleContactsService.getContacts(
      { type: ContactTypes.EmergencyContact },
      this.storeService.getVehicleData().vehicleId,
      locale,
      accessToken || this.storeService.getAccessToken(),
    );
  }

  getDrivers(locale: string, accessToken: string) {
    return vehicleContactsService.getContacts(
      { type: ContactTypes.AdditionalDriver },
      this.storeService.getVehicleData().vehicleId,
      locale,
      accessToken || this.storeService.getAccessToken(),
    );
  }

  getPaymentMethod(locale: string, accessToken: string) {
    return paymentMethodService.getPaymentMethods(
      this.storeService.getUserId(),
      locale,
      accessToken || this.storeService.getAccessToken(),
    );
  }

  getByString(path: string) {
    return mockService.getByString(path);
  }

  fetchService(serviceName: string, locale: string) {
    return invoke(this.services.GET, serviceName, locale);
  }

  addContact(data: ContactFormData) {
    const accessToken = this.storeService.getAccessToken();
    return vehicleContactUpdateService.postContact(
      data,
      { type: ContactTypes.EmergencyContact },
      accessToken,
      this.storeService.getVehicleData().vehicleId,
      this.storeService.getUserId(),
      this.storeService.getTenantId(),
    );
  }

  addDriver(data: ContactFormData) {
    const accessToken = this.storeService.getAccessToken();
    return vehicleContactUpdateService.postContact(
      data,
      { type: ContactTypes.AdditionalDriver },
      accessToken,
      this.storeService.getVehicleData().vehicleId,
      this.storeService.getUserId(),
      this.storeService.getTenantId(),
    );
  }

  changeAccount(data: AccountFormData, accessToken?: string) {
    const token = accessToken || this.storeService.getAccessToken();
    return accountUpdateService.patchAccount(data, token);
  }

  changeContact(data: ContactFormData) {
    const accessToken = this.storeService.getAccessToken();
    return vehicleContactUpdateService.patchContact(data, accessToken);
  }

  changeDriver(data: ContactFormData) {
    const accessToken = this.storeService.getAccessToken();
    return vehicleContactUpdateService.patchContact(data, accessToken);
  }

  changePaymentMethod(data: PaymentMethodData) {
    const accessToken = this.storeService.getAccessToken();
    const userId = this.storeService.getUserId();
    return paymentMethodService.updatePaymentMethod(data, userId, accessToken);
  }

  deleteContact(data: ContactFormData) {
    const accessToken = this.storeService.getAccessToken();
    return vehicleContactUpdateService.deleteContact(data, accessToken);
  }

  exchangeToken(tokenId: string, clientId: string) {
    return tokenExchangeService.exchangeTokens({ tokenId, clientId });
  }

  sendOTPCode(requestData: {
    userId?: string;
    vehicleId?: string;
    channel: string;
    discountId?: string;
    externalTenantUserId?: string;
    isSecondaryPhone?: boolean;
    expiresIn?: number;
  }) {
    const tenantId = this.storeService.getTenantId();
    return otpCodeService.sendOTPCode({ tenantId, ...requestData });
  }

  validateOTPCode(tokenId: string, otp: string) {
    return otpCodeService.validateOTPCode(tokenId, otp);
  }

  loginToken(tokenId: string, otp?: string) {
    return tokenLoginService.loginToken(tokenId, otp);
  }

  updateService(serviceName: string, operation: string, data?: ApiRequestPayload) {
    return invoke(this.services, `${operation.toUpperCase()}.${serviceName}`, data);
  }

  services = {
    GET: {
      contacts: this.getContacts,
      drivers: this.getDrivers,
      subscription: this.getSubscription,
      account: this.getAccountDetails,
      vehicle: this.getVehicleDetails,
      vehicleHealth: this.getVehicleHealth,
      storeService: this.storeService,
      myCarLocation: this.getVehicleLocation,
      paymentMethod: this.getPaymentMethod,
      capableServices: this.getCapableServices,
    },
    POST: {
      contacts: this.addContact.bind(this),
      drivers: this.addDriver.bind(this),
    },
    PUT: {
      account: this.changeAccount.bind(this),
      contacts: this.changeContact.bind(this),
      drivers: this.changeDriver.bind(this),
      paymentMethod: this.changePaymentMethod.bind(this),
    },
    DELETE: {
      contacts: this.deleteContact.bind(this),
      drivers: this.deleteContact.bind(this),
    },
  };

  resetVehicleData() {
    return headUnitsService.wipeVehicle(
      this.storeService.getAccessToken(),
      this.storeService.getVehicleData().vehicleId,
    );
  }

  getPoiItems(searchTerm: string, maxReturned?: number, distance: MapDistance = {}) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    const coordinates = this.storeService.getVehicleLocation();
    const { unit = DistanceUnit.Mile, value = 5 } = distance;
    const data = {
      searchString: searchTerm,
      geoNear: {
        coordinates,
        distance: {
          unit,
          value,
        },
      },
      maxReturned,
    };
    const id_token = this.storeService.getIdToken();

    return poiService.poiSearch({ accessToken, data, vin, id_token });
  }

  getAutocompleteAddress(searchTerm: string) {
    return mockService.getAutocompleteAddress(searchTerm);
  }

  searchDestinations(destination: string) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    const id_token = this.storeService.getIdToken();

    const data = {
      searchString: destination,
    };
    return poiService.poiSearch({ accessToken, data, vin, id_token });
  }

  getDestinations(folderId: string, channelId: string) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();

    return destinationService.getDestinations({
      accessToken,
      vin,
      folderId,
      channelId,
    });
  }

  addDestination(data: AddDestination) {
    const accessToken = this.storeService.getAccessToken();
    const id_token = this.storeService.getIdToken();
    const { vin } = this.storeService.getVehicleData();
    return destinationService.addDestination({
      accessToken,
      id_token,
      vin,
      data,
    });
  }

  deleteDestination(data: UpdateDestination) {
    const accessToken = this.storeService.getAccessToken();
    const id_token = this.storeService.getIdToken();
    const { vin } = this.storeService.getVehicleData();

    return destinationService.deleteDestination({
      accessToken,
      id_token,
      vin,
      data,
    });
  }

  getFavorites() {
    return mockService.getFavorites();
  }

  getSentToCar() {
    return mockService.getSentToCar();
  }

  getVehicleHealth() {
    return vehicleStatusService.getHealthInformation(
      this.storeService.getAccessToken(),
      this.storeService.getIdToken(),
      this.storeService.getVehicleData().vehicleId,
      this.storeService.getLocale(),
    );
  }

  getVehicleHealthReports() {
    return vehicleStatusService.getHealthReports(
      this.storeService.getAccessToken(),
      this.storeService.getIdToken(),
      this.storeService.getVehicleData().vehicleId,
      this.storeService.getLocale(),
    );
  }

  makeRemoteDoorAPICall(req: DoorUnlockRequestPayload) {
    return doorLockUnlockService.makeRemoteDoorAPICall(
      req,
      this.storeService.getAccessToken(),
      this.storeService.getIdToken(),
      this.storeService.getVehicleData().vehicleId,
    );
  }

  getVehicleDetails(locale?: string, vehicleId?: string, include?: string, subscribedServices?: SubscribedServices[]) {
    return vehicleDetailsService.getVehicleDetails({
      vehicleId: vehicleId || this.storeService.getVehicleData().vehicleId,
      subscribedServices: subscribedServices || this.storeService.getSubscribedServices(),
      accessToken: this.storeService.getAccessToken(),
      locale: locale || this.storeService.getLocale(),
      tenantId: this.storeService.getTenantId(),
      include,
    });
  }

  getVehiclesData(vehicleIds: string[]) {
    const vehiclesDetailRequests = vehicleIds.map((vehicleIds) => this.getVehicleDetails(undefined, vehicleIds));
    return Promise.all(vehiclesDetailRequests).then((results) =>
      results?.map((el) => {
        return {
          vin: el?.data?.vin,
          active: el?.data?.active,
          make: el?.data?.make,
          model: el?.data?.model,
          nickname: el?.data?.nickname,
          year: el?.data?.year,
          vehicleId: el?.data?.vehicleId,
          telematicsProgramId: el?.data?.telematicsProgramId,
          activeServices: el?.data?.activeServices,
          services: el?.data?.services,
          marketingServiceNames: el?.data?.marketingServiceNames,
          registrationCountry: el?.data?.registrationCountry,
          ownershipType: el?.data?.ownershipType,
          additionalFeatures: el?.data?.additionalFeatures,
          color: el?.data?.color,
        } as IVehicle;
      }),
    );
  }

  getVinSubscribedPackages = (vehicleIds: string[], userId?: string) => {
    const subscribedPackagesRequests = vehicleIds?.map((vehicleId) =>
      this.getSubscription({ vehicleId, userId, excludeDefaultPkgs: true }),
    );
    return Promise.all(subscribedPackagesRequests)
      .then((results) =>
        results?.map(
          (packages) =>
            ({
              vin: packages?.data?.vehicle?.vin,
              vehicleId: packages?.data?.vehicle?.vehicleId,
              activeType: packages?.data?.services?.some(
                (subscribed) =>
                  subscribed.packageType === PackageType.Regular || subscribed.packageType === PackageType.Default,
              ),
              subscribedServiceIds: flatten(packages?.data?.services.map((service) => service.serviceIds)),
              packages: packages?.data?.subscribedPackages,
            } as SubscribedServices),
        ),
      )
      .catch((error) => {
        console.error('sxm subscribed vehicle status error', error);
        return [];
      });
  };

  searchVehicle({
    vin,
    activeCriteria,
    deviceId,
    telematicsProgramId,
  }: Optional<SearchVehiclePayload, 'activeCriteria'>) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    const tenantId = this.storeService.getTenantId();
    return vehicleDetailsService.searchVehicle({
      vin,
      activeCriteria,
      deviceId,
      telematicsProgramId,
      accessToken,
      locale,
      tenantId,
    });
  }

  queryVehicle({ filter, fields }: { filter: QueryFilterKeys; fields?: string[] }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return vehicleDetailsService.queryVehicle({ filter, fields, accessToken, locale });
  }

  linkVehicle({ vehicleId, userId, roleId }: { vehicleId: string; userId: string; roleId: string }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return vehicleLinkService.linkVehicle({ vehicleId, userId, roleId, accessToken, locale });
  }

  unlinkVehicle({ vehicleId, userId, roleId }: { vehicleId: string; userId: string; roleId: string }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return vehicleLinkService.unlinkVehicle({ vehicleId, userId, roleId, accessToken, locale });
  }

  makeHornLightsAPICall(request: HornLightsRequestPayload) {
    return hornLightsService.makeHornLightsAPICall(
      request,
      this.storeService.getAccessToken(),
      this.storeService.getIdToken(),
      this.storeService.getVehicleData().vehicleId,
    );
  }

  makeEngineStartStopCall(request: EngineStartStopRequestPayload) {
    return engineStartStopService.makeEngineStartStopCall(
      request,
      this.storeService.getAccessToken(),
      this.storeService.getIdToken(),
      this.storeService.getVehicleData().vehicleId,
    );
  }

  getBreaches(type: string, alertType: string, startDateTime: string, endDateTime: string) {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    const idToken = this.storeService.getIdToken();
    // return mockService.getBreaches(); // Mock service for local development
    return alertsService.getBreaches({ vehicleId, accessToken, type, alertType, startDateTime, endDateTime, idToken });
  }

  getPostalAddress(coordinates: { latitude: number; longitude: number }) {
    const vin = this.storeService.getVehicleData().vin;
    const accessToken = this.storeService.getAccessToken();
    const idToken = this.storeService.getIdToken();
    return alertsService.getPostalAddress({ vin, accessToken, idToken, coordinates });
  }

  getAlertService() {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    const idToken = this.storeService.getIdToken();
    return alertsService.getAlerts({ vehicleId, accessToken, idToken });
  }

  updateAlertService(data: DrivingBehaviorByVehicleId) {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    const idToken = this.storeService.getIdToken();
    return alertsService.updateAlerts({ vehicleId, accessToken, idToken, data });
  }

  createAlertService(data: CreateAlertsRequest) {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    const idToken = this.storeService.getIdToken();
    return alertsService.createAlerts({ vehicleId, accessToken, idToken, data });
  }

  deleteAlertService(data: { lastSvcReqId: string }) {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    const idToken = this.storeService.getIdToken();
    return alertsService.deleteAlerts({ vehicleId, accessToken, idToken, data });
  }

  getCurfewsService() {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    const idToken = this.storeService.getIdToken();
    return curfewService.getCurfews({ vehicleId, accessToken, idToken });
  }

  updateCurfewsService(data: UpdateCurfewPayload) {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    const idToken = this.storeService.getIdToken();
    return curfewService.updateCurfews({ vehicleId, accessToken, idToken, data });
  }

  createCurfewsService(data: CreateCurfewPayload) {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    const idToken = this.storeService.getIdToken();
    return curfewService.createCurfews({ vehicleId, accessToken, idToken, data });
  }

  deleteCurfewsService(data: { id: string }) {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    const idToken = this.storeService.getIdToken();
    return curfewService.deleteCurfews({ vehicleId, accessToken, idToken, data });
  }

  getGeofence() {
    const accessToken = this.storeService.getAccessToken();
    const { vehicleId } = this.storeService.getVehicleData();
    const idToken = this.storeService.getIdToken();
    return geofenceService.getGeofence({ accessToken, vehicleId, idToken });
  }

  createGeofence(data: CreateGeofencePayload) {
    const accessToken = this.storeService.getAccessToken();
    const { vehicleId } = this.storeService.getVehicleData();
    const idToken = this.storeService.getIdToken();
    return geofenceService.createGeofence({ accessToken, vehicleId, data, idToken });
  }

  updateGeofence(data: UpdateGeofencePayload) {
    const accessToken = this.storeService.getAccessToken();
    const { vehicleId } = this.storeService.getVehicleData();
    const idToken = this.storeService.getIdToken();
    return geofenceService.updateGeofence({ accessToken, vehicleId, data, idToken });
  }

  deleteGeofence(geofenceId: string) {
    const accessToken = this.storeService.getAccessToken();
    const { vehicleId } = this.storeService.getVehicleData();
    const idToken = this.storeService.getIdToken();
    return geofenceService.deleteGeofence({ accessToken, vehicleId, geofenceId, idToken });
  }

  getFolders() {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    return folderService.getFolders({ accessToken, vin });
  }

  getNews(folderId: string) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    return newsService.getNews({ accessToken, vin, folderId });
  }

  addNews(data: Category[]) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    return newsService.addNews({
      accessToken,
      vin,
      data,
    });
  }

  deleteNews(queryParams: { folderId: string; channelId: string }) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    return newsService.deleteNews({
      accessToken,
      vin,
      ...queryParams,
    });
  }

  getSports(folderId: string) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    return sportsService.getSports({ accessToken, vin, folderId });
  }

  addSports(data: SportsItem[]) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    return sportsService.addSports({
      accessToken,
      vin,
      data,
    });
  }

  deleteSports(queryParams: { folderId: string; channelId: string }) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    return sportsService.deleteSports({
      accessToken,
      vin,
      ...queryParams,
    });
  }

  getJourney(folderId: string, channelId: string) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();

    return journeyService.getJourney({
      accessToken,
      vin,
      folderId,
      channelId,
    });
  }

  updateJourney(data: Journey) {
    const accessToken = this.storeService.getAccessToken();
    const id_token = this.storeService.getIdToken();
    const { vin } = this.storeService.getVehicleData();

    return journeyService.updateJourney({
      accessToken,
      id_token,
      vin,
      data,
    });
  }

  addJourney(data: AddJourney) {
    const accessToken = this.storeService.getAccessToken();
    const id_token = this.storeService.getIdToken();
    const { vin } = this.storeService.getVehicleData();

    return journeyService.addJourney({
      accessToken,
      id_token,
      vin,
      data,
    });
  }

  deleteJourney(folderId: string, channelId: string) {
    const accessToken = this.storeService.getAccessToken();
    const id_token = this.storeService.getIdToken();
    const { vin } = this.storeService.getVehicleData();

    return journeyService.deleteJourney({
      accessToken,
      id_token,
      vin,
      folderId,
      channelId,
    });
  }

  getJourneys() {
    return mockService.getJourneys();
  }

  removeJourney() {
    return mockService.removeJourney();
  }

  getVehicleConditionCarInfo() {
    return mockService.getVehicleConditionCarInfo();
  }

  updatePin(data: ResetPinPayload) {
    const userId = this.storeService.getUserId();
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return pinService.pinUpdate({ data, userId, accessToken, locale });
  }

  resetPin(data: ResetPinByKBAPayload) {
    const userId = this.storeService.getUserId();
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return pinService.pinReset({ data, userId, accessToken, locale });
  }

  updatePasswordData(data: UpdatePasswordPayload | {}, userId: string, tenantId: string) {
    const accessToken = this.storeService.getAccessToken();
    return passwordService.updatePassword({ data, userId, accessToken, tenantId });
  }

  getSecurityQuestions() {
    const userId = this.storeService.getUserId();
    const accessToken = this.storeService.getAccessToken();
    return securityQuestionsService.getSecurityQuestions({
      userId,
      accessToken,
    });
  }

  updateSecurityQuestions(data: UpdateSecurityQuestionsByPinRequest['data']) {
    const userId = this.storeService.getUserId();
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return securityQuestionsService.updateSecurityQuestions({
      data,
      userId,
      accessToken,
      locale,
    });
  }

  setupSecurityPin(data: SetupPinAndSecurityQuestionsRequest['data']) {
    const userId = this.storeService.getUserId();
    const accessToken = this.storeService.getAccessToken();
    return securityPinService.setupSecurityPin({ data, userId, accessToken });
  }

  storeTokenResponse(data: { access_token: string; refresh_token: string; id_token: string }) {
    const { access_token, refresh_token, id_token } = data;

    this.storeService.setTokens({
      accessToken: access_token,
      refreshToken: refresh_token,
      idToken: id_token,
    });
    return data;
  }

  authenticateWithToken(token: string) {
    return authService.exchangeToken({ token }).then(({ data }) => {
      return this.storeTokenResponse(data);
    });
  }

  getIdmAccessToken({ code, verifier, stateCode }: { code: string; verifier: string; stateCode: string }) {
    return authService.getIdmAccessToken({ code, verifier, stateCode });
  }

  getAccessToken({ exchangeToken }: { exchangeToken: string }) {
    return authService.getAccessToken({ exchangeToken }).then(({ data }) => {
      data.id_token ||= exchangeToken;
      return data;
    });
  }

  refreshToken() {
    const refreshToken = this.storeService.getRefreshToken();

    if (!refreshToken) {
      return Promise.reject('Missing refresh token');
    }

    return authService.refreshToken({ refreshToken }).then((response) => {
      const { access_token, id_token } = response.data;

      this.storeService.setTokens({
        accessToken: access_token,
        idToken: id_token,
      });

      return response;
    });
  }

  getSxmKeyDetails({ sxmKey }: { sxmKey: string }) {
    return authService.getSxmKeyDetails({ sxmKey });
  }

  getUserInfo() {
    const accessToken = this.storeService.getAccessToken();
    return authService.getUserInfo({ accessToken });
  }

  queryRole({ filter, fields }: { filter: QueryFilterKeys; fields?: string[] }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return roleService.queryRole({ filter, fields, accessToken, locale });
  }

  getSvl(vehicleId: string) {
    return stolenVehicleLocatorService.getSvl(
      this.storeService.getLocale(),
      this.storeService.getAccessToken(),
      vehicleId || this.storeService.getVehicleData().vehicleId,
      this.storeService.getIdToken(),
    );
  }

  addVin(data: AddVinData) {
    const locale = this.storeService.getLocale();
    const userId = this.storeService.getUserId();
    const tenantId = this.storeService.getTenantId();

    return vinService.addVin({ data, locale, userId, tenantId });
  }

  removeVin({ vin }: { vin: string }) {
    const locale = this.storeService.getLocale();
    const userId = this.storeService.getUserId();
    const tenantId = this.storeService.getTenantId();

    return vinService.removeVin({ vin, locale, userId, tenantId });
  }

  getTelematicServices(vehicleId: string, locale: string) {
    const accessToken = this.storeService.getAccessToken();
    return telematicServices.getTelematicServices({
      vehicleId,
      accessToken,
      locale,
    });
  }

  getOTPContacts(userId: string) {
    return otpCodeService.getOTPContacts(userId);
  }

  searchStocks(ticker: string) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    return stockService.searchStocks({ accessToken, vin, ticker });
  }

  getStocksList(folderId: string) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    return stockService.getStocksList({ accessToken, vin, folderId });
  }

  addStocks(data: StockCategory[]) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    return stockService.addStocks({
      accessToken,
      vin,
      data,
    });
  }

  deleteStock(queryParams: { folderId: string; channelId: string }) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    return stockService.deleteStock({
      accessToken,
      vin,
      ...queryParams,
    });
  }

  async listAddresses(data: { isoCountryCode: string; query: string }) {
    const accessToken = this.storeService.getAccessToken();
    return searchAddressService.listAddresses({ accessToken, ...data });
  }

  async getFormattedAddress(data: { isoCountryCode: string; id: string }) {
    const accessToken = this.storeService.getAccessToken();
    return searchAddressService.getFormattedAddress({ accessToken, ...data });
  }

  getLink(linkConfig: string | undefined) {
    const accessToken = this.storeService.getAccessToken();
    const refreshToken = this.storeService.getRefreshToken();

    return getLinkService.getLink({ accessToken, refreshToken, linkConfig });
  }

  async retrieveCalendar() {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();

    return calendarService.retrieveCalendar({ accessToken, vin });
  }

  async saveCalendar(selectedCalendar: UserCalendar) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    const data = convertToSend(selectedCalendar);

    return calendarService.saveCalendar({ accessToken, vin, data });
  }

  async deleteCalendar() {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();

    return calendarService.deleteCalendar({ accessToken, vin });
  }
}
