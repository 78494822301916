import React, { useState, useEffect } from 'react';
import { useApi } from '@api';
import { ContentfulFile } from '@app/types/ContentfulComponent';
import InfoCardContent from '@components/InfoCard/InfoCardContent';

type CTAWiFiBlockProps = {
  title: string;
  description: string;
  infoImage?: ContentfulFile;
  linkText: string;
  urls: Array<data>;
  clickableCard?: boolean;
  tags?: string[];
};

type data = {
  url: string;
  name: string;
  tags?: string[];
};

const CTAWiFiBlock = ({ description, infoImage, title, linkText, urls, clickableCard }: CTAWiFiBlockProps): JSX.Element | null => {
  const api = useApi();
  const carMake = api.storeService.getVehicleData().make;
  const wifiUrls = urls?.find(({ tags }) => tags?.some((tag) => carMake.toLowerCase().includes(tag.toLowerCase())));
  const [isEligible, setIsEligible] = useState(false);

  useEffect(() => {
    const isWifiCapable = async () => {
      try {
        const {
          data: { allVehicleServiceDetails },
        } = await api.getVehicleDetails();
        const capableServices = allVehicleServiceDetails.vehicleServiceDetails.filter(({ vehicleServiceName }) =>
          ['Wifi Hotspot', 'Wi-Fi Product'].includes(vehicleServiceName),
        );
        setIsEligible(!!capableServices.length);
      } catch (error) {
        console.error('sxm wifi capable error', error);
      }
    };

    isWifiCapable();
  }, []);

  if (isEligible) {
    return (
      <InfoCardContent
        subtitle={title}
        shortDescription={description}
        url={wifiUrls?.url}
        infoImage={infoImage}
        clickableCard={clickableCard}
        linkText={linkText}
      />
    );
  }

  return null;
};

export default CTAWiFiBlock;
