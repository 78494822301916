import React, { FunctionComponent, useMemo } from 'react';
import { LibProvider } from '@cv/portal-components-lib';
import { useSubscriptionTheme } from '@components/SubscriptionWrapper/utils';
import { useTheme } from '@components/Theme';

interface LibThemeProviderProps {
  children: JSX.Element;
}

const LibThemeProvider: FunctionComponent<LibThemeProviderProps> = ({ children }) => {
  const theme = useTheme();
  const fontFamily = theme.get('font-family');
  const libTheme = useMemo(() => {
    return {
      color: theme.get('color-primary'),
      secondaryColor: theme.get('color-secondary'),
      backgroundColor: theme.get('manage-subscription-text-color'),
      greyColor: theme.get('color-grey-8a'),
      headerTextColor: theme.get('color-text'),
      infopageHeaderSecondaryTextColor: theme.get('infopage-header-secondary-text-color'),
      infopageFooterTextColor: theme.get('infopage-footer-text-color'),
      infopageFooterBgColor: theme.get('infopage-footer-bg-color'),
      fontFamily,
      themeCardSizeBorder: theme.get('theme-card-size-border'),
      themeCardColorBorder: theme.get('theme-card-color-border'),
      themeParagraphFontSize: theme.get('theme-paragraph-font-size'),
      cardColorBorderTop: theme.get('card-color-border-top'),
      themeCardColorBorderMain: theme.get('theme-card-color-border-main'),
      themeColorError: theme.get('theme-color-error'),
      buttonRadius: theme.get('theme-input-border-radius'),
    };
  }, [fontFamily]);
  return <LibProvider theme={libTheme}>{children}</LibProvider>;
};

export default LibThemeProvider;
