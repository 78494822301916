import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { FaCircle } from 'react-icons/fa';

import styles from './InputRadio.module.css';
import ControlLabel from '@components/ControlLabel';
import RoundToggleButton from '@components/RoundToggleButton';

type OptionType = {
  description?: string;
  label: string;
  value: string;
  analyticsEventName?: string;
};

type InputSelectProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  options: Array<OptionType>;
  onChange: () => void;
};

const InputRadio = ({
  name = '',
  value,
  onChange,
  options,
  className = '',
  helpers = null,
}: InputSelectProps): JSX.Element => {
  const getInitialValue = options.find((option: OptionType) => option.value === value || option.label === value);
  const [selectedOption, setSelectedOption] = useState(getInitialValue);

  const handleChange = (selectedOption: OptionType) => {
    setSelectedOption(selectedOption);
    onChange?.(selectedOption);

    // Helpers is an object from formik useField
    // it is needed to change state in Formik for custom inputs
    if (helpers) {
      const { setValue } = helpers;
      setValue(selectedOption.value);
    }
  };

  // immediately update value if it was selected based on label
  // this fixes some translation weirdness
  useEffect(() => {
    if (getInitialValue && value !== getInitialValue.value) {
      handleChange(getInitialValue);
    }
  }, [value, getInitialValue]);

  const inputClassName = clsx(styles.InputRadio, className);

  return (
    <>
      {options.map(({ value, label, description, analyticsEventName }) => {
        return (
          <div className={inputClassName} key={value}>
            <ControlLabel
              label={label}
              labelPlacement="right"
              control={
                <RoundToggleButton
                  checked={selectedOption?.value === value}
                  onClick={() => handleChange({ label, value })}
                  data-analyticseventname={analyticsEventName}
                  name={name}
                  classes={{
                    input: styles['InputRadio-input'],
                    button: styles['InputRadio-button'],
                    selected: styles['InputRadio-selected'],
                  }}
                >
                  <FaCircle />
                </RoundToggleButton>
              }
            />
            <div className={styles['InputRadio-description']}>{description}</div>
          </div>
        );
      })}
    </>
  );
};

export default InputRadio;
