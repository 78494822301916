import React from 'react';
import { NewsItem } from '@cv/portal-rts-lib/v0/information/news';
import { MISLabels } from '../types';
import SelectedItemsList from '../SelectedItemsList';

type Props = {
  labels: MISLabels;
  selectedCategories: NewsItem[];
  onRemove: (item: NewsItem) => void;
};

const RightColumn = ({ labels, selectedCategories, onRemove }: Props) => (
  <SelectedItemsList
    listTitle={labels.yourNews}
    items={selectedCategories}
    textProperty="channelName"
    onRemove={onRemove}
  />
);

export default RightColumn;
