import React, { useEffect } from 'react';
import { ContentfulConnect } from '@cv/webframework-react-components';
import { connect, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import config from '@config/config';
import App, { AppProps } from './App';
import { getOemName } from '@utils/oem';
import { RootState } from '@app/reducers';
import { parseSearchParams } from '@utils/urlSearchParams';
import { SETTINGS_ACTIONS } from '@redux/actions';
import { mapLocale, unMapLocale } from './localeMapper';
import { getCookie } from '@utils/cookies';

const filterTags = (pageContent: object, oemName: string) => {
  const filterPageContent = (obj: any): any => {
    if (typeof obj !== 'object' || obj == null) {
      return obj;
    }

    const valueExcludedByTags = (value: string, tags: string[] | undefined) => {
      if (!Array.isArray(tags)) {
        // only exclude via an array of tags
        return false;
      }
      if (isEmpty(tags)) {
        // no tags means include all
        return false;
      }
      // excluded if not explicitly listed, matching case-insensitively
      return !tags.find((member) => member.toLowerCase() === value.toLowerCase());
    };

    if (Array.isArray(obj)) {
      return obj.map(filterPageContent).filter((item) => !isEmpty(item));
    }

    const hasExcludingTags =
      valueExcludedByTags(oemName, obj?.tags) || valueExcludedByTags(config.getEnvironmentEnum(), obj?.envTags);

    if (hasExcludingTags) {
      return {};
    }

    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, filterPageContent(value)]));
  };

  return filterPageContent({ ...pageContent });
};

const useSetContentfulLanguage = (language?: string) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (language) {
      dispatch({ type: SETTINGS_ACTIONS.SET_CONTENTFUL_LOCALE, data: unMapLocale(language) });
    }
  }, [language]);
};

const ContentfulTagFilter = ({ pageContent = {}, pageContentLocale }: AppProps) => {
  useSetContentfulLanguage(pageContentLocale);

  const isContentLoaded = Boolean(Object.keys(pageContent).length);
  if (!isContentLoaded) {
    // TODO: Display loading indicator. We can't use LoaderBackdrop here because it relies on a theme
    return null;
  }

  const [params] = parseSearchParams(location.search);
  // TODO: move getting tenantId from all possible places to one function, to have it in one place
  // and prevent bugs where we missing to provide new way to take tenantId
  const tenantId = getCookie('b');
  const oemToFilter = tenantId || params.tenantId || getOemName();

  const filteredPageContent = filterTags(pageContent, oemToFilter);

  return <App pageContent={filteredPageContent} />;
};

const mapStateToProps = (state: RootState) => {
  const {
    settingsReducer: { locale },
  } = state;

  return {
    locale: mapLocale(locale),
  };
};

export default connect(mapStateToProps)(
  ContentfulConnect(ContentfulTagFilter, {
    pageContent: { id: config.get<string>('CONTENTFUL_PAGE_ID'), depth: 10 },
  }),
);
