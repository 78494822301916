import config from '@config/config';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { userCreateUrls } from '@cv/portal-idm-lib/user/user-create/enums';
import { UserCreatePayload, UserCreate } from '@cv/portal-idm-lib/user/user-create/models';
import { userCreate } from '@cv/portal-idm-lib/user/user-create';
import { formatUserData } from '../formatters/';

type CreateAccountArgs = {
  data: UserCreatePayload;
  accessToken: string;
  locale: string;
};

export default class AccountCreationService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  createUser({ data, accessToken, locale }: CreateAccountArgs) {
    const payload: UserCreate = {
      data,
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        'Accept-Language': locale,
      },
    };

    const idmApiProxyBaseUrl = config.get<string>('IDM_API_PROXY_BASE_URL');
    if (idmApiProxyBaseUrl) {
      const { userCreate } = userCreateUrls;

      const url = new URL(userCreate, idmApiProxyBaseUrl);
      payload.url = url.toString();

      payload.headers['CV-Tenant-Id'] = data.tenantId;
    }

    return userCreate(payload).then((response) => ({ data: formatUserData(response.data) }));
  }
}
