import React, { useCallback, useEffect, useState } from 'react';
import ErrorModal from '@components/MobileInformationService/ErrorModal';
import { useApi } from '@api';
import Loader from '@components/Loader';
import { FolderPayload, MISLabels } from '@components/MobileInformationService/types';
import ContentLayout from '../ContentLayout';
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
import { StocksItem } from '@cv/portal-rts-lib/v0/information/stocks/models';
import { ExistingStocks, ExistingStocksItem, StockCategory } from '@cv/portal-rts-lib/v0/information/stocks';

export type StockProps = {
  labels: MISLabels;
  folders?: FolderPayload[];
};

const Stocks = ({ labels, folders = [{}] }: StockProps) => {
  const api = useApi();
  const [stockFound, setStockFound] = useState<StocksItem>();
  const [isLoading, setLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [savedStocks, setSavedStocks] = useState<ExistingStocksItem[]>(null);
  const [deleteStock, setDeleteStock] = useState<string | undefined>(undefined);
  const [ticker, setTicker] = useState('');
  const { folderId = '', folderName = '' } = folders[0];

  const launchLoading = useCallback(
    (requestPromise: Promise<any>) => {
      setLoading(true);
      return requestPromise;
    },
    [setLoading],
  );

  const fetchExistingStocks = useCallback(
    () => launchLoading(api.getStocksList(folderId)),
    [launchLoading, api, folderId],
  );
  const remove = async (channelId: string) => await api.deleteStock({ folderId, channelId });

  const saveNewStock = (data: StockCategory[]) => launchLoading(api.addStocks(data)).then(fetchExistingStocks);
  const onSave = () => update(saveNewStock(attachFolder(savedStocks)));

  function attachFolder(allSavedStocks: ExistingStocksItem[]): StockCategory[] {
    if (stockFound) {
      const newSearchedStock = {
        ticker: stockFound.ticker,
        exchange: stockFound.exchange,
        name: stockFound.companyName,
        folderId: folderId,
        folderName: folderName,
      };
      allSavedStocks.push(newSearchedStock as ExistingStocksItem);
    }

    return allSavedStocks as StockCategory[];
  }

  const update = useCallback(
    (fetchCategoriesPromise: Promise<{ data: ExistingStocks }>) =>
      fetchCategoriesPromise
        .then(({ data = [] }) => setSavedStocks(data))
        .then(() => setDeleteStock)
        .catch(() => {
          if (!savedStocks) {
            setSavedStocks([]);
          }
        })
        .finally(() => {
          setLoading(false);
        }),
    [savedStocks, setSavedStocks, setLoading, setDeleteStock],
  );
  const removeStock = (channelId: string) => update(remove(channelId).then(fetchExistingStocks));
  useEffect(() => {
    if (!savedStocks) {
      update(fetchExistingStocks());
    }
  }, [fetchExistingStocks]);

  const searchStocks = async (ticker: string) => {
    setLoading(true);
    try {
      const response: { data: StocksItem[] } = await api.searchStocks(ticker);
      setStockFound(response && response.data && response.data.length > 0 ? response.data[0] : undefined);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching Stocks Data', error);
      setLoading(false);
      setShowErrorModal(true);
    }
  };
  const handleSubmit = (ticker: string) => {
    if (ticker !== null && ticker !== '') {
      setStockFound(undefined);
      searchStocks(ticker);
    } else {
      console.log('will not submit empty string');
    }
  };

  if (isLoading && !deleteStock) {
    return <Loader style={{ position: 'absolute' }} />;
  }

  const onRemove = ({ channelId }: ExistingStocksItem) => {
    removeStock(channelId);
  };

  const saved = savedStocks?.some((stk) => stockFound && stk['ticker'] === stockFound?.ticker);
  return (
    <>
      <ContentLayout
        left={
          <LeftColumn
            labels={labels}
            stock={stockFound}
            handleSubmit={handleSubmit}
            ticker={ticker}
            setTicker={setTicker}
            saved={saved}
            onSave={onSave}
          />
        }
        right={<RightColumn labels={labels} savedStocks={savedStocks || []} onRemove={onRemove} />}
      />
      <ErrorModal
        showErrorModal={showErrorModal}
        handleCloseErrorModal={() => setShowErrorModal(false)}
        title={labels.stocksErrorHeading}
        description={labels.stocksErrorDescription}
        buttonText={labels.stocksOk}
      />
    </>
  );
};

export default Stocks;
