// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2QaRju4ivJtED6Y6TQxCwV {\n  color: var(--theme-color-text);\n  margin-left: 1rem;\n  margin-right: 1rem;\n}\n\n._2QaRju4ivJtED6Y6TQxCwV h1 {\n  color: var(--theme-color-header);\n  font-size: 1.25rem;\n  margin-bottom: 1rem;\n  text-align: center;\n  text-transform: uppercase;\n}\n\n._3uZ4dFF12bbixyN40LJtup {\n  text-align: center;\n}\n\n@media (min-width: 768px) {\n  ._2QaRju4ivJtED6Y6TQxCwV {\n    margin: 3rem auto;\n    max-width: 560px;\n    width: 100%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/LinkVehicle/LinkVehicle.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;EAChC,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;EACb;AACF","sourcesContent":[".card {\n  color: var(--theme-color-text);\n  margin-left: 1rem;\n  margin-right: 1rem;\n}\n\n.card h1 {\n  color: var(--theme-color-header);\n  font-size: 1.25rem;\n  margin-bottom: 1rem;\n  text-align: center;\n  text-transform: uppercase;\n}\n\n.error {\n  text-align: center;\n}\n\n@media (--viewport-s) {\n  .card {\n    margin: 3rem auto;\n    max-width: 560px;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "_2QaRju4ivJtED6Y6TQxCwV",
	"error": "_3uZ4dFF12bbixyN40LJtup"
};
export default ___CSS_LOADER_EXPORT___;
