import React, { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { useSelector } from 'react-redux';

import { Breach } from '@cv/portal-rts-lib/history/models/history';

import styles from './AlertHistoryContent.module.css';

type AlertHistoryTableProps = {
  tableFirstColLabel: string;
  tableSecondColLabel: string;
  tableThirdColLabel: string;
  data: AlertHistory[];
};

type AlertHistory = Breach & {
  street: string;
  addressLine: string;
}

export default function AlertHistoryTable({
  tableFirstColLabel,
  tableSecondColLabel,
  tableThirdColLabel,
  data,
}: AlertHistoryTableProps) {
  const locale: string = useSelector(({ settingsReducer }) => settingsReducer.locale);
  const dateFormatOptions = locale === 'es-MX' ? { locale: es } : null;
  const [alertHistory, setAlertHistory] = useState<AlertHistory[]>([]);

  useEffect(() => {
    //On pagination chage state is becoming Stale, so added timeout
    setAlertHistory([]);
    window.setTimeout(() => {
      setAlertHistory(data);
    }, 50);
  }, [data]);

  return (
    <table className={styles['alert-history-table']}>
      <thead className={styles['alert-history-table-head']}>
        <tr className={styles['alert-history-table-row']}>
          <th className={styles['alert-history-table-head-cell']}>{tableFirstColLabel}</th>
          <th className={styles['alert-history-table-head-cell']}>{tableSecondColLabel}</th>
          <th className={styles['alert-history-table-head-cell']}>{tableThirdColLabel}</th>
        </tr>
      </thead>
      <tbody className={styles['alert-history-table-body']}>
        {alertHistory.map(({ alertId, name, breachDateTime, street, addressLine }: AlertHistory) => {
          const parseIsoStartDateTime = parseISO(breachDateTime);
          const formattedStartDate = format(parseIsoStartDateTime, 'MM/dd/yyyy', dateFormatOptions);
          const formattedStartTime = format(parseIsoStartDateTime, 'h:mm a');

          return (
            <tr className={styles['alert-history-table-body-row']} key={`${name}-${alertId}`}>
              <td className={styles['alert-history-table-data-cell']}>
                <div>{name}</div>
              </td>
              <td className={styles['alert-history-table-data-cell']}>
                <div>{street}</div>
                <div>{addressLine}</div>
              </td>
              <td className={styles['alert-history-table-data-cell']}>
                <div>{formattedStartDate}</div>
                <div>{formattedStartTime}</div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
