import React from 'react';
import { useSelector } from 'react-redux';

import ContentRenderer from '@components/ContentRenderer';
import { REDUCER_MAP } from '@redux/dataMap';
import ContentfulComponent from '@app/types/ContentfulComponent';
import { Product } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { VehicleServiceDetails } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';

export const FILTER_TYPE = {
  CAPABLE_PRODUCTS: 'Capable products',
  CAPABLE_SERVICES: 'Capable services',
};

const VisibilityFilterSelectorsMap = {
  [FILTER_TYPE.CAPABLE_PRODUCTS]: (reducers) => reducers[REDUCER_MAP.account].capableServices?.data,
  [FILTER_TYPE.CAPABLE_SERVICES]: (reducers) =>
    reducers[REDUCER_MAP.account].capableServices?.data.map(({ services }) => services).flat(),
};

const VisibilityFilterFilters: Record<string, (data: any, value: string) => boolean> = {
  [FILTER_TYPE.CAPABLE_PRODUCTS]: (data, searchedProductName) =>
    data.find((product: Product) => product.productName.toLowerCase() === searchedProductName.toLowerCase()),
  [FILTER_TYPE.CAPABLE_SERVICES]: (data, searchedServiceName) =>
    data.find(
      (service: VehicleServiceDetails) =>
        service.vehicleServiceName.toLowerCase() === searchedServiceName.toLowerCase(),
    ),
};

const getDataFilter = (name: string) => {
  const filter = VisibilityFilterFilters[name];
  if (filter === undefined) {
    console.warn(`No filter found for '${name}' in VisibilityFilter - nothing will be rendered.`);
  }

  return filter || false;
};

const getSelector = (name: string) => {
  const selector = VisibilityFilterSelectorsMap[name];
  if (selector === undefined) {
    console.warn(`No selector found for '${name}' in VisibilityFilter - nothing will be rendered.`);
  }
  return selector || (() => undefined);
};

type VisibilityFilterProps = {
  contentSections: Array<ContentfulComponent>;
  filterBy: string;
  filterValue: string;
  name: string;
};

function VisibilityFilter({ filterBy, filterValue, name, contentSections }: VisibilityFilterProps): JSX.Element | null {
  const selector = getSelector(filterBy);
  const data = useSelector(selector);

  if (!data) {
    // nothing in the store yet or no selector found
    return null;
  }

  const dataFilter = getDataFilter(filterBy);

  if (!dataFilter || !dataFilter(data, filterValue)) {
    return null;
  }

  return <ContentRenderer name={name} content={contentSections} />;
}

export default VisibilityFilter;
