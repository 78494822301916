import { useCallback } from 'react';
import { ServicesContentItem } from '@customTypes/ContentfulComponent';
import { useVehicleSelector } from '@redux/selectors/vehicle';

export const checkRenderServices = (contentfulItem: ServicesContentItem, activeServices: string[]) =>
  !contentfulItem.services ||
  contentfulItem.services.every((supportedService) => activeServices.includes(supportedService));

const useFilterByServices = () => {
  const vehicle = useVehicleSelector();
  return useCallback(
    (contentItems: ServicesContentItem[]) =>
      contentItems.filter((item) => checkRenderServices(item, vehicle.activeServices)),
    [vehicle],
  );
};

export default useFilterByServices;
