// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2pqHjlZ2kljNK_YxuK67qz {\n  display: flex;\n  align-items: flex-start;\n}\n\n._1wVRMCB1-ZHjk-84KmECXg {\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  color: var(--theme-color-white);\n}\n\n._1W5-gbxjW_ZqJn9BL1sLAU {\n  text-transform: uppercase;\n  font-size: 18px;\n  font-weight: 700;\n  margin-bottom: 1rem;\n}\n\n.dT16qS3pkyZ0-EOac3cgv {\n  width: 45px;\n  margin-bottom: 1rem;\n}\n\n._22ki2IayEsdqyfEYogvC3h {\n  font-size: 24px;\n  margin-bottom: 0.5rem;\n}\n\np._1ag4BgVkxu1kpe57Jz5JAj {\n  font-size: 12px;\n  margin-bottom: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ActionCard/ActionCardLayout.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,+BAA+B;AACjC;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".container {\n  display: flex;\n  align-items: flex-start;\n}\n\n.wrapper {\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  color: var(--theme-color-white);\n}\n\n.title {\n  text-transform: uppercase;\n  font-size: 18px;\n  font-weight: 700;\n  margin-bottom: 1rem;\n}\n\n.icon {\n  width: 45px;\n  margin-bottom: 1rem;\n}\n\n.subtitle {\n  font-size: 24px;\n  margin-bottom: 0.5rem;\n}\n\np.description {\n  font-size: 12px;\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_2pqHjlZ2kljNK_YxuK67qz",
	"wrapper": "_1wVRMCB1-ZHjk-84KmECXg",
	"title": "_1W5-gbxjW_ZqJn9BL1sLAU",
	"icon": "dT16qS3pkyZ0-EOac3cgv",
	"subtitle": "_22ki2IayEsdqyfEYogvC3h",
	"description": "_1ag4BgVkxu1kpe57Jz5JAj"
};
export default ___CSS_LOADER_EXPORT___;
