import React from 'react';
import ContentRenderer from "@components/ContentRenderer";

import styles from './Tab.module.css';

export default function TabContent({ content, className }) {
  return (
    <div className={styles.TabContent}>
      <ContentRenderer
        name={content[0].name}
        content={content}
        className={className}
      />
    </div>
  )
}
