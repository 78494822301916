import set from 'lodash/set';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { UpdateSecurityQuestionsByPinRequest } from '@cv/portal-idm-lib/security-questions-pin/models';
import { updateSecurityQuestionsByPin } from '@cv/portal-idm-lib/security-questions-pin';
import { getSecurityQuestions } from '@cv/portal-idm-lib/security-questions';
import { SecurityQuestionsRequest } from '@cv/portal-idm-lib/security-questions/models';

type GetSecurityQuestions = {
  userId: string;
  accessToken: string;
};

type UpdateSecurityQuestions = {
  data: UpdateSecurityQuestionsByPinRequest['data'];
  userId: string;
  accessToken: string;
  locale: string;
};

export default class SecurityQuestionsService {
  private environment: Environment;
  private payload: Omit<UpdateSecurityQuestionsByPinRequest, 'data'>;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
    this.payload = {
      environment,
      pathParams: {
        id: '',
      },
      headers: {
        Authorization: 'token',
        'If-Match': '*',
      },
    };
  }

  getSecurityQuestions({ userId, accessToken }: GetSecurityQuestions) {
    const request: SecurityQuestionsRequest = {
      environment: this.environment,
      pathParams: {
        id: userId,
      },
      headers: {
        Authorization: accessToken,
      },
    };
    return getSecurityQuestions(request);
  }

  updateSecurityQuestions({ data, userId, accessToken, locale }: UpdateSecurityQuestions) {
    const { payload } = this;
    set(payload, 'data', data);
    set(payload, 'pathParams.id', userId);
    set(payload, 'headers.Authorization', accessToken);
    set(payload, 'headers.Accept-Language', locale);
    return updateSecurityQuestionsByPin(payload as UpdateSecurityQuestionsByPinRequest);
  }
}
