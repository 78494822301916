import { Environment } from "@cv/portal-common-lib/ajax/constants";
import { getTelematicsServicesByVin } from "@cv/portal-cps-lib/vehicle/service-management";
import { SearchTelematicServiceRequest } from "@cv/portal-cps-lib/vehicle/service-management/models/search-telematics-service";

type TelematicServicesArgs = {
    vehicleId: string;
    accessToken: string;
    locale: string;
}

export default class TelematicServices {
    private environment: Environment;
    constructor(environment = Environment.LOCAL) {
        this.environment = environment
    }
    async getTelematicServices({ vehicleId, accessToken, locale }: TelematicServicesArgs) {
        const request: SearchTelematicServiceRequest = {
            environment: this.environment,
            headers: {
                Authorization: accessToken,
                'Accept-Language': locale,
            },
            data: { vehicleId },
        };
        const response = await getTelematicsServicesByVin(request);
        return { data: response.data || {} };
    }
}
