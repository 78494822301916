export type VehicleHealth = {
  lastUpdateTime: string,
  vehicleType: 'sedan' | 'truck' | 'suv',
  cockpit: {
    fuelAutonomy: {
      unit: string,
      value: number
    },
    totalMileage: {
      unit: string,
      value: number
    }
  },
  odometer: {
    UoM: string,
    value: string,
    lastUpdatedTime: string,
  }
  pressure: {
    flPressure: {
      unit: string,
      value: number
    },
    frPressure: {
      unit: string,
      value: number
    },
    rlPressure: {
      unit: string,
      value: number
    },
    rrPressure: {
      unit: string,
      value: number
    },
    flStatus: number,
    frStatus: number,
    rlStatus: number,
    rrStatus: number
  },
  healthStatus: {
    maintenance: {
      distanceToMaintenance: {
        unit: string,
        value: number
      },
      timeToMaintenance: number
    },
    malfunctionIndicatorLamps: {
      absWarning: number,
      airbagWarning: number,
      brakeFluidWarning: number,
      emergencyBrakingWarning: number,
      oilPressureWarning: number,
      tyrePressureWarning: number,
      oilPressureSwitch: number,
      lampRequest: number
    }
 },
  lockStatus: {
    lockStatus: LockStatus,
    doorStatusFrontLeft: DoorStatus,
    doorStatusFrontRight: DoorStatus,
    doorStatusRearLeft: DoorStatus,
    doorStatusRearRight: DoorStatus,
    engineHoodStatus: DoorStatus,
    hatchStatus: DoorStatus
  }
};

export enum DoorStatus {
  Closed = 'closed',
  Open ='open',
}

export enum LockStatus {
  Locked = 'locked',
  Unlocked = 'unlocked'
}
