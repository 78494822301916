import React, { useEffect, useState } from 'react';
import { useApi } from '@api';

import { formatAnalyticsEventName } from '@cv/portal-common-lib';

import styles from './AgreementLink.module.css';

type PortalTextLinkProps = {
  name: string;
  linkText: string;
  regularText: string;
  textAlignment?: 'left' | 'right' | 'center' | 'justify';
  analyticsEventName?: string;
};

function AgreementLink({ linkText, regularText, textAlignment, analyticsEventName }: PortalTextLinkProps) {
  const api = useApi();
  const formatEventName = formatAnalyticsEventName(analyticsEventName);
  const [agreementDocUrl, setAgreementDocUrl] = useState<string | undefined>();

  useEffect(() => {
    const vehicleDetails = api.storeService.getVehicleData();
    const retrieveAgreement = async () => {
      try {
        const {
          data: { payload },
        } = await api.getAgreement();
        const agreement = payload.find((details) => details.vin === vehicleDetails.vin);

        setAgreementDocUrl(agreement?.agreementUrl);
      } catch (error) {
        console.error(error);
      }
    };
    retrieveAgreement();
  }, []);

  const openAgreement = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const win = window.open(agreementDocUrl, '_blank');
    win?.focus();
  };

  if (!agreementDocUrl) {
    return null;
  }

  return (
    <div className={`${styles.AgreementLinkContainer} text-${textAlignment}`}>
      <p>
        {regularText}{' '}
        <button type="button" onClick={openAgreement} data-analyticseventname={formatEventName}>
          {linkText}
        </button>
      </p>
    </div>
  );
}

export default AgreementLink;
