import React from 'react';
import clsx from 'clsx';
import { MISLabels } from '../types';
import Button from '@components/Button';
import styles from '@components/MobileInformationService/MobileInformationService.module.css';
import Grid from '@components/Grid';
import { StocksItem } from '@cv/portal-rts-lib/v0/information/stocks/models';

export type Props = {
  labels: MISLabels;
  onSave: () => void;
  handleSubmit: (term: string) => void;
  stock?: StocksItem;
  saved: boolean;
  ticker: string;
  setTicker: React.Dispatch<React.SetStateAction<string>>;
};

const LeftColumn = ({ labels, stock, handleSubmit, saved, onSave, ticker, setTicker }: Props) => {
  return (
    <div className={styles.container}>
      <h4 className={clsx(styles['sports-intro'], styles['stocks-intro'])}>{labels.stocksIntro}</h4>
      <form
        className={styles.search_farm}
        onSubmit={() => {
          handleSubmit(ticker);
        }}
      >
        <input
          type="text"
          name="search"
          placeholder="Search"
          value={ticker}
          onChange={(e) => setTicker(e.target.value)}
          autoComplete="off"
          title="search ticker"
        />
        <Button className={styles.search_btn} type="submit" variant="filled" disabled={!ticker.trim()}>
          {labels.stocksSearch}
        </Button>
      </form>
      {stock && (
        <div>
          <div className={styles.stock_imp}>
            <h3 data-testid="companyName">{stock.companyName}</h3>
            <h3>{stock.ticker}</h3>
            <h3>
              {stock.lastValue} {'\u00A0'} {stock.change}
            </h3>
          </div>
          <Grid className={styles.list_grid} columns={'4'}>
            <dl className={styles.list_items}>
              <dt>{labels.stocksLastTrade}</dt>
              <dd>{stock.lastValue}</dd>
              <dt>{labels.stocksPreviousClose}</dt>
              <dd>{stock.previousClose}</dd>
              <dt>{labels.stocksChange}</dt>
              <dd>{stock.change}</dd>
              <dt>{labels.stocksPreviousVolume}</dt>
              <dd>{stock.previousVolume}</dd>
              <dt>{labels.stocksTradeTime}</dt>
              <dd>{stock.timeLast}</dd>
              <dt>{labels.stocksYearLow}</dt>
              <dd>{stock.yearLow}</dd>
              <dt>{labels.stocksYearHigh}</dt>
              <dd>{stock.yearHigh}</dd>
              <dt>{labels.stocksOpen}</dt>
              <dd>{stock.dayOpen}</dd>
              <dt>{labels.stocksDayHigh}</dt>
              <dd>{stock.dayHigh}</dd>
              <dt>{labels.stocksDayLow}</dt>
              <dd>{stock.dayLow}</dd>
              <dt>{labels.stocksEps}</dt>
              <dd>{stock.eps}</dd>
              <dt>{labels.stocksRatio}</dt>
              <dd>{stock.peRatio}</dd>
              <dt>{labels.stocksVolume}</dt>
              <dd>{stock.volume}</dd>
              <dt>{labels.stocksBeta}</dt>
              <dd>{stock.beta}</dd>
            </dl>
          </Grid>
          <span className={styles['stocks-disclaimer']}>{labels.stocksDisclaimer}</span>
          <Button className={clsx(styles['bottom-btn-save'], styles['stocks-save'])} onClick={onSave} disabled={saved}>
            {!saved ? labels.misSave : labels.misSaved}
          </Button>
        </div>
      )}
    </div>
  );
};

export default LeftColumn;
