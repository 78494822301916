// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".k10U7CrlaNl5bT27kbIpH {\n  --label-color: var(--theme-color-grey-54);\n  --callout-bg-color: var(--theme-color-grey-4a);\n  --callout-color: var(--theme-color-white);\n\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  color: var(--color);\n  font-family: inherit;\n  font-size: 0.75rem;\n  line-height: 0.875rem;\n}\n\n._35pAvMIdKvcvlq7MogRZPb {\n  margin: 0 0.5rem;\n}\n\n._32JmN-Yk8JBjFsf5jVdnsE {\n  width: auto;\n  margin-bottom: 0.25rem;\n}\n\n@media (min-width: 768px) {\n  ._32JmN-Yk8JBjFsf5jVdnsE {\n    max-width: 530px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Label/Label.module.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,8CAA8C;EAC9C,yCAAyC;;EAEzC,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".Label {\n  --label-color: var(--theme-color-grey-54);\n  --callout-bg-color: var(--theme-color-grey-4a);\n  --callout-color: var(--theme-color-white);\n\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  color: var(--color);\n  font-family: inherit;\n  font-size: 0.75rem;\n  line-height: 0.875rem;\n}\n\n.Help-callout {\n  margin: 0 0.5rem;\n}\n\n.Help-callout-popup {\n  width: auto;\n  margin-bottom: 0.25rem;\n}\n\n@media (--viewport-s) {\n  .Help-callout-popup {\n    max-width: 530px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Label": "k10U7CrlaNl5bT27kbIpH",
	"Help-callout": "_35pAvMIdKvcvlq7MogRZPb",
	"Help-callout-popup": "_32JmN-Yk8JBjFsf5jVdnsE"
};
export default ___CSS_LOADER_EXPORT___;
