// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3NLrOiByasKTdkamu71Hee {\n  display: flex;\n  align-items: flex-start;\n  padding: 0.4rem 0;\n}\n\n._3NLrOiByasKTdkamu71Hee span {\n  margin-left: 0.4rem;\n  font-size: 0.8rem;\n  font-weight: bold;\n}\n\n._3NLrOiByasKTdkamu71Hee input {\n  margin: 0;\n}\n\n._3NLrOiByasKTdkamu71Hee .K0B-YzLUqVURmW2Kn1j2y {\n  margin-left: 0;\n  padding: 0;\n}\n\n._3NLrOiByasKTdkamu71Hee ._2T96K03ldoH4h-tgQt71BV {\n  padding: 0;\n  background-color: transparent;\n  color: transparent;\n  border-color: var(--theme-color-primary);\n}\n", "",{"version":3,"sources":["webpack://./src/components/InputCheckbox/InputCheckbox.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,6BAA6B;EAC7B,kBAAkB;EAClB,wCAAwC;AAC1C","sourcesContent":[".InputCheckbox {\n  display: flex;\n  align-items: flex-start;\n  padding: 0.4rem 0;\n}\n\n.InputCheckbox span {\n  margin-left: 0.4rem;\n  font-size: 0.8rem;\n  font-weight: bold;\n}\n\n.InputCheckbox input {\n  margin: 0;\n}\n\n.InputCheckbox .InputCheckbox-button {\n  margin-left: 0;\n  padding: 0;\n}\n\n.InputCheckbox .InputCheckbox-input {\n  padding: 0;\n  background-color: transparent;\n  color: transparent;\n  border-color: var(--theme-color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InputCheckbox": "_3NLrOiByasKTdkamu71Hee",
	"InputCheckbox-button": "K0B-YzLUqVURmW2Kn1j2y",
	"InputCheckbox-input": "_2T96K03ldoH4h-tgQt71BV"
};
export default ___CSS_LOADER_EXPORT___;
