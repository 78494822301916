import React from 'react';
import { MISLabels } from '../types';
import { ExistingStocksItem } from '@cv/portal-rts-lib/v0/information/stocks';
import SelectedItemsList from '@components/MobileInformationService/SelectedItemsList';

type Props = {
  labels: MISLabels;
  savedStocks: ExistingStocksItem[];
  onRemove: (item: ExistingStocksItem) => void;
};

const RightColumn = ({ labels, savedStocks, onRemove }: Props) => (
  <SelectedItemsList listTitle={labels.yourStocks} items={savedStocks} textProperty="name" onRemove={onRemove} />
);

export default RightColumn;
