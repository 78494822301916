import React, { ChangeEventHandler, useEffect, useState, useCallback } from 'react';
import { FaTimes } from 'react-icons/fa';

import { CreatorForm } from '@components/EntryCreator/';
import Dialog from '@components/Dialog';
import { ContentfulRichText } from '@cv/webframework-react-components';
import useToggle from '@hooks/useToggle';

import { formatPhoneNumber } from 'react-phone-number-input';
import ApiRequestPayload from '@customTypes/ApiRequestPayload';
import InfoBox from '../InfoBox/InfoBoxView';
import '../InfoBox/InfoBox.css';
import '../EntryCreator/EntryCreator.module.css';
import { ContactFormData, useApi } from '@api';
import { ACTION_MAP } from '@redux/dataMap';
import { useDispatch, useSelector } from 'react-redux';
import CardView from '@components/Card/CardView';
import RichTextContainer, { RichTextContainerProps } from '@components/RichTextContainer';
import { ServerLabel } from '@utils/labels';
import CreatorButton from '@components/EntryCreator/CreatorButton';
import * as yup from 'yup';
import { RootState } from '@app/reducers';
import Loader from '@components/Loader';
import FormikCheckboxField from '@components/FormikFields/FormikCheckboxField';
import useLabels, { UseLabelsReturn } from '@hooks/useLabels';
import isNumber from 'lodash/isNumber';
import { FormField } from '@components/EntryCreator/CreatorForm';

export const NOTIFICATIONS_LIMIT = 3;
export const CONTACTS_LIMIT = 4;

interface FetchedDataType {
  _id: string | undefined;
  primaryPhone: {
    number: string;
    type: string;
    typeShortcut: string;
  };
  secondaryPhone: {
    number: string;
    type: string;
    typeShortcut: string;
  };
  firstName: string;
  fathersName: string;
  relationship: string;
  email: string;
  notificationChannel: string;
}

export type ContactInfoCardProps = {
  emergencyContactsHeader: RichTextContainerProps;
  automaticCollisionNotificationHeader: RichTextContainerProps;
  labels: ServerLabel[];
  form: any;
  dataSharingConsentDialogue: any;
  removeContactDialogueBox: any;
};

const ContactInfoCard = ({
  emergencyContactsHeader,
  automaticCollisionNotificationHeader,
  labels,
  form,
  dataSharingConsentDialogue,
  removeContactDialogueBox,
}: ContactInfoCardProps) => {
  const api = useApi();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState<FetchedDataType[]>([]);
  const [isFormOpen, toggleForm] = useToggle(false);
  const [formOpenForEditIndex, toggleEditForm] = useState(-1);
  const [formInitialValues, setInitialValues] = useState<ContactFormData | null>(null);
  const [emailNotification, setEmailNotification] = useState(false);
  const isCcs = useSelector(({ vehicleReducer }: RootState) => !!vehicleReducer.vehicle?.additionalFeatures?.ccsGen);
  const vehicleId = useSelector(({ vehicleReducer }: RootState) => vehicleReducer.vehicle?.vehicleId);
  const labelsCollection = useLabels(labels);

  const onFormEdit = (index?: number) => {
    if (!(isNumber(index) && Number.isInteger(index))) {
      toggleEditForm(-1);
      return;
    }
    const data = fetchedData[index] || {};
    const mappedValues: Partial<ContactFormData> = {
      givenName: data.firstName,
      surname: data.fathersName,
      email: data.email,
      relationship: data.relationship,
      primaryPhone: data.primaryPhone.number,
      primaryPhoneType: data.primaryPhone.type,
      secondaryPhone: data.secondaryPhone.number,
      secondaryPhoneType: data.secondaryPhone.type,
    };
    const notifications = fetchedData[index].notificationChannel || '';
    notifications.includes('EMAIL') && setEmailNotification(true);
    setInitialValues(mappedValues as ContactFormData);
    toggleEditForm(index);
  };

  const onFormOpen = () => {
    toggleForm(true);
  };

  const onFormClose = () => {
    toggleForm(false);
    toggleEditForm(-1);
    setEmailNotification(false);
    setInitialValues(null);
  };

  const locale: string = api.storeService.getLocale();
  const modelKey = 'contacts';

  const fetchData = async () => {
    const { data }: { data: FetchedDataType[] } = await api.fetchService(modelKey, locale);
    dispatch({ type: ACTION_MAP[modelKey], data });
    setFetchedData(data);
  };

  useEffect(() => {
    fetchData();
  }, [vehicleId, locale]);

  const updateEntry = async (submittedPayload: ContactFormData, index: number) => {
    setLoading(true);
    toggleEditForm(-1);
    submittedPayload._id = fetchedData[index]._id;
    await api.updateService(modelKey, 'PUT', submittedPayload as ApiRequestPayload);
    await fetchData();
    setLoading(false);
  };

  const removeEntry = async (index: number) => {
    setLoading(true);
    toggleEditForm(-1);
    const submittedPayload = fetchedData[index];
    await api.updateService(modelKey, 'DELETE', submittedPayload as unknown as ApiRequestPayload);
    await fetchData();
    setLoading(false);
    onFormClose();
  };

  const addNewEntry = async (submittedPayload: ContactFormData) => {
    setLoading(true);
    toggleForm(false);
    await api.updateService(modelKey, 'POST', submittedPayload as ApiRequestPayload);
    await fetchData();
    setLoading(false);
  };

  const isFormOpenForEdit = formOpenForEditIndex > -1;
  const hasNotificationChannel = !!(isFormOpenForEdit && fetchedData[formOpenForEditIndex].notificationChannel);
  const showInfoBox = !isFormOpen && !isFormOpenForEdit;

  const getRelationship = useCallback(
    (relationshipKey) => {
      const relationshipFieldDetails = form.formFields.find(
        (formField: FormField) => formField.fieldName === 'relationship',
      );
      const relationshipValue = relationshipFieldDetails?.fieldValues.find((relationship: string | any[]) =>
        relationship.includes(relationshipKey),
      );
      if (relationshipValue) {
        return relationshipValue.split('::')[1];
      }

      return relationshipKey;
    },
    [form?.formFields],
  );

  const createInfoBoxEntry = (data: FetchedDataType) => {
    const infoBoxValues = [];
    if (data?.firstName || data?.fathersName) {
      infoBoxValues.push({
        label: labelsCollection.getPrimary('name'),
        value: `${data?.firstName} ${data?.fathersName}`,
      });
    }
    data?.relationship &&
      infoBoxValues.push({
        label: labelsCollection.getPrimary('relationship'),
        value: getRelationship(data.relationship),
      });
    data?.primaryPhone &&
      data?.primaryPhone?.number &&
      infoBoxValues.push({
        label: labelsCollection.getPrimary('primaryPhone'),
        value: `${formatPhoneNumber(data.primaryPhone.number)} ${data.primaryPhone.typeShortcut}`,
      });
    data?.secondaryPhone &&
      data?.secondaryPhone?.number &&
      infoBoxValues.push({
        label: labelsCollection.getPrimary('secondaryPhoneNumber'),
        value: `${formatPhoneNumber(data.secondaryPhone.number)} ${data.secondaryPhone.typeShortcut}`,
      });
    data?.email &&
      infoBoxValues.push({
        label: labelsCollection.getPrimary('email'),
        value: data.email,
      });
    data?.notificationChannel &&
      infoBoxValues.push({
        label: labelsCollection.getPrimary('notificationChannel'),
        value: data.notificationChannel,
      });

    return infoBoxValues;
  };

  const filteredForm = isCcs
    ? form
    : { ...form, formFields: (form.formFields || []).filter((field) => field.fieldName !== 'email') };

  const additionalValidationSchema = yup.object().shape({
    emailNotification: yup.boolean(),
    email: yup.string().when('emailNotification', {
      is: true,
      then: yup.string().required(labelsCollection.getPrimary('thisIsRequiredField')),
    }),
  });

  const notificationsLimitReached =
    fetchedData.reduce((count, data) => count + +!!data?.notificationChannel, 0) >= NOTIFICATIONS_LIMIT;

  const onFormConfirm = (data: ApiRequestPayload) => {
    data.notificationChannel = emailNotification ? 'EMAIL' : '';
    delete data.emailNotification;
    if (isFormOpenForEdit) {
      updateEntry(data as ContactFormData, formOpenForEditIndex);
    } else addNewEntry(data as ContactFormData);
    onFormClose();
  };

  if (isLoading)
    return (
      <CardView>
        <RichTextContainer {...emergencyContactsHeader} />
        <Loader />
      </CardView>
    );

  return (
    <CardView>
      <RichTextContainer {...emergencyContactsHeader} />
      {showInfoBox &&
        fetchedData.map((data, index) => (
          <InfoBox
            entries={createInfoBoxEntry(data)}
            columns="3"
            isEditable={showInfoBox}
            onEdit={onFormEdit}
            index={index}
            key={data._id}
          />
        ))}
      <ContactsInfoBoxBody
        showNotificationPreferences={isCcs && (!notificationsLimitReached || hasNotificationChannel)}
        showCreatorButton={showInfoBox && fetchedData.length < CONTACTS_LIMIT}
        {...{
          isFormOpen,
          isFormOpenForEdit,
          formOpenForEditIndex,
          labelsCollection,
          automaticCollisionNotificationHeader,
          onFormClose,
          onFormOpen,
          onFormConfirm,
          removeEntry,
          emailNotification,
          setEmailNotification,
          formInitialValues,
          additionalValidationSchema,
          filteredForm,
          dataSharingConsentDialogue,
          removeContactDialogueBox,
        }}
      />
    </CardView>
  );
};

interface ContactsInfoBoxBodyProps {
  showCreatorButton: boolean;
  isFormOpen: boolean;
  isFormOpenForEdit: boolean;
  formOpenForEditIndex: number;
  showNotificationPreferences: boolean;
  automaticCollisionNotificationHeader: RichTextContainerProps;
  labelsCollection: UseLabelsReturn;
  emailNotification: boolean;
  setEmailNotification: (p: boolean) => void;
  removeEntry: (index: number) => void;
  onFormOpen: () => void;
  onFormClose: () => void;
  onFormConfirm: (data: ApiRequestPayload) => void;
  formInitialValues: ContactFormData | null;
  additionalValidationSchema: yup.ObjectSchema<any>;
  filteredForm: any;
  dataSharingConsentDialogue: any;
  removeContactDialogueBox: any;
}

const ContactsInfoBoxBody = ({
  labelsCollection,
  showCreatorButton,
  isFormOpen,
  isFormOpenForEdit,
  formOpenForEditIndex,
  showNotificationPreferences,
  automaticCollisionNotificationHeader,
  emailNotification,
  setEmailNotification,
  removeEntry,
  onFormOpen,
  onFormClose,
  onFormConfirm,
  formInitialValues,
  additionalValidationSchema,
  filteredForm,
  dataSharingConsentDialogue,
  removeContactDialogueBox,
}: ContactsInfoBoxBodyProps) => {
  const formikEmailNotificationCallbackRef = React.useRef<((checked: boolean) => void) | null>(null);
  const [isDialogOpen, toggleDialog] = useToggle(false);
  const [isRemoveDialogOpen, toggleRemoveDialog] = useToggle(false);

  return (
    <div className="EntryCreator InfoBox-edit-container">
      {showCreatorButton && <CreatorButton label={labelsCollection.getPrimary('addContact')} onClick={onFormOpen} />}
      {(isFormOpen || isFormOpenForEdit) && (
        <>
          <CreatorForm
            onFormClose={onFormClose}
            onFormConfirm={onFormConfirm}
            additionalSchemaObject={additionalValidationSchema}
            initialValues={formInitialValues}
            {...filteredForm}
          >
            {showNotificationPreferences && (
              <>
                <RichTextContainer {...automaticCollisionNotificationHeader} />
                <div className="InfoBox-inline">
                  <FormikCheckboxField
                    name={'emailNotification'}
                    placeholder={labelsCollection.getPrimary('emailNotificationLabel')}
                    checked={emailNotification}
                    onChange={(e, cb) => {
                      formikEmailNotificationCallbackRef.current = (checked: boolean) => {
                        e.target.checked = checked;
                        cb(e);
                      };
                      if (e.target.checked) toggleDialog(true);
                      else {
                        setEmailNotification(false);
                        formikEmailNotificationCallbackRef.current(false);
                      }
                    }}
                  />
                </div>
              </>
            )}
          </CreatorForm>
          {isFormOpenForEdit && (
            <button className="InfoBox-button InfoBox-remove-button" onClick={toggleRemoveDialog}>
              <FaTimes className={'InfoBox-edit-icon'} />
            </button>
          )}
          {isDialogOpen && (
            <Dialog
              {...dataSharingConsentDialogue}
              labelCancel={'Cancel'}
              onOk={() => {
                setEmailNotification(true);
                formikEmailNotificationCallbackRef.current?.(true);
                toggleDialog(false);
              }}
              onCancel={() => {
                setEmailNotification(false);
                formikEmailNotificationCallbackRef.current?.(false);
                toggleDialog(false);
              }}
            >
              <div className="text-center">
                <ContentfulRichText document={dataSharingConsentDialogue.body} />
              </div>
            </Dialog>
          )}
          {isRemoveDialogOpen && (
            <Dialog
              {...removeContactDialogueBox}
              onOk={() => {
                removeEntry(formOpenForEditIndex);
                toggleRemoveDialog();
                formikEmailNotificationCallbackRef.current?.();
              }}
              onCancel={() => {
                toggleRemoveDialog();
              }}
            >
              <div className="text-center">
                <ContentfulRichText document={removeContactDialogueBox.body} />
              </div>
            </Dialog>
          )}
        </>
      )}
    </div>
  );
};

export default ContactInfoCard;
