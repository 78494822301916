import useFilterByMake from '@hooks/useFilterByMake';
import { BrandNamesFilterGroup } from '@customTypes/BrandNamesFilterGroup';
import { Links, User } from '@customTypes/Navigation';
import filter from '@utils/filterByMake';
import { useAccountSelector } from '@redux/selectors/account';

const useFilterNavigation = (links: Links = [], filterGroup: BrandNamesFilterGroup | undefined = undefined): Links => {
  const user = useAccountSelector();
  const makeLinks = useFilterByMake({ content: links, filterGroup, filter });
  const filteredLinks = removeCALink(makeLinks, user);
  return filteredLinks;
};

function removeCALink(links: Links, { mailingCountry, state }: User) {
  const isCalifornia = mailingCountry === 'USA' && state === 'CA';
  if (!isCalifornia) {
    return links.filter((link) => link.name !== 'Do Not Sell My Info');
  }

  return links;
}

export default useFilterNavigation;
