// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1eQi6QACevIonMmhWW5gof {\n  border-top: 8px solid var(--card-color-border-top, var(--theme-card-color-border-main));\n  font-size: var(--theme-paragraph-font-size);\n  max-height: 75vh;\n}\n\n._1eQi6QACevIonMmhWW5gof h1 {\n  text-align: center;\n}\n\n.r8eMuP1nK1m6LZWqpJHsx {\n  display: none;\n}\n\n.K23GswsKmnxCW1hZocSaI {\n  margin-bottom: 2rem;\n}\n\n.K23GswsKmnxCW1hZocSaI p {\n  margin-bottom: 1rem;\n}\n\n._1FjI-ls16VPbrMwYbNDHP5 {\n  display: flex;\n  justify-content: center;\n  -moz-column-gap: 1rem;\n       column-gap: 1rem;\n}\n\n._1FjI-ls16VPbrMwYbNDHP5 button {\n  min-width: 150px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Dialog/Dialog.module.css"],"names":[],"mappings":"AAAA;EACE,uFAAuF;EACvF,2CAA2C;EAC3C,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,qBAAgB;OAAhB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".dialog {\n  border-top: 8px solid var(--card-color-border-top, var(--theme-card-color-border-main));\n  font-size: var(--theme-paragraph-font-size);\n  max-height: 75vh;\n}\n\n.dialog h1 {\n  text-align: center;\n}\n\n.header {\n  display: none;\n}\n\n.body {\n  margin-bottom: 2rem;\n}\n\n.body p {\n  margin-bottom: 1rem;\n}\n\n.buttons {\n  display: flex;\n  justify-content: center;\n  column-gap: 1rem;\n}\n\n.buttons button {\n  min-width: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "_1eQi6QACevIonMmhWW5gof",
	"header": "r8eMuP1nK1m6LZWqpJHsx",
	"body": "K23GswsKmnxCW1hZocSaI",
	"buttons": "_1FjI-ls16VPbrMwYbNDHP5"
};
export default ___CSS_LOADER_EXPORT___;
