import React from 'react';
import ContentRenderer from '@components/ContentRenderer';

import styles from './Footer.module.css';

export type FooterContent = {
  componentType: string;
  name: string;
  content: FooterContent[];
};

type Props = Pick<FooterContent, 'content'>;

function Footer({ content }: Props): JSX.Element {
  return (
    <footer className={styles['Footer-container']}>
      <div className={styles['Footer-wrapper']}>
        <ContentRenderer name="footer" content={content} />
      </div>
    </footer>
  );
}

export default Footer;
