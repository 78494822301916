import {
  getDestinations,
  addDestination,
  deleteDestination,
} from "@cv/portal-rts-lib/v0/information/destinations";
import { Environment } from "@cv/portal-common-lib/ajax/constants";
import {
  DestinationsRequest,
  AddDestinationRequest,
  AddDestination,
  UpdateDestinationRequest,
  UpdateDestination,
} from "@cv/portal-rts-lib/v0/information/destinations/models";

type GetDestinationsRequestProps = {
  accessToken: string;
  vin: string;
  folderId: string;
  channelId: string;
};

type AddDestinationRequestProps = {
  accessToken: string;
  id_token: string;
  vin: string;
  data: AddDestination;
};

type DeleteDestinationRequestProps = {
  accessToken: string;
  id_token: string;
  vin: string;
  data: UpdateDestination;
};

export default class DestinationService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  async getDestinations({
    accessToken,
    vin,
    folderId,
    channelId,
  }: GetDestinationsRequestProps) {
    const request = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        vin,
      },
      queryParams: {
        folderId,
        channelId
      }
    } as DestinationsRequest;

    const response = await getDestinations(request);
    return { data: response.data };
  }

  async addDestination({
    accessToken,
    id_token,
    vin,
    data,
  }: AddDestinationRequestProps) {
    const request = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        id_token,
        vin,
      },
      data,
    } as AddDestinationRequest;

    const response = await addDestination(request);
    return { data: response.data };
  }

  async deleteDestination({
    accessToken,
    id_token,
    vin,
    data,
  }: DeleteDestinationRequestProps) {
    const request = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        id_token,
        vin,
      },
      data,
    } as UpdateDestinationRequest;

    const response = await deleteDestination(request);
    return { data: response.data };
  }
}
