import React, { ReactNode } from 'react';

import styles from './SubscriptionOverview.module.css';
import SubscriptionPackage from '@customTypes/SubscriptionPackage';
import { FlagGroup } from '@customTypes/package-flags';
import useExpireRenewText from '@hooks/useExpireRenewText';
import useLocalDate from '@hooks/useLocalDate';
import isPackageToShow from '@utils/isPackageToShow';
import ServiceExpiry from './ServiceExpiry';
import PackageSubscriptionType, { PackageSubscriptionTypeProps } from './PackageSubscriptionType';

export type SubscriptionOverviewProps = PackageSubscriptionTypeProps & {
  data: {
    vehicle: {
      name: string;
      vin: string;
    };
    subscribedPackages: SubscriptionPackage[];
    defaultPkg: {
      expiry: string;
    };
  };
  flagGroup?: FlagGroup;
  vehicleInfo: ReactNode;
  labelExpiry: string;
  renewLabel: string;
  packagePrefixLabel: string;
  labelTrialExpiry: string;
  apiConnector: {
    api: string;
  };
  componentType: string;
};

function SubscriptionOverview({
  data,
  labelExpiry: expiresLabel,
  renewLabel,
  packagePrefixLabel,
  labelTrialExpiry,
  vehicleInfo,
  flagGroup,
  paidSubscriptionLabel,
  trialSubscriptionLabel,
  renewsAnnuallyLabel,
  renewsMonthlyLabel,
  endOfTermLabel,
  promoTermLabel,
  apiConnector,
  componentType,
  ...restProps
}: SubscriptionOverviewProps): JSX.Element {
  const expireRenewText = useExpireRenewText();
  const formatDate = useLocalDate();

  const renderPackageInfo = (subscribedPackage: SubscriptionPackage) => {
    const { marketingName, subscriptionPackageId } = subscribedPackage;
    const text = expireRenewText({ ...subscribedPackage, renewLabel, expiresLabel });

    return (
      <div key={subscriptionPackageId} className={styles['SubscriptionOverview-service']}>
        <div className={styles['SubscriptionOverview-service-name']}>
          {packagePrefixLabel}
          {marketingName}
        </div>
        <PackageSubscriptionType subscribedPackage={subscribedPackage} />

        <div className={styles['SubscriptionOverview-service-expiry']}>{text}</div>
      </div>
    );
  };
  return (
    <div className={styles.SubscriptionOverview} {...restProps}>
      {vehicleInfo}
      {data?.subscribedPackages
        .filter((anyPackage: SubscriptionPackage) => isPackageToShow(anyPackage, (flagGroup || {}).flags))
        .map(renderPackageInfo)}
      {data?.defaultPkg?.expiry && labelTrialExpiry && (
        <ServiceExpiry labelTrialExpiry={labelTrialExpiry} expiry={formatDate(data.defaultPkg.expiry)} />
      )}
    </div>
  );
}

export default SubscriptionOverview;
