import React from "react";
import clsx from "clsx";
import styles from "./Input.module.css";

export type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  error?: boolean;
  showErrorIcon?: boolean;
  helpers?: object;
};

const Input = ({
  className,
  error = false,
  showErrorIcon = false,
  helpers,
  ...props
}: InputProps) => {
  const inputClassName = clsx(styles.input, className, {
    [styles.error]: error,
    [styles.errorIcon]: showErrorIcon,
  });

  return <input className={inputClassName} {...props} />;
};

export default Input;
