import React from "react";
import clsx from 'clsx';

import styles from './Tab.module.css';

export type TabItemProps = {
  activeTab: 'none' | string;
  label: string;
  onClick: () => void;
  type: string;
  children: React.ReactNode;
  ["data-label"]: string;
  className: string;
}

const TabItem = ({ activeTab = 'none', label, onClick = () => { }, type = 'primary', className}: Partial<TabItemProps>) => {
  const itemClassName = clsx(
    styles.TabItem,
    styles[`TabItem--${type}`],
    { [styles['TabItem--active']]: label === activeTab },
    className,
  );

  return (
    <button className={itemClassName} onClick={onClick} >
      {label}
    </button>
  );
};

export default TabItem;
