import React, { MouseEvent } from 'react';
import clsx from 'clsx';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import styles from './Pagination.module.css';

const PAGINATION_NAV = {
  BACKWARD: 'BACKWARD',
  FORWARD: 'FORWARD',
  FAST_BACKWARD: 'FAST_BACKWARD',
  FAST_FORWARD: 'FAST_FORWARD'
};

type PaginationProps = {
  totalPages: number,
  currentPage?: number,
  fastBackwardLabel: string
  fastForwardLabel: string
  pagesLabel: string
  preposition: string
  onPageChange?: (value: number) => void,
  className?: string
}

export default function Pagination({
  totalPages,
  currentPage = 1,
  fastBackwardLabel,
  fastForwardLabel,
  pagesLabel,
  preposition,
  className,
  onPageChange = () => { },
}: PaginationProps) {

  const onPageChangeHandler = (e: MouseEvent) => {
    e.preventDefault();
    const page = parseInt(e.currentTarget.dataset.page, 10);
    onPageChange(Math.max(1, Math.min(page, totalPages)));
  }


  const renderNavButton = (type: string) => {
    let page;
    let icon;
    let isDisabled;

    switch (type) {
      case PAGINATION_NAV.BACKWARD:
        page = currentPage > 1 ? currentPage - 1 : currentPage;
        isDisabled = currentPage === 1;
        icon = (<span aria-hidden="true"><FaChevronLeft /></span>);
        break;
      case PAGINATION_NAV.FORWARD:
        page = currentPage < totalPages ? currentPage + 1 : totalPages;
        isDisabled = currentPage === totalPages;
        icon = (<span aria-hidden="true"><FaChevronRight /></span>);
        break;
      case PAGINATION_NAV.FAST_BACKWARD:
        page = 1;
        isDisabled = currentPage === 1;
        icon = (<span className={styles['pagination-nav-label']}>{fastBackwardLabel}</span>);
        break;
      case PAGINATION_NAV.FAST_FORWARD:
        page = totalPages;
        isDisabled = currentPage === totalPages;
        icon = (<span className={styles['pagination-nav-label']}>{fastForwardLabel}</span>);
        break;
      default:
        break;
    }

    return (
      <div className={styles['pagination-nav-button']}>
        <button
          data-page={page}
          className={styles['pagination-nav-link']}
          onClick={onPageChangeHandler}
          disabled={isDisabled}
        >
          {icon}
        </button>
      </div>
    );
  }

  return (
    <div className={clsx(styles['pagination'], className)}>
      <nav aria-label="pagination" className={styles['pagination-nav']}>
        {renderNavButton(PAGINATION_NAV.FAST_BACKWARD)}
        <div className={styles['pagination-page-wrapper']}>
          {renderNavButton(PAGINATION_NAV.BACKWARD)}
          <span>{currentPage} {preposition} {totalPages} {pagesLabel}</span>
          {renderNavButton(PAGINATION_NAV.FORWARD)}
        </div>
        {renderNavButton(PAGINATION_NAV.FAST_FORWARD)}
      </nav>
    </div>
  );
}
