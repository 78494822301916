import { ISxmKey, useApi } from '@api';
import React, { useEffect, useState } from 'react';

import { LoaderBackdrop } from '@components/Loader';
import { OtpForm } from '@cv/portal-components-lib';
import SecurityAuthenticationForm, { Contacts } from './SecurityAuthenticationForm';
import UserInfo from '@components/Login/UserInfo';
import styles from './SecurityAuthentication.module.css';
import AnalyticsTrackView from '@components/Analytics';
import { generateLabelsObjects, ContentfulContent } from '@utils/labels';
import { useHistory } from 'react-router-dom';
import EmailSearch, { EmailSearchFormData } from '@components/LinkVehicle/EmailSearch';
import Widget from '@components/Widget';
import urlSearchParams from '@utils/urlSearchParams';
import { SUBSCRIPTION_PAGE_URL } from '@components/App/UrlParser';
import { SalesChannel } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { HttpStatus } from '@cv/portal-common-lib/ajax/constants';
import ErrorModal from '@components/Login/ErrorModal';
import usePreferencesSelector from '@redux/selectors/preferences';

enum AnalyticsEventNameSuffix {
  form = 'Enter Code',
  access = 'SAC Choice',
}

enum Step {
  Form = 'form',
  Access = 'access',
}

enum Status {
  Idle = 'idle',
  Loading = 'loading',
  Error = 'error',
}

export enum OtpDigitsFormat {
  RRS = 'rrs',
  Promotional = 'promotional',
}

type PageListData = {
  contentSections?: ContentfulContent[];
  analyticsEventName?: string;
};

export type BodyTextSource = 'email' | 'sms' | 'RRS-IVA';

type rrsParams = {
  sxmkey?: string;
  discountid?: string;
  u?: string;
  v?: string;
  ti?: string;
  exchangeToken?: string;
  lang?: string;
  flowName?: string;
};

const SecurityAuthentication = ({ contentSections, analyticsEventName }: PageListData) => {
  const preferences = usePreferencesSelector();
  const { otpTimeout } = preferences;
  const securityAuthenticationLabels = generateLabelsObjects(contentSections?.[0]?.content || []);
  const {
    title: securityAuthenticationTitle,
    otpErrorMessage,
    ...restSecurityAuthenticationLabels
  } = securityAuthenticationLabels;
  const { submitButton, enterEmail, errorEmailRequired, errorEmailInvalid } = securityAuthenticationLabels;
  const [status, setStatus] = useState<Status>(Status.Idle);
  const [bodyTextSource, setBodyTextSource] = useState<BodyTextSource>('email');
  const [step, setStep] = useState<Step>('' as Step);
  const [otpDigitsFormat, setOtpDigitsFormat] = useState<OtpDigitsFormat>(OtpDigitsFormat.Promotional);
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
  const [isEmailAsked, askUserEmail] = useState<boolean>(false);
  const [showPrimarySubscriberError, setShowPrimarySubscriberError] = useState<boolean>(false);
  const [contacts, setContacts] = useState<Contacts>({
    primaryPhone: '',
    secondaryPhone: '',
    email: '',
  });

  const api = useApi();
  const history = useHistory();

  const {
    sxmkey = '',
    discountid: discountId,
    u: userId,
    v: vehicleId,
    ti: externalTenantUserId,
    exchangeToken = '',
    flowName,
    lang,
  } = urlSearchParams.getAll<rrsParams>();

  const [tokenId, setTokenId] = useState(sxmkey || exchangeToken);

  const { nonSsoErrorRedirect = '/login' } = usePreferencesSelector();

  useEffect(() => {
    const handleFlow = async () => {
      if (sxmkey) {
        // RRS FLOW
        const tokenDetails = await getTokenDetails(sxmkey);

        const tokenLanguage = tokenDetails?.lang || tokenDetails?.preferredLanguage;
        if (!lang && tokenLanguage) {
          urlSearchParams.set('lang', tokenLanguage);
        }
        urlSearchParams.set('sxmKeyDetails', tokenDetails);

        await checkSource(tokenDetails!);
      } else if ((userId && vehicleId) || externalTenantUserId) {
        // PROMOTIONAL FLOW
        setStep(Step.Form);
      } else if (exchangeToken) {
        const tokenDetails = await getTokenDetails(exchangeToken);

        // we cannot work with broken marketing data
        if (!tokenDetails) {
          return;
        }

        urlSearchParams.set('salesChannel', tokenDetails!.source);

        // EXCHANGE TOKEN FLOW
        await handlePortalFlow();
      } else {
        // SSO FLOW
        history.replace('/login');
      }
    };

    handleFlow();
  }, []);

  useEffect(() => {
    if (userId) {
      api
        .getOTPContacts(userId)
        .then(({ data }) => {
          if (data) setContacts(data);
        })
        .catch((e) => console.error(e));
    }
  }, [userId]);

  const handleEmailSearch = async ({ email }: EmailSearchFormData) => {
    urlSearchParams.set('userEmail', email);
    history.replace(SUBSCRIPTION_PAGE_URL);
  };

  const getTokenDetails = async (token: string) => {
    try {
      const sxmKeyResponse = await api.getSxmKeyDetails({ sxmKey: token });
      const tokenDetails = sxmKeyResponse?.data?.data;

      // we cannot work with broken marketing data
      if (!tokenDetails) {
        throw new Error('tokenDetails is absent');
      }

      return tokenDetails as ISxmKey;
    } catch (e) {
      if (flowName === 'learnmore') {
        // to trigger default fallback for unauth
        history.replace('/');
      } else {
        // navigate user to login as we don't have data to navigate user further through marketing flow
        history.replace(nonSsoErrorRedirect);
      }
    }
  };

  const checkSource = async (tokenDetails: ISxmKey) => {
    switch (tokenDetails?.source) {
      case 'RRS-IVA': {
        urlSearchParams.setMultiple({
          uri: SUBSCRIPTION_PAGE_URL,
        });
        setOtpDigitsFormat(OtpDigitsFormat.RRS);
        setStep(Step.Access);
        setBodyTextSource('RRS-IVA');
        break;
      }
      case 'RRS-Dealer': {
        urlSearchParams.setMultiple({
          uri: SUBSCRIPTION_PAGE_URL,
          userEmail: tokenDetails.email,
        });
        if (flowName === 'completeprofile') {
          history.replace(SUBSCRIPTION_PAGE_URL);
        } else {
          await handlePortalFlow();
        }
        break;
      }
      default: {
        history.replace('/');
        break;
      }
    }
  };

  const handleOTPCodeSend = async (channelAndType: string) => {
    try {
      setStatus(Status.Loading);
      let channel = channelAndType;
      let isSecondaryPhone = undefined;
      if (channelAndType.toLocaleLowerCase().includes('secondary')) {
        [channel] = channelAndType.split('-');
        isSecondaryPhone = true;
      }
      const response = await api.sendOTPCode({
        discountId,
        externalTenantUserId,
        userId,
        vehicleId,
        channel,
        isSecondaryPhone,
        ...(otpTimeout && { expiresIn: parseInt(otpTimeout) }),
      });
      setStatus(Status.Idle);
      if (response?.data) {
        const { tokenId } = response.data;
        urlSearchParams.set('sxmkey', tokenId);
        // we need to save it also to local data,
        // because rrsFlow hook doesn't trigger react ro re-render (for optimization purpose)
        setTokenId(tokenId);
        setStep(Step.Access);
        setBodyTextSource(channel as BodyTextSource);
      } else {
        history.replace('/login');
      }
    } catch (error) {
      if (
        error.response.status === HttpStatus.UNPROCESSABLE_ENTITY &&
        error.response.data.code === 'IDM_USER_2_VEHICLE_NOT_FOUND'
      ) {
        setShowPrimarySubscriberError(true);
        return;
      }

      console.error('Sending otp code failed. Reason: ', error.response.data.message);
      history.replace(nonSsoErrorRedirect);
      setStatus(Status.Error);
    }
  };

  const handleValidateOTPCode = async (otp: string) => {
    try {
      setStatus(Status.Loading);
      const { data } = await api.validateOTPCode(tokenId, otp);
      setStatus(Status.Idle);

      if (bodyTextSource === 'RRS-IVA') {
        askUserEmail(true);
      } else {
        data?.email && urlSearchParams.set('userEmail', data.email);
        await handlePortalFlow(otp);
      }
    } catch (error) {
      console.error('Validating otp code failed. Reason: ', error);
      setStatus(Status.Error);
    }
  };

  const handlePortalFlow = async (otp?: string) => {
    try {
      const { data } = await api.loginToken(tokenId, otp);
      api.storeTokenResponse(data);
      setLoggedIn(true);
    } catch (error) {
      setStatus(Status.Error);
      history.replace(nonSsoErrorRedirect);
    }
  };
  const tryAgain = () => {
    setStep(Step.Form);
  };
  const Page = {
    [Step.Form]: (
      <SecurityAuthenticationForm
        onSubmit={handleOTPCodeSend}
        labels={securityAuthenticationLabels}
        contacts={contacts}
      />
    ),
    [Step.Access]: (
      <Widget title={securityAuthenticationTitle?.primary}>
        <OtpForm
          onSubmit={handleValidateOTPCode}
          labels={restSecurityAuthenticationLabels}
          apiError={status === Status.Error}
          onChange={() => setStatus(Status.Idle)}
          inputDigitsFormat={otpDigitsFormat}
          bodyTextSource={bodyTextSource}
          tryAgain={!!(userId && vehicleId) ? tryAgain : undefined}
          errorMessage={otpErrorMessage}
        />
      </Widget>
    ),
  };

  return (
    <div className={styles['SecurityAuthentication']}>
      {showPrimarySubscriberError && (
        // TODO: combine all PS role error dialogs in one place
        <ErrorModal
          errorMessage={preferences.noLinkedVehiclesErrorMessage ?? preferences.notLinkedVehicleErrorMessage}
          okLabel={preferences.ownerPortalButtonLabel}
          onOk={() => {
            window.location.replace(preferences.ownerPortalLink);
          }}
        />
      )}
      {status === Status.Loading && <LoaderBackdrop />}
      {isLoggedIn && <UserInfo goBack={() => setStep(Step.Form)} labels={securityAuthenticationLabels} />}
      {isEmailAsked ? (
        <Widget title="Email Address">
          <EmailSearch
            labelInputPlaceholder={enterEmail?.primary}
            labelSubmit={submitButton?.primary}
            labelErrorEmailRequired={errorEmailRequired?.primary}
            labelErrorEmailInvalid={errorEmailInvalid?.primary}
            onSubmit={handleEmailSearch}
          />
        </Widget>
      ) : (
        <AnalyticsTrackView
          analyticsEventName={
            AnalyticsEventNameSuffix[step] && `${analyticsEventName}${AnalyticsEventNameSuffix[step]}`
          }
        >
          {Page[step]}
        </AnalyticsTrackView>
      )}
    </div>
  );
};

export default SecurityAuthentication;
