import React from 'react';
import ContentRenderer from '@components/ContentRenderer';
import { ActionCardProps } from '@components/ActionCard';
import ActionCardLayout from '@components/ActionCard/ActionCardLayout';

const MisAction = ({ content, ...props }: ActionCardProps) => {
  return (
    <ActionCardLayout {...props}>
      {content.map((item) => (
        <ContentRenderer name="mis-content" content={[item]} key={item.name} />
      ))}
    </ActionCardLayout>
  );
};

export default MisAction;
