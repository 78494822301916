import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import LanguagePicker from '@components/LanguagePicker/';
import HeaderService from './HeaderService';
import HeaderHamburger from './HeaderHamburger';
import HeaderUser from './HeaderUser';
import HeaderMobileMenu from './HeaderMobileMenu';
import HeaderMenuBottomNav from './HeaderMenuBottomNav';
import Navigation from '../Navigation';
import styles from './Header.module.css';
import StolenVehicleLocator from './StolenVehicleLocator';
import VehicleSwitcher from '@components/VehicleSwitcher';
import { useSelector } from 'react-redux';
import { ACTIVE, STOLEN_VEHICLE } from './constant';
import { useNavigation } from '@components/Navigation';
import HeaderOemBrandLogo, { HeaderOemBrandLogoType } from './HeaderOemBrandLogo';
import { useApi } from '@api';
import { getSearchParam } from '@utils/url';
import { useLocation } from 'react-router-dom';
import WarningBannerConnector, { WarningBannerProps } from './WarningBanner';
import SubscriptionInformation from './SubscriptionInformation';
import { SubscriptionInformationProps } from './SubscriptionInformation/SubscriptionInformation';

export type HeaderProps = {
  content: {
    service?: object;
    mobileMenuNavigation?: object;
    userMenuNavigation?: object;
    userPrefix?: string;
    navigation?: object;
    logoutLabel?: string;
    loginLabel?: string;
    pagesToDisplayLogout?: string[];
    pagesToHideDropdown: string[];
    pagesToHideTcLinkInUserDropDown?: string[];
    stolenVehicleLocator: {
      header: string;
      firstText: string;
      secondText: string;
      thirdText: string;
      url: string;
    };
    languagePreference: {
      languages: string[];
    };
    bottomBarTheme?: string;
    oemBrandLogo: Array<HeaderOemBrandLogoType>;
    warningBanner?: WarningBannerProps;
    subscriptionInformation?: SubscriptionInformationProps;
  };
  withNavigation?: boolean;
  withUser?: boolean;
};

function Header({ content, withNavigation, withUser }: HeaderProps) {
  const location = useLocation();
  const hideNavigation = getSearchParam(location.search, 'hideNavigation');

  const api = useApi();
  const {
    service,
    mobileMenuNavigation,
    userMenuNavigation,
    userPrefix,
    stolenVehicleLocator,
    bottomBarTheme,
    oemBrandLogo,
    warningBanner,
    subscriptionInformation,
  } = content;
  const isAuthenticated = api.storeService.getAccessToken() && api.storeService.getRefreshToken();
  const { svl } = useSelector(({ stolenVehicleReducer }) => stolenVehicleReducer);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const [dropDownContainer, setDropDownContainer] = useState<HTMLDivElement | undefined>(undefined);
  const { currentPath } = useNavigation();
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (headerRef?.current) {
      setDropDownContainer(headerRef.current);
    }
  }, [headerRef]);

  const currentLocation = currentPath.page;

  const toggleMobileMenu = () => {
    setIsMobileMenuVisible(!isMobileMenuVisible);
  };

  const renderNavItems = (style: string) => {
    if (content && content['navigation']) {
      return (
        <Navigation name="mainNavigation" className={styles[style]} {...content['navigation']} isNavigationMachine />
      );
    }
  };
  const renderHeaderUser = () => (
    <HeaderUser
      className={styles['Header-user']}
      userPrefix={userPrefix}
      navigation={userMenuNavigation}
      logoutLabel={content?.logoutLabel}
      loginLabel={content?.loginLabel}
      pagesToDisplayLogout={content?.pagesToDisplayLogout}
      pagesToHideDropdown={content.pagesToHideDropdown}
      pagesToHideTcLinkInUserDropDown={content?.pagesToHideTcLinkInUserDropDown}
      theme={bottomBarTheme}
    />
  );

  const renderSvlHeader = () => {
    if (svl && svl.status === ACTIVE && svl.type === STOLEN_VEHICLE) {
      return <StolenVehicleLocator stolenVehicleLocator={stolenVehicleLocator} />;
    }
  };

  if (hideNavigation) {
    return null;
  }

  return (
    <>
      <header className={clsx(styles['Header-container'], { [styles['Header--with-nav']]: withNavigation })}>
        <div className={styles['Header-wrapper']}>
          <HeaderOemBrandLogo
            content={oemBrandLogo}
            pagesToHideTcLinkInUserDropDown={content?.pagesToHideTcLinkInUserDropDown}
          />
          <div className={clsx(styles['Header-inner'], styles['Header-upper'])}>
            {withNavigation && renderNavItems('Header-main-navigation')}
          </div>
          {service && <HeaderService imageData={service} />}
          <div className={clsx(styles['Header-mobile-nav'], styles['Header-upper'])}>
            {(withNavigation || withUser) && <HeaderHamburger onClick={toggleMobileMenu} />}
          </div>
        </div>
        <div className={styles['Header-lower']} ref={headerRef}>
          <div className={styles['Header-lower-content-left']}>
            {isAuthenticated && (
              <>
                <VehicleSwitcher theme={bottomBarTheme} className={styles['Header-content-left']} />
                {warningBanner && (
                  <WarningBannerConnector
                    {...warningBanner}
                    theme={bottomBarTheme}
                    dropDownContainer={dropDownContainer}
                  />
                )}
              </>
            )}
          </div>
          <div className={styles['Header-lower-content-right']}>
            {isAuthenticated && subscriptionInformation && (
              <SubscriptionInformation
                {...subscriptionInformation}
                theme={bottomBarTheme}
                dropDownContainer={dropDownContainer}
              />
            )}
            {isAuthenticated && content.languagePreference && (
              <LanguagePicker
                languagePreference={content.languagePreference}
                theme={bottomBarTheme}
                className={styles['Header-language-picker']}
              />
            )}
            {withUser && renderHeaderUser()}
          </div>
        </div>
        {(withNavigation || withUser) && (
          <HeaderMobileMenu onClose={() => setIsMobileMenuVisible(false)} isVisible={isMobileMenuVisible}>
            {withUser && renderHeaderUser()}
            {withNavigation && renderNavItems('Header-main-mobile-navigation')}
            {withNavigation && mobileMenuNavigation && <HeaderMenuBottomNav navigation={mobileMenuNavigation} />}
          </HeaderMobileMenu>
        )}
      </header>
      {renderSvlHeader()}
    </>
  );
}

Header.defaultProps = {
  content: {
    logo: null,
    url: '/',
    userPrefix: null,
    service: null,
    navigation: [],
    mobileMenuNavigation: null,
    userMenuNavigation: null,
  },
};

export default Header;
