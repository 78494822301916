import { useSelector } from 'react-redux';

type Preferences = {
  preferencesReducer: {
    preferences: Record<string, string>;
  };
};

const getPreferences = (state: Preferences) => {
  return state.preferencesReducer.preferences;
};

const usePreferencesSelector = () => {
  const preferences = useSelector(getPreferences);
  return preferences;
};

export default usePreferencesSelector;
