import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { queryManagedRole } from '@cv/portal-idm-lib/role';
import { QueryManagedRoleRequest } from '@cv/portal-idm-lib/role/models';
import { QueryFilterKeys } from '@cv/portal-idm-lib/models';

type QueryRoleArgs = {
  filter: QueryFilterKeys;
  fields?: string[];
  accessToken: string;
  locale: string;
}

export default class RoleService {
  private environment: Environment;

  constructor(environment = Environment.SIT) {
    this.environment = environment
  }

  queryRole({ filter, fields = [], accessToken, locale }: QueryRoleArgs) {
    const payload: QueryManagedRoleRequest = {
      queryParams: {
        _queryFilter: filter,
        _fields: fields.concat(['*']).join(','),
      },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        'Accept-Language': locale,
      },
    };

    return queryManagedRole(payload);
  }
}
