import React, { Ref } from 'react';
import clsx from 'clsx';
import styles from './Button.module.css';

type Props = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  variant?: 'filled' | 'outlined' | 'link';
};

const Button = React.forwardRef(
  ({ children, variant = 'filled', className, ...rest }: Props, ref: Ref<HTMLButtonElement>) => {
    if (variant === 'link') {
      return (
        <span role="button" className={clsx(styles.link, className)} {...rest} ref={ref}>
          {children}
        </span>
      );
    }
    return (
      <button className={clsx(styles.button, styles[variant], className)} {...rest} ref={ref}>
        {children}
      </button>
    );
  },
);

export default Button;
