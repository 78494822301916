// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._O0WjI77inYzV4Z-NEdc7 {\n  background: none;\n  border: none;\n  color: var(--theme-disclaimer-color-text);\n  text-decoration: underline;\n  padding: 0px;\n  font-size: 0.75rem;\n  display: inline-block;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ModalExpandingText/ModalExpandingText.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,yCAAyC;EACzC,0BAA0B;EAC1B,YAAY;EACZ,kBAAkB;EAClB,qBAAqB;AACvB","sourcesContent":[".minifiedText {\n  background: none;\n  border: none;\n  color: var(--theme-disclaimer-color-text);\n  text-decoration: underline;\n  padding: 0px;\n  font-size: 0.75rem;\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"minifiedText": "_O0WjI77inYzV4Z-NEdc7"
};
export default ___CSS_LOADER_EXPORT___;
