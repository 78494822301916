import { APIRequest, APIResponse } from '@cv/portal-common-lib/ajax/models';
import { HttpMethodEnum } from '@cv/portal-common-lib/ajax/constants';
import { callHttpRequestMethod } from '@cv/portal-common-lib/utils';
import config from '@config/config';
import axios from 'axios';
import { ISxmKey } from '@api/types';
import urlSearchParams from '@utils/urlSearchParams';

const refreshAccessTokenUrl: string = config.getOemValue('REFRESH_ACCESS_TOKEN_URL') || '';

const sxmAccessTokenUrl: string = config.getOemValue('SXM_ACCESS_TOKEN_URL') || '';

const accessTokenRedirectUri: string = config.getOemValue('LOGIN_REDIRECT') || '';
const sxmKeyUrl: string = config.getOemValue('SXM_KEY_URL') || '';
const sxmKeyClientId: string = config.getOemValue('SXM_KEY_CLIENT_ID') || '';
const userInfoUrl: string = config.getOemValue('USER_INFO_URL') || '';

const exchangeTokenUrl: string = config.get('EXCHANGE_TOKEN_URL') || '';
const exchangeTokenClientId: string = config.getOemValue('DEALER_CLIENT_ID') || '';

type GetAccessTokenArgs = {
  code: string;
  verifier: string;
  stateCode: string;
};

type GetSXMAccessTokenArgs = {
  exchangeToken: string;
};

type RefreshTokenArgs = {
  refreshToken: string;
};

type GetSxmKeyDetailsArgs = {
  sxmKey: string;
};

type GetUserInfoArgs = {
  accessToken: string;
};

type ExchangeTokenArgs = {
  token: string;
};

export type TokenResponse = {
  access_token: string;
  refresh_token: string;
  id_token: string;
  token_type: string;
};

type UserInfoResponse = {
  sub: string;
  sxmTenantId: string;
};

const AcceptApplicationJson = 'application/json';

export default class AuthService {
  constructor() {}

  getIdmAccessToken({ code, verifier, stateCode }: GetAccessTokenArgs): Promise<APIResponse<TokenResponse>> {
    const request: APIRequest = {
      url: config.getOemValueBySuffix('IDM_ACCESS_TOKEN_URL', urlSearchParams.get('idp')),
      method: HttpMethodEnum.POST,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: AcceptApplicationJson,
      },
      data: new URLSearchParams({
        grant_type: 'authorization_code',
        code,
        state: stateCode,
        client_id: config.getOemValueBySuffix('LOGIN_CLIENT_ID', urlSearchParams.get('idp')),
        code_verifier: verifier,
        redirect_uri: accessTokenRedirectUri,
      }).toString(),
    };

    return axios(request);
  }

  getAccessToken({ exchangeToken }: GetSXMAccessTokenArgs): Promise<APIResponse<TokenResponse>> {
    const request: APIRequest = {
      url: sxmAccessTokenUrl,
      method: HttpMethodEnum.POST,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: AcceptApplicationJson,
        'cv-oem-auth-token': exchangeToken,
        'cv-client-id': config.getOemValueBySuffix('SXM_CLIENT_ID', urlSearchParams.get('idp')),
      },
    };

    return callHttpRequestMethod(request);
  }

  refreshToken({ refreshToken }: RefreshTokenArgs): Promise<APIResponse<TokenResponse>> {
    const request: APIRequest = {
      url: refreshAccessTokenUrl,
      method: HttpMethodEnum.POST,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: AcceptApplicationJson,
      },
      data: new URLSearchParams({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        client_id: config.getOemValueBySuffix('LOGIN_CLIENT_ID', urlSearchParams.get('idp')),
      }).toString(),
    };

    return callHttpRequestMethod(request);
  }

  async getSxmKeyDetails({ sxmKey }: GetSxmKeyDetailsArgs): Promise<{ data: { data: ISxmKey } }> {
    const request: APIRequest = {
      url: `${sxmKeyUrl}/${sxmKey}`,
      method: HttpMethodEnum.GET,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: AcceptApplicationJson,
        'cv-client-id': sxmKeyClientId,
      },
    };
    return callHttpRequestMethod(request);
  }

  getUserInfo({ accessToken }: GetUserInfoArgs): Promise<APIResponse<UserInfoResponse>> {
    const request: APIRequest = {
      url: userInfoUrl,
      method: HttpMethodEnum.POST,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': AcceptApplicationJson,
        Accept: AcceptApplicationJson,
      },
    };

    return callHttpRequestMethod(request);
  }

  exchangeToken({ token }: ExchangeTokenArgs): Promise<APIResponse<TokenResponse>> {
    const request: APIRequest = {
      url: exchangeTokenUrl,
      method: HttpMethodEnum.POST,
      headers: {
        'Content-Type': AcceptApplicationJson,
        Accept: AcceptApplicationJson,
        'cv-client-id': exchangeTokenClientId,
        'cv-oem-auth-token': token,
      },
    };

    return callHttpRequestMethod(request);
  }
}
