import React from "react";
import Label from "@components/Label";

import clsx from "clsx";

import styles from "./ControlLabel.module.css";

type ControlLabelProps = {
  label: string;
  control: React.ReactNode;
  labelPlacement?: "top" | "right" | "bottom" | "left";
  className?: string;
  classes?: {
    container?: string;
    label?: string;
  };
};

const ControlLabel = ({ label, labelPlacement = "right", control, className, classes = {} }: ControlLabelProps) => {
  const LabelContainer = (
    <span className={clsx(styles["label"], className, classes.label)}>
      {label}
    </span>
  );

  const [FirstComponent, SecondComponent] = ["top", "left"].includes(labelPlacement)
    ? [LabelContainer, control]
    : [control, LabelContainer];

  const isColumnDirection = ["top", "bottom"].includes(labelPlacement);

  return (
    <Label
      className={clsx(styles["container"], classes.container, {
        [styles["column"]]: isColumnDirection,
        [styles["row"]]: !isColumnDirection,
      })}
    >
      {FirstComponent}
      {SecondComponent}
    </Label>
  );
};

export default ControlLabel;
