import React from 'react';
import styles from '../MobileInformationService.module.css';
import SelectedItemsList from '../SelectedItemsList';
import { Team } from '@components/MobileInformationService/types';
import { chopTillSpecialChar } from "@components/MobileInformationService/utils";

export type Props = {
  savedScheduleTeams: Team[];
  savedScoreTeams: Team[];
  onRemoveTeam: (team: Team) => void;
  yourSchedulesText: string;
  yourScoresText: string;
};

const RightColumn = ({
  savedScheduleTeams,
  savedScoreTeams,
  onRemoveTeam,
  yourSchedulesText,
  yourScoresText,
}: Props) => {

  const savedTeamDetails = (savedTeams: Team[]) => {
    return savedTeams.map(item => ({...item, value: chopTillSpecialChar(item.value)}));
  }

  return (
    <div className={styles['sports-right-wrapper']}>
      <SelectedItemsList
        className={styles['sports-right-list']}
        textProperty="value"
        items={savedTeamDetails(savedScoreTeams)}
        listTitle={yourScoresText}
        onRemove={onRemoveTeam}
      />
      <SelectedItemsList
        className={styles['sports-right-list']}
        textProperty="value"
        items={savedTeamDetails(savedScheduleTeams)}
        listTitle={yourSchedulesText}
        onRemove={onRemoveTeam}
      />
    </div>
  );
};

export default RightColumn;
