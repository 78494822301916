import { combineReducers } from 'redux';

import mapReducer from '@components/Map/reducer';
import accountReducer from '@redux/reducers/account';
import settingsReducer from '@redux/reducers/settings';
import userReducer from '@redux/reducers/user';
import vehicleReducer from '@redux/reducers/vehicle';
import curfewReducer from '@redux/reducers/curfew';
import alertsReducer from '@redux/reducers/alerts';
import geofenceReducer from '@redux/reducers/geofence';
import loadingReducer from '@redux/reducers/loading';
import boundaryReducer from '@redux/reducers/boundaries';
import dialogReducer from '@redux/reducers/dialog';
import stolenVehicleReducer from '@redux/reducers/stolen-vehicle';
import preferencesReducer from '@redux/reducers/preferences';
import rrsReducer from '@redux/reducers/rrs';

export enum ROOT_EVENTS {
  CLEAR_CACHE = 'CLEAR_CACHE',
  DESTROY_SESSION = 'DESTROY_SESSION',
  CLEAR_SUBSCRIPTIONS = 'CLEAR_SUBSCRIPTIONS',
}
const appReducer = combineReducers({
  mapReducer,
  accountReducer,
  settingsReducer,
  userReducer,
  vehicleReducer,
  curfewReducer,
  alertsReducer,
  geofenceReducer,
  loadingReducer,
  boundaryReducer,
  dialogReducer,
  stolenVehicleReducer,
  preferencesReducer,
  rrsReducer,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === ROOT_EVENTS.DESTROY_SESSION) {
    localStorage.clear();
    sessionStorage.clear();
    document.cookie.split(';').forEach((c) => {
      document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;
export default rootReducer;
