import React, { useState } from 'react';
import ModalContainer from '@components/ModalContainer';
import { ProductWithServices } from './SubscriptionInformation';
import s from './SubscriptionInformation.module.css';

type ProductItemProps = {
  product: ProductWithServices;
  infoIcon: JSX.Element | string;
  productIcon?: JSX.Element | false | null;
  statusIcon?: JSX.Element | false | null;
};

const ProductItem = ({ product, infoIcon, productIcon, statusIcon }: ProductItemProps) => {
  const [isInfoModalVisible, toggleInfoModal] = useState<boolean>(false);
  const infoButton = (
    <button className={s.infoButton} onClick={() => toggleInfoModal(true)} aria-label="Info">
      {infoIcon}
    </button>
  );
  return (
    <li className={s.productItem}>
      <div className={s.productItemTitle}>
        {productIcon}
        {product.marketingName}
        {infoButton}
      </div>

      <ModalContainer
        header={{ text: product.marketingName, position: 'left', showXButton: true, showTopBorder: true }}
        show={isInfoModalVisible}
        onCloseHandler={() => toggleInfoModal(false)}
        classes={{
          dialog: s.modal,
          header: s.modalHeader,
          body: s.modalBody,
        }}
      >
        {product.shortDescription}
        <ul>
          {product.services?.map((service) => (
            <li key={service.vehicleServiceId}>{service.marketingName}</li>
          ))}
        </ul>
      </ModalContainer>
    </li>
  );
};

export default ProductItem;
