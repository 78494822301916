import React from "react";
import MaskedInput from 'react-text-mask';
import clsx from "clsx";

import useToggle from "@hooks/useToggle";
import styles from "@components/Input/Input.module.css";

export type InputMaskedProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  error?: boolean;
  showErrorIcon?: boolean;
  mask: (string | RegExp)[];
  showMask?: boolean;
  showMaskOnHover?: boolean;
  helpers?: object
};

const InputMasked = ({
  className,
  error = false,
  showErrorIcon = false,
  showMaskOnHover = false,
  helpers,
  ...props
}: InputMaskedProps) => {
  const inputClassName = clsx(styles.input, className, {
    [styles.error]: error,
    [styles.errorIcon]: showErrorIcon,
  });
  const [showMask, setShowMask] = useToggle(false);

  const showMaskHandler = () => showMaskOnHover && setShowMask(true);
  const hideMaskHandler = () => showMaskOnHover && setShowMask(false);

  return (
    <MaskedInput
      className={inputClassName}
      onMouseEnter={showMaskOnHover ? showMaskHandler : undefined}
      onMouseLeave={showMaskOnHover ? hideMaskHandler : undefined}
      showMask={showMask}
      {...props}
    />
  );
};

export default InputMasked;
