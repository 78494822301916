// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1nmZke7i3L6PpjSm_wiJhH {\n  text-align: center;\n}\n\n._3MhTacUug63wLW4kWOyjXH {\n  align-items: center;\n  flex: 1;\n  margin-bottom: 2rem;\n}\n\n.b1HHLil37WpVmuDGT0cNn {\n  color: var(--theme-color-grey-7a);\n  margin-bottom: 1rem;\n}\n\n._FMTO_9cTIu4xHwLnytXl {\n  font-size: 0.75rem;\n}\n\n._2E73zGJO7h6skV6CAQzdnw {\n  margin-top: 0.75rem;\n}\n\n._2T_VUUtHJGxL1NYsuHIZh6 {\n  margin: 0 auto;\n  min-width: 150px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/LinkVehicle/VinSearch/VinSearch.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,OAAO;EACP,mBAAmB;AACrB;;AAEA;EACE,iCAAiC;EACjC,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".form {\n  text-align: center;\n}\n\n.input-wrapper {\n  align-items: center;\n  flex: 1;\n  margin-bottom: 2rem;\n}\n\n.input-label {\n  color: var(--theme-color-grey-7a);\n  margin-bottom: 1rem;\n}\n\n.input-field {\n  font-size: 0.75rem;\n}\n\n.input-error {\n  margin-top: 0.75rem;\n}\n\n.submit {\n  margin: 0 auto;\n  min-width: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "_1nmZke7i3L6PpjSm_wiJhH",
	"input-wrapper": "_3MhTacUug63wLW4kWOyjXH",
	"input-label": "b1HHLil37WpVmuDGT0cNn",
	"input-field": "_FMTO_9cTIu4xHwLnytXl",
	"input-error": "_2E73zGJO7h6skV6CAQzdnw",
	"submit": "_2T_VUUtHJGxL1NYsuHIZh6"
};
export default ___CSS_LOADER_EXPORT___;
