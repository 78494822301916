import React, { useEffect, useState } from 'react';
import { CapableServicesResponse, useApi } from '@api';
import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { useVehicleSelector } from '@redux/selectors/vehicle';
import SubscriptionInformation, {
  SubscribedPackageWithServices,
  SubscriptionInformationProps,
} from './SubscriptionInformation';
import { useSelector } from 'react-redux';
import { RootState } from '@app/reducers';

const SubscriptionInformationConnector = (props: SubscriptionInformationProps) => {
  const api = useApi();
  const userId = useSelector(({ userReducer }) => userReducer.userId);
  const selectedVehicle = useVehicleSelector();
  const accountSubscriptions = useSelector(({ userReducer }: RootState) => userReducer.subscribedServices);
  const [selectedVehiclePackages, setSelectedVehiclePackages] = useState([]);
  const [capableServices, setCapableServices] = useState<CapableServicesResponse['data'] | null>(null);
  const [data, setData] = useState<SubscribedPackageWithServices[]>([]);

  useEffect(() => {
    if (selectedVehicle && accountSubscriptions?.length && userId) {
      setSelectedVehiclePackages(
        accountSubscriptions.find((packageItem: any) => packageItem.vehicleId === selectedVehicle.vehicleId)?.packages,
      );
      const loadData = async () => {
        const services = await api.getCapableServices({
          vehicleId: selectedVehicle.vehicleId,
          userId,
        });
        setCapableServices(services.data);
      };
      loadData();
    }
  }, [selectedVehicle, accountSubscriptions, api, userId]);

  useEffect(() => {
    if (capableServices?.length && selectedVehiclePackages?.length) {
      const data = selectedVehiclePackages.map((item: SubscribedPackage) => {
        return {
          ...item,
          packageName: item.marketingName,
          products: item.products?.map((product) => ({
            ...product,
            services: capableServices.find((service) => service.id === product.id)?.services,
          })),
        };
      });
      setData(data);
    } else {
      setData([]);
    }
  }, [capableServices, selectedVehiclePackages]);

  return <SubscriptionInformation {...props} data={data} />;
};

export default SubscriptionInformationConnector;
