import { Environment } from '@cv/portal-common-lib/ajax/constants';
import {
  searchStocks,
  getStocksList,
  deleteStocks,
  addStocks,
  GetExistingStocksRequest,
} from '@cv/portal-rts-lib/v0/information/stocks';
import {
  SearchStocksRequest,
  AddStocksRequest,
  DeleteStocksRequest,
} from '@cv/portal-rts-lib/v0/information/stocks/models';
import { StockCategory } from '@cv/portal-rts-lib/v0/information/stocks';

export type GetStockRequestProps = {
  accessToken: string;
  vin: string;
  ticker: string;
};

export default class StockService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  async searchStocks({ accessToken, vin, ticker }: GetStockRequestProps) {
    const request = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        vin,
      },
      queryParams: {
        ticker,
      },
      data: {},
    } as SearchStocksRequest;

    const response = await searchStocks(request);
    return { data: response.data };
  }

  async getStocksList({ accessToken, vin, folderId }: GetExistingStocks) {
    const request: GetExistingStocksRequest = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        vin,
      },
      queryParams: {
        folderId,
      },
    };

    const response = await getStocksList(request);
    return { data: response.data };
  }

  async addStocks({ accessToken, vin, data }: AddStocks) {
    const request: AddStocksRequest = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        vin,
      },
      data,
    };

    return await addStocks(request);
  }

  async deleteStock({ accessToken, vin, folderId, channelId }: DeleteStocks) {
    const request: DeleteStocksRequest = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        vin,
      },
      queryParams: {
        folderId,
        channelId,
      },
    };

    return await deleteStocks(request);
  }
}

export type GetExistingStocks = StocksRequest & { folderId: string };

export type AddStocks = StocksRequest & { data: StockCategory[] };

export type DeleteStocks = StocksRequest & { folderId: string; channelId: string };

type StocksRequest = {
  accessToken: string;
  vin: string;
};
