// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d48xbiiR7EEMYGQz0gURl {\n  padding: 0;\n  margin: 0;\n}\n\n._26pm30HuCglIShq2Ytp63x {\n  color: var(--theme-color-white);\n  width: 100%;\n  padding: 6px;\n  background-color: var(--theme-header-secondary-dropdown-background);\n  cursor: pointer;\n  white-space: nowrap;\n  list-style: none;\n}\n\n._26pm30HuCglIShq2Ytp63x:hover {\n  background-color: #939393;\n}\n\n._29qnM136Ojw325NUg8cDMN {\n  text-decoration: line-through;\n}\n", "",{"version":3,"sources":["webpack://./src/components/VehicleSwitcher/VehicleSwitcher.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,SAAS;AACX;;AAEA;EACE,+BAA+B;EAC/B,WAAW;EACX,YAAY;EACZ,mEAAmE;EACnE,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".VehicleItemsList {\n  padding: 0;\n  margin: 0;\n}\n\n.VehicleItem {\n  color: var(--theme-color-white);\n  width: 100%;\n  padding: 6px;\n  background-color: var(--theme-header-secondary-dropdown-background);\n  cursor: pointer;\n  white-space: nowrap;\n  list-style: none;\n}\n\n.VehicleItem:hover {\n  background-color: #939393;\n}\n\n.disabled {\n  text-decoration: line-through;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"VehicleItemsList": "d48xbiiR7EEMYGQz0gURl",
	"VehicleItem": "_26pm30HuCglIShq2Ytp63x",
	"disabled": "_29qnM136Ojw325NUg8cDMN"
};
export default ___CSS_LOADER_EXPORT___;
