import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@components/Theme';

import './MapWindow.css';

type TimerProps = {
  labels: any;
  updatedTimerValue: (result?: any) => void;
};

const MapCountDownTimer = React.memo(({ labels, updatedTimerValue }: TimerProps) => {
  const dispatch = useDispatch();
  const mapTimerValue = useSelector(({ mapReducer }) => mapReducer.mapTimerValue);
  const theme = useTheme();
  const timerTheme = {
    color: theme.get('count-down-window-timer-color'),
    trailColor: theme.get('count-down-window-timer-trail-color'),
  };
  const { color, trailColor } = timerTheme;

  const timerProps = {
    isPlaying: true,
    size: 95,
    strokeWidth: 4,
    trailColor,
  };

  const renderTime = ({ remainingTime }: any) => {
    const min = Math.ceil(remainingTime / 60);
    if (remainingTime < mapTimerValue) {
      dispatch({ type: 'SET_MAP_TIMER_VALUE', mapTimerValue: remainingTime });
    }
    if (remainingTime === 0) {
      updatedTimerValue(remainingTime);
    }
    return (
      <div className="map-window-count-timer">
        <span className="map-window-time_left">{labels?.timeLeft}</span>
        <span className="map-window-time-mins">{min}</span>
        <span className="map-window-time-mins-label">{labels?.min}</span>
        <span className="map-window-time-stop-label">{labels?.pressToStop}</span>
      </div>
    );
  };
  return (
    <CountdownCircleTimer {...timerProps} colors={color} duration={mapTimerValue}>
      {renderTime}
    </CountdownCircleTimer>
  );
});

export default MapCountDownTimer;
