import * as yup from 'yup';

import { CarrierCodes, ChannelName } from '@cv/portal-cps-lib/vehicle/vehicle-notification-service/enums';
import { Form, Formik, FormikProps } from 'formik';
import { FormikInputField, FormikPhoneField } from '@components/FormikFields';
import React, { useMemo } from 'react';
import styles from './NotificationPreferences.module.css';

import Button from '@components/Button';
import Grid from '@components/Grid';
import InputSelect from '@components/InputSelect';
import SettingsGrid from '@components/SettingsGrid';

export type PreferenceValues = {
  email?: string;
  phone: string;
  timeZone?: string;
  carrier: string;
  serviceNotificationPreferences: ChangeData[];
};

type ChangeData = Channel & {
  serviceName: string;
  telematicsServiceName: string;
};

export type EditPreferencesProps = {
  labels: Record<string, string>;
  timeZoneValues: string[];
  fullName: string;
  handleSubmit: (values: PreferenceValues) => void;
  handleCancel: () => void;
  preferences: PreferenceValues;
};

type Channel = Record<Lowercase<ChannelName>, boolean>;

const isChannelName = (name: string): name is keyof Channel =>
  [ChannelName.EMAIL.toLowerCase(), ChannelName.PUSH.toLowerCase(), ChannelName.TEXT.toLowerCase()].includes(name);

const allCarriers = Object.values(CarrierCodes);

export function EditPreferencesForm({
  fullName,
  labels,
  timeZoneValues,
  handleSubmit,
  handleCancel,
  preferences,
}: EditPreferencesProps) {
  const timeZoneOptions = timeZoneValues.map((option: string) => {
    const [value, label] = option.split('::');
    if (label) {
      return { value, label };
    }
    return { value: option, label: option };
  });

  const carrierOptions = useMemo(
    () => allCarriers.map((carrier) => ({ value: carrier, label: labels[carrier] || carrier })),
    [labels],
  );

  const validationSchema = yup.object().shape({
    email: yup.string().trim().email(labels.errorEmailInvalid).required(labels.errorEmailRequired),
    phone: yup
      .string()
      .trim()
      .matches(/^\+?[1-9]\d{10,14}$/, { message: labels.errorPhoneInvalid }),
    carrier: yup.string().uppercase(),
  });

  const fields = {
    serviceName: labels.notificationType,
    email: labels.notificationTypeEmail,
    push: labels.notificationTypePush,
    text: labels.notificationTypeText,
  };

  return (
    <Formik initialValues={preferences} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {(props: FormikProps<PreferenceValues>) => (
        <Form className={styles['form']}>
          <Grid className={styles['grid']} columns="2">
            <div>
              <div className={styles['label']}>{labels.name}</div>
              <div>{fullName}</div>
            </div>
            <FormikInputField
              name="email"
              type="email"
              label={labels.primaryEmail}
              placeholder={labels.primaryEmail}
              className={styles['input']}
            />
            <FormikPhoneField
              name="phone"
              label={labels.primaryPhone}
              placeholder={labels.primaryPhone}
              classes={{ input: styles['input-field'] }}
              form={{
                errors: props.errors,
                handleBlur: props.handleBlur,
                setFieldValue: props.setFieldValue,
                touched: props.touched,
              }}
            />

            <FormikInputField
              name="timeZone"
              type="timeZone"
              label={labels.timeZone}
              placeholder={labels.timeZone}
              onChange={() => undefined}
              options={timeZoneOptions}
              InputComponent={InputSelect}
              className={styles['drop-down-field']}
            />
            <FormikInputField
              name="carrier"
              type="carrier"
              label={labels.carrier}
              placeholder={labels.carrier}
              onChange={() => undefined}
              options={carrierOptions}
              InputComponent={InputSelect}
              className={styles['drop-down-field']}
            />
          </Grid>
          <SettingsGrid
            indexing="serviceName"
            data={props.values.serviceNotificationPreferences}
            fields={fields}
            mode="edit"
            onChange={(index: number, _, name: string, value: boolean) => {
              if (!isChannelName(name)) {
                return;
              }

              const services = props.values.serviceNotificationPreferences;

              props.setFieldValue(`serviceNotificationPreferences[${index}]`, {
                ...services[index],
                [name]: value,
              });
            }}
          />
          <p className={styles['push-footnote']}>{labels.pushFootnote}</p>
          <div className={styles['buttons']}>
            <Button variant="outlined" onClick={handleCancel} type="button">
              {labels.cancel}
            </Button>
            <Button variant="filled" type="submit">
              {labels.save}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default EditPreferencesForm;
