import { APIResponse } from '@cv/portal-common-lib/ajax/models';
import { GetGeofenceResponse } from '@cv/portal-rts-lib/geofences/models';

export enum PollingStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
}

type PollingArgs = {
  pollingFunc: () => Promise<unknown>;
  pollingInterval?: number;
  validationCallback: (response: APIResponse<GetGeofenceResponse>) => PollingStatus;
  successCallback: () => void;
  errorCallback: () => void;
  timeout?: number;
  maxAttempts?: number;
};

const initiatePolling = ({
  pollingFunc,
  validationCallback,
  successCallback,
  errorCallback,
  timeout,
  pollingInterval = 10000,
  maxAttempts = 10,
}: PollingArgs) => {
  let isTimerOff = false;
  let attempts = 0;
  if (typeof timeout === 'number') {
    setTimeout(() => (isTimerOff = true), timeout);
  }
  const poll = async () => {
    const result = await pollingFunc();
    attempts++;

    const validationStatus = validationCallback(result);
    if (validationStatus === PollingStatus.SUCCESS) {
      return successCallback();
    }

    const attemptsExceeded = !timeout && attempts >= maxAttempts;
    if (validationStatus === PollingStatus.ERROR || attemptsExceeded || isTimerOff) {
      return errorCallback();
    }

    setTimeout(poll, pollingInterval);
  };
  poll();
};

export default initiatePolling;
