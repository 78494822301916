import {
  Discount,
  SubscribedPackage,
} from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import { OfferType, PackageType, TermUnit } from '@cv/portal-cps-lib/subscription/subscription-management/enums';

export const isAnnualTerm = ({ currentTermUnit, currentTerm }: SubscribedPackage) => {
  return currentTermUnit === TermUnit.Months && currentTerm === 12;
};

export const isPaidPackage = (sPkg: SubscribedPackage) => {
  const PAID_PKG_OFFER_TYPES = [OfferType.Base_discount, OfferType.Promotional];
  return (
    sPkg.packageType === PackageType.Regular &&
    (!sPkg?.discounts?.length || getDiscounts(sPkg.discounts, PAID_PKG_OFFER_TYPES).length > 0)
  );
};

const getDiscounts = (discounts: Discount[], offerTypes: OfferType[]) => {
  return discounts?.filter((d) => offerTypes.includes(d.offerType));
};
