import React, { useEffect, useState, useCallback } from 'react';
import clsx from 'clsx';
import { Category, NewsItem } from '@cv/portal-rts-lib/v0/information/news';
import { useApi } from '@api';
import Loader from '@components/Loader';
import ContentLayout from '../ContentLayout';
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
import { MISLabels, FolderPayload } from '../types';

import styles from '../MobileInformationService.module.css';

export type Props = {
  labels: MISLabels;
  folders?: FolderPayload[];
};

const News = ({ labels, folders = [] }: Props) => {
  const [selected, setSelected] = useState<NewsItem[] | null>(null);
  const [newCategories, setNewCategories] = useState<string[]>([]);
  const [deleted, setDeleted] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const api = useApi();

  const launchLoading = useCallback(
    (requestPromise: Promise<unknown>) => {
      setLoading(true);
      return requestPromise;
    },
    [setLoading],
  );

  const add = (data: Category[]) => (data.length && api.addNews(data)) || {};
  const remove = (channelId: string) => api.deleteNews({ folderId: folders[0]?.folderId, channelId });

  const update = useCallback(
    (updatePromise: Promise<unknown>) =>
      updatePromise
        .then(() => api.getNews(folders[0]?.folderId))
        .then(({ data = [] }) => setSelected(data))
        .then(() => setNewCategories([]))
        .then(() => setDeleted([]))
        .catch(() => setSelected([]))
        .finally(() => setLoading(false)),
    [api, folders, setSelected, setNewCategories, setDeleted, setLoading],
  );

  const save = (all: Category[]) =>
    update(
      removeSomeCategories().then(() => {
        const categoriesToSave = all.filter(({ category }) => !deleted.includes(category));
        return add(categoriesToSave);
      }),
    );

  const removeOneCategory = (channelId: string) => update(launchLoading(remove(channelId)));

  const removeSomeCategories = () =>
    launchLoading(
      Promise.all(
        deleted
          .reduce<string[]>(
            (channelIds, currentCategory) => [
              ...channelIds,
              ...selected!
                .filter(({ channelName }) => channelName === currentCategory)
                .map(({ channelId }) => channelId),
            ],
            [],
          )
          .map(remove),
      ),
    );

  useEffect(() => {
    if (!selected && !!folders[0]) {
      const startFetching = () => launchLoading(Promise.resolve());
      update(startFetching());
    }
  }, [selected, update, folders, launchLoading]);

  const addNew = (category: string) => setNewCategories((alreadyNewlyAdded) => [...alreadyNewlyAdded, category]);
  const removeNew = (category: string) =>
    setNewCategories((allNew) => allNew.filter((categoryItem) => categoryItem !== category));

  const addToDeleted = (category: string) => setDeleted((selectedToDelete) => [...selectedToDelete, category]);
  const removeFromDeleted = (category: string) =>
    setDeleted((selectedToDelete) => selectedToDelete.filter((deletedItem) => deletedItem !== category));

  const onAdd = (category: string, isSelected: boolean) => {
    if (isSelected) {
      if (newCategories.includes(category)) {
        removeNew(category);
      } else {
        addToDeleted(category);
      }
    } else {
      if (deleted.includes(category)) {
        removeFromDeleted(category);
      } else {
        addNew(category);
      }
    }
  };

  const allCategories = [...(selected || []), ...newCategories];

  const canSave = !!newCategories.length || !!deleted.length;

  const onSave = () => canSave && save(attachFolder(allCategories, folders[0]?.folderId, folders[0]?.folderName));

  const onRemove = ({ channelId }: NewsItem) => removeOneCategory(channelId);

  if (loading) {
    return <Loader style={{ position: 'absolute' }} />;
  }

  return (
    <ContentLayout
      className={clsx(styles['sports-left-wrapper'], styles['news-layout'])}
      left={
        <LeftColumn
          labels={labels}
          allCategories={allCategories}
          onAdd={onAdd}
          canSave={canSave}
          onSave={onSave}
          deleted={deleted}
        />
      }
      right={<RightColumn labels={labels} selectedCategories={selected || []} onRemove={onRemove} />}
    />
  );
};

function attachFolder(categories: (string | NewsItem)[], folderId: string, folderName: string): Category[] {
  return categories.map((category) => ({
    folderId,
    folderName,
    category: typeof category === 'string' ? category : category.channelName,
  }));
}

export default News;
