import React from "react";
import ReactDom from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Api, ApiProvider } from "@api";
import App from "@components/App";
import { ContentfulInit } from "@cv/webframework-react-components";

import config from "@config/config";
import configureStore from './store';
import StoreService from "./api/services/StoreService";

import "./index.css";
import "./commons.css";

// Custom Media Queries
import "./customMedia.css";

// Themes
import "./themes/common.theme.css";
import "./themes/nissan.theme.css";
import "./themes/infinity.theme.css";
import "./themes/stellantis.theme.css";
import "./themes/abc.theme.css";
import "./themes/toyota.theme.css";
import "./themes/lexus.theme.css";
import "./themes/honda.theme.css";
import "./themes/acura.theme.css";
import "./themes/subaru.theme.css";

// Dealer portal theme
import "./themes/common.dealer.theme.css";
import { DialogProvider } from "@components/Dialog/useDialog";

// Contentful settings
const contentfulConfig = {
  accessToken: config.get<string>('CONTENTFUL_ACCESS_TOKEN'),
  spaceId: config.get<string>('CONTENTFUL_SPACE_ID'),
  environment: config.get<string>('CONTENTFUL_ENV'),
};

const { store, persistor } = configureStore();
const storeService = new StoreService(store);
const api = new Api(storeService);

// we need it in order to remove all react persist values in localStorage that were added before
window.localStorage?.clear();

ReactDom.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ContentfulInit {...contentfulConfig}>
          <DialogProvider>
            <ApiProvider api={api}>
              <App />
            </ApiProvider>
          </DialogProvider>
        </ContentfulInit>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
