import React from 'react';
import { isPaidPackage } from '@cv/portal-components-lib';
import { SubscriptionTerm, subscriptionTerm } from '@utils/subscriptionTerm';
import { VariantInfo } from '@cv/portal-components-lib/src/manageSubscription/Types';
import styles from './SubscriptionOverview.module.css';
import SubscriptionPackage from '@customTypes/SubscriptionPackage';
import usePreferencesSelector from '@redux/selectors/preferences';

export interface PackageSubscriptionTypeProps {
  subscribedPackage: SubscriptionPackage;
}

const PackageSubscriptionType = ({ subscribedPackage }: PackageSubscriptionTypeProps) => {
  const globalPreferences = usePreferencesSelector();
  const term = subscriptionTerm(subscribedPackage);
  const isPaid = isPaidPackage(subscribedPackage as unknown as VariantInfo);

  if (!isPaid) {
    return <span>{globalPreferences.trialSubscriptionLabel}</span>;
  }

  const { renewalTerm, currentTermUnit = 'months' } = subscribedPackage || {};

  const termLabel = {
    [SubscriptionTerm.month]: globalPreferences.renewsMonthlyLabel,
    [SubscriptionTerm.annual]: globalPreferences.renewsAnnuallyLabel,
    [SubscriptionTerm.expires]: globalPreferences.endOfTermLabel,
    [SubscriptionTerm.promo]: `${globalPreferences.renew} ${renewalTerm} ${
      globalPreferences[currentTermUnit.toLowerCase()] || currentTermUnit
    }`,
  }[term];

  return (
    <span>
      <span>{globalPreferences.paidSubscriptionLabel}</span>
      {termLabel && (
        <>
          <span>:&nbsp;</span>
          <span className={styles['grey-text']}>{termLabel}</span>
        </>
      )}
    </span>
  );
};

export default PackageSubscriptionType;
