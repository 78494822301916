// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IjCC0O_v1AhvEyLzAaxH_ {\n  --card-color-border-top: var(--manage-subscription-border-color);\n  margin: 0.7rem auto;\n}\n\n.uBfPeNU73PgjSAmr1Dslk {\n  padding: 16px;\n}\n\n._1CWm7CgLMIIN7SelRgj-CM {\n  border: 0;\n}\n\n._1CWm7CgLMIIN7SelRgj-CM > div {\n  box-shadow: none;\n}\n\n@media (min-width: 600px) {\n  .uBfPeNU73PgjSAmr1Dslk {\n    padding: 32px;\n  }\n\n  .vEXL4p4HlDn2qE_hKUwtu {\n    width: 566px;\n  }\n}\n\n@media (min-width: 768px) {\n  .vEXL4p4HlDn2qE_hKUwtu {\n    width: 750px;\n  }\n}\n\n@media (min-width: 992px) {\n  .vEXL4p4HlDn2qE_hKUwtu {\n    width: 970px;\n  }\n}\n\n@media (min-width: 1200px) {\n  .vEXL4p4HlDn2qE_hKUwtu {\n    width: 1170px;\n  }\n}\n\n.IjCC0O_v1AhvEyLzAaxH_ p {\n  margin: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/SubscriptionWrapper/SubscriptionWrapper.module.css"],"names":[],"mappings":"AAAA;EACE,gEAAgE;EAChE,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE,SAAS;AACX","sourcesContent":[".subscription-wrapper {\n  --card-color-border-top: var(--manage-subscription-border-color);\n  margin: 0.7rem auto;\n}\n\n.subscription-wrapper-inner {\n  padding: 16px;\n}\n\n.subscription-wrapper-without-border {\n  border: 0;\n}\n\n.subscription-wrapper-without-border > div {\n  box-shadow: none;\n}\n\n@media (--viewport-xs) {\n  .subscription-wrapper-inner {\n    padding: 32px;\n  }\n\n  .card-with-margin {\n    width: 566px;\n  }\n}\n\n@media (--viewport-s) {\n  .card-with-margin {\n    width: 750px;\n  }\n}\n\n@media (--viewport-m) {\n  .card-with-margin {\n    width: 970px;\n  }\n}\n\n@media (--viewport-l) {\n  .card-with-margin {\n    width: 1170px;\n  }\n}\n\n.subscription-wrapper p {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subscription-wrapper": "IjCC0O_v1AhvEyLzAaxH_",
	"subscription-wrapper-inner": "uBfPeNU73PgjSAmr1Dslk",
	"subscription-wrapper-without-border": "_1CWm7CgLMIIN7SelRgj-CM",
	"card-with-margin": "vEXL4p4HlDn2qE_hKUwtu"
};
export default ___CSS_LOADER_EXPORT___;
