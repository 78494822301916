import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLabels, { Label } from '@hooks/useLabels';

import Dropdown from '@components/Dropdown';
import LanguagePickerItem from './LanguagePickerItem';
import { SETTINGS_ACTIONS } from '@redux/actions/';
import { UpdateOperation } from '@cv/portal-idm-lib/user/user-update-profile/enums';
import { toAlpha2 } from 'i18n-iso-countries';
import urlSearchParams from '@utils/urlSearchParams';
import { useApi } from '@api';
import { useDialog } from '@components/Dialog/useDialog';
import { RootState } from '@app/reducers';
import ControlLabel from '@components/ControlLabel';
import RoundToggleButton from '@components/RoundToggleButton';
import { FaCheck } from 'react-icons/fa';
import config from '@config/config';

type AvailableLocaleProps = {
  key: string;
  label: string;
};

type LanguagePickerProps = {
  languagePreference: {
    defaultLanguage?: string;
    isPreferredLanguageVerified?: boolean;
    labels?: Label;
    languages: string[];
  };
  theme?: string;
  className?: string;
};

const getAvailableLocales = (labels: Label, languages: string[]): AvailableLocaleProps[] =>
  Object.keys(labels)
    .filter((key) => languages.includes(key))
    .reduce((arr: AvailableLocaleProps[], key, i) => {
      arr[i] = { key, label: labels[key] };
      return arr;
    }, []);

// Do not move to custom hooks, as it not really the one
// this hook is `action` for redux only
export const useChangeLanguage = () => {
  const dispatch = useDispatch();

  return useCallback(
    (key: string) => {
      dispatch({ type: SETTINGS_ACTIONS.SET_IS_LOCALE_UPDATED_BY_USER, data: true });
      dispatch({ type: SETTINGS_ACTIONS.SET_LOCALE, data: key });
    },
    [dispatch],
  );
};

const configDefaultLanguage = config.get('DEFAULT_LANGUAGE');

const LanguagePicker = ({ languagePreference, theme, className }: LanguagePickerProps): JSX.Element | null => {
  const { locale } = useSelector(({ settingsReducer }: RootState) => settingsReducer);
  const { account } = useSelector(({ accountReducer }: RootState) => accountReducer);
  const { isLangModalSuppressed } = useSelector(({ settingsReducer }: RootState) => settingsReducer);
  const api = useApi();
  const dispatch = useDispatch();
  const { registrationCountry } = api.storeService.getVehicleData() || {};
  const dialog = useDialog();

  const lang = urlSearchParams.get<string>('lang');
  const labelsValue = useLabels(languagePreference?.labels?.content).getAllPrimaries() || {};
  const isAuthenticated = api.storeService.getAccessToken();

  const { defaultLanguage: contentfulDefaultLanguage, languages } = languagePreference;

  // * clear availableLocales first, before going through available languages
  const availableLocales = useMemo<Array<AvailableLocaleProps>>(() => {
    const locales = getAvailableLocales(labelsValue, languages);
    if (registrationCountry) {
      const localeCountry = toAlpha2(registrationCountry);
      return locales.filter(({ key }) => {
        return new Intl.Locale(key).region === localeCountry;
      });
    }
    return locales;
  }, [registrationCountry]);

  const [langKey, setLangKey] = useState(
    availableLocales[0]?.key || contentfulDefaultLanguage || configDefaultLanguage,
  );
  const findLocaleByKey = useCallback(
    (localeKey) => availableLocales.find(({ key }) => key === localeKey),
    [availableLocales],
  );
  const firstLocaleLabel = useMemo<string>(
    () => findLocaleByKey(locale)?.label || availableLocales[0]?.label,
    [availableLocales, locale],
  );

  const changeLanguage = useChangeLanguage();

  const changeUserLanguage = useCallback(
    (key: string, onSuccess?: () => void) => {
      api
        .updateUserInfo([
          {
            operation: UpdateOperation.Replace,
            field: 'preferredLanguage',
            value: key,
          },
        ])
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          changeLanguage(key);
          onSuccess?.();
        });
    },
    [changeLanguage],
  );

  const renderCheckbox = useCallback(() => {
    return (
      <ControlLabel
        label={labelsValue.suppressCheckboxLabel}
        control={
          <RoundToggleButton
            checked={isLangModalSuppressed}
            onToggle={() => {
              dispatch({ type: SETTINGS_ACTIONS.TOGGLE_SUPPRESS_LANG_MODAL });
            }}
          >
            {isLangModalSuppressed && <FaCheck />}
          </RoundToggleButton>
        }
      />
    );
  }, [isLangModalSuppressed, dispatch, labelsValue.suppressCheckboxLabel]);

  useEffect(() => {
    dialog({
      title: `${labelsValue.dialogTitle} ${findLocaleByKey(langKey)?.label}`,
      labelOk: labelsValue.dialogOk,
      labelCancel: labelsValue.dialogCancel,
      initialClosed: true,
      onOk: () => {
        changeUserLanguage(langKey, dialog.close());
      },
      children: renderCheckbox(),
    });
  }, [
    renderCheckbox,
    findLocaleByKey,
    isLangModalSuppressed,
    labelsValue.dialogTitle,
    labelsValue.dialogOk,
    labelsValue.dialogCancel,
    langKey,
  ]);

  const renderDialog = useCallback(
    (key: string) => {
      setLangKey(key);
      dialog.open();
    },
    [dialog],
  );

  const onLanguageChange = useCallback(
    (key: string) => {
      !isLangModalSuppressed ? renderDialog(key) : changeUserLanguage(key);
    },
    [renderDialog, changeUserLanguage, isLangModalSuppressed],
  );

  const getUserLanguage = useCallback(async () => {
    let prefLang = '';
    try {
      const {
        data: { preferredLanguage },
      } = await api.getAccountDetails(undefined, account?.data?._id);
      prefLang = preferredLanguage;
    } catch (e) {
      console.error(e);
    }
    if (findLocaleByKey(prefLang)) {
      // * if user have set preferred language
      return prefLang;
    } else if (findLocaleByKey(lang)) {
      // * if we have 'lang' param in url
      return lang;
    } else if (availableLocales.length) {
      return availableLocales[0].key;
    } else if (contentfulDefaultLanguage) {
      // * default language from contentful
      return contentfulDefaultLanguage;
    } else {
      // * return default language from env if anything else didn't fired
      return configDefaultLanguage;
    }
  }, [account?.data?._id, api, availableLocales, contentfulDefaultLanguage, findLocaleByKey, lang]);

  useEffect(() => {
    const setUserLanguage = async () => {
      const _userLanguage = await getUserLanguage();
      _userLanguage && changeLanguage(_userLanguage);
    };
    if (!findLocaleByKey(locale)) {
      setUserLanguage();
    }
  }, [getUserLanguage]);

  if (!isAuthenticated) {
    return null;
  }

  if (availableLocales.length <= 1 || !registrationCountry) return null;

  return (
    <Dropdown firstItemLabel={firstLocaleLabel} theme={theme} classes={{ container: className }}>
      {availableLocales
        .filter((item) => item.key !== locale)
        .map((availableLocale: AvailableLocaleProps, index) => (
          <LanguagePickerItem
            index={index}
            key={`language-${availableLocale.key}`}
            onClick={onLanguageChange}
            data={availableLocale}
          />
        ))}
    </Dropdown>
  );
};

export default LanguagePicker;
