import React from 'react';
import Grid from '@components/Grid/';
import InfoItem from './InfoItem';
import { FaEdit } from 'react-icons/fa';

import './InfoBox.css';

type InfoBoxProps = {
  entries?: Array<{ label: string; value: string }>;
  isEditable?: boolean;
  onEdit?: (index?: number) => void;
  columns?: '1' | '2' | '3';
  index?: number;
};

function InfoBox({ entries = [], isEditable = false, onEdit, columns = '2', index }: InfoBoxProps) {
  const onEditClick = () => {
    if (onEdit) {
      onEdit(index);
    }
  };

  return (
    <Grid className="InfoBox-container" columns={columns}>
      {entries.map(({ label, value }, i) => {
        const formatValue = value?.trim();
        return Boolean(formatValue) && <InfoItem label={label} content={formatValue} key={`${label}-${i}`} />;
      })}

      {isEditable && (
        <button className="InfoBox-button InfoBox-edit-button" onClick={onEditClick} title="Edit">
          <FaEdit className={'InfoBox-edit-icon'} />
        </button>
      )}
    </Grid>
  );
}

export default InfoBox;
