import { Environment } from '@cv/portal-common-lib/ajax/constants';
import {
  getSports,
  addSports,
  deleteSports,
  GetSportsRequest,
  AddSportsRequest,
  DeleteSportsRequest,
  SportsItem,
} from '@cv/portal-rts-lib/v0/information/sports';

export default class SportsService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  async getSports({ accessToken, vin, folderId }: GetSports) {
    const request: GetSportsRequest = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        vin,
      },
      queryParams: {
        folderId,
      },
    };

    const response = await getSports(request);
    return { data: response.data };
  }

  async addSports({ accessToken, vin, data }: AddSports) {
    const request: AddSportsRequest = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        vin,
      },
      data,
    };

    return await addSports(request);
  }

  async deleteSports({ accessToken, vin, folderId, channelId }: DeleteSports) {
    const request: DeleteSportsRequest = {
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        vin,
      },
      queryParams: {
        folderId,
        channelId,
      },
    };

    return await deleteSports(request);
  }
}

export type GetSports = SportsRequest & { folderId: string };

export type AddSports = SportsRequest & { data: SportsItem[] };

export type DeleteSports = SportsRequest & { folderId: string; channelId: string };

type SportsRequest = {
  accessToken: string;
  vin: string;
};
