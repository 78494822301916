import React, { useEffect, useState } from 'react';
import styles from './MobileInformationService.module.css';
import tabStyles from '@components/Map/TempDestinationTab/Tab.module.css';
import { useApi } from '@api';
import { TabItem, Tab } from '@components/Map/TempDestinationTab';
import useLabels from '@hooks/useLabels';
import { ServerLabel } from '@utils/labels';
import { MISLabels, FoldersResponse, TabFolders, SportsTeams } from './types';
import News from './News';
import Sports from './Sports';
import Stocks from './Stocks/Stocks';
import clsx from 'clsx';

export type MISProps = {
  title: string;
  description: string;
  labels: ServerLabel[];
  sportsTeams: SportsTeams;
};

const MobileInformationService = ({ title, description, labels, sportsTeams }: MISProps) => {
  const api = useApi();
  const [folders, setFolders] = useState<TabFolders>({});

  const labelsCollection = useLabels(labels);
  const allLabels: MISLabels = labelsCollection.getAllPrimaries();

  useEffect(() => {
    if (!Object.keys(folders).length) {
      api
        .getFolders()
        .then(takeContent)
        .then(setFolders)
        .catch((error) => console.error('Error fetching of folders', error));
    }

    function takeContent({ data: serverFolders = [] }: FoldersResponse) {
      const tabNames = [allLabels.newsTabName, allLabels.stocksTabName, allLabels.sportsTabName];
      return tabNames.reduce(
        (tabs, tabName) => ({
          ...tabs,
          [tabName]: serverFolders.filter(({ folderName }) => folderName === tabName || folderName.includes(tabName)),
        }),
        {},
      );
    }
  }, [folders, api, allLabels]);

  return (
    <div className={clsx('Layout-container', 'OneColumn-container', styles['container'])}>
      <div className="column">
        <h1 className={styles.heading}>{title}</h1>
        <p className={styles.para}>{description}</p>
        <Tab type={'primary'} selectedTab={allLabels.newsTabName} contentClassName={styles['min-height-tab-content']}>
          <TabItem
            className={clsx(styles['btn'], styles['tab-btn'], tabStyles['mis-tab'])}
            label={allLabels.newsTabName}
            data-label={allLabels.newsTabName}
          >
            <News labels={allLabels} folders={folders[allLabels.newsTabName]} />
          </TabItem>
          <TabItem
            className={clsx(styles['btn'], styles['tab-btn'], tabStyles['mis-tab'])}
            label={allLabels.stocksTabName}
            data-label={allLabels.stocksTabName}
          >
            <Stocks labels={allLabels} folders={folders[allLabels.stocksTabName]} />
          </TabItem>
          <TabItem
            className={clsx(styles['btn'], styles['tab-btn'], tabStyles['mis-tab'])}
            label={allLabels.sportsTabName}
            data-label={allLabels.sportsTabName}
          >
            <Sports labels={allLabels} sportsTeams={sportsTeams} folders={folders[allLabels.sportsTabName]} />
          </TabItem>
        </Tab>
      </div>
    </div>
  );
};

export default MobileInformationService;
