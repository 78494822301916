import React from 'react';
import { FaTimes } from 'react-icons/fa';
import clsx from 'clsx';

import styles from './MobileInformationService.module.css';
import { Team } from './types';

export type Props<T> = {
  className?: string;
  listTitle: string;
  items: T[];
  textProperty: keyof T;
  onRemove: (item: T) => void;
};

type CategoryItem = { channelId: string; channelName: string };

const SelectedItemsList = <T extends CategoryItem | Team>({
  className = '',
  listTitle,
  items,
  textProperty,
  onRemove,
}: Props<T>) => (
  <div className={clsx(styles['mis-selected-items-container'], 'column', className)}>
    <h4 className={styles['mis-selected-items-title']}>{listTitle}</h4>
    <ul className={styles['mis-selected-items-list']}>
      {items.map((item, i) => (
        <li key={`${i}-${item[textProperty]}`}>
          <span title={item[textProperty]}>{item[textProperty]}</span>
          <button
            className={styles['remove-icon-button']}
            onClick={() => onRemove(item)}
            title={`Remove ${item[textProperty]?.toLowerCase()}`}
          >
            <FaTimes className={styles['remove-icon']} />
          </button>
        </li>
      ))}
    </ul>
  </div>
);

export default SelectedItemsList;
