import { useMemo } from 'react';
import { generateLabelsObjects, ServerLabel, Label } from '@utils/labels';
import { BrandNamesFilterGroup } from '@customTypes/BrandNamesFilterGroup';
import useFilterDictionary from '@components/LabelDictionary/useFilterDictionary';

export type AllLabels = {
  [key: string]: Label;
};

export type UseLabelsReturn = {
  get(key: string): Label;
  getAll(): AllLabels;
  getAllAsArray(): string[];
  getPrimary(key: string, defaultValue?: string): string;
  getAllPrimaries(): { [key: string]: string };
};

export type LabelsPrimaries = ReturnType<UseLabelsReturn['getAllPrimaries']>;

const useLabels = (content: ServerLabel[] = [], filterGroup?: BrandNamesFilterGroup): UseLabelsReturn => {
  const allLabels = useMemo(() => generateLabelsObjects(content), [content]);
  const labels = useFilterDictionary({ labels: allLabels, filterGroup });

  const primariesLabels = useMemo(() => {
    return Object.entries(labels).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value.primary,
      }),
      {} as { [key: string]: string },
    );
  }, [labels]);

  return {
    get(key) {
      return key in labels ? labels[key] : { primary: '[UNKNOWN]' };
    },
    getAll() {
      return labels;
    },
    getAllAsArray() {
      return Object.values(primariesLabels);
    },
    getPrimary(key, defaultValue = '[UNKNOWN]') {
      return key in labels ? labels[key].primary : defaultValue;
    },
    getAllPrimaries() {
      return primariesLabels;
    },
  };
};

export default useLabels;
