import React from "react";

import styles from "./RegistrationForm.module.css";

type ContentContainerProps = {
  children: React.ReactChild;
};

const ContentContainer = ({ children }: ContentContainerProps) => {
  return (
    <div className={styles.Page}>
      <div className={styles["Content-container"]}>{children}</div>
    </div>
  );
};

export default ContentContainer;
