import React from 'react';
import Dialog from '@components/Dialog';

import styles from './UnlinkSuccess.module.css';

export type UnlinkSuccessProps = {
  labelUnlinkSuccessTitle: string;
  labelUnlinkSuccessText: string;
  labelContinue: string;
  onContinue: () => void;
};

const UnlinkSuccess = ({
  labelUnlinkSuccessTitle,
  labelUnlinkSuccessText,
  labelContinue,
  onContinue,
}: UnlinkSuccessProps) => {
  return (
    <Dialog
      title={labelUnlinkSuccessTitle}
      labelOk={labelContinue}
      onOk={onContinue}
      classes={{ body: styles.success }}
    >
      {labelUnlinkSuccessText}
    </Dialog>
  );
};

export default UnlinkSuccess;
