import Button from '@components/Button';
import React, { useEffect } from 'react';
import styles from './Widget.module.css';

export interface IWidgetProps {
  title?: string;
  titleAlignment?: 'center' | 'flex-start' | 'flex-end';
  title2?: React.ReactDOM;
  avatar?: any;
  subTitle?: string;
  children: any;
  prevButton?: string;
  nextButton?: string;
  disablePrevButton?: boolean;
  disableNextButton?: boolean;
}

const Widget = (props: any) => {
  const {
    title,
    titleAlignment = 'center',
    avatar,
    title2,
    subTitle,
    children,
    prevButton,
    nextButton,
    disablePrevButton = false,
    disableNextButton = false,
  }: IWidgetProps = props;

  return (
    <div>
      <div className={styles.Page}>
        <div className={styles['Content-container']}>
          <div className={styles['Card']}>
            <div className={styles['Title-container']} style={{ justifyContent: titleAlignment }}>
              {avatar && <span className={styles['Avatar']}> {avatar} </span>}
              <h1 className={styles['Title']}>{title}</h1>
              {title2}
            </div>
            {subTitle && <div className={styles['Sub-title-container']}>{subTitle}</div>}
            <div className={styles['Content']}>{children}</div>
            <div className={styles['Actions']}>
              {prevButton && (
                <Button
                  variant="outlined"
                  className={styles['Button']}
                  disabled={disablePrevButton}
                  onClick={() => {
                    props.handlePrev();
                  }}
                >
                  {prevButton}
                </Button>
              )}
              {nextButton && (
                <Button
                  variant="filled"
                  className={styles['Button']}
                  disabled={disableNextButton}
                  onClick={() => {
                    props.handleNext();
                  }}
                >
                  {nextButton}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Widget;
