import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import milliseconds from 'date-fns/milliseconds';

import { useApi } from '@api';
import { REDUCER_MAP, ACTION_MAP } from '@redux/dataMap';
import { StateItem } from '@redux/types';
import { LOADING_ACTIONS } from '@redux/actions';
import ApiRequestPayload from '@customTypes/ApiRequestPayload';

type ModelConnectorProps = {
  apiConnector: {
    api: string;
    errorLabel?: string;
  };
};

const LOADING_STATUS = {
  PENDING: 'pending',
  READY: 'ready',
  LOADING: 'loading',
  ERROR: 'error',
};

// TODO: move this to config?
const MAX_CACHE_AGE = milliseconds({ minutes: 10 });

type Options = {
  showUiOnError: boolean;
};

const defaultOptions = {
  showUiOnError: false,
};

function ModelConnector(WrappedComponent: React.ElementType, options: Options = defaultOptions) {
  return function (props: ModelConnectorProps) {
    const isFetched = useRef(false);
    const { apiConnector } = props;
    const api = useApi();
    const modelKey = apiConnector.api.split('/')[1];
    const foundReducer: string | undefined = REDUCER_MAP[modelKey];
    const foundAction: string | undefined = ACTION_MAP[modelKey];
    const locale: string | undefined = api.storeService.getLocale();

    let foundData: StateItem;
    if (foundReducer) {
      foundData = useSelector((reducers) => reducers[foundReducer][modelKey]);
    } else {
      console.warn('ModelConnector could not find reducer for key', modelKey, ' - lookup skipped');
    }

    const [data, setData] = useState(null);
    const [status, setStatus] = useState(LOADING_STATUS.PENDING);
    const [error, setError] = useState('');
    const dispatch = useDispatch();

    function getAge(timestamp: number) {
      const now = new Date().getTime();
      return now - timestamp; // in milliseconds
    }

    useEffect(() => {
      isFetched.current = false;
    }, [locale]);

    useEffect(() => {
      const loadData = async () => {
        if (isFetched.current && foundData && getAge(foundData.timestamp) < MAX_CACHE_AGE) {
          setData(foundData.data);
          setStatus(LOADING_STATUS.READY);
        } else {
          // data in reducer unavailable or outdated, fetch again
          dispatch({ type: LOADING_ACTIONS.SET_LOADING_STATUS, data: true });
          try {
            setStatus(LOADING_STATUS.LOADING);
            const { data }: { data: ApiRequestPayload } = await api.fetchService(modelKey, locale);
            isFetched.current = true;
            if (foundAction) {
              dispatch({ type: foundAction, data });
            } else {
              console.warn('ModelConnector could not find action at key', modelKey, ' - dispatch skipped');
            }
            setData(data);
            setStatus(LOADING_STATUS.READY);
          } catch (e) {
            setStatus(LOADING_STATUS.ERROR);
            setError(e.message);
          }
          dispatch({ type: LOADING_ACTIONS.SET_LOADING_STATUS, data: false });
        }
      };
      loadData();
    }, [api, foundData, foundAction, locale]);

    if (status === LOADING_STATUS.LOADING || status === LOADING_STATUS.PENDING) {
      return null;
    }

    if (status === LOADING_STATUS.ERROR && !options.showUiOnError) {
      console.error('API Connector error', apiConnector, error);
      return <div className="error">{apiConnector.errorLabel}</div>;
    }

    return <WrappedComponent data={data} {...props} />;
  };
}

export default ModelConnector;
