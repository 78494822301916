import React from 'react';
import { useSelector } from 'react-redux';

import ContentRenderer from '@components/ContentRenderer';
import ContentfulComponent, { ContentfulFile } from '@customTypes/ContentfulComponent';
import { isServiceSubscribed } from '@utils/checkIsServiceSubscribed';

import styles from '../services.module.css';

interface JourneyProps {
  header: string;
  title: string;
  description: string;
  image: ContentfulFile;
  tag: string[]
  button?: Array<ContentfulComponent>;
}

const Journey = ({
  header,
  title,
  description,
  image,
  button,
  tag
}: JourneyProps) => {
  const { vehicle } = useSelector(({ vehicleReducer }) => vehicleReducer);
  if(!isServiceSubscribed(tag, vehicle?.activeServices)){
    return null;
  }
  return (
    <div className={styles.Wrapper}>
      <div className={styles.ServiceContainer}>
        <h1 className={styles.ServiceHeader}>{header}</h1>
        <div className={styles.ServiceContent}>
          <img className={styles.ServiceImage} src={image.file.url} />
          <div>
            <div className={styles.ServiceTitle}>{title}</div>
            <div className={styles.ServiceDescription}>{description}</div>
          </div>
        </div>
        {button && (
          <div className={styles.ServiceButtonContainer}>
            <button className={styles.ServiceButton}>
              <ContentRenderer name="Link" content={button} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Journey;
