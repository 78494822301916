import React, { Fragment, useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import * as yup from "yup";
import { FormikInputField } from "@components/FormikFields";
import Button from "@components/Button";
import Widget from '@components/Widget/Widget';
import { SubscribedServices, useApi } from "@api";
import styles from "./VinSearch.module.css";
import { VIN_REGEX } from "@components/AccountEnrollment/constants";
import { useHistory } from "react-router-dom";
import { IVehicle } from "@redux/actions";
import usePreferencesSelector from '@redux/selectors/preferences';
import failure from "@assets/failure_icon.svg";
import ModalContainer from "../ModalContainer";
import { useSelector } from 'react-redux';

type IVinForm = {
  vin: string;
};

const initialValues = {
  vin: "",
};

const validationSchema = yup.object().shape({
  vin: yup
    .string()
    .trim()
    .required("Debe ingresar un VIN")
    .min(17, "Ingrese un VIN válido")
    .matches(VIN_REGEX, "Ingrese un VIN válido"),
});



const VinSearch = () => {
  const api = useApi();
  const history = useHistory();

  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const { vehicleVins, subscribedServices } = useSelector(({ userReducer }) => userReducer);
  const preferences = usePreferencesSelector();

  const onSubmitForm = (values: IVinForm, actions: any) => {
    if (values?.vin) {
      getVinEligiblePackages(values.vin);
    }
  }


  const getVinEligiblePackages = async (vin: string) => {
    const vehicleId = vehicleVins?.find((e: IVehicle) => e.vin === vin)?.vehicleId;
    const subscribedServicesForVehicleId = subscribedServices?.find((e: SubscribedServices) => e.vehicleId === vehicleId);

    if (subscribedServicesForVehicleId?.activeType) {
      setModalVisible(true);
    } else if (subscribedServicesForVehicleId?.subscribedServiceIds?.length) {
      api.storeService.setVehicleData({ vin, vehicleId } as IVehicle);
      history.push('/serviceOverview');
    } else {
      setModalVisible(true);
    }
  };

  return (
    <Fragment>
      <Widget
        title="REGISTRO DEL PROPIETARIO">
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => onSubmitForm(values, actions)}
          validationSchema={validationSchema}
        >
          {(props: FormikProps<IVinForm>) => (
            <>
              <Form onSubmit={props.handleSubmit} className={styles["Form"]}>
                <FormikInputField label="Ingrese el VIN del cliente." name="vin" maxLength={17} />
              </Form>
              <div className={styles["Actions"]}>
                <Button variant="outlined" className={styles["Button"]}
                  onClick={() => { history.push('/'); }}
                >
                  ATRÁS
                </Button>
                <Button variant="filled" className={styles["Button"]}
                  disabled={!props.dirty || !props.isValid}
                  onClick={props.submitForm}
                >
                  ENVIAR
                </Button>
              </div>
            </>
          )}
        </Formik>
      </Widget>

      <ModalContainer show={isModalVisible}
                      height='auto'
                      header={{ text: "", position: "center", showXButton: false }}
                      onCloseHandler={() => setModalVisible(false)}>
        <>
          <div className={styles["ModalHeader"]}>
            <img src={failure} />  <span className={styles["ModalHead"]}>No elegible</span>
          </div>
          <div className={styles["ModalContent"]}>
            <p>
              La cuenta no es elegible para el registro, llame al {preferences.customerCareNumber} para hablar con nuestro equipo de atención al cliente.
            </p>
          </div>
          <div className={styles["Actions"]}>
            <Button variant="filled" className={styles["Button"]} onClick={() => setModalVisible(false)}> OK </Button>
          </div>
        </>
      </ModalContainer>
    </Fragment>
  );
};

export default VinSearch;
