import React from 'react';

import Api from './Api';

export { MockService } from './services';
export { Api };
export * from './types';

type ApiProviderProps = {
  api: Api;
  children?: React.ReactNode;
};

const ApiContext = React.createContext<Api | null>(null);

export const useApi = () => {
  const api = React.useContext(ApiContext);

  if (api === null) {
    throw new Error('useApi hook must be used within ApiProvider');
  }

  return api;
};

export const ApiProvider = ({ api, children }: ApiProviderProps) => (
  <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
);
