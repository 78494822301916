// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1XiDwo1VvLGRRe3R0PL5GZ {\n  margin: 2rem auto;\n}\n\n._2p6v6Jk_-W3gN2TndEIFE7 {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  min-width: 550px;\n}\n\n@media (min-width: 768px) {\n  ._2p6v6Jk_-W3gN2TndEIFE7 {\n    width: auto;\n  }\n}\n\n._3CvreK5i7iN4C0AdpvliC4 {\n  color: var(--theme-color-error);\n}\n\n._203n2fvKByqaoDukraZPZk {\n  display: flex;\n  flex-direction: row;\n  justify-items: center;\n}\n\n._203n2fvKByqaoDukraZPZk svg {\n  color: var(--theme-color-error);\n}\n\n._203n2fvKByqaoDukraZPZk span {\n  margin-left: 0.5rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ErrorPage/ErrorPage.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".container {\n  margin: 2rem auto;\n}\n\n.card {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  min-width: 550px;\n}\n\n@media (--viewport-s) {\n  .card {\n    width: auto;\n  }\n}\n\n.errorContainer {\n  color: var(--theme-color-error);\n}\n\n.title {\n  display: flex;\n  flex-direction: row;\n  justify-items: center;\n}\n\n.title svg {\n  color: var(--theme-color-error);\n}\n\n.title span {\n  margin-left: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_1XiDwo1VvLGRRe3R0PL5GZ",
	"card": "_2p6v6Jk_-W3gN2TndEIFE7",
	"errorContainer": "_3CvreK5i7iN4C0AdpvliC4",
	"title": "_203n2fvKByqaoDukraZPZk"
};
export default ___CSS_LOADER_EXPORT___;
