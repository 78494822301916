import { useEffect, useRef } from 'react';

import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import config from '@config/config';
import { isEmpty } from 'lodash';
import { useApi } from '@api';
import useDialogActions from '@redux/hooks/dialog';
import useLogout from '@hooks/useLogout';
import useRequestBlocker from '@hooks/useRequestBlocker';
import { useDispatch } from 'react-redux';
import usePreferencesSelector from '@redux/selectors/preferences';
import { useDialog } from '@components/Dialog/useDialog';
import isTokenExpired from '@utils/isTokenExpired';
import { Page } from '@customTypes/Navigation';
import { HttpStatus } from '@cv/portal-common-lib/ajax/constants';

let result: boolean | null = null;
const isApiErrorsEnabled = (): boolean => {
  if (result !== null) {
    return result;
  }

  try {
    result = JSON.parse(config.getOemValue('API_ERRORS_ENABLED'));
  } catch (e) {
    result = true;
  }

  return result!;
};

type AxiosErrorWithRetryMarker = AxiosError & { config: { retry: boolean } };

const useInterceptor = (errorsInterpolation: Record<string, string>, pages: Page[] = []) => {
  const apiErrorsEnabled = isApiErrorsEnabled();
  const interceptorId = useRef({
    response: -1,
    request: -1,
  });

  const dispatch = useDispatch();
  const { showDialog } = useDialogActions();
  const globalPreferences = usePreferencesSelector();
  const api = useApi();
  const dialog = useDialog();
  const logout = useLogout();
  const requestBlocker = useRequestBlocker(pages);

  const isUnauthorized = (status: number) => {
    // Don't handle authentication errors on certain pages
    const authExcludePath = config.get<string[]>('authExcludePath') || [];
    const { pathname } = window.location;
    return (
      status === HttpStatus.UNAUTHORIZED &&
      !authExcludePath.includes(pathname) &&
      isTokenExpired(api.storeService.getAccessToken())
    );
  };

  const refreshOrLogout = (requestUrl: URL, error: AxiosErrorWithRetryMarker) => {
    if (requestUrl.hostname.includes('rts')) {
      dialog({
        title: errorsInterpolation['unauthorizedRtsErrorTitle'],
        labelOk: errorsInterpolation['unauthorizedRtsErrorAgree'],
        labelCancel: errorsInterpolation['unauthorizedRtsErrorCancel'],
        description: errorsInterpolation['unauthorizedRtsErrorDescription'],
        onOk: logout,
        children: undefined,
      });
      return;
    }
    if (error.config.retry) {
      logout();
      return;
    }
    return api
      .refreshToken()
      .then((response) => {
        const { access_token, id_token, token_type } = response.data;
        error.config.headers ??= {};
        error.config.headers.Authorization = `${token_type} ${access_token}`;
        if ('id_token' in error.config.headers) {
          error.config.headers.id_token = id_token;
        }
        return axios.request({ ...error.config, retry: true } as AxiosRequestConfig);
      })
      .catch(() => {
        dialog({
          title: errorsInterpolation.sessionHasExpired,
          description: errorsInterpolation.loginAgain,
          labelOk: errorsInterpolation.relogin,
          onOk: logout,
          children: undefined,
        });
      });
  };

  const throwErrorWhenExcludedFromDialogs = (error: AxiosError) => {
    const { code, detail } = error.response?.data || { message: '', code: '' };
    const excludeErrorDialog = config.get<Array<string>>('excludeErrorDialog');
    const suppressErrorDialogUrls = config.get<Array<string>>('suppressErrorDialogUrls');
    const excludeCodeOnRoute = config.get<Array<string>>('excludeCodeOnRoute');
    const failedPolicyRequirements = detail?.failedPolicyRequirements?.[0] || null;
    const failedPolicyCode = failedPolicyRequirements?.policyRequirements?.[0]?.policyRequirement || '';
    const failedPolicyProperty = failedPolicyRequirements?.property;
    const isErrorExcluded = excludeErrorDialog?.some((error: string) => {
      const getErrorProperty = error.split('__')?.[1] || '';
      const hasErrorProperty = getErrorProperty && failedPolicyProperty?.includes(getErrorProperty);

      const excludedCode = hasErrorProperty
        ? `${code}:${failedPolicyCode}__${getErrorProperty}`
        : [code, detail?.code].filter(Boolean).join(':');

      return excludedCode.includes(error);
    });
    if (isErrorExcluded) {
      throw error;
    }
    const suppressError = suppressErrorDialogUrls?.some((url: string) => error?.response?.config?.url?.match(url));
    if (suppressError) {
      throw error;
    }

    const isErrorAndRouteExcluded = excludeCodeOnRoute?.some((predicate) => {
      const [_code, _apiRoute] = predicate.split('::');
      return _code === code && error?.response?.config?.url?.includes(_apiRoute);
    });
    if (isErrorAndRouteExcluded) {
      throw error;
    }
  };

  const interpolateMessage = (error: AxiosError) => {
    const { message, code } = error.response?.data || { message: '', code: '' };
    let interpolatedMessage;
    const errorCodeKey = Object.keys(globalPreferences).find((key) => key.split(',')?.includes(code));
    const errorCodesToIgnore = globalPreferences['errorCodesToIgnore']?.split(',');
    if (errorCodeKey && globalPreferences[errorCodeKey]) {
      interpolatedMessage = globalPreferences[errorCodeKey];
    } else if (errorCodesToIgnore && errorCodesToIgnore.includes(code)) {
      interpolatedMessage = message;
    } else {
      interpolatedMessage = globalPreferences['defaultErrorMsg'];
    }
    if (interpolatedMessage?.includes('{{') && !isEmpty(errorsInterpolation)) {
      interpolatedMessage = interpolatedMessage.replace(
        /{{([^{}]*)}}/g,
        (match: string, foundKey: string) => errorsInterpolation[foundKey] || match,
      );
    }
    return interpolatedMessage;
  };

  useEffect(() => {
    axios.interceptors.request.eject(interceptorId.current.request);
    interceptorId.current.request = axios.interceptors.request.use((config) =>
      requestBlocker({
        ...config,
        headers: {
          ...config.headers,
          'Accept-Language': api.storeService.getLocale(),
        },
      }),
    );

    if (!apiErrorsEnabled) {
      return;
    }

    // Remove previous interceptor to "refresh" variables values
    // interceptor behave as singleton, if you have any variable outside - it will
    // take only the data on the moment it was initialized (so all values would be "baked"/stored)
    axios.interceptors.response.eject(interceptorId.current.response);
    interceptorId.current.response = axios.interceptors.response.use(
      (data) => data,
      (error) => {
        const { status, config: axiosConfig } = error.response;
        const idmApiProxyBaseUrl = config.get<string>('IDM_API_PROXY_BASE_URL') || '';
        const requestUrl = new URL(axiosConfig?.url || '');
        // Special handling is needed for the shim requests only
        // Proxied requests should land to a normal error handling
        const proxyPathPrefix = '/v1';
        const isApiProxyRequest =
          requestUrl.origin.startsWith(idmApiProxyBaseUrl) && requestUrl.pathname.startsWith(proxyPathPrefix);

        if (isUnauthorized(status)) {
          return refreshOrLogout(requestUrl, error);
        }

        if (isApiProxyRequest) {
          const { pooErrorMessage, pooErrorTitle } = globalPreferences;
          const apiProxyPath = requestUrl.pathname.slice(proxyPathPrefix.length);
          const enrollmentPaths = ['/user/enroll', '/proof-of-ownership'];
          if (enrollmentPaths.includes(apiProxyPath)) {
            showDialog({ message: pooErrorMessage, title: pooErrorTitle });
            throw error;
          }
          return;
        }

        throwErrorWhenExcludedFromDialogs(error);

        const interpolatedMessage = interpolateMessage(error);
        if (interpolatedMessage) {
          showDialog({
            message: interpolatedMessage,
            title: globalPreferences['errorHeaderLabel'],
            reLogin: status === HttpStatus.UNAUTHORIZED,
          });
        }
      },
    );
  }, [dispatch, config, globalPreferences]);
};

export default useInterceptor;
