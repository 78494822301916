import React, { useEffect } from 'react';
import get from 'lodash/get';

import { ModelConnector } from '@components/ApiConnectorHOCs/';
import ExpandingListView from './ExpandingListView';

type ExpandingListConnectorProps = {
  data: Object;
  value: { fields: Array<string> };
  numberOfRows: string;
  numberOfColumns: '2' | '3';
  expandLabel: string;
  hideLabel: string;
};

function ExpandingListConnector({ data, value, ...restProps }: ExpandingListConnectorProps) {
  // TODO: turn this into a proper utility function
  const path = value.fields[0].split('[].');
  const values =
    path.length > 1 ? get(data, path[0], []).map((el) => get(el, path[1], '')) : get(data, value.fields[0], []);

  return <ExpandingListView values={values} {...restProps} />;
}

ExpandingListConnector.defaultProps = {
  apiConnector: {},
  numberOfColumns: '2',
  numberOfRows: '3',
};

export default ModelConnector(ExpandingListConnector);
