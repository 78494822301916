import { APIRequest } from '@cv/portal-common-lib/ajax/models';
import { HttpMethodEnum } from '@cv/portal-common-lib/ajax/constants';
import axios from 'axios';
import config from '@config/config';

const tokenLoginUrl: string = config.getOemValue('TOKEN_LOGIN_URL') || '';
const marketingClientId: string = config.getOemValue('MARKETING_CLIENT_ID') || '';

export default class TokenLoginService {
  constructor() {}

  loginToken(tokenId: string, otp?: string) {
    const request: APIRequest = {
      url: tokenLoginUrl,
      method: HttpMethodEnum.POST,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'cv-client-id': marketingClientId,
      },
      data: {
        tokenId,
        ...(otp && { otp }),
      },
    };

    return axios(request);
  }
}
