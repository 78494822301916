import React, { useState } from 'react';
import { omit } from 'lodash';
import CardView from '@components/Card/CardView';
import Accordion from '@components/Accordion';
import Markdown from '@components/Markdown';
import { formatAnalyticsEventName } from '@cv/portal-common-lib';

import { OnlyLabels } from '@utils/labels';
import styles from './Faq.modules.css';

const Faq = ({ labels: questions }: OnlyLabels): JSX.Element => {
  const [openedAccordion, setOpen] = useState<string>('');

  const toggleAccordion = (title: string) => {
    setOpen((prevTitle) => {
      if (prevTitle === title) {
        return '';
      }

      return title;
    });
  };

  return (
    <CardView>
      <div className={styles.root}>
        <h2>{questions?.title?.primary}</h2>
        {Object.values(omit(questions, 'title')).map(
          ({ primary: title, secondary: text = '', analyticsEventName = '' }) => {
            const formatEventName = formatAnalyticsEventName(analyticsEventName);

            return (
              <Accordion
                key={title}
                title={title}
                text={<Markdown>{text}</Markdown>}
                open={title === openedAccordion}
                onChange={() => toggleAccordion(title)}
                className={styles.accordion}
                classes={{
                  title: styles['accordion-title'],
                }}
                analyticsEventName={formatEventName}
              />
            );
          },
        )}
      </div>
    </CardView>
  );
};

export default Faq;
