import React, { useState } from 'react';
import clsx from 'clsx';
import { ContentfulImage } from '@cv/webframework-react-components';
import { WarningModal } from '@components/VehicleCondition/VehicleConditionItem';
import ContentRenderer from '@components/ContentRenderer';
import { ContentfulFile, ContentfulComponent } from '@cv/portal-components-lib';
import { FaChevronRight } from 'react-icons/fa';
import Dropdown from '@components/Dropdown';
import dropDownStyle from '@components/Dropdown/Dropdown.module.css';
import styles from './WarningBanner.module.css';

export type WarningContent = {
  isAuthStatusError?: boolean;
  statusWarningLabel: string;
  label?: string;
  description?: string;
  modal: (
    isConfirmationModalVisible: boolean,
    toggleConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>,
    isAuthStatusError?: boolean,
  ) => React.ReactNode;
  [key: string]: any;
};
export type CustomWarning = {
  id: string;
  content: WarningContent;
  renderMethod: (content: WarningContent) => React.ReactNode;
};

export type WarningBannerContentProps = {
  name: string;
  warningBannerLabel: string;
  theme?: string;
  dropDownContainer?: HTMLElement;
  commonWarningIcon?: ContentfulFile;
  className?: string;
  modalWarningIcon?: ContentfulFile;
  scheduleServiceButtonLabel: string;
  scheduleServiceButtonUrl: string;
};
export type WarningBannerProps = WarningBannerContentProps & {
  standardWarnings: ContentfulComponent[];
  customWarnings: CustomWarning[];
  warningsAmount: number;
  singleWarningContent: WarningContent;
  isConfirmationModalVisible: boolean;
  toggleConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const WarningBanner = ({
  name,
  warningBannerLabel,
  theme,
  dropDownContainer,
  commonWarningIcon,
  className,
  modalWarningIcon,
  scheduleServiceButtonLabel,
  scheduleServiceButtonUrl,
  warningsAmount,
  standardWarnings,
  customWarnings,
  singleWarningContent,
}: WarningBannerProps) => {
  const [isConfirmationModalVisible, toggleConfirmationModal] = useState<boolean>(false);
  const renderFirstItem = (): JSX.Element => {
    return (
      <span className={styles['warning-menu']}>
        {!!commonWarningIcon && (
          <ContentfulImage id="warning-icon" className={styles['warning-menu-icon']} imageData={commonWarningIcon} />
        )}
        {warningsAmount > 1 ? warningBannerLabel : singleWarningContent?.statusWarningLabel}
      </span>
    );
  };

  if (warningsAmount > 1) {
    return (
      <Dropdown
        firstItemLabel={renderFirstItem()}
        theme={theme}
        classes={{ dropdown: styles.dropDown, container: className, wrapper: styles.wrapper }}
        isContentToggle={false}
        container={dropDownContainer}
        hideOnLocationChange
        anchorOrigin={{ horizontal: 'center' }}
      >
        <>
          {standardWarnings && (
            <ContentRenderer
              content={standardWarnings}
              {...{ name, scheduleServiceButtonUrl, scheduleServiceButtonLabel, modalWarningIcon, commonWarningIcon }}
            />
          )}
          {customWarnings && customWarnings.map((cw) => cw.renderMethod(cw.content))}
        </>
      </Dropdown>
    );
  }
  return (
    <div className={clsx(dropDownStyle['Dropdown'], { [dropDownStyle.dark]: theme === 'dark' })}>
      <button
        className={clsx(dropDownStyle['Dropdown-button'])}
        onClick={() => toggleConfirmationModal(!isConfirmationModalVisible)}
      >
        {renderFirstItem()}
        <FaChevronRight className={dropDownStyle['Dropdown-chevron']} />
      </button>
      {customWarnings?.[0] ? (
        customWarnings[0].content.modal?.(
          isConfirmationModalVisible,
          toggleConfirmationModal,
          customWarnings[0].content.isAuthStatusError,
        )
      ) : (
        <WarningModal
          icon={modalWarningIcon}
          label={singleWarningContent?.label}
          description={singleWarningContent?.description}
          {...{
            toggleConfirmationModal,
            isConfirmationModalVisible,
            scheduleServiceButtonUrl,
            scheduleServiceButtonLabel,
          }}
        />
      )}
    </div>
  );
};

export default WarningBanner;
