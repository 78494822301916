import React, { useCallback, useMemo } from 'react';
import { useVehicleSelector } from '@redux/selectors/vehicle';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '../Button';
import { RootState } from '@app/reducers';
import { PackageType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { generateLabelsObjects } from '@utils/labels';
import { FlagGroup, PackageFlag } from '@customTypes/package-flags';
import { formatAnalyticsEventName } from '@cv/portal-common-lib';

import styles from './SubscriptionManagementButtons.module.css';
import { SUBSCRIPTION_PAGE_URL } from '@components/App/UrlParser';

type Props = {
  labels: {
    componentType: string;
    name: string;
    content: Array<{
      componentType: string;
      name: string;
      labelKey: string;
      labelValue: string;
      analyticsEventName?: string;
    }>;
  };
  flagGroup?: FlagGroup;
};

export const SubscriptionManagementButtons = ({ labels, flagGroup }: Props): JSX.Element => {
  const history = useHistory();
  const { activeButtonText, inactiveButtonText, cancelButtonText } = generateLabelsObjects(labels?.content);

  const vehiclesPackages = useSelector(({ userReducer }: RootState) => userReducer.packages);
  const selectedVehicle = useVehicleSelector();

  const selectedVehiclePackages = vehiclesPackages.find((vehicle) => vehicle.vehicleId === selectedVehicle.vehicleId);

  const isSubscribed = useMemo(() => {
    const packages = selectedVehiclePackages?.packages;

    if (!packages?.length) {
      return false;
    }

    let allPackagesCancelled = true;
    let isDefaultsOnly = true;
    let containsActivePackage = false;

    packages.forEach(({ cancelReceiveDate, packageType, active }) => {
      if (!cancelReceiveDate) {
        allPackagesCancelled = false;

        // monthly cancelled packages are still marked as active packages
        if (active) {
          containsActivePackage = true;
        }
      }

      if (packageType !== PackageType.Default) {
        isDefaultsOnly = false;
      }
    });

    if (allPackagesCancelled) {
      return false;
    }

    if (isDefaultsOnly) {
      const flagDetailsOfDefaultPackage = flagGroup?.flags.find(
        ({ componentType, name, isActive, priority, ...flag }: PackageFlag) =>
          Object.keys(flag).length === 1 &&
          flag?.packageType === PackageType.Default,
      );

      return !flagDetailsOfDefaultPackage || flagDetailsOfDefaultPackage.isActive;
    }

    return containsActivePackage;
  }, [selectedVehiclePackages]);

  const navigate = useCallback((route: string) => () => {
    history.push(route);
  }, [history]);

  const mainButton = isSubscribed ? activeButtonText : inactiveButtonText;

  return (
    <div className={styles['container']}>
      <Button
        className={styles['main-button']}
        onClick={navigate(SUBSCRIPTION_PAGE_URL)}
        data-analyticseventname={formatAnalyticsEventName(mainButton?.analyticsEventName)}
      >
        {mainButton?.primary}
      </Button>
      {isSubscribed && (
        <button
          className={styles['cancel-button']}
          onClick={navigate('/cancel')}
          data-analyticseventname={formatAnalyticsEventName(cancelButtonText?.analyticsEventName)}
        >
          {cancelButtonText?.primary}
        </button>
      )}
    </div>
  );
};
