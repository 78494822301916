import { CurfewData, Curfew } from '@api/types';

import { CURFEW_ACTIONS } from '../actions';
import { StateItem } from '../types';

type CurfewDataState = {
  curfew?: StateItem<CurfewData>,
};

type CurfewDataAction = {
  type: CURFEW_ACTIONS,
  data: CurfewData | Curfew,
};

export default (state:CurfewDataState = {}, action: CurfewDataAction) => {
  switch (action.type) {
    case CURFEW_ACTIONS.GET_CURFEWS:
      return {
        ...state,
        curfew: action.data
      };
      case CURFEW_ACTIONS.CREATE_CURFEW:
      return {
        ...state,
        curfew: {
          ...state.curfew,
          curfews: [
            ...state?.curfew?.curfews,
            action.data.curfews[0]
          ]
        }
      };
    case CURFEW_ACTIONS.UPDATE_CURFEW:
      return {
        ...state,
        curfew: {
          ...state.curfew,
          curfews: state.curfew.curfews.map(curfew => {
            if (curfew.lastSvcReqId === action.data.lastSvcReqId) {
               return action.data;
            }
            return curfew;
        }),
        }
      };
    case CURFEW_ACTIONS.DELETE_CURFEW:
      return {
        ...state,
        curfew: {
          ...state.curfew,
          curfews: action.data.curfews
        }
      };
    default:
      return state;
  }
};
