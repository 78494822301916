import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sub } from 'date-fns';

import { useApi } from '@api';
import ContentRenderer from '@components/ContentRenderer';
import Select from '@components/InputSelect';
import AlertHistoryTable from './AlertHistoryTable';
import TabHeader from '../TabHeader';

import styles from './AlertHistoryContent.module.css';
import { formatDateToISO } from '../Forms/utils';
import { GetBreachResponse } from '@cv/portal-rts-lib/history';
import { clearMapBoundaries, clearMapPins } from '@utils/clearMapEntities';
import { Breach, Location } from '@cv/portal-rts-lib/history/models/history';
import { useAnalytics } from '@cv/webframework-react-components';
import { ServerLabel } from '@utils/labels';

type ContentRendererProps = {
  componentType: string;
};

type HistoryType = 'all' | 'speed' | 'boundary' | 'curfew' | 'valet';

export type AlertHistoryContentProps = {
  title: string;
  emptyDescription: string;
  dropdownOptions?: ServerLabel[];
  defaultOption?: ServerLabel;
  historyType: HistoryType;
  alertHistoryItemLabel: string;
  tableFirstColLabel: string;
  tableSecondColLabel: string;
  tableThirdColLabel: string;
  pagination: ContentRendererProps;
};

type MapApiCall = {
  [index: string]: {
    breachType: string;
    geofenceAlertType?: AlertType;
    label: string;
  };
};

type OnChangeProps = {
  type: string;
  value: string;
  analyticsEventName: string;
};

type AlertHistory = Breach & {
  street: string;
  addressLine: string;
};

export default function AlertHistoryContent({
  title,
  emptyDescription,
  dropdownOptions = [],
  defaultOption,
  historyType,
  alertHistoryItemLabel,
  tableFirstColLabel,
  tableSecondColLabel,
  tableThirdColLabel,
  pagination,
}: AlertHistoryContentProps) {
  const api = useApi();
  const { trackEvent } = useAnalytics();
  const map = useSelector(({ mapReducer }) => mapReducer.map);
  const dispatch = useDispatch();
  const initialPaginationPage = 1;
  const [currentPage, setCurrentPage] = useState(initialPaginationPage);
  const [breaches, setBreaches] = useState<AlertHistory[]>([]);
  const [apiLoadingData, setApiLoadingData] = useState<boolean>(false);

  const selectOptions = useMemo(
    () =>
      dropdownOptions.map(({ labelValue, labelKey, additionalLabelValue, ...other }) => ({
        ...other,
        label: labelValue,
        type: labelKey,
        value: Number(additionalLabelValue),
      })),
    [dropdownOptions],
  );

  const weekHistoryOption = useMemo(
    () => selectOptions.find(({ name }) => name === defaultOption?.name) || selectOptions[0],
    [selectOptions, defaultOption],
  );

  useEffect(() => {
    clearMapBoundaries(map);
    clearMapPins(map);
    dispatch({ type: 'SET_BOUNDARY_COORDINATES', boundaryCoordinates: null });
  }, [map]);

  const mapApiCall: MapApiCall = {
    all: {
      breachType: 'SPEED_ALERT,GEOFENCE,CURFEW_ALERT',
      label: alertHistoryItemLabel,
    },
    speed: {
      breachType: 'SPEED_ALERT',
      label: alertHistoryItemLabel,
    },
    boundary: {
      breachType: 'GEOFENCE',
      label: alertHistoryItemLabel,
    },
    curfew: {
      breachType: 'CURFEW_ALERT',
      label: alertHistoryItemLabel,
    },
    valet: {
      breachType: 'GEOFENCE',
      geofenceAlertType: 'VALET',
      label: alertHistoryItemLabel,
    },
  };

  const getAlertHistory = async (historyType: HistoryType, currentDate: Date, substractedDate: Date) => {
    try {
      setApiLoadingData(true);
      const formattedCurrentDate = formatDateToISO(currentDate);
      const formattedSubstractedDate = formatDateToISO(substractedDate);
      const { breachType, geofenceAlertType } = mapApiCall[historyType];
      const { data } = await api.getBreaches(
        breachType,
        geofenceAlertType,
        formattedSubstractedDate,
        formattedCurrentDate,
      );

      const breachesWithAddress = await getBreachesWithAddress(data, breachType, geofenceAlertType);
      const sortedBreaches =
        breachesWithAddress?.sort((a, b) => new Intl.Collator().compare(b.breachDateTime, a.breachDateTime)) || [];
      setBreaches(sortedBreaches);
      setApiLoadingData(false);
    } catch (err) {
      // TODO - IMPLEMENT ERROR HANDLING
      setApiLoadingData(false);
      console.log('error api', err);
    }
  };

  const getBreachesWithAddress = (data: GetBreachResponse, breachType: string, geofenceAlertType) => {
    const historys: Promise<AlertHistory>[] = data?.items
      ?.filter((el) => {
        if (breachType === 'GEOFENCE') {
          return geofenceAlertType === 'VALET' ? el.alertId === '14' : el.alertId !== '14';
        } else {
          return true;
        }
      })
      ?.map(async (el) => {
        const addressRes = await getPostalAddress(el.location);
        const { city, state, street, postalCode, country } = addressRes?.[0]?.address;
        const addressLine = [city, state, country, postalCode].filter(Boolean).join(', ');
        return {
          ...el,
          street,
          addressLine,
        } as AlertHistory;
      });
    return Promise.all(historys).then((results) =>
      results?.map(
        (history) =>
          ({
            ...history,
          } as AlertHistory),
      ),
    );
  };

  const getPostalAddress = async (coordinates: Location) => {
    try {
      const { data } = await api.getPostalAddress(coordinates);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const { type, value } = weekHistoryOption;
    const substractedDate = sub(currentDate, { [type]: value });
    getAlertHistory(historyType, currentDate, substractedDate);
  }, []);

  const onPageChange = (currentPage: number) => {
    setCurrentPage(currentPage);
  };

  const onDateChange = ({ type, value, analyticsEventName }: OnChangeProps) => {
    //We need to reset Pagination on changing the date
    trackEvent(analyticsEventName);
    setCurrentPage(initialPaginationPage);
    const currentDate = new Date();
    const substractedDate = sub(currentDate, { [type]: value });
    getAlertHistory(historyType, currentDate, substractedDate);
  };

  const renderSelectAlert = (
    <Select
      name="alert-history"
      isSearchable={false}
      styleType="primary"
      onChange={onDateChange}
      options={selectOptions}
      defaultValue={weekHistoryOption}
    />
  );

  const vehiclesPerPage = 5;
  const totalPages = breaches?.length > 0 ? Math.ceil(breaches.length / 5) : 1;
  const offset = (currentPage - 1) * vehiclesPerPage;
  const offsetData = breaches?.length > 0 ? breaches.slice(offset, offset + vehiclesPerPage) : [];

  return (
    <div className={styles['alert-history']}>
      <header className={styles['alert-history-header']}>
        <TabHeader label={title} Component={renderSelectAlert} />
      </header>
      {!apiLoadingData && (
        <div className={styles['alert-history-content']} title="Alert History Content">
          {!breaches?.length && <div className={styles['alert-history-empty']}>{emptyDescription}</div>}

          {!!breaches?.length && (
            <AlertHistoryTable
              tableFirstColLabel={tableFirstColLabel}
              tableSecondColLabel={tableSecondColLabel}
              tableThirdColLabel={tableThirdColLabel}
              data={offsetData}
            />
          )}

          {totalPages > 1 && (
            <ContentRenderer
              name="pagination"
              content={[pagination]}
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          )}
        </div>
      )}
    </div>
  );
}
