import React from 'react';

import './InfoBox.css';

type InfoItemProps = {
  label: string;
  content: string;
};

function InfoItem({ label, content }: InfoItemProps) {
  return (
    <div className="InfoItem-container">
      <div>{label}</div>
      <div className="InfoItem-value">{content}</div>
    </div>
  );
}

export default InfoItem;
