// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._27P_eru7NxBX5b7wgbROkC {\n  color: var(--theme-color-white);\n  width: 100%;\n  padding: 6px;\n  background-color: var(--theme-color-grey-5a);\n  cursor: pointer;\n}\n\n._27P_eru7NxBX5b7wgbROkC:hover {\n  background-color: var(--theme-color-grey-93);\n}\n", "",{"version":3,"sources":["webpack://./src/components/LanguagePicker/LanguagePicker.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,WAAW;EACX,YAAY;EACZ,4CAA4C;EAC5C,eAAe;AACjB;;AAEA;EACE,4CAA4C;AAC9C","sourcesContent":[".LanguagePickerItem {\n  color: var(--theme-color-white);\n  width: 100%;\n  padding: 6px;\n  background-color: var(--theme-color-grey-5a);\n  cursor: pointer;\n}\n\n.LanguagePickerItem:hover {\n  background-color: var(--theme-color-grey-93);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LanguagePickerItem": "_27P_eru7NxBX5b7wgbROkC"
};
export default ___CSS_LOADER_EXPORT___;
