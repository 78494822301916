import React, { useMemo } from 'react';
import CreatorForm, { CreatorFormProps } from '@components/EntryCreator/CreatorForm';

type Props = CreatorFormProps & {
  securityQuestionLabel: string;
  question: string;
};

const PinResetForm = ({ formFields = [], securityQuestionLabel, question, ...creatorFormProps }: Props) => {
  const pinResetFields = useMemo(
    () =>
      formFields.map((field) =>
        field.fieldLabel === securityQuestionLabel ? { ...field, fieldPlaceholder: question } : field,
      ),
    [formFields, securityQuestionLabel, question],
  );

  return <CreatorForm {...creatorFormProps} formFields={pinResetFields} />;
};

export default PinResetForm;
