import { useApi } from '@api';
import Input from '@components/Input';
import InputSelect from '@components/InputSelect';
import { useField, useFormikContext } from 'formik';
import React, { useCallback, useMemo } from 'react';
import AsyncSelect from 'react-select/async';
import styles from './FormikFields.module.css';
import FormikInputField from './FormikInputField';
import { debounce, set } from 'lodash';
import { FormField } from '@components/EntryCreator/CreatorForm';
import { Props as ReactSelectProps } from 'react-select';
import clsx from 'clsx';
import { isMarkedRemoved } from '@utils/form-removal-utils';

type AsyncOptions = {
  loadOptions: Promise<Array<{ label: string; value: string }>>;
  name: string;
};
type SelectProps = Omit<ReactSelectProps, 'onChange'> & AsyncOptions;
const SelectField: React.FunctionComponent<SelectProps> = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);

  const { setValue } = helpers;

  const { onSelectOption, name } = props;
  const currentValue = useMemo(() => ({ label: field.value, value: field.value }), [field]);
  const _onChange = useCallback(
    (event) => {
      const { label, value } = event;
      field.onChange({ target: { value: label, name: field.name } });
      return onSelectOption(value);
    },
    [field],
  );
  const _components = useMemo(() => ({ DropdownIndicator: () => null, IndicatorSeparator: () => null }), []);

  return (
    <AsyncSelect
      {...field}
      {...props}
      value={currentValue}
      onChange={_onChange}
      components={_components}
      onBlur={(event) => {
        const val = event.target?.value;
        if (val) setValue(val);
      }}
    />
  );
};

const transformToOptions = (values: string[]) =>
  values.map((option: string) => {
    const [value, label] = option.split('::');
    if (label) {
      return { value, label };
    }
    return { value: option, label: option };
  });

interface Props {
  name: string;
  formFields: Array<FormField>;
  hideWithField?: FormField;
  addressType: string;
}
const FormikAddressField = (props: Props) => {
  const api = useApi();
  const { values, setValues } = useFormikContext();
  const { billingCountry } = values;
  const { formFields = [], hideWithField, addressType } = props;
  let isAutocompleteRendered = false;


  const getAsyncOptions = (inputText: string) => {
    return api
      .listAddresses({
        isoCountryCode: billingCountry,
        query: inputText,
      })
      .then(({ data = [] }) => {
        return data.map(({ id, address }) => ({ label: address, value: id }));
      });
  };
  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      getAsyncOptions(inputText).then((options) => callback(options));
    }, 500),
    [],
  );
  const onSelectOption = (id: string) => {
    api
      .getFormattedAddress({
        isoCountryCode: billingCountry,
        id,
      })
      .then(({ data }) => {
        const { addressLine1, addressLine2, locality, postalCode, province } = data;
        const formattedAddress = {};
        set(formattedAddress, `${addressType}PostalAddress1`, addressLine1);
        set(formattedAddress, `${addressType}PostalAddress2`, addressLine2);
        set(formattedAddress, `${addressType}City`, locality);
        set(formattedAddress, `${addressType}PostalCode`, postalCode);
        set(formattedAddress, `${addressType}StateProvince`, province);
        setValues({
          ...values,
          ...formattedAddress,
        });
      });
  };
  const hidingFieldName = hideWithField?.fieldName;
  if (hidingFieldName && values[hidingFieldName]) {
    return null;
  }

  return (
    <>
      {formFields.map(
        ({ fieldName, fieldLabel, fieldPlaceholder, fieldValidation, fieldType, fieldValues = [] }, i) => {
          const key = `${i}-${fieldName}`;
          const options = transformToOptions(fieldValues);

          if (!isAutocompleteRendered && fieldType !== 'static') {
            isAutocompleteRendered = true;
            return (
              <FormikInputField
                label={fieldLabel}
                name={fieldName}
                key={fieldName}
                type="autocomplete"
                placeholder={fieldPlaceholder}
                styleType="primary"
                className={styles.autocomplete}
                loadOptions={loadOptions}
                onSelectOption={onSelectOption}
                InputComponent={SelectField}
              />
            );
          }

          // Static text
          if (fieldType === 'static') {
            /* Trim needed for Empty Static Field, see: PORTAL Form Field  */
            const isLabelEmpty = true;
            const initialValue = '';
            const fieldValue = isMarkedRemoved(initialValue) ? '' : initialValue;
            return (
              <div className={clsx(styles['field-static'], { [styles['field-static-empty']]: isLabelEmpty })} key={key}>
                <div className={styles['field-static--label']}>{fieldLabel}</div>
                <div className={styles['field-static--value']}>{fieldValue || fieldPlaceholder}</div>
              </div>
            );
          }

          if (fieldType === 'dropdown') {
            return (
              <FormikInputField
                name={fieldName}
                label={fieldLabel}
                type={fieldType}
                placeholder={fieldPlaceholder}
                key={key}
                InputComponent={InputSelect}
                onChange={() => undefined}
                options={options}
                styleType="primary"
                minLength={fieldValidation?.fieldMin}
                maxLength={fieldValidation?.fieldMax}
                readOnly={fieldValidation?.fieldReadOnly}
                readOnlyError={fieldValidation?.fieldReadOnlyError}
              />
            );
          }
          return (
            <FormikInputField
              label={fieldLabel}
              name={fieldName}
              key={key}
              type={fieldType}
              placeholder={fieldPlaceholder}
              InputComponent={Input}
              styleType="primary"
              options={options}
              minLength={fieldValidation?.fieldMin}
              maxLength={fieldValidation?.fieldMax}
              readOnly={fieldValidation?.fieldReadOnly}
              readOnlyError={fieldValidation?.fieldReadOnlyError}
            />
          );
        },
      )}
    </>
  );
};

export default FormikAddressField;
