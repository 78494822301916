import React, { ReactNode, useCallback, useMemo } from 'react';
import { ContentfulFile } from '@app/types/ContentfulComponent';
import { memoize } from 'lodash';

type GlobalThemeFontFile = {
  format: string;
  file: ContentfulFile;
};

type GlobalThemeFont = {
  family: string;
  src: GlobalThemeFontFile[];
  key?: string;
  style?: string;
  weight?: string;
};

type GlobalThemeProperty = {
  key: string;
  value?: string;
  asset?: ContentfulFile;
};

export type GlobalTheme = {
  slug?: string;
  fonts?: GlobalThemeFont[];
  properties?: GlobalThemeProperty[];
};

type ThemeProviderProps = {
  themes?: GlobalTheme[];
  children?: ReactNode;
};

const loadFont = (font: GlobalThemeFont, sourceIndex = 0): Promise<FontFace> => {
  const source = font.src[sourceIndex];
  if (!source) {
    return Promise.reject(new Error('Font source file is missing'));
  }
  const customFont = new FontFace(font.family.trim(), `url(${source.file.file.url})`, {
    style: font.style || 'normal',
    weight: font.weight || 'normal',
  });
  return customFont
    .load()
    .then((fontFace) => {
      document.fonts.add(fontFace);
      return fontFace;
    })
    .catch(() => loadFont(font, sourceIndex++));
};

interface Theme {
  isLoaded: boolean;
  get: (key: string, defaultValue?: string) => string;
  getBool: (key: string) => boolean;
  getAssetUrl: (key: string) => string;
}

const ThemeContext = React.createContext<Theme | null>(null);

// Not currently supported
export const useTheme = (): Theme => {
  const theme = React.useContext(ThemeContext);

  if (theme === null) {
    throw new Error('useTheme must be used within ThemeProvider');
  }

  return theme;
};

export const ThemeProvider = ({ themes = [], children = null }: ThemeProviderProps): JSX.Element => {
  useMemo(() => {
    themes.forEach((theme) => {
      const { slug, fonts = [], properties = [] } = theme;

      // Add theme specific class name
      if (slug) {
        document.documentElement.classList.add(slug.trim());
      }

      // Load fonts
      fonts.forEach((font) => {
        loadFont(font)
          .then(() => {
            if (font.key) {
              document.documentElement.style.setProperty(font.key.trim(), `"${font.family.trim()}"`);
            }
          })
          .catch(() => {
            /* Do nothing */
          });
      });

      // Add custom properties
      properties.forEach((property) => {
        const { key, value, asset } = property;
        const assetUrl = asset ? `url(${asset.file.url})` : '';
        const propKey = key.trim();
        const propValue = value ? value.trim() : assetUrl;

        document.documentElement.style.setProperty(propKey, propValue);
      });
    });
  }, [themes]);

  // This is going to be a built theme to be consumed by React Components
  const theme: Theme = useMemo(
    () => ({
      isLoaded: Boolean(themes.length),
      get(key: string, defaultValue = '') {
        let propKey = key;
        if (!propKey.startsWith('theme')) {
          propKey = `theme-${propKey}`;
        }

        return getComputedStyle(document.documentElement).getPropertyValue(`--${propKey}`)?.trim() || defaultValue;
      },

      getBool(key: string) {
        const value = this.get(key);
        return value && value !== 'false' && value !== '0' && value !== 'no';
      },

      getAssetUrl(key: string) {
        const value = this.get(key);
        const match = /^url\((.+)\)$/i.exec(value);
        return match ? match[1] : '';
      },
    }),
    [themes],
  );

  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
};
