import React from 'react';
import { ContentfulRichText } from '@cv/webframework-react-components';

import './RichTextContainer.css';

export type RichTextContainerProps = {
  content: object;
  textAlignment?: 'left' | 'right' | 'center' | 'justify';
};

const RichTextContainer = ({ content, textAlignment = 'left' }: RichTextContainerProps) => {
  return (
    <div className={`RichTextContainer text-${textAlignment}`}>
      <ContentfulRichText document={content} />
    </div>
  );
};

export default RichTextContainer;
