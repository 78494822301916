import { AccountData, ContactData, SubscriptionData, VehicleDetails, PaymentMethodData } from '@api/types/';
import { ACCOUNT_ACTIONS, VEHICLE_ACTIONS } from '../actions/';
import { ROOT_EVENTS } from '../../reducers';
import { SETTINGS_ACTIONS } from '../actions/';
import { USER_EVENTS } from '@redux/actions/user';
import { StateItem } from '../types';

type AccountDataState = {
  account?: StateItem<AccountData>;
  contacts?: StateItem<ContactData[]>;
  drivers?: StateItem<ContactData[]>;
  subscription?: StateItem<SubscriptionData>;
  vehicle?: StateItem<VehicleDetails>;
  paymentMethod?: StateItem<PaymentMethodData>;
  tenantId?: string;
};

type TenantId = string;

type AccountDataAction = {
  type: ACCOUNT_ACTIONS | VEHICLE_ACTIONS | ROOT_EVENTS | SETTINGS_ACTIONS | USER_EVENTS;
  data?: AccountData | ContactData[] | SubscriptionData | VehicleDetails | PaymentMethodData | TenantId;
  payload?: boolean;
};

const accountReducer = (state: AccountDataState = {}, action: AccountDataAction) => {
  switch (action.type) {
    case ACCOUNT_ACTIONS.SET_ACCOUNT_DATA:
      return {
        ...state,
        account: {
          data: action.data,
          timestamp: new Date().getTime(),
        },
        tenantId: action.data?.tenantId,
      };
    case ACCOUNT_ACTIONS.SET_CONTACTS:
      return {
        ...state,
        contacts: {
          data: action.data,
          timestamp: new Date().getTime(),
        },
      };
    case ACCOUNT_ACTIONS.SET_DRIVERS:
      return {
        ...state,
        drivers: {
          data: action.data,
          timestamp: new Date().getTime(),
        },
      };
    case ACCOUNT_ACTIONS.SET_SUBSCRIPTIONS:
      return {
        ...state,
        subscription: {
          data: action.data,
          timestamp: new Date().getTime(),
        },
      };
    case ACCOUNT_ACTIONS.SET_VEHICLE_DETAILS:
    case VEHICLE_ACTIONS.SET_VEHICLE_DATA:
      const vehicleData = action.data || state.vehicle.data;
      return {
        ...state,
        vehicle: {
          data: vehicleData,
          timestamp: new Date().getTime(),
        },
        contacts: null,
        drivers: null,
        capableServices: null,
      };
    case ACCOUNT_ACTIONS.SET_CAPABLE_SERVICES:
      return {
        ...state,
        capableServices: {
          data: action.data,
          timestamp: new Date().getTime(),
        },
      };
    case ACCOUNT_ACTIONS.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: {
          data: action.data,
          timestamp: new Date().getTime(),
        },
      };
    case ACCOUNT_ACTIONS.SET_TENANT_ID:
      return {
        ...state,
        account: {
          ...state.account,
          data: {
            ...state.account?.data,
            tenantId: action.data,
          },
        },
        tenantId: action.data,
      };
    case ROOT_EVENTS.CLEAR_SUBSCRIPTIONS: {
      return {
        ...state,
        subscription: null,
        vehicle: { ...state.vehicle, timestamp: 0 },
      };
    }
    // force re-fetch of data after a change of locale
    case SETTINGS_ACTIONS.SET_LOCALE:
      return {
        ...state,
        drivers: { ...state.drivers, timestamp: 0 },
        contacts: { ...state.contacts, timestamp: 0 },
        subscription: { ...state.subscription, timestamp: 0 },
        vehicle: { ...state.vehicle, timestamp: 0 },
        paymentMethod: { ...state.paymentMethod, timestamp: 0 },
      };
    case ACCOUNT_ACTIONS.CLEAR_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethod: { ...state.paymentMethod, timestamp: 0 },
      };
    case USER_EVENTS.SET_USER_PIN_STATUS:
      return {
        ...state,
        account: {
          ...state.account,
          data: {
            ...state.account?.data,
            isPinConfigured: action.payload,
          },
        },
      };
    case ROOT_EVENTS.CLEAR_CACHE:
    case ROOT_EVENTS.DESTROY_SESSION:
      return {
        ...state,
        account: null,
        drivers: null,
        contacts: null,
        subscription: null,
        vehicle: null,
        paymentMethod: null,
      };
    default:
      return state;
  }
};

export default accountReducer;
