import { APIRequest } from '@cv/portal-common-lib/ajax/models';
import { HttpMethodEnum } from '@cv/portal-common-lib/ajax/constants';
import config from '@config/config';
import axios from 'axios';
import { nonNil } from '@utils/objects';

const sendOTPUrl: string = config.getOemValue('OTP_CODE_SEND_URL');
const validateOTPUrl: string = config.getOemValue('OTP_CODE_VALIDATE_URL');
const marketingClientId: string = config.getOemValue('MARKETING_CLIENT_ID');
const templateId = config.getOemValue('PROMOTION_LINK');
const OTPGetContactsUrl: string = config.getOemValue('OTP_GET_CONTACTS_URL') || '';
const sxmBrand: string = config.getOemValue('SXM_BRAND_NAME') || 'SXM Guardian';

type SendOTPCodeProps = {
  tenantId: string;
  discountId?: string;
  userId?: string;
  vehicleId?: string;
  channel: string;
  externalTenantUserId?: string;
  isSecondaryPhone?: boolean;
  expiresIn?: number;
};

export default class OTPCodeService {
  sendOTPCode({
    tenantId,
    discountId,
    userId,
    vehicleId,
    channel,
    externalTenantUserId,
    isSecondaryPhone,
    expiresIn,
  }: SendOTPCodeProps) {
    const request: APIRequest = {
      url: sendOTPUrl,
      method: HttpMethodEnum.POST,
      headers: {
        'Content-Type': 'application/json',
        'cv-client-id': marketingClientId,
      },
      data: nonNil({
        data: nonNil({
          // IDM team has requested us to send userId and vehicleId in the 'data' block as well - PORTAL-2623
          // also hardcoded sxmBrand values for now as per Harshit's request
          userId,
          vehicleId,
          tenantId,
          discountId,
          sxmBrand,
          templateId,
        }),
        externalTenantUserId,
        userId,
        vehicleId,
        channel,
        isSecondaryPhone,
        expiresIn,
      }),
    };

    return axios(request);
  }

  validateOTPCode(tokenId: string, otp: string) {
    const request: APIRequest = {
      url: validateOTPUrl,
      method: HttpMethodEnum.POST,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'cv-client-id': marketingClientId,
      },
      data: {
        tokenId,
        otp,
      },
    };

    return axios(request).then(({ data }) => data);
  }

  getOTPContacts(userId: string) {
    const request: APIRequest = {
      url: `${OTPGetContactsUrl}/${userId}`,
      method: HttpMethodEnum.GET,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'cv-client-id': marketingClientId,
      },
    };
    return axios(request);
  }
}
