// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1i-oSMAOvAao3GNclUDbaj {\n  display: flex;\n  flex-direction: column;\n}\n\n._1LmxipUCwC51y-9DGsalsw {\n  font-size: 0.875rem;\n  line-height: 1rem;\n}\n\n._3Y-Gmy_CDwbVbxGiSjMaKE {\n  width: 100%;\n  margin-top: 0.25rem;\n  font-size: 0.75rem;\n  line-height: 0.875rem;\n}\n\n._3Y-Gmy_CDwbVbxGiSjMaKE.Xqox77J6OJcaRmZNbnbyp {\n  color: #dc3545;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Field/Field.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".field-wrapper {\n  display: flex;\n  flex-direction: column;\n}\n\n.help-callout {\n  font-size: 0.875rem;\n  line-height: 1rem;\n}\n\n.help-text {\n  width: 100%;\n  margin-top: 0.25rem;\n  font-size: 0.75rem;\n  line-height: 0.875rem;\n}\n\n.help-text.error {\n  color: #dc3545;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field-wrapper": "_1i-oSMAOvAao3GNclUDbaj",
	"help-callout": "_1LmxipUCwC51y-9DGsalsw",
	"help-text": "_3Y-Gmy_CDwbVbxGiSjMaKE",
	"error": "Xqox77J6OJcaRmZNbnbyp"
};
export default ___CSS_LOADER_EXPORT___;
