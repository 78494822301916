import React from 'react';
import clsx from 'clsx';

import styles from './MobileInformationService.module.css';

type Props = {
  className?: string;
  leftClassName?: string;
  left: JSX.Element;
  rightClassName?: string;
  right: JSX.Element;
};

const ContentLayout = ({ className = '', leftClassName = '', left, rightClassName = '', right }: Props) => (
  <div className={clsx(styles['mis-columns-container'], className)} title="Decide how things should be changed">
    <div className={clsx('column width-60', styles['left-column-container'], leftClassName)}>{left}</div>
    <div className={clsx('column width-40', styles['right-column-container'], rightClassName)}>{right}</div>
  </div>
);

export default ContentLayout;
