import React from 'react';
import { FaEdit } from "react-icons/fa";

import InputSwitch from '@components/InputSwitch';

import styles from './MonitoringAlerts.module.css';

type CardItemProps = {
  label: string | React.ReactNode;
  handlers?: 'edit' | 'switch' | 'none';
  isChecked?: boolean;
  onItemEdit?: (item: any) => void;
  onItemRemove?: (item: any) => void;
  onSwitchToggle?: (e: Event) => void;
}

export default function CardItem({ label, handlers = 'switch', isChecked, onItemEdit, onItemRemove, onSwitchToggle }: CardItemProps) {
  return (
    <div className={styles['card-item']}>
      <div className={styles['card-item-label']}>
        {label}
      </div>
      <div className={styles['card-item-handlers']}>
        {handlers === 'edit' && (
          <>
            <button onClick={onItemEdit}><FaEdit /></button>
            <button onClick={onItemRemove}>X</button>
          </>
        )}
        {handlers === 'switch' && (
          <InputSwitch checked={isChecked} onChange={onSwitchToggle} />
        )}
      </div>
    </div>
  )
}
