// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2MqXT71JfyQz9jKi-M1JKp {\n  text-align: center;\n}\n\n._1O6K2o7gRhx8oim2zkvcMK {\n  display: flex;\n  margin-bottom: 1rem;\n}\n\n._1O6K2o7gRhx8oim2zkvcMK > label {\n  display: inline-flex;\n  flex-direction: column;\n  margin-left: 0.5rem;\n}\n\n._29y7YTY9PpD8fODcIYe7nZ {\n  align-items: center;\n  flex: 1;\n  margin-bottom: 2rem;\n}\n\n._3_AZ9DoVfcohqIn24mCwSc {\n  color: var(--theme-color-grey-7a);\n  margin-bottom: 1rem;\n}\n\n._30DClURf-BCs6gbb6uvmCj {\n  font-size: 0.75rem;\n}\n\n._2T5NQZhBkNmToRBvaq_5Yo {\n  margin-top: 0.75rem;\n}\n\n._26Lbb8XBAmoIZf9TxbWj2F {\n  margin: 0 auto;\n  min-width: 150px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/LinkVehicle/EmailSearch/EmailSearch.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,OAAO;EACP,mBAAmB;AACrB;;AAEA;EACE,iCAAiC;EACjC,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".form {\n  text-align: center;\n}\n\n.input-radio {\n  display: flex;\n  margin-bottom: 1rem;\n}\n\n.input-radio > label {\n  display: inline-flex;\n  flex-direction: column;\n  margin-left: 0.5rem;\n}\n\n.input-wrapper {\n  align-items: center;\n  flex: 1;\n  margin-bottom: 2rem;\n}\n\n.input-label {\n  color: var(--theme-color-grey-7a);\n  margin-bottom: 1rem;\n}\n\n.input-field {\n  font-size: 0.75rem;\n}\n\n.input-error {\n  margin-top: 0.75rem;\n}\n\n.submit {\n  margin: 0 auto;\n  min-width: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "_2MqXT71JfyQz9jKi-M1JKp",
	"input-radio": "_1O6K2o7gRhx8oim2zkvcMK",
	"input-wrapper": "_29y7YTY9PpD8fODcIYe7nZ",
	"input-label": "_3_AZ9DoVfcohqIn24mCwSc",
	"input-field": "_30DClURf-BCs6gbb6uvmCj",
	"input-error": "_2T5NQZhBkNmToRBvaq_5Yo",
	"submit": "_26Lbb8XBAmoIZf9TxbWj2F"
};
export default ___CSS_LOADER_EXPORT___;
