import React from 'react';

import styles from './ServiceExpiry.module.css';

export type Props = {
  labelTrialExpiry: string;
  expiry: string;
};

const ServiceExpiry = ({ labelTrialExpiry, expiry }: Props): JSX.Element => (
  <div className={styles['ServiceExpiry-service']}>
    <div className={styles['ServiceExpiry-expiry']}>
      {labelTrialExpiry}
      {expiry}
    </div>
  </div>
);

export default ServiceExpiry;
