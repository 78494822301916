import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { FaTimesCircle } from 'react-icons/fa';

import { Environment } from '@cv/portal-common-lib/ajax/constants';
import usePreferencesSelector from '@redux/selectors/preferences';
import config from '@config/config';

import Button from '@components/Button';
import Accordion from '@components/Accordion';
import { CardView } from '@components/Card';

import styles from './ErrorPage.module.css';

const ErrorPage = ({ error, resetErrorBoundary }: FallbackProps) => {
  const preferences = usePreferencesSelector();
  const environmentEnum = config.getEnvironmentEnum();
  const showDetails = [Environment.LOCAL, Environment.DEVINT, Environment.SIT].includes(environmentEnum);

  return (
    <div className={styles.container}>
      <CardView type="main" classes={{ inner: styles.card }}>
        <h3 className={styles.title}>
          <FaTimesCircle />
          <span>{preferences.error}</span>
        </h3>
        <p>{preferences.customerService}</p>
        {showDetails && (
          <Accordion title={preferences.details}>
            <span className={styles.errorContainer}>{error.message}</span>
          </Accordion>
        )}
        <Button onClick={resetErrorBoundary}>Try again</Button>
      </CardView>
    </div>
  );
};

export default ErrorPage;
