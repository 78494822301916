import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { differenceInMinutes } from 'date-fns';

import { UserSecurityQuestionObject } from '@cv/portal-idm-lib/models';
import { APIErrorResponse } from '@cv/portal-common-lib/ajax/models';
import { ResetPinByKBAPayload, ResetPinPayload } from '@cv/portal-idm-lib/pin/models';
import {
  SetupPinAndSecurityQuestionsRequest,
  UpdateSecurityQuestionsByPinRequest,
} from '@cv/portal-idm-lib/security-questions-pin/models';

import { useApi } from '@api';
import usePreferencesSelector from '@redux/selectors/preferences';
import { CreatorForm } from '@components/EntryCreator';
import { CreatorFormProps, FormField } from '@components/EntryCreator/CreatorForm';
import { setUserPinLockedTimestamp, setUserPinStatus } from '@redux/actions';
import useLabels, { Label } from '@hooks/useLabels';
import Grid from '@components/Grid';
import ContentRenderer from '@components/ContentRenderer';
import Loader from '@components/Loader';
import Button from '@components/Button';

import styles from './SecurityInfoBox.module.css';
import Dialog from '@components/Dialog';
import PinResetForm from './PinResetForm';
import { RootState } from '@app/reducers';

type ContentRendererProps = {
  componentType: string;
};

type SecurityInfoBoxProps = {
  pinLabel?: string;
  pinValue: ContentRendererProps;
  pinForm: React.ReactNode;
  securityQuestionLabel: string;
  securityQuestionValue: ContentRendererProps;
  securityQuestionForm: React.ReactNode;
  pinResetLabel?: string;
  pinResetForm: React.ReactNode;
  securityAnswerLabel?: string;
  securityAnswerValue: ContentRendererProps;
  errorLabel?: string;
  configurePinDescription?: string;
  configurePinButtonLabel: string;
  configurePinFormDescription: string;
  configurePinForm: React.ReactNode;
  labels: { content: Label[] };
};

type SecurityBoxFormName<Prop = keyof SecurityInfoBoxProps> = Prop extends `${infer FormName}Form` ? FormName : never;

type SecurityBoxForm = {
  [key in SecurityBoxFormName]: SecurityBoxFormProps;
};

type OnFormConfirmFunction = CreatorFormProps['onFormConfirm'];

type SecurityBoxFormProps = {
  form: React.ReactNode;
  onFormConfirm: OnFormConfirmFunction;
  description?: string;
};

enum DialogType {
  PinLocked = 'pinLocked',
  CustomerCare = 'customerCare',
}

type ResetPinEventError = {
  data: {
    detail: {
      code: string;
    };
  };
};

export default function SecurityInfoBox({
  pinLabel,
  pinValue,
  pinForm,
  securityQuestionLabel,
  securityQuestionForm,
  pinResetLabel,
  pinResetForm,
  securityAnswerLabel,
  securityAnswerValue,
  errorLabel,
  configurePinDescription,
  configurePinButtonLabel,
  configurePinFormDescription,
  configurePinForm,
  labels,
}: SecurityInfoBoxProps) {
  const api = useApi();
  const globalPreferences = usePreferencesSelector();
  const [activeForm, setActiveForm] = useState<SecurityBoxFormName | null>(null);
  const [status, setStatus] = useState<'idle' | 'loading' | 'error'>('idle');
  const [invalidPinAttempts, setInvalidPinAttempts] = useState<number>(0);
  const [securityQuestions, setSecurityQuestions] = useState<UserSecurityQuestionObject | null>(null);
  const [activeDialog, setActiveDialog] = useState<DialogType | null>(null);
  const [error, setError] = useState('');

  const defaultSecurityQuestions = {
    question: '',
    tenantQuestionId: '',
    tenantQuestions: {},
  };
  const [securityQuestion, setSecurityQuestion] = useState(defaultSecurityQuestions);
  const [filledFormData, setFilledFormData] = useState<Record<string, unknown> | null>(null);
  const { pinLockedTimestamp } = useSelector(({ userReducer }: RootState) => userReducer);
  const { isPinConfigured } = useSelector(({ accountReducer }: RootState) => accountReducer?.account?.data || {});
  const dispatch = useDispatch();
  const dict = useLabels([labels].map((labelsSet) => labelsSet.content).flat());

  const maximumPinAttempts = 4;
  const pinLockedForMinutes = 10;
  const incorrectPinCode = 'RESPONSE.ERROR.INPUT.INCORRECT_PIN';
  const incorrectKba = 'RESPONSE.ERROR.INPUT.INCORRECT_KBA';
  const pinLockedCode = 'RESPONSE.ERROR.DATAERROR.PIN_LOCKED';
  const isPinLocked =
    (pinLockedTimestamp && differenceInMinutes(Date.now(), pinLockedTimestamp) < pinLockedForMinutes) ||
    invalidPinAttempts > maximumPinAttempts;

  const { locale } = useSelector(({ settingsReducer }) => settingsReducer);

  useEffect(() => {
    setActiveForm(null);
    setSecurityQuestions(null);
  }, [locale]);

  const handleFormClose = () => {
    setActiveForm(null);
    setStatus('idle');
    setFilledFormData(null);
    setError('');
  };

  const handleCancel = () => {
    setActiveDialog(null);
  };

  const handleError = (
    e: Error & { data?: APIErrorResponse },
    formValues?: Record<string, unknown>,
    setFieldError?: (fieldName: string, message: string) => void,
    setSubmitting?: (isSubmitting: boolean) => void,
    fieldName?: string,
    errorMsg?: string,
  ) => {
    if (formValues) {
      setFilledFormData(formValues);
    }

    setStatus('error');
    setSubmitting && setSubmitting(false);

    if (e?.data?.detail?.code === incorrectPinCode) {
      setInvalidPinAttempts((prevState) => prevState + 1);
    }

    // lock pin for 10 minutes based on the PIN code from the API response
    // or on the UI side if the code doesn't exist in the response
    if (e?.data?.detail?.code === pinLockedCode || invalidPinAttempts > maximumPinAttempts) {
      setActiveForm(null);
      setActiveDialog(DialogType.PinLocked);
      dispatch(setUserPinLockedTimestamp(Date.now()));
    } else if (e.data) {
      setFieldError && fieldName && setFieldError(fieldName, errorMsg || e.message || e.data.message);
      setError(e.message || e.data.message);
    } else {
      setFieldError && fieldName && setFieldError(fieldName, errorLabel || e.message || 'Unknown error');
      setError(errorLabel || e.message || 'Unknown error');
    }
  };

  const refreshSecurityQuestion = async (tenantQuestionId: string) => {
    const question = (await fetchSecurityQuestions())?.tenantQuestions[tenantQuestionId] || '';
    setSecurityQuestion({ question, tenantQuestionId });
  };

  useEffect(() => {
    if (pinLockedTimestamp && differenceInMinutes(Date.now(), pinLockedTimestamp) >= pinLockedForMinutes) {
      setActiveForm(null);
      setActiveDialog(null);
      setInvalidPinAttempts(0);
      dispatch(setUserPinLockedTimestamp(0));
    }
  }, [pinLockedTimestamp, isPinLocked]);

  const setupPin: OnFormConfirmFunction = async (formValues) => {
    const payload: SetupPinAndSecurityQuestionsRequest['data'] = {
      input: {
        kbaInfo: [
          {
            tenantQuestionId: formValues.securityQuestion,
            answer: formValues.securityAnswer,
          },
        ],
        pin: formValues.newPinField,
      },
    };

    try {
      setStatus('loading');
      await api.setupSecurityPin(payload);
      await refreshSecurityQuestion(formValues.securityQuestion);
      handleFormClose();
      dispatch(setUserPinStatus(true));
    } catch (e) {
      handleError(e, formValues);
    }
  };

  const updatePin: OnFormConfirmFunction = async (formValues, setErrors, setSubmitting) => {
    const payload: ResetPinPayload = {
      input: {
        oldPin: formValues.currentPin,
        pin: formValues.newPinField,
      },
    };

    try {
      setStatus('loading');
      await api.updatePin(payload);
      handleFormClose();
    } catch (e) {
      handleError(e, formValues, setErrors, setSubmitting, 'currentPin');
    }
  };

  const updateSecurityQuestions: OnFormConfirmFunction = async (formValues, setErrors, setSubmitting) => {
    const payload: UpdateSecurityQuestionsByPinRequest['data'] = {
      input: {
        kbaInfo: [
          {
            tenantQuestionId: formValues.securityQuestion,
            answer: formValues.securityAnswer,
          },
        ],
        pin: formValues.currentPin,
      },
    };

    try {
      setStatus('loading');
      await api.updateSecurityQuestions(payload);
      const tenantQuestionId = formValues.securityQuestion;
      await refreshSecurityQuestion(tenantQuestionId);
      handleFormClose();
    } catch (e) {
      handleError(e, formValues, setErrors, setSubmitting, 'currentPin');
    }
  };

  const getErrorField = (e: ResetPinEventError) => (e?.data?.detail?.code === incorrectKba ? 'securityAnswer' : null);

  const getFieldErrorMessage = (fieldName: string | null) => {
    if (fieldName !== 'securityAnswer') {
      return '';
    }

    const currentForm = activeForm && forms[activeForm]?.form;
    const field = currentForm?.formFields?.find((f: FormField) => f.fieldName === fieldName);
    return field?.fieldValidation?.fieldErrorMessage;
  };

  const resetPin: OnFormConfirmFunction = async (formValues, setErrors, setSubmitting) => {
    const payload: ResetPinByKBAPayload = {
      input: {
        answer: [
          {
            tenantQuestionId: securityQuestion.tenantQuestionId,
            answer: formValues.securityAnswer,
          },
        ],
        pin: formValues.newPinField,
      },
    };

    try {
      setStatus('loading');
      await api.resetPin(payload);
      handleFormClose();
    } catch (e) {
      const errorField = getErrorField(e);
      const errorMessage = getFieldErrorMessage(errorField);
      errorField && errorMessage
        ? handleError(e, formValues, setErrors, setSubmitting, errorField, errorMessage)
        : handleError(e, formValues);
    }
  };

  const forms: SecurityBoxForm = {
    pin: {
      form: pinForm,
      onFormConfirm: updatePin,
    },
    securityQuestion: {
      form: securityQuestionForm,
      onFormConfirm: updateSecurityQuestions,
    },
    pinReset: {
      form: pinResetForm,
      onFormConfirm: resetPin,
    },
    configurePin: {
      form: configurePinForm,
      onFormConfirm: setupPin,
      description: configurePinFormDescription,
    },
  };

  const fetchSecurityQuestions = async () => {
    if (securityQuestions) {
      return securityQuestions;
    }
    try {
      const { data: secQuestions } = await api.getSecurityQuestions();
      setSecurityQuestions(secQuestions);
      return secQuestions;
    } catch (e) {
      handleError(e);
      return defaultSecurityQuestions;
    }
  };

  // Replace security question list with data from API
  // and inject security question to the reset pin form
  // TODO: figure out how to avoid manual forms patching
  useEffect(() => {
    const formHasSecurityQuestion = activeForm === 'securityQuestion' || activeForm === 'configurePin';
    const populateSecurityQuestions = (data: UserSecurityQuestionObject | null, formWithSecurityQuestion: any) => {
      const securityQuestionsField = formWithSecurityQuestion?.formFields.find(
        (field) => field.fieldName === 'securityQuestion',
      );
      securityQuestionsField.fieldValues = data
        ? Object.entries(data.tenantQuestions).map(([key, value]) => `${key}::${value}`)
        : [];
      setSecurityQuestion({ question: '', tenantQuestionId: '' });
    };

    const updateSecurityQuestion = (
      secQuestions: UserSecurityQuestionObject,
      formWithSecurityQuestion: SecurityBoxFormProps,
    ) => {
      // Populate the list of security questions with data from API
      populateSecurityQuestions(secQuestions, formWithSecurityQuestion);

      const tenantQuestionId = secQuestions?.savedResponses?.[1]?.tenantQuestionId || '';
      const question = secQuestions.tenantQuestions[tenantQuestionId] || '';

      setSecurityQuestion({
        question,
        tenantQuestionId,
      });
    };

    const loadSecurityQuestions = async (formWithSecurityQuestion: SecurityBoxFormProps) => {
      populateSecurityQuestions(null, formWithSecurityQuestion);
      const secQuestions = await fetchSecurityQuestions();
      secQuestions && updateSecurityQuestion(secQuestions, formWithSecurityQuestion);
    };

    if (formHasSecurityQuestion) {
      loadSecurityQuestions(forms[activeForm].form);
    }
  }, [api, activeForm]);

  useEffect(() => {
    const addStaticSecurityQuestion = async () => {
      const questionData = await fetchSecurityQuestions();

      const tenantQuestionId = questionData?.savedResponses?.[1]?.tenantQuestionId || '';
      const question = questionData?.tenantQuestions[tenantQuestionId] || '';

      // display previously set security question when pin is configured
      setSecurityQuestion({ question, tenantQuestionId });
    };

    addStaticSecurityQuestion();
  }, [securityQuestions]);

  const renderLabel = (label: string | undefined) =>
    label ? <div className={styles['security-info-label']}>{label}</div> : null;
  const renderDescription = (description?: string) =>
    description && <div className={styles['security-info-description']}>{description}</div>;

  const renderEditButton = (formName: SecurityBoxFormName) => {
    return (
      <button className={styles['button-edit']} onClick={() => setActiveForm(formName)}>
        <FaEdit className={styles['edit-icon']} />
      </button>
    );
  };

  return (
    <div className={styles['security-info-box']}>
      {!isPinConfigured && !activeForm && (
        <>
          {renderDescription(configurePinDescription)}
          <Button className={styles['security-button']} onClick={() => setActiveForm('configurePin')}>
            {configurePinButtonLabel}
          </Button>
        </>
      )}
      {isPinConfigured && !activeForm && (
        <Grid className={styles['grid']} columns={'2'}>
          <div className={styles['grid-item']}>
            {renderLabel(pinLabel)}

            <div className={styles['security-info-value']}>
              <ContentRenderer name="pinValue" content={[pinValue]} />
            </div>
            {!isPinLocked && renderEditButton('pin')}
          </div>

          <div className={styles['grid-item']}>
            {renderLabel(securityQuestionLabel)}

            <div className={styles['security-info-value']}>
              <p>{securityQuestion.question}</p>
            </div>
            {!isPinLocked && renderEditButton('securityQuestion')}
          </div>

          <div className={styles['grid-item']}>
            {!isPinLocked && (
              <button className={styles['security-info-button']} onClick={() => setActiveForm('pinReset')}>
                {pinResetLabel}
              </button>
            )}
          </div>

          <div className={styles['grid-item']}>
            {renderLabel(securityAnswerLabel)}

            <div className={styles['security-info-value']}>
              <ContentRenderer name="pinValue" content={[securityAnswerValue]} />
            </div>
          </div>
        </Grid>
      )}
      {status === 'loading' && (
        <div className={styles['loader-wrapper']}>
          <Loader />
        </div>
      )}
      {activeForm && (
        <>
          {Boolean(forms[activeForm]?.description) && (
            <strong>{renderDescription(forms[activeForm]?.description)}</strong>
          )}
          {activeForm === 'pinReset' ? (
            <PinResetForm
              securityQuestionLabel={securityQuestionLabel}
              question={securityQuestion.question}
              onFormConfirm={forms[activeForm]?.onFormConfirm}
              onFormClose={handleFormClose}
              initialValues={filledFormData}
              {...forms[activeForm]?.form}
            />
          ) : (
            <CreatorForm
              onFormConfirm={forms[activeForm]?.onFormConfirm}
              onFormClose={handleFormClose}
              initialValues={filledFormData}
              {...forms[activeForm]?.form}
            />
          )}
        </>
      )}
      {isPinLocked && activeDialog === DialogType.PinLocked && (
        <Dialog
          labelOk="OK"
          onOk={handleCancel}
          title={dict.getPrimary('entriesTitle')}
          classes={{ body: styles['security-info-dialog'] }}
        >
          <p>{dict.getPrimary('entriesDescriptionPrimary')}</p>
          <p>{dict.getPrimary('entriesDescriptionSecondary')}</p>
          <button
            className={styles['security-info-dialog-button']}
            onClick={() => setActiveDialog(DialogType.CustomerCare)}
          >
            {dict.getPrimary('entriesLinkLabel')}
          </button>
        </Dialog>
      )}
      {isPinLocked && activeDialog === DialogType.CustomerCare && (
        <Dialog
          labelOk="OK"
          onOk={handleCancel}
          title={dict.getPrimary('customerTitle')}
          classes={{ body: styles['security-info-dialog'] }}
        >
          <p>
            {dict.getPrimary('customerDescription')}
            <br />
            {globalPreferences?.customerCareNumber}
          </p>
        </Dialog>
      )}
    </div>
  );
}
