import React, { useEffect, useRef } from "react";

import {
  addPointerEventListener,
  removePointerEventListener,
  POINTER_EVENTS,
} from "../../utils/pointer-events";

type IOutsideClickProps = {
  event: string;
  onClick: (event?: Event) => void;
  children: React.ReactElement;
};

const OutsideClick = ({ event: Event, onClick, children }: IOutsideClickProps): React.ReactNode => {
  const childrenRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const onClickOutside = (event: Event) => {
      if (childrenRef.current && !childrenRef.current.contains(event.target as HTMLElement)) {
        onClick();
      }
    };

    if (childrenRef.current) {
      addPointerEventListener(document.body, POINTER_EVENTS.POINTER_DOWN, onClickOutside);
      return () => {
        removePointerEventListener(document.body, POINTER_EVENTS.POINTER_DOWN, onClickOutside);
      };
    }
  }, [childrenRef.current, onClick]);

  if (!children) {
    return;
  }

  return React.cloneElement(children, { ...children.props, ref: childrenRef });
};

OutsideClick.defaultProps = {
  event: "mousedown",
};

export default OutsideClick;
