export const isServiceSubscribed = (servicesToCheck: string[], activeServices: string[]) => {
  if (servicesToCheck?.length === 0 || activeServices?.length === 0) {
    return false;
  }
  return servicesToCheck?.some((service: string) => activeServices?.includes(service));
};
export const isAllServicesSubscribed = (servicesToCheck: string[], activeServices: string[]) => {
  if (servicesToCheck?.length === 0 || activeServices?.length === 0) {
    return false;
  }
  return servicesToCheck?.every((service: string) => activeServices?.includes(service));
};

export const checkServicesSubscribed = (servicesToCheck: string[], activeServices: string[]) => {
  if (!servicesToCheck?.length || !activeServices?.length) {
    return [];
  }
  return servicesToCheck.filter((service: string) => activeServices.includes(service));
};
