// Constants

export const EMAIL_REGEX: RegExp = /^[a-zA-Z0-9\'._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$/;
export const NAME_REGEX: RegExp = /^[a-zA-Z0-9\u00C0-\u00FF\\s\'~-]*$/;
export const STREET_REGEX: RegExp = /^[a-zA-Z0-9 \u00C0-\u00FF\\s\'.-~]*$/;
export const CITY_REGEX: RegExp = /^[a-zA-Z0-9\u00C0-\u00FF\\s\'~-]*$/;
export const PHONE_NUMBER_REGEX: RegExp = /^\\d{10}$/;
export const TAXID_REGEX: RegExp = /^[A-Za-z0-9 ]+$/;
export const INVOICE_REGEX: RegExp = /^[A-Za-z0-9 ]+$/;
export const VIN_REGEX: RegExp = /^[A-Za-z0-9]+$/;
export const PASSWORD_REGEX = '^(?!.*(.)\\1\\1)(?=(.*\\d){2})(?=(?:.*?[A-Z]){2})[0-9a-zA-Z\\~`!@#$%^&*()_\\-+=[\\]{}|\\/;:\'<\\.,>\\?"\\\\]{8,}';

export const STATES = [
    { label: 'AGU', value: 'Aguascalientes' },
    { label: 'BCN', value: 'Baja California' },
    { label: 'BCS', value: 'Baja California Sur' },
    { label: 'CAM', value: 'Campeche' },
    { label: 'CHP', value: 'Chiapas' },
    { label: 'CHH', value: 'Chihuahua' },
    { label: 'CMX', value: 'Ciudad de México' },
    { label: 'COA', value: 'Coahuila' },
    { label: 'COL', value: 'Colima' },
    { label: 'DUR', value: 'Durango' },
    { label: 'GUA', value: 'Guanajuato' },
    { label: 'GRO', value: 'Guerrero' },
    { label: 'HID', value: 'Hidalgo' },
    { label: 'JAL', value: 'Jalisco' },
    { label: 'MEX', value: 'México' },
    { label: 'MIC', value: 'Michoacán' },
    { label: 'MOR', value: 'Morelos' },
    { label: 'NAY', value: 'Nayarit' },
    { label: 'NLE', value: 'Nuevo León' },
    { label: 'OAX', value: 'Oaxaca' },
    { label: 'PUE', value: 'Puebla' },
    { label: 'QUE', value: 'Querétaro' },
    { label: 'ROO', value: 'Quintana Roo' },
    { label: 'SLP', value: 'San Luis Potosí' },
    { label: 'SIN', value: 'Sinaloa' },
    { label: 'SON', value: 'Sonora' },
    { label: 'TAB', value: 'Tabasco' },
    { label: 'TAM', value: 'Tamaulipas' },
    { label: 'TLA', value: 'Tlaxcala' },
    { label: 'VER', value: 'Veracruz' },
    { label: 'YUC', value: 'Yucatán' },
    { label: 'ZAC', value: 'Zacatecas' }
];

export const ACCOUNT_ENROLLMENT_PATH: string = "accountEnrollment";
export const PORTAL_LABEL_DICTIONARY: string = "portalLabelDictionary";
