export default {
  suppressErrorDialogUrls: [
    '/telematicsservices/v1/vehicles\/.*\/locations/tracker/locate',
    '/agreement-api/v1/agreements/agreements',
    '/wireless-management/v1/wifi/eligibility',
    '/subscription-api/v1/orders/preview',
    '/auth/v1/tokens/mktg',
    '/user/info\/.*',
    'subscription-api/v1/eligible-packages',
    'subscription-api/v1/subscribed-packages',
    '/utility-api/v1/address',
    'telematicsservices\/v1\/vehicles\/.+\/geofences',
    '/p/ehp/v1/user/token',
    '/openidm/v1/managed/user/profile/',
    '/tokens/login',
  ],
} as const;
