/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { ContentfulImage } from '@cv/webframework-react-components';
import { ContentfulFile } from '@app/types/ContentfulComponent';
import Markdown from '@components/Markdown';
import Dialog from '@components/Dialog';
import { formatAnalyticsEventName } from '@cv/portal-common-lib';
import styles from './infoCard.module.css';
import { FaChevronRight } from 'react-icons/fa';
import clsx from 'clsx';

export enum TContentColor {
  DARK = 'dark',
  LIGHT = 'light',
}

export type InfoCardContentProps = {
  subtitle?: string;
  description: string;
  shortDescription?: string;
  infoImage?: ContentfulFile;
  analyticsEventName?: string;
  buttonText?: string;
  url?: string;
  backgroundColor?: string;
  backgroundImage?: ContentfulFile;
  tags?: string[];
  clickableCard?: boolean;
  linkText?: string;
  component?: JSX.Element;
  contentColor: TContentColor;
  isLeftAlignedStyle?: boolean;
};

const InfoCardContent = ({
  description,
  infoImage,
  subtitle,
  shortDescription,
  url,
  clickableCard,
  buttonText,
  linkText,
  analyticsEventName,
  backgroundColor,
  backgroundImage,
  contentColor,
  component,
  isLeftAlignedStyle,
}: InfoCardContentProps): JSX.Element => {
  const [isModalOpen, setModal] = useState<boolean>(false);
  const formatEventName = formatAnalyticsEventName(analyticsEventName);
  const inlineStyles = {
    ...(backgroundColor && { backgroundColor }),
    ...(backgroundImage && { backgroundImage: `url(${backgroundImage?.file?.url})` }),
    ...(contentColor === TContentColor.LIGHT
      ? { color: 'var(--theme-color-white)' }
      : { color: 'var(--theme-color-text)' }),
  };

  const openModal = () => setModal(true);

  const alignmentStyles = isLeftAlignedStyle ? styles['left-alignment'] : '';

  return (
    <>
      <div
        role={clickableCard ? 'button' : undefined}
        className={clsx(styles['grid-item'], alignmentStyles, clickableCard && styles['show'])}
        onClick={clickableCard ? openModal : undefined}
        data-analyticseventname={formatEventName}
        style={inlineStyles}
        data-testid="info-card-content"
      >
        <div>
          <div className={clsx(styles['info-cart-header'], alignmentStyles)}>
            {infoImage && <ContentfulImage className={styles['img-container']} imageData={infoImage} />}
            {subtitle && <h3 className={alignmentStyles}>{subtitle}</h3>}
          </div>

          <div>
            {shortDescription && <p className={clsx(styles['short-desc'], alignmentStyles)}>{shortDescription}</p>}
            {component}
            {url && linkText && (
              <a href={url} target="_blank" rel="noreferrer" data-testid="wifi-link">
                <p className={styles['learn-btn']}>
                  {linkText} <FaChevronRight />
                </p>
              </a>
            )}
            {buttonText && (
              <button className={styles['learn-btn']} data-testid="learn-btn" onClick={openModal}>
                {buttonText}
              </button>
            )}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className={styles['grid']}>
          <Dialog>
            <div className={styles['main-section']}>
              <div>{infoImage && <ContentfulImage className={styles['img-popup']} imageData={infoImage} />}</div>
              <div className={styles['details-popup']}>
                <h2>{subtitle}</h2>
                <br />
                <Markdown className={styles['details-content']}>{description}</Markdown>
              </div>
              <button className={styles['info-card-btn']} name="x" onClick={() => setModal(false)}>
                X
              </button>
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default InfoCardContent;
