import React, { useEffect, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useApi } from '../../api';
import { RRS_ACTIONS, LOADING_ACTIONS } from '@redux/actions';

type TokenExchangeProps = {
  children?: ReactNode;
};

export default function TokenExchange({children}: TokenExchangeProps) {
  const { search } = useLocation();
  const api = useApi();
  const dispatch = useDispatch();


  useEffect(async () => {
    const params = new URLSearchParams(search);
    const tokenId = params.get('tokenId');
    const clientId = params.get('clientId');
    if (tokenId && clientId) {
      try {
        dispatch({ type: LOADING_ACTIONS.SET_LOADING_STATUS, data: true });
        const { data } = await api.exchangeToken(tokenId, clientId);
        dispatch({ type: RRS_ACTIONS.SET_RRS_DATA, data: data.data });
        dispatch({ type: LOADING_ACTIONS.SET_LOADING_STATUS, data: false });
      } catch (e) {
        dispatch({ type: LOADING_ACTIONS.SET_LOADING_STATUS, data: false });
      }
    }
  }, [search]);

  return <>{children}</>;
}
