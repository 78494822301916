import React from "react";

import OutsideClick from '@components/OutsideClick';

import styles from "./Header.module.css";

type HeaderMobileMenuProps = {
  children?: React.ReactNode;
  onClose: () => void;
  isVisible: boolean
};

export default function HeaderMobileMenu({ children, onClose, isVisible }: HeaderMobileMenuProps) {
  const mobileMenuClassName = `${styles["Header-mobile-menu"]} ${isVisible ? styles["show"] : ""}`;

  return (
    <div className={mobileMenuClassName}>
      <OutsideClick onClick={onClose}>
        <div className={styles["Header-mobile-menu-inner"]}>
          <button className={styles["Header-mobile-menu-close"]} type="button" onClick={onClose}>X</button>

          {children}
        </div>
      </OutsideClick>
    </div>
  );
}

HeaderMobileMenu.defaultProps = {
  children: null,
  isVisible: false
}