import React from 'react';

import { ModelConnector } from '@components/ApiConnectorHOCs/';
import styles from './SubscriptionOverview.module.css';
import {useApi} from "@api";

function SubscriptionModelInfo ({data}) {
  const api = useApi();
  const userVehicleInfo = api.storeService.getUserVehicleInfo();
  return (
    <>
      <div className={styles['SubscriptionOverview-vehicle-name']}>{userVehicleInfo}</div>
      <div className={styles['SubscriptionOverview-vin']}>{data?.vin}</div>
    </>
  );
}

export default ModelConnector(SubscriptionModelInfo);
