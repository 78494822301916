import { APIRequest, APIResponse } from '@cv/portal-common-lib/ajax/models';
import { HttpMethodEnum } from '@cv/portal-common-lib/ajax/constants';
import config from '@config/config';
import axios from 'axios';

const tokenExchangeUrl: string = config.getOemValue('TOKEN_EXCHANGE_URL') || '';

type TokenExchangeArgs = {
  tokenId: string;
  clientId: string;
}

type TokenExchangeResponse = {
  tenantId: string;
  vin: string;
  lang: string;
  phoneNumber: string;
  email: string;
  channel: string;
  source: string;
}

export default class TokenExchangeService {
  constructor() { }

  exchangeTokens({ tokenId, clientId }: TokenExchangeArgs): Promise<APIResponse<TokenExchangeResponse>> {
    const request: APIRequest = {
      url: `${tokenExchangeUrl}${tokenId}`,
      method: HttpMethodEnum.GET,
      headers: {
        'Accept': 'application/json',
        'cv-client-id': clientId,
      },
    };

    return axios(request);
  }
}
