import React from "react";
import Label from "@components/Label";
import clsx from "clsx";

import styles from "./Field.module.css";

import { DefaultFieldWrapper, DefaultHelpTextContainer } from "./components";

export type FieldProps = {
  /* The label which would be placed on the top of the child */
  label: React.ReactNode;
  /* The element that would be placed as the content of the field */
  children: React.ReactElement;
  /* Text to show beneath content */
  helpText?: React.ReactNode;
  /* Related input id */
  htmlFor?: string;
  /* Mark the whole component in red */
  error?: boolean;
  /* Node which would be shown as tootlip */
  helpCallout?: React.ReactNode;
  /* Container for children */
  ContentContainer?: React.ComponentType<any>;
  /* Wrapper for label */
  LabelContainer?: React.ComponentType<any>;
  /* Wrapper for help text */
  HelpTextContainer?: React.ComponentType<any>;
  /* Field wrapper */
  FieldWrapper?: React.ComponentType<any>;
  /* Icon to show inside of input in case if there would be an error */
  ErrorIcon?: React.ComponentType<any>;
  /* classNames to override default styles of elements */
  classes?: {
    label?: string;
    wrapper?: string;
    helpText?: string;
    error?: string;
  };
};

const Field = (props: FieldProps) => {
  const {
    FieldWrapper = DefaultFieldWrapper,
    LabelContainer = Label,
    HelpTextContainer = DefaultHelpTextContainer,
    htmlFor,
    classes = {},
  } = props;

  return (
    <FieldWrapper className={classes.wrapper}>
      {props.label && (
        <LabelContainer
          className={classes.label}
          helpCallout={props.helpCallout}
          htmlFor={htmlFor}
          classes={{
            helpCalloutPopup: styles["help-callout"],
          }}
        >
          {props.label}
        </LabelContainer>
      )}
      {React.cloneElement(props.children, { error: props.error })}
      {props.helpText && (
        <HelpTextContainer
          className={clsx(classes.helpText, classes.error)}
          error={props.error}
        >
          {props.helpText}
        </HelpTextContainer>
      )}
    </FieldWrapper>
  );
};

export default Field;
