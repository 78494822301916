import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useApi } from '@api';
import { ROOT_EVENTS } from '@app/reducers';
import { LoaderBackdrop } from '@components/Loader';
import { useAuth } from '@components/AuthProvider/AuthProvider';
import config from '@config/config';
import UserInfo from '@components/Login/UserInfo';

export type EmailSearchFormData = {
  email: string;
};

const LoginCallBack = (): JSX.Element | null => {
  const history = useHistory();
  const api = useApi();
  const { error, isLoading, loginWithCallback } = useAuth();
  const dispatch = useDispatch();
  const isLoginCallbackCalled = React.useRef(false);

  const isAuthenticated = api.storeService.getAccessToken();
  const accountData = api.storeService.getAccountData();

  useEffect(() => {
    loginWithCallback();
    isLoginCallbackCalled.current = true;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (accountData || !error) {
      return;
    }

    const loginFallbackUrl = config.getOemValue('LOGIN_FALLBACK_URL');
    if (loginFallbackUrl) {
      window.location.replace(loginFallbackUrl);
    } else {
      history.push({ pathname: '/login' });
    }
  }, [error, history, accountData]);

  const clearValues = () => dispatch({ type: ROOT_EVENTS.DESTROY_SESSION });

  if (error) {
    if (accountData) {
      clearValues();
    }

    return null;
  }

  const sxmAccessTokenUrl = config.getOemValue('SXM_ACCESS_TOKEN_URL');
  const isInternalIdp = !sxmAccessTokenUrl;

  if (isLoginCallbackCalled.current && !isLoading && isAuthenticated) {
    if (isInternalIdp) {
      return <Redirect to="/" />;
    }
    return <UserInfo />;
  }

  return <LoaderBackdrop />;
};

export default LoginCallBack;
