import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LoaderBackdrop } from '@components/Loader';
import ContentfulComponent from '@customTypes/ContentfulComponent';
import CardView from '@components/Card/CardView';
import { CancelSubscriptionOverview } from './CancelSubscriptionOverview';
import { CancelSubscriptionOptions } from './CancelSubscriptionsOptions';
import { CancelSubscriptionConfirmation } from './CancelSubscriptionConfirmation';
import { CancelSubscriptionDetails } from './CancelSubscriptionDetails';
import { useVehicleSelector } from '@redux/selectors/vehicle';
import { useApi } from '@api';
import { RootState } from '@app/reducers';
import styles from './CancelSubscription.module.css';
import { cancelSubscriptionRequest } from './cancelSubscriptionRequest';
import { generateLabelsObjects, getDictionariesContent } from '@utils/labels';
import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import urlSearchParams from '@utils/urlSearchParams';
import { EligiblePackagesResponse } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { findFreePackageVariant } from '@components/LinkVehicle/utils';
import { SUBSCRIPTION_PAGE_URL } from '@components/App/UrlParser';
import { PAGE_LOADING_STATUS } from '@utils/constants';
import { isAnnualTerm, isPaidPackage } from '@utils/subscriptionUtils';
import { isTiered } from '@cv/portal-components-lib';

type PageListData = {
  contentSections: Array<ContentfulComponent>;
};

export const CancelSubscription = ({ contentSections }: PageListData) => {
  const labels = getDictionariesContent(contentSections);
  const {
    title,
    cancelDescription,
    keepSubscriptionButton,
    keepSubscriptionButtonSecond,
    continueButton,
    continueButtonSecond,
    beforeYouGo,
    saveMoneyBy,
    cancelDateLater,
    contactCustomerCare,
    importantCancellationInfo__Monthly,
    importantCancellationInfo__Annual,
    byClickingCancel__Monthly,
    byClickingCancel__Annual,
    sorryToSeeYouGo,
    subscriptionIsNowCancelled__Monthly,
    subscriptionIsNowCancelled__Annual,
    goToButton,
    goBackLabel,
    detailsTitle,
    moreDetailsLabel,
    errorTitle,
    errorMessage,
    freeTrialAvailable,
  } = generateLabelsObjects(labels);

  const api = useApi();
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedVehicle = useVehicleSelector();
  const vehiclesPackages = useSelector(({ userReducer }: RootState) => userReducer.packages);
  const products = api.storeService.getVehicleData().services || [];
  const userId = api.storeService.getUserId();
  const { vehicleId } = api.storeService.getVehicleData();

  const [eligiblePackages, setEligiblePackages] = useState<EligiblePackagesResponse[]>();

  const [status, setStatus] = useState<PAGE_LOADING_STATUS>(PAGE_LOADING_STATUS.IDLE);
  const [currentView, setCurrentView] = useState('overview');
  const [selectedPackages, setSelectedPackages] = useState<Array<SubscribedPackage>>([]);

  const selectedVehiclePackages = useMemo(
    () => vehiclesPackages && vehiclesPackages.find((vehicle) => vehicle.vehicleId === selectedVehicle.vehicleId),
    [vehiclesPackages],
  );

  const packages = useMemo(() => {
    const filteredPackages = selectedVehiclePackages?.packages.filter((pkg) => !pkg.cancelReceiveDate) || [];
    filteredPackages.sort((a: SubscribedPackage, b: SubscribedPackage) => a.tier - b.tier);
    return filteredPackages;
  }, [selectedVehiclePackages]);

  useEffect(() => {
    api.searchEligiblePackages({ vehicleId, userId }).then(({ data }) => setEligiblePackages(data));
  }, [vehicleId, userId, api]);

  useEffect(() => {
    // if user navigates to `/cancel` directly without packages for cancel, just navigate to profile page
    if (packages.length === 0) {
      handleCancel();
    }
  }, []);

  const isCancellingMonthlyPackages = selectedPackages?.some((sPkg) => isPaidPackage(sPkg) && !isAnnualTerm(sPkg));

  const [byClickingCancel, importantCancellationInfo] = isCancellingMonthlyPackages
    ? [byClickingCancel__Monthly, importantCancellationInfo__Monthly]
    : [byClickingCancel__Annual, importantCancellationInfo__Annual];

  const isAllTieredPackagesArePaid = useMemo(
    () => packages.every((pkg) => isPaidPackage(pkg) && isTiered(pkg.tier)),
    [packages],
  );
  const eligibleTrialPackages = eligiblePackages?.filter(findFreePackageVariant);
  const isEligibleForFreeTrial = isAllTieredPackagesArePaid && !!eligibleTrialPackages?.length;

  const handleCancel = () => {
    history.push('/profile');
  };
  const handleGoBack = () => {
    setCurrentView('overview');
  };
  const handleContinueDowngrade = () => {
    urlSearchParams.setMultiple({
      flowName: 'downgrade',
      pkgIdsToRemove: selectedPackages.map((pkg) => pkg.packageId),
    });
    history.push(SUBSCRIPTION_PAGE_URL);
  };

  const handleConfirmationView = (subscriptionIsNowCancelled: { primary: string; analyticsEventName?: string }) => {
    return (
      <CancelSubscriptionConfirmation
        labels={{ sorryToSeeYouGo, subscriptionIsNowCancelled, goToButton }}
        handleContinue={() => history.push('/')}
      />
    );
  };

  const handleContinue = async () => {
    return await cancelSubscriptionRequest({
      api,
      dispatch,
      setStatus,
      setCurrentView,
      errorMessage,
      errorTitle,
      isMonthlySubscription: isCancellingMonthlyPackages,
      packagesToRemove: selectedPackages,
      isAllPackages: selectedPackages.length === packages.length,
    });
  };

  const renderSubscriptionCard = () => {
    switch (currentView) {
      case 'overview':
        return (
          <CancelSubscriptionOverview
            labels={{ title, cancelDescription, keepSubscriptionButton, continueButton, moreDetailsLabel }}
            handleCancel={handleCancel}
            handleContinue={() => setCurrentView('options')}
            handleMoreDetails={() => setCurrentView('details')}
            packages={packages}
            handleSelect={setSelectedPackages}
            selectedPackages={selectedPackages}
          />
        );
      case 'details':
        return (
          <CancelSubscriptionDetails
            labels={{ detailsTitle, goBackLabel }}
            handleGoBack={handleGoBack}
            packages={packages}
            products={products}
          />
        );
      case 'options':
        return (
          <CancelSubscriptionOptions
            labels={{
              title,
              beforeYouGo,
              saveMoneyBy,
              cancelDateLater,
              contactCustomerCare,
              importantCancellationInfo,
              byClickingCancel,
              continueButtonSecond,
              keepSubscriptionButtonSecond,
              freeTrialAvailable,
            }}
            isEligibleForFreeTrial={isEligibleForFreeTrial}
            handleCancel={handleCancel}
            handleContinue={handleContinue}
            handleContinueDowngrade={handleContinueDowngrade}
          />
        );
      case 'confirmation-monthly':
        return handleConfirmationView(subscriptionIsNowCancelled__Monthly);
      case 'confirmation-annual':
        return handleConfirmationView(subscriptionIsNowCancelled__Annual);
    }
  };

  return (
    <CardView className={styles['cancel-container']} type="main">
      {status === PAGE_LOADING_STATUS.LOADING && <LoaderBackdrop />}
      {renderSubscriptionCard()}
    </CardView>
  );
};
