import React  from 'react';
import Button from '@components/Button';
import styles from './MobileInformationService.module.css';
import ModalContainer from '@components/ModalContainer';

type Props = {
  className?: string;
  buttonText?: string;
  title: string;
  description: string;
  handleCloseErrorModal: () => void;
  showErrorModal: boolean;
};

const ErrorModal = ({ showErrorModal, handleCloseErrorModal, buttonText = 'OK', description, title }: Props) => (
  <ModalContainer
    show={showErrorModal}
    height="auto"
    position={'center'}
    header={{ text: '', position: 'center', showXButton: false }}
    onCloseHandler={handleCloseErrorModal}
  >
    <div className={styles['error-modal-wrap']}>
      <h3 className={styles['error_heading']}>{title}</h3>
      <p className={styles['error']}>{description}</p>
      <Button onClick={handleCloseErrorModal} variant="filled" className={styles.btn_error}>
        {buttonText}
      </Button>
    </div>
  </ModalContainer>
);

export default ErrorModal;
