// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3WWuJ5wOaHTOPkQNuZDRqE {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin-top: 2rem;\n}\n\n._3WWuJ5wOaHTOPkQNuZDRqE.cvr1aic_9lo2gCEEopfz6 {\n  flex-direction: row;\n}\n\n._3WWuJ5wOaHTOPkQNuZDRqE._1YIDAD7yqzgcy6ncBqx9Ea {\n  flex-direction: column;\n}\n\n._3OnxLhBU4cVq8z5qdgRpgM {\n  margin: 0.5rem;\n}\n\n._218GD7xZyDYs9EDvU0CPaL {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 1rem 1rem;\n}\n\n._218GD7xZyDYs9EDvU0CPaL img {\n  padding: 0 1rem;\n}\n\n.ertgflCDBcivrk1zK73ey {\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Login/ErrorModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".Actions {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin-top: 2rem;\n}\n\n.Actions.horizontal {\n  flex-direction: row;\n}\n\n.Actions.vertical {\n  flex-direction: column;\n}\n\n.Button {\n  margin: 0.5rem;\n}\n\n.ModalHeader {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 1rem 1rem;\n}\n\n.ModalHeader img {\n  padding: 0 1rem;\n}\n\n.ModalContent {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Actions": "_3WWuJ5wOaHTOPkQNuZDRqE",
	"horizontal": "cvr1aic_9lo2gCEEopfz6",
	"vertical": "_1YIDAD7yqzgcy6ncBqx9Ea",
	"Button": "_3OnxLhBU4cVq8z5qdgRpgM",
	"ModalHeader": "_218GD7xZyDYs9EDvU0CPaL",
	"ModalContent": "ertgflCDBcivrk1zK73ey"
};
export default ___CSS_LOADER_EXPORT___;
