import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { useAnalytics } from '@cv/webframework-react-components';
import { invalidateCache } from '@cv/portal-components-lib';

import { ISxmKey, SubscribedServices, useApi } from '../../api';
import Dropdown from '@components/Dropdown';
import { IVehicle, setVehicleData, svlStatus } from '@redux/actions';
import { isServiceSubscribed } from '@utils/checkIsServiceSubscribed';
import { ServiceLabels } from '@components/Map/constants';
import ErrorModal from '@components/Login/ErrorModal';

import VehicleItem from './VehicleItem';
import urlSearchParams from '@utils/urlSearchParams';
import useEntitlements from '@hooks/useEntitlements';
import { HttpStatus } from '@cv/portal-common-lib/ajax/constants';
import { setLoadingStatus } from '@redux/actions/loading';
import { setAccountSubscription } from '@redux/actions/account';
import { getVinSubscribedPackages } from '@utils/getUserData';
import styles from './VehicleSwitcher.module.css';
import usePreferencesSelector from '@redux/selectors/preferences';

type VehicleSwitcherProps = {
  theme?: string;
  className?: string;
};

export const maskVin = (vin?: string): string => {
  if (!vin) {
    return '';
  }

  return vin.replace(/^.{1,9}/, (character) => character.replace(/./g, '*'));
};

export default function VehicleSwitcher({ theme, className }: VehicleSwitcherProps) {
  const preferences = usePreferencesSelector();
  const api = useApi();
  const dispatch = useDispatch();
  const { sxmKeyDetails, vin } = urlSearchParams.getAll<{ sxmKeyDetails?: ISxmKey }>();
  const { vehicleIds } = useSelector(({ userReducer }) => userReducer);
  const { primarySubscriberVehicleIds = [] } = useSelector(({ accountReducer }) => accountReducer.account?.data) || {};
  const { vehicle } = useSelector(({ vehicleReducer }) => vehicleReducer);
  const { canUseRts } = useEntitlements();

  const [apiStatus, setApiStatus] = useState<'idle' | 'loading' | 'error' | 'success'>('idle');
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [vehicleData, setAllVehicleData] = useState<Array<IVehicle>>([]);
  const { trackEvent } = useAnalytics();

  const getSvl = async (vehicleId: string) => {
    try {
      const { data } = await api.getSvl(vehicleId);
      setApiStatus('success');
      if (!isEmpty(data)) {
        dispatch(svlStatus(data));
      }
    } catch (err) {
      setApiStatus('error');
    }
  };

  const getVehicleDetails = async (vehicleId: string, subscribedServices?: SubscribedServices[]): Promise<IVehicle> => {
    try {
      const { data } = await api.getVehicleDetails(undefined, vehicleId, 'vehicle_services', subscribedServices);
      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  const updateSubscriptionInformation = async (vehicle: IVehicle) => {
    try {
      invalidateCache();
      const { vehicleId } = vehicle;
      dispatch(setLoadingStatus(true));
      const subscription = await api.getSubscription({ vehicleId });
      if (subscription) {
        dispatch(setAccountSubscription(subscription.data));
      }

      const subscribedServices = await getVinSubscribedPackages({ dispatch, api, vehicleIds: [vehicleId] });
      const vehicleDetails = await getVehicleDetails(vehicleId, subscribedServices);
      dispatch(setVehicleData(vehicleDetails ?? vehicle));
    } catch (err) {
      console.error(err);
      dispatch(setVehicleData(vehicle));
    } finally {
      dispatch(setLoadingStatus(false));
    }
  };

  useEffect(() => {
    if (
      vehicle &&
      isServiceSubscribed([...ServiceLabels.STOLEN_VEHICLE_LOCATOR], vehicle?.activeServices) &&
      canUseRts
    ) {
      getSvl(vehicle.vehicleId);
    }
  }, [vehicle, canUseRts]);

  useEffect(() => {
    setApiStatus('loading');
    const fetchAllVehicleData = vehicleIds.map((vehicleId: string) =>
      getVehicleDetails(vehicleId).catch((e) => {
        // TODO: we probably don't need this anymore
        if (!e.status || e.status === HttpStatus.FORBIDDEN) {
          return null;
        }
        throw e;
      }),
    );
    Promise.all(fetchAllVehicleData)
      .then((results: IVehicle[]) => {
        setAllVehicleData(results.filter(Boolean));
        setApiStatus('success');
      })
      .catch((e) => setApiStatus('error'));
  }, [vehicleIds]);

  const onVehicleSelected = React.useCallback(
    (selectedVehicle: IVehicle) => {
      updateSubscriptionInformation(selectedVehicle);

      // mask first 9 symbols to have only 8 last visible
      const maskedVin = maskVin(selectedVehicle.vin);
      trackEvent(`vin:${maskedVin}`, 'maskedVin');

      document.cookie = `vehicleID=${selectedVehicle.vehicleId};`;
    },
    [vehicle],
  );

  useEffect(() => {
    if (!vin) {
      return;
    }

    const _selectVehicle = vehicleData.find((vehicleItem: IVehicle) => vehicleItem.vin === vin);
    _selectVehicle && onVehicleSelected(_selectVehicle);
  }, [vin]);

  useEffect(() => {
    const isOldVehicleList = !vehicleData.some((e) => e.vehicleId === vehicle?.vehicleId);
    const [firstVehicle] = vehicleData;
    if (vehicleData.length && isOldVehicleList) {
      onVehicleSelected(firstVehicle);
    } else {
      // track it here if we don't trigger onVehicleSelected initially
      const maskedVin = maskVin(vehicle?.vin || firstVehicle?.vin);
      trackEvent(`vin:${maskedVin}`, 'maskedVin');
    }
  }, [vehicle, vehicleData]);

  useEffect(() => {
    if (!sxmKeyDetails?.vehicleId) {
      return;
    }

    const foundVehicle = vehicleData.find((v) => v.vehicleId === sxmKeyDetails.vehicleId);
    if (foundVehicle) {
      onVehicleSelected(foundVehicle);
    }
  }, [sxmKeyDetails?.vehicleId, vehicleData]);

  if (vehicleIds.length <= 1 || apiStatus !== 'success' || !vehicle?.vehicleId) {
    return null;
  }

  const getVehicleLabel = (vehicle: IVehicle) => {
    return vehicle ? `${vehicle.year} ${vehicle.make} ${vehicle.model}` : '';
  };

  return (
    <>
      {showErrorDialog && (
        // TODO: combine all PS role error dialogs in one place
        <ErrorModal
          buttonOrientation="vertical"
          errorMessage={preferences.notLinkedVehicleErrorMessage}
          onCancel={() => setShowErrorDialog(false)}
          okLabel={preferences.ownerPortalButtonLabel}
          onOk={() => {
            window.location.replace(preferences.ownerPortalLink);
          }}
        />
      )}
      <Dropdown firstItemLabel={getVehicleLabel(vehicle)} theme={theme} classes={{ container: className }}>
        <ul className={styles['VehicleItemsList']}>
          {vehicleData
            .filter((e) => e.vehicleId !== vehicle.vehicleId)
            .map((e, i) => {
              const isPSRoleVehicle = primarySubscriberVehicleIds.includes(e.vehicleId);
              return (
                <VehicleItem
                  key={`vehicle-item-${i}`}
                  label={getVehicleLabel(e)}
                  onVehicleSelect={() => {
                    if (!isPSRoleVehicle) {
                      setShowErrorDialog(true);
                    } else {
                      onVehicleSelected(e);
                    }
                  }}
                  disabled={!isPSRoleVehicle}
                />
              );
            })}
        </ul>
      </Dropdown>
    </>
  );
}
