// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2unLnDW8RsjAinm-ZJsUTI {\n  margin-top: 10px;\n}\n._2unLnDW8RsjAinm-ZJsUTI p {\n  font-size: var(--theme-paragraph-font-size);\n}\n\n._2unLnDW8RsjAinm-ZJsUTI button {\n  padding: 0;\n  border: 0;\n  border-radius: 0;\n  background-color: transparent;\n  color: var(--theme-color-primary);\n  font-size: inherit;\n  cursor: pointer;\n}\n\n._2unLnDW8RsjAinm-ZJsUTI button:hover {\n  text-decoration: underline;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AgreementLink/AgreementLink.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,2CAA2C;AAC7C;;AAEA;EACE,UAAU;EACV,SAAS;EACT,gBAAgB;EAChB,6BAA6B;EAC7B,iCAAiC;EACjC,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".AgreementLinkContainer {\n  margin-top: 10px;\n}\n.AgreementLinkContainer p {\n  font-size: var(--theme-paragraph-font-size);\n}\n\n.AgreementLinkContainer button {\n  padding: 0;\n  border: 0;\n  border-radius: 0;\n  background-color: transparent;\n  color: var(--theme-color-primary);\n  font-size: inherit;\n  cursor: pointer;\n}\n\n.AgreementLinkContainer button:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AgreementLinkContainer": "_2unLnDW8RsjAinm-ZJsUTI"
};
export default ___CSS_LOADER_EXPORT___;
