// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2LvDeu7FvNNn2gXSjv62eT {\n  position: relative;\n}\n\n._1TySVuBhTp0YVkMAi9qi_V {\n  cursor: pointer;\n  padding: 0.625rem 1rem;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n\n._1TySVuBhTp0YVkMAi9qi_V > ._3dZsBsl_MmcVBSTawa_4fl {\n  transition: transform 0.2s ease;\n}\n\n._3dZsBsl_MmcVBSTawa_4fl._3elGVqV7PqhEyee9qqY9D3 {\n  transform: rotate(180deg);\n}\n\n._3dZsBsl_MmcVBSTawa_4fl._3LYdp7XW758Gs7JOwqN9et {\n  transform: rotate(0deg);\n}\n\n._3WPSIns7lZlxlDW2G3rZ5x {\n  transition: height 0.15s ease-out;\n  overflow: hidden;\n  height: auto;\n}\n\n._3WPSIns7lZlxlDW2G3rZ5x > p {\n  padding: 1rem;\n  margin: 0;\n}\n\n._1wB0DzqKviE3KNZp3kQ-X2 {\n  visibility: hidden;\n  position: fixed;\n  padding: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Accordion/Accordion.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,aAAa;AACf","sourcesContent":[".accordion {\n  position: relative;\n}\n\n.accordion-title {\n  cursor: pointer;\n  padding: 0.625rem 1rem;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n\n.accordion-title > .toggle-icon {\n  transition: transform 0.2s ease;\n}\n\n.toggle-icon.open {\n  transform: rotate(180deg);\n}\n\n.toggle-icon.close {\n  transform: rotate(0deg);\n}\n\n.accordion-body {\n  transition: height 0.15s ease-out;\n  overflow: hidden;\n  height: auto;\n}\n\n.accordion-body > p {\n  padding: 1rem;\n  margin: 0;\n}\n\n.hidden {\n  visibility: hidden;\n  position: fixed;\n  padding: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion": "_2LvDeu7FvNNn2gXSjv62eT",
	"accordion-title": "_1TySVuBhTp0YVkMAi9qi_V",
	"toggle-icon": "_3dZsBsl_MmcVBSTawa_4fl",
	"open": "_3elGVqV7PqhEyee9qqY9D3",
	"close": "_3LYdp7XW758Gs7JOwqN9et",
	"accordion-body": "_3WPSIns7lZlxlDW2G3rZ5x",
	"hidden": "_1wB0DzqKviE3KNZp3kQ-X2"
};
export default ___CSS_LOADER_EXPORT___;
