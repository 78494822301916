import { formatAnalyticsEventName } from '@cv/portal-common-lib';
import React from 'react';
import Button from '../Button';
import styles from './CancelSubscription.module.css';

type CancelSubscriptionOverviewProps = {
  handleContinue: () => void;
  labels: {
    sorryToSeeYouGo: { primary: string; analyticsEventName?: string };
    subscriptionIsNowCancelled: { primary: string; analyticsEventName?: string };
    goToButton: { primary: string; analyticsEventName?: string };
  };
};

export const CancelSubscriptionConfirmation = ({ handleContinue, labels }: CancelSubscriptionOverviewProps) => {
  const { sorryToSeeYouGo, subscriptionIsNowCancelled, goToButton } = labels;
  return (
    <div className={styles['container']}>
      <h1 className="sorry">{sorryToSeeYouGo?.primary}</h1>

      <p className="subscription-cancelled">{subscriptionIsNowCancelled?.primary}</p>

      <Button
        className={styles['continue-button']}
        onClick={handleContinue}
        data-analyticseventname={formatAnalyticsEventName(goToButton?.analyticsEventName)}
      >
        {goToButton?.primary}
      </Button>
    </div>
  );
};
