import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa';

import styles from './MonitoringAlerts.module.css';

type SettingSelectProps = {
  title: string;
  body: string;
  onClick: () => void;
  selected: boolean;
};

export default function SettingSelect({ title, body, onClick, selected }: SettingSelectProps) {
  useEffect(() => {
    setChecked(selected)
  }, [selected])

  const [checked, setChecked] = useState(selected);
  const checkButton = checked ? (
    <span className={styles['settings-select--icon']}>
      <FaCheckCircle />
    </span>
  ) : (
    <span className={styles['settings-select--icon']}>
      <FaRegCircle />
    </span>
  );
  const handleClick = () => {
    setChecked(!checked);
    onClick(checked);
  };

  return (
    <div className={styles['setting-select']}>
      <button className={styles['settings-select--button']} onClick={handleClick}>
        {checkButton}
      </button>
      <span className={styles['settings-select--title']}>{title}</span>
      <div className={styles['settings-select--body']} dangerouslySetInnerHTML={{ __html: body }} />
    </div>
  );
}
