import React from "react";

import ContentRenderer from '@components/ContentRenderer';

import "./MapWindow.css";

type MapWindowMonitor = {
  content: any;
}

function MapWindowMonitor({ content = {} }: MapWindowMonitor) {

  if (!content.name) {
    return null;
  }

  return (
    <div className="map-window-destinations">
      <ContentRenderer name="Monitoring Tab" content={content?.contentList} className="TabContainer--max-height" />
    </div>
  );
}

export default MapWindowMonitor;
