import { Label, LabelDictionaryProps } from '@components/LabelDictionary/LabelDictionary';
import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';

import { ContentfulFile } from '@app/types/ContentfulComponent';
import { BrandNamesFilterGroup } from '@customTypes/BrandNamesFilterGroup';
import useLabels from '@hooks/useLabels';
import useSetPreferences from '@redux/hooks/preferences';
import { findDictionary } from '@utils/labels';

type Favicon = {
  favicon: ContentfulFile;
  name: string;
  size: string;
};

export type GlobalPreferencesContent = {
  pageTitle: string;
  faviconList: Favicon[];
  keywords: string;
  pageDescription: string;
  sharedProperties: LabelDictionaryProps[];
  cookiesCloseIcon: ContentfulFile;
  isSinglePaymentMethod?: boolean;
  goToManageToSubscribe?: boolean;
  isAuthCodeFunctionality?: boolean;
  otpTimeout?: number;
};

type GlobalPreferencesProps = {
  content: GlobalPreferencesContent;
  labelsFilter?: BrandNamesFilterGroup;
};

// Create static data from contentful to use it outside of react world.
const preferences: Record<string, string> = {};

export function get<T>(key: string, defaultValue: T): string | T;
export function get(key: string): string | null;
export function get<T>(key: string, defaultValue: T | null = null): string | T | null {
  if (key in preferences) {
    return preferences[key] as T;
  }

  return defaultValue;
}

const GlobalPreferences = ({
  content: {
    pageTitle,
    keywords,
    pageDescription,
    faviconList,
    isSinglePaymentMethod = false,
    goToManageToSubscribe = false,
    isAuthCodeFunctionality = false,
    sharedProperties,
    otpTimeout,
  },
  labelsFilter,
}: GlobalPreferencesProps): JSX.Element => {
  const setPreferences = useSetPreferences();

  const combinedLabels = useMemo(
    () => sharedProperties?.reduce((acc, labelsDictionary) => [...acc, ...labelsDictionary.content], [] as Label[]),
    [sharedProperties],
  );
  const labels = useLabels(combinedLabels, labelsFilter).getAllPrimaries();

  const phoneTypeShortcutsLabels = useLabels(findDictionary(sharedProperties, 'phoneTypeShortcuts')).getAllPrimaries();

  useEffect(() => {
    const allPreferences = Object.assign(preferences, {
      ...labels,
      isSinglePaymentMethod,
      goToManageToSubscribe,
      isAuthCodeFunctionality,
      otpTimeout,
      phoneTypeShortcutsLabels,
    });
    setPreferences(allPreferences);
  }, [
    labels,
    isSinglePaymentMethod,
    goToManageToSubscribe,
    isAuthCodeFunctionality,
    otpTimeout,
    phoneTypeShortcutsLabels,
    setPreferences,
  ]);

  const addFavicons = () => {
    return faviconList.map((item, index) => (
      <link
        rel="icon"
        type={item?.favicon?.file?.contentType}
        href={`https:${item?.favicon.file?.url}`}
        sizes={item.size}
        key={`meta-item-${index}`}
      />
    ));
  };

  return (
    <Helmet titleTemplate={`%s - ${pageTitle}`} defaultTitle={pageTitle}>
      {addFavicons()}
      <meta name="description" content={pageDescription} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};

export default GlobalPreferences;
