// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._21rNJiyce-SA8AQ7PDdY-V:not(:last-child) {\n  margin-bottom: 0.375rem;\n}\n\n._1yzmt1t1PC4MiUA7UNI0PO > div {\n  border: none;\n  font-size: var(--theme-label-font-size);\n  margin-top: 0.3rem;\n  background-color: var(--theme-color-grey-e);\n  font-family: inherit;\n  padding: 0 0.625rem;\n  color: #4c4c4c;\n  border-radius: 0;\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://./src/components/FormikFields/FormikFields.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,uCAAuC;EACvC,kBAAkB;EAClB,2CAA2C;EAC3C,oBAAoB;EACpB,mBAAmB;EACnB,cAAc;EACd,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".error-spacer:not(:last-child) {\n  margin-bottom: 0.375rem;\n}\n\n.autocomplete > div {\n  border: none;\n  font-size: var(--theme-label-font-size);\n  margin-top: 0.3rem;\n  background-color: var(--theme-color-grey-e);\n  font-family: inherit;\n  padding: 0 0.625rem;\n  color: #4c4c4c;\n  border-radius: 0;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error-spacer": "_21rNJiyce-SA8AQ7PDdY-V",
	"autocomplete": "_1yzmt1t1PC4MiUA7UNI0PO"
};
export default ___CSS_LOADER_EXPORT___;
