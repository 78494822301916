import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { PaymentMethodData } from '@api/types/index';
import { ServicesContentItem } from '@customTypes/ContentfulComponent';
import { formatAnalyticsEventName } from '@cv/portal-common-lib';
import 'pure-react-carousel/dist/react-carousel.es.css';
import useMediaQuery from '@hooks/useMediaQuery';
import useFilterByServices from '@hooks/useFilterByServices';
import validations from './utils';
import CarouselContent, { CarouselContentProps } from './CarouselContent';
import { useAnalytics } from '@cv/webframework-react-components';

import styles from './Carousel.module.css';

type SwipeEvent = MouseEvent & TouchEvent;

type SlideProps = CarouselContentProps &
  ServicesContentItem & {
    componentType: string;
    name: string;
  };

export type CarouselProps = {
  data?: PaymentMethodData;
  slides: Array<SlideProps>;
  showArrows: boolean;
  showDots: boolean;
  interval: number;
  validationMethod: string;
  prioritySlides?: Array<SlideProps>;
  carouselSlideWidth: number;
  carouselSlideHeightPc: number;
  carouselSlideHeightTablet?: number;
  carouselSlideHeightMobile?: number;
  analyticsEventName?: string;
  visibleSlidesPc: number;
  visibleSlidesMobile: number;
  textPlacementOutsideImage?: boolean;
};

function Carousel({
  data,
  slides,
  showArrows,
  showDots,
  interval,
  validationMethod,
  prioritySlides = [],
  carouselSlideWidth = 1024,
  carouselSlideHeightPc,
  carouselSlideHeightTablet,
  carouselSlideHeightMobile,
  analyticsEventName,
  visibleSlidesPc,
  visibleSlidesMobile,
  textPlacementOutsideImage,
  ...restProps
}: CarouselProps): JSX.Element | null {
  const [slideHeight, setSlideHeight] = useState<number>(0);
  const { trackEvent } = useAnalytics();

  const isPcView = useMediaQuery('(min-width: 1200px)');
  const isMobileView = useMediaQuery('(max-width: 768px)');
  const tabletSlideHeight = carouselSlideHeightTablet || carouselSlideHeightPc;
  const mobileSlideHeight = carouselSlideHeightMobile || carouselSlideHeightTablet || carouselSlideHeightPc;

  let touchX: Number | null;
  let touchY: Number | null;
  const formattedAnalyticsEventName = formatAnalyticsEventName(analyticsEventName);

  const filterByServices = useFilterByServices();

  useEffect(() => {
    switch (true) {
      case isPcView:
        setSlideHeight(carouselSlideHeightPc);
        break;
      case isMobileView:
        setSlideHeight(mobileSlideHeight);
        break;
      default:
        setSlideHeight(tabletSlideHeight);
    }
  }, [isPcView, isMobileView]);

  const listOfSlides = useMemo(() => {
    const needPrioritySlides =
      prioritySlides.length > 0 && data && validationMethod && !validations[validationMethod]?.(data);
    return filterByServices(needPrioritySlides ? prioritySlides : slides);
  }, [data, slides, filterByServices, prioritySlides, validationMethod]);

  const slidesCount = listOfSlides.length;

  const dotsStyle = {
    top: isMobileView ? slideHeight - 20 : 'auto',
  };

  const buttonsStyle = {
    top: isMobileView || textPlacementOutsideImage ? slideHeight / 2 : '50%',
  };

  if (slidesCount === 0) {
    return null;
  }

  if (slidesCount === 1) {
    return <CarouselContent {...listOfSlides[0]} minImageHeight={slideHeight} isMobile={isMobileView} />;
  }

  const showSlidesNumber = (slidesToShow: number): number =>
    slidesCount === Math.floor(slidesToShow) ? slidesCount : slidesToShow;

  const visibleSlides = isMobileView ? showSlidesNumber(visibleSlidesMobile) : showSlidesNumber(visibleSlidesPc);
  const isAllSlidesFits = slidesCount <= visibleSlides;

  const trackSwipeEvent = (ev: SwipeEvent) => {
    if (ev.type === 'touchstart') {
      touchX = Math.round(ev.touches[0].clientX);
      touchY = Math.round(ev.touches[0].clientY);
    }
    if (ev.type === 'mousedown') {
      touchX = Math.round(ev.clientX);
      touchY = Math.round(ev.clientY);
    }

    if (ev.type === 'touchend' || ev.type === 'mouseup') {
      const { clientX, clientY } = ev.changedTouches ? ev.changedTouches[0] : ev;
      const xDiff = clientX - touchX;
      const yDiff = clientY - touchY;
      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        trackEvent(formattedAnalyticsEventName);
      }
      touchX = null;
      touchY = null;
    }
  };

  return (
    <div className={styles['carousel-wrapper']}>
      <CarouselProvider
        naturalSlideWidth={carouselSlideWidth}
        naturalSlideHeight={0}
        isIntrinsicHeight
        totalSlides={slidesCount}
        isPlaying={interval > 0}
        interval={interval}
        infinite
        visibleSlides={visibleSlides}
        {...restProps}
      >
        <Slider
          trayProps={{
            onTouchStart: trackSwipeEvent,
            onTouchEnd: trackSwipeEvent,
            onMouseDown: trackSwipeEvent,
            onMouseUp: trackSwipeEvent,
          }}
          data-testid="Slider"
          style={{ minHeight: slideHeight }}
        >
          {listOfSlides.map((slide, index) => (
            <Slide
              index={index}
              key={slide.name}
              className={clsx({ [styles['carousel-with-margin']]: textPlacementOutsideImage })}
            >
              <CarouselContent
                {...slide}
                minImageHeight={slideHeight}
                isMobile={isMobileView}
                textPlacementOustideImage={textPlacementOutsideImage}
              />
            </Slide>
          ))}
        </Slider>
        {showArrows && isPcView && !isAllSlidesFits && (
          <>
            <ButtonBack
              className={clsx(styles['button-back'], styles['button-control'])}
              style={buttonsStyle}
              data-testid="ButtonBack"
              data-analyticseventname={formattedAnalyticsEventName}
            >
              <FaChevronLeft color="white" />
            </ButtonBack>
            <ButtonNext
              className={clsx(styles['button-next'], styles['button-control'])}
              style={buttonsStyle}
              data-testid="ButtonNext"
              data-analyticseventname={formattedAnalyticsEventName}
            >
              <FaChevronRight color="white" />
            </ButtonNext>
          </>
        )}

        {showDots && <DotGroup className={styles['dot-group']} data-testid="DotGroup" style={dotsStyle} />}
      </CarouselProvider>
    </div>
  );
}

export default Carousel;
