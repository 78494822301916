import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

import Button from '@components/Button';
import Widget from '@components/Widget/Widget';

import styles from '@components/ResetPassword/ResetPassword.module.css';

type UpdatePasswordSuccessProps = {
  onClick: () => void;
};

const UpdatePasswordSuccess = ({ onClick }: UpdatePasswordSuccessProps) => {
  const renderAvatar = () => (
    <span className={styles['reset-check-big']}>
      <FaCheckCircle />
    </span>
  );

  return (
    <Widget avatar={renderAvatar()} title="¡EXCELENTE!" titleAlignment="center">
      <p className={styles['forget-password-description']}>
        Tu contraseña está configurada. Haga clic en el botón de abajo para iniciar sesión.
      </p>

      <div className={styles['actions']}>
        <Button variant="filled" className={styles['button']} onClick={onClick}>
          INICIAR SESIÓN
        </Button>
      </div>
    </Widget>
  );
};

export default UpdatePasswordSuccess;
