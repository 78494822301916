import React, { Fragment, useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as yup from 'yup';

import config from '@config/config';
import { useApi } from '@api';
import { FormikInputField } from '@components/FormikFields';
import { LoaderBackdrop } from '@components/Loader';
import Button from '@components/Button';
import Widget from '@components/Widget/Widget';
import InputSelect from '@components/InputSelect';
import ContentfulComponent from '@customTypes/ContentfulComponent';

import { UserByIdResponse } from '@cv/portal-idm-lib/user/user-Info/models';

type Roles = UserByIdResponse['roles'];

import {
  STATES,
  VIN_REGEX,
  INVOICE_REGEX,
  TAXID_REGEX,
  ACCOUNT_ENROLLMENT_PATH,
  PORTAL_LABEL_DICTIONARY,
} from './constants';

import AccountEnrollmentDetails from './AccountEnrollmentDetails';

import profile from '@assets/profile.png';

import styles from './AccountEnrollment.module.css';
import SuccessPage from './SuccessPage';
import {labelReducer} from "../../utils/filter-label-dictionary";
import { format } from "date-fns-tz";

export type AddVinFormValues = {
  vin: string;
  invoiceNumber: string;
  taxId: string;
  street: string;
  street2: string;
  city: string;
  state: string;
  postalCode: string;
  phoneNumber: string;
  securityQuestion?: string;
  currencyLabel?: string;
  packagePrefixLabel?: string;
  agreementDate?: string;
  agreementTime?: string;
};

type PageListData = {
  pagesList?: Array<any>;
};

type Views = 'default' | 'loading' | 'success';
const AccountEnrollment = ({ pagesList }: PageListData) => {
  const accountEnrollmentLabels = labelReducer(
    pagesList
      ?.find((item) => item.path === ACCOUNT_ENROLLMENT_PATH)
      ?.contentSections.find(({ componentType }: ContentfulComponent) => componentType === PORTAL_LABEL_DICTIONARY),
  );

  const api = useApi();
  const history = useHistory();
  const { account } = useSelector(({ accountReducer }) => accountReducer);
  const { vehicle } = useSelector(({ vehicleReducer }) => vehicleReducer);
  const [currentView, setCurrentView] = useState<Views>('default');

  // TaxID and Invoice number are required if user is not a primary subscriber
  const primarySubscriberRoleId = config.getOemValue('PRIMARY_SUBSCRIBER_ROLE_ID');
  const roles: Roles = account?.data?.roles;
  const proofOfOwnershipRequired = !roles?.find(
    (r) => r.refProperties.roleId === primarySubscriberRoleId
  )?.refProperties?.vehicleId?.includes(vehicle?.vehicleId);

  let validationSchema = yup.object().shape({
    vin: yup
      .string()
      .trim()
      .required('Debe ingresar un VIN')
      .min(17, 'Ingrese un VIN válido')
      .matches(VIN_REGEX, 'Ingrese un VIN válido'),
    street: yup
      .string()
      .trim()
      .required('Debe ingresar una dirección de casa.')
      .min(3, 'La dirección debe tener al menos 3 caracteres alfanuméricos.'),
    /*.matches(STREET_REGEX, 'Dirección inválida')*/ //street2: yup.string().trim().matches(STREET_REGEX, 'Dirección inválida'),
    city: yup
      .string()
      .trim()
      .required('Debe ingresar una ciudad de residencia.')
      .min(2, 'La ciudad debe tener al menos 2 caracteres de largo'),
    /*.matches(CITY_REGEX, 'Código de ciudad no válido.')*/ state: yup
      .string()
      .required('Debe ingresar un código de estado'),
    postalCode: yup
      .string()
      .trim()
      .required('Debe ingresar un código postal')
      .min(5, 'Ingrese un código postal válido.'),
    phoneNumber: yup.string().trim().required('Debe ingresar un número de teléfono.'),
    // .matches(PHONE_NUMBER_REGEX, "Ingrese un número de teléfono válido"),
  });

  const initialFormValues: Record<string, string> = {
    vin: vehicle?.vin || '',
    street: account?.data?.homeAddress?.street || '',
    street2: account?.data?.homeAddress?.addressLine2 || '',
    city: account?.data?.homeAddress?.city || '',
    state: account?.data?.homeAddress?.state || '',
    postalCode: account?.data?.homeAddress?.zip || '',
    phoneNumber: account?.data?.primaryPhone?.number || '',
  };

  if (proofOfOwnershipRequired) {
    validationSchema = validationSchema.concat(
      yup.object().shape({
        invoiceNumber: yup
          .string()
          .trim()
          .required('Debe ingresar un número de factura')
          .min(6, 'El folio de la factura debe contener al menos de 6 caracteres.')
          .matches(INVOICE_REGEX, 'Ingrese un número de factura válido'),
        taxId: yup
          .string()
          .trim()
          .required('Debe ingresar una identificación fiscal.')
          .min(12, 'El RFC de la factura debe contener al menos de 12 caracteres.')
          .matches(TAXID_REGEX, 'Ingrese una identificación fiscal válida'),
      })
    );

    initialFormValues.taxId = '';
    initialFormValues.invoiceNumber = '';
  }

  const redirectToVinStatusPage = () => {
    history.push('/');
  };

  const onSubmitForm = (values: AddVinFormValues) => {
    addVinNumber(values);
  };

  const addVinNumber = async (values: AddVinFormValues) => {
    try {
      setCurrentView('loading');
      values.securityQuestion = accountEnrollmentLabels?.securityQuestion;
      values.currencyLabel = accountEnrollmentLabels?.currencyLabel;
      values.packagePrefixLabel = accountEnrollmentLabels?.packagePrefixLabel;
      values.agreementDate = format(new Date(), 'MM/dd/yyyy', { timeZone: 'America/Chicago' });
      values.agreementTime = format(new Date(), 'hh:mm a z', { timeZone: 'America/Chicago' });
      await api.addVin(values);
      setCurrentView('success');
    } catch (e) {
      // show default modal with error message
      setCurrentView('default');
    }
  };

  const renderVinNumber = () =>
    vehicle?.vin ? <span className={styles['Vin-number']}>{`VIN: #${vehicle?.vin}`}</span> : null;

  if (currentView === 'success') {
    return <SuccessPage labels={accountEnrollmentLabels} />;
  }

  return (
    <Fragment>
      {currentView === 'loading' && <LoaderBackdrop />}
      <Widget
        avatar={<img src={profile} />}
        title={vehicle?.vin ? accountEnrollmentLabels.title : 'INSCRIPCIÓN'}
        titleAlignment="flex-start"
        title2={renderVinNumber()}
        subTitle={accountEnrollmentLabels.description}
      >
        <AccountEnrollmentDetails vehicle={vehicle} account={account} />

        <Formik
          initialValues={initialFormValues}
          onSubmit={onSubmitForm}
          validationSchema={validationSchema}
        >
          {(props: FormikProps<AddVinFormValues>) => (
            <Form onSubmit={props.handleSubmit} className={styles['Form']}>
              {!vehicle?.vin && <FormikInputField label="VIN" name="vin" maxLength={17} />}
              {proofOfOwnershipRequired && (
                <>
                  <FormikInputField label="Número de factura" name="invoiceNumber" />
                  <FormikInputField label="Identificación fiscal" name="taxId" />
                </>
              )}
              {!account?.data?.homeAddress?.street && <FormikInputField label="Calle" name="street" />}
              {!account?.data?.homeAddress && <FormikInputField label="Número Interior" name="street2" />}
              {!account?.data?.homeAddress?.city && <FormikInputField label="Ciudad" name="city" />}
              {!account?.data?.homeAddress?.state && (
                <div className={styles['State']}>
                  <FormikInputField
                    name="state"
                    label="Código del estado"
                    InputComponent={InputSelect}
                    onChange={() => {}}
                    options={STATES}
                    styleType="secondary"
                  />
                </div>
              )}
              {!account?.data?.homeAddress?.zip && (
                <div className={styles['PostalCode']}>
                  <FormikInputField label="Código postal" name="postalCode" />
                </div>
              )}
              {!account?.data?.primaryPhone?.number && (
                <FormikInputField label="Número del teléfono" name="phoneNumber" />
              )}
              <div className={styles['Actions']}>
                <Button variant="outlined" className={styles['Button']} onClick={redirectToVinStatusPage}>
                  CANCELAR
                </Button>
                <Button
                  variant="filled"
                  type="submit"
                  className={styles['Button']}
                >
                  ENVIAR
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Widget>
    </Fragment>
  );
};

export default AccountEnrollment;
