import React from 'react';
import clsx from 'clsx';

import { getOemName } from '@utils/oem';
import HeaderLogo from './HeaderLogo';
import { ContentfulFile } from '@app/types/ContentfulComponent';
import styles from './Header.module.css';
import { IVehicle } from '@redux/actions';
import { useApi } from '@api';

export type HeaderOemBrandLogoType = {
  countries?: Array<string>;
  makes?: Array<string>;
  tags?: Array<string>;
  image: ContentfulFile;
  url?: string;
  openLinkInNewTab?: boolean;
};

type HeaderOemBrandLogoProps = {
  content: Array<HeaderOemBrandLogoType>;
  pagesToHideTcLinkInUserDropDown?: string[];
  className?: string;
  vehicleData?: IVehicle;
};

const oemName = getOemName();

const listAllowsValue = (allowed: string[], value: string) => {
  if (!allowed.length) {
    // empty list allows all values
    return true;
  }
  return allowed.map((member) => member.toLowerCase()).includes(value.toLowerCase());
};

function HeaderOemBrandLogo({
  content = [],
  pagesToHideTcLinkInUserDropDown,
  className = '',
  vehicleData,
}: HeaderOemBrandLogoProps): JSX.Element | null {
  const api = useApi();
  const vehicle = vehicleData || api.storeService.getVehicleData() || {};
  const { registrationCountry, make: selectedMake } = vehicle || {};

  const logos = React.useMemo(() => {
    if (!selectedMake || !registrationCountry || content.length === 0) {
      return [];
    }

    return content.filter(
      ({ countries = [], makes = [], tags = [] }) =>
        listAllowsValue(tags, oemName) &&
        listAllowsValue(makes, selectedMake) &&
        listAllowsValue(countries, registrationCountry),
    );
  }, [selectedMake, registrationCountry, content]);

  if (logos.length === 0) {
    return (
      <div className={clsx(styles['Header-oem-brand-logo'], styles['Header-oem-brand-logo-no-padding'], className)} />
    );
  }

  const [{ image, url, openLinkInNewTab }] = logos;

  return (
    <div className={clsx(styles['Header-oem-brand-logo'], className)}>
      <HeaderLogo
        logo={image}
        url={url}
        pagesToHideTcLinkInUserDropDown={pagesToHideTcLinkInUserDropDown}
        openLinkInNewTab={openLinkInNewTab}
      />
    </div>
  );
}

export default HeaderOemBrandLogo;
