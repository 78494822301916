import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { SportsItem } from '@cv/portal-rts-lib/v0/information/sports';
import ContentLayout from '../ContentLayout';
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
import { Option, Team, MISLabels, FolderPayload, SPORTS, SportsTeams } from '../types';
import { useApi } from '@api';
import ErrorModal from '@components/MobileInformationService/ErrorModal';
import Loader from '@components/Loader';
import { chopTillSpecialChar } from "@components/MobileInformationService/utils";

export type Props = {
  labels: MISLabels;
  sportsTeams: SportsTeams;
  folders?: FolderPayload[];
};

const Sports = ({ labels, sportsTeams, folders = [] }: Props) => {
  const { sportsIntro, saveSchedules, saveScores, schedulesName, scoresName, yourSchedules, yourScores, errorApiText } =
    labels;
  const sportTypesList: Option[] = useMemo(
    () =>
      sportsTeams.misSportGameType.map((sportType: { name: string }) => ({
        label: sportType.name.replace(/_/g, ' '),
        value: sportType.name.toLowerCase(),
      })),
    [sportsTeams?.misSportGameType],
  );
  const updateTypeList: Option[] = [
    { label: schedulesName, value: schedulesName.toLowerCase() },
    { label: scoresName, value: scoresName.toLowerCase() },
  ];
  const [selectedSportType, setSelectedSportType] = useState<string>(sportTypesList[0].value);
  const [selectedUpdateType, setSelectedUpdateType] = useState<string>(updateTypeList[0].value);
  const [selectedScoreTeams, setSelectedScoreTeams] = useState<Team[]>([]);
  const [selectedScheduleTeams, setSelectedScheduleTeams] = useState<Team[]>([]);
  const [savedScoreTeams, setSavedScoreTeams] = useState<Team[]>([]);
  const [savedScheduleTeams, setSavedScheduleTeams] = useState<Team[]>([]);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isSelectedSchedules = selectedUpdateType === 'schedules';
  const api = useApi();
  const schedulesFolder = useMemo(
    () =>
      folders.find(
        (item) =>
          item.folderName.replace(/(.+)(schedules|scores)/gi, '$2').toLowerCase() === schedulesName.toLowerCase(),
      ),
    [folders, schedulesName],
  );
  const scoresFolder = useMemo(
    () =>
      folders.find(
        (item) => item.folderName.replace(/(.+)(schedules|scores)/gi, '$2').toLowerCase() === scoresName.toLowerCase(),
      ),
    [folders, scoresName],
  );
  const currentFolder = isSelectedSchedules ? schedulesFolder : scoresFolder;

  const fetchScores = useCallback(async () => {
    setIsLoading(true);
    try {
      if (scoresFolder?.folderId) {
        const response = await api.getSports(scoresFolder.folderId);
        const mappedData = response.data?.map((team) => ({
          key: chopTillSpecialChar(team.name),
          value: team.name || '',
            channelId: team.channelId,
        })) || [];
        setSelectedScoreTeams(mappedData);
        setSavedScoreTeams(mappedData);
      }
    } catch (e) {
      setShowErrorModal(true);
    }
    setIsLoading(false);
  }, [api, scoresFolder?.folderId]);

  const fetchSchedules = useCallback(async () => {
    setIsLoading(true);
    try {
      if (schedulesFolder?.folderId) {
        const response = await api.getSports(schedulesFolder.folderId);
        const mappedData: Team[] = response.data?.map((team) => ({
          key:  chopTillSpecialChar(team.name),
          value: team.name || '',
            channelId: team.channelId,
        })) || [];
        setSelectedScheduleTeams(mappedData);
        setSavedScheduleTeams(mappedData);
      }
    } catch (e) {
      setShowErrorModal(true);
    }
    setIsLoading(false);
  }, [api, schedulesFolder?.folderId]);

  const fetchCurrentSport = async () => {
    if (isSelectedSchedules) {
      await fetchSchedules();
    } else {
      await fetchScores();
    }
  };

  useEffect(() => {
    if (folders.length) {
      fetchScores();
      fetchSchedules();
    }
  }, [folders.length, fetchScores, fetchSchedules]);

  const teamsList = useMemo(() => {
    const data = sportsTeams.misSportGameType.find(
      (sportType: { name: string }) => sportType.name.toLowerCase() === selectedSportType,
    );
    return data?.misSportTeams?.misSportTeam || [];
  }, [selectedSportType, sportsTeams.misSportGameType]);

  const handleChangeUpdateType = (option: Option) => {
    setSelectedUpdateType(option.value);
  };
  const handleChangeSportType = (option: Option) => {
    setSelectedSportType(option.value);
  };

  const handleAddSelectedTeam = (team: Team) => {
    const teamWithSportType = {...team, value: `${selectedSportType.toUpperCase()}|${team.value}`};
    if (isSelectedSchedules) {
      setSelectedScheduleTeams((prevState) => [...prevState, teamWithSportType]);
    } else {
      setSelectedScoreTeams((prevState) => [...prevState, teamWithSportType]);
    }
  };

  const handleRemoveSelectedTeam = (team: Team) => {
    const newArr = [...(isSelectedSchedules ? selectedScheduleTeams : selectedScoreTeams)];
    const idx = newArr.findIndex((item) => item.key === team.key);
    newArr.splice(idx, 1);
    if (isSelectedSchedules) {
      setSelectedScheduleTeams(newArr);
    } else {
      setSelectedScoreTeams(newArr);
    }
  };

  const handleRemoveSaved = async (team: Team) => {
    if (!currentFolder?.folderId) {
      setShowErrorModal(true);
      return;
    }
    setIsLoading(true);
    try {
      await api.deleteSports({ folderId: currentFolder.folderId, channelId: team.channelId || '' });
      await fetchScores();
      await fetchSchedules();
    } catch (e) {
      setShowErrorModal(true);
    }
    setIsLoading(false);
  };

  const removeAllSaved = async () => {
    if (!currentFolder?.folderId) {
      setShowErrorModal(true);
      return;
    }
    try {
      const newArr = [...(isSelectedSchedules ? savedScheduleTeams : savedScoreTeams)];
      const promises: unknown[] = [];
      newArr.forEach((team) => {
        promises.push(api.deleteSports({ folderId: currentFolder.folderId, channelId: team.channelId || '' }));
      });
      await Promise.all(promises);
      await fetchCurrentSport();
    } catch (e) {
      setShowErrorModal(true);
    }
    setIsLoading(false);
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const selectedTeams = isSelectedSchedules ? selectedScheduleTeams : selectedScoreTeams;
      // This is workaround because of backend doesn't accept
      // empty array to remove all teams
      if (!selectedTeams.length) {
        removeAllSaved();
        return;
      }
      const { SPORTS_SCHEDULES, SPORTS_SCORES, SCHEDULES, SCORES } = SPORTS;
      const data: SportsItem[] = selectedTeams.map((team) => ({
        folderId: currentFolder?.folderId || '',
        folderName: currentFolder?.folderName || '',
        channelContentType: isSelectedSchedules ? SPORTS_SCHEDULES : SPORTS_SCORES,
        category: isSelectedSchedules ? SCHEDULES : SCORES,
        teamName: team.value,
      }));

      await api.addSports(data);
      await fetchCurrentSport();
    } catch (e) {
      setShowErrorModal(true);
    }
    setIsLoading(false);
  };

  if (isLoading || !folders.length) {
    return <Loader style={{ position: 'absolute' }} />;
  }

  return (
    <>
      <ErrorModal
        showErrorModal={showErrorModal}
        handleCloseErrorModal={() => setShowErrorModal(false)}
        title="Error"
        description={errorApiText}
      />
      <ContentLayout
        left={
          <LeftColumn
            onSave={handleSave}
            saveButtonText={isSelectedSchedules ? saveSchedules : saveScores}
            onAddTeam={handleAddSelectedTeam}
            onRemoveTeam={handleRemoveSelectedTeam}
            teamsList={teamsList}
            selectedSportType={selectedSportType}
            selectedUpdateType={selectedUpdateType}
            sportsIntroText={sportsIntro}
            sportTypesList={sportTypesList}
            updateTypeList={updateTypeList}
            handleChangeSportType={handleChangeSportType}
            handleChangeUpdateType={handleChangeUpdateType}
            selectedTeams={isSelectedSchedules ? selectedScheduleTeams : selectedScoreTeams}
            savedTeams={isSelectedSchedules ? savedScheduleTeams : savedScoreTeams}
          />
        }
        right={
          <RightColumn
            onRemoveTeam={handleRemoveSaved}
            savedScoreTeams={savedScoreTeams}
            savedScheduleTeams={savedScheduleTeams}
            yourSchedulesText={yourSchedules}
            yourScoresText={yourScores}
          />
        }
      />
    </>
  );
};

export default Sports;
