import React, { ReactNode } from 'react';
import clsx from 'clsx';
import Button from '@components/Button';

import styles from './MobileInformationService.module.css';

export type Props = {
  className?: string;
  onClick: () => void;
  isSelected: boolean;
  htmlTitle?: string;
  disabled?: boolean;
  children: ReactNode;
};

const TextTile = ({ className = '', onClick, isSelected, htmlTitle = '', disabled = false, children }: Props) => (
  <Button
    className={clsx(styles['mis-text-tile'], !isSelected && styles['mis-text-tile-unselected'], className)}
    onClick={onClick}
    title={`Click and ${isSelected ? 'unselect' : 'select'} ${htmlTitle}`}
    disabled={disabled}
  >
    {children}
  </Button>
);

export default TextTile;
