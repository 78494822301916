// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1zQSRriyNjfojzufXItuSN {\n  width: 100px;\n}\n\n._3xSjw30o1yVs5xUXXTkHoB {\n  display: block;\n  cursor: pointer;\n  height: 100%;\n  width: 100%;\n  max-width: 200px;\n  min-width: 100px;\n}\n\n._3xSjw30o1yVs5xUXXTkHoB > img {\n  width: 100%;\n  height: 100%;\n}\n\n._1_Qx7AZ04vNsyJfm1IXsge {\n  width: 100%;\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AppLink/AppLink.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;EACd,eAAe;EACf,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".wrapper {\n  width: 100px;\n}\n\n.url {\n  display: block;\n  cursor: pointer;\n  height: 100%;\n  width: 100%;\n  max-width: 200px;\n  min-width: 100px;\n}\n\n.url > img {\n  width: 100%;\n  height: 100%;\n}\n\n.theme-button-size {\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_1zQSRriyNjfojzufXItuSN",
	"url": "_3xSjw30o1yVs5xUXXTkHoB",
	"theme-button-size": "_1_Qx7AZ04vNsyJfm1IXsge"
};
export default ___CSS_LOADER_EXPORT___;
