import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import { Page } from '@customTypes/Navigation';
import useComputePaths from '@hooks/useComputePaths';

const useRequestBlocker = (pages: Page[]) => {
  const computePaths = useComputePaths(pages);

  const isGuestPage = useCallback(() => {
    const { currentPath } = computePaths();
    return !currentPath.authorised;
  }, [computePaths]);

  return useCallback((request) => (hasAuthToken(request) && isGuestPage() ? cancel(request) : request), [isGuestPage]);
};

export const hasAuthToken = (request: AxiosRequestConfig<any>) => {
  const authHeader = String(request.headers?.Authorization || '');
  return !!authHeader && !authHeader.includes('undefined') && !!authHeader.replace('Bearer', '').trim();
};

function cancel(config: AxiosRequestConfig<any>) {
  const request = new AbortController();
  request.abort();

  return { ...config, signal: request.signal };
}

export default useRequestBlocker;
