// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2mMS9Z18gJWwek4Jc_hJQZ {\n  display: inline-flex;\n  cursor: pointer;\n}\n\n._1DTnPg05ZRdC0Gm_Et2HRD {\n  flex-direction: column;\n  justify-content: center;\n  text-align: center;\n}\n\n._2O_YKkr4Yay1XpeO1cJ7mH {\n  flex-direction: row;\n  align-items: center;\n  vertical-align: middle;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ControlLabel/ControlLabel.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,sBAAsB;AACxB","sourcesContent":[".container {\n  display: inline-flex;\n  cursor: pointer;\n}\n\n.column {\n  flex-direction: column;\n  justify-content: center;\n  text-align: center;\n}\n\n.row {\n  flex-direction: row;\n  align-items: center;\n  vertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_2mMS9Z18gJWwek4Jc_hJQZ",
	"column": "_1DTnPg05ZRdC0Gm_Et2HRD",
	"row": "_2O_YKkr4Yay1XpeO1cJ7mH"
};
export default ___CSS_LOADER_EXPORT___;
