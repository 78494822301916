import React from "react";

import ContentRenderer from "@components/ContentRenderer";

import styles from "./Header.module.css";

type HeaderMenuBottomNavProps = {
  navigation: object;
};

export default function HeaderMenuBottomNav({ navigation }: HeaderMenuBottomNavProps) {

  return (
    <div className={styles["Header-mobile-bottom-wrapper"]}>
      <ContentRenderer name="mobileMenuBottomNavigation" className={styles["Header-mobile-bottom-nav"]} content={[navigation]} />
    </div>
  );
}
