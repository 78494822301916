import React from 'react';
import clsx from 'clsx';

import { ContentfulFile } from '@app/types/ContentfulComponent';

import styles from './AppLink.module.css';
import { formatAnalyticsEventName } from '@cv/portal-common-lib';
import Button from '@components/Button';

interface Props {
  name: string;
  image: ContentfulFile;
  treatAsButton?: boolean;
  url: string;
  newTab?: boolean;
  analyticsEventName?: string;
  linkText?: string;
}

const AppLink = ({ name, image, treatAsButton, url, newTab, analyticsEventName, linkText }: Props): JSX.Element => {
  const target = newTab ? '_blank' : undefined;

  return (
    <a
      className={styles.url}
      href={url}
      target={target}
      rel="noreferrer"
      data-analyticseventname={formatAnalyticsEventName(analyticsEventName)}
    >
      {treatAsButton ? (
        <Button className={styles['theme-button-size']}>{linkText}</Button>
      ) : (
        <img src={image?.file?.url} alt={name} />
      )}
    </a>
  );
};

export default AppLink;
